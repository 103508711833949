import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Booking } from 'src/app/core/booking.service';
import { Event, EventsService } from 'src/app/core/events.service';
import { FormService } from 'src/app/core/form.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { VEHICLES_DETAILS_PATH } from 'src/app/core/navigation.service';
import { Station } from 'src/app/core/station.service';
import {
	IT_LOCALE,
	EN_LOCALE,
	BOOKING_EVENTS_WHITELIST,
	LOCAL_STATION_KEY,
	DATE_FORMATS,
} from 'src/app/shared/constants';
import {
	CREATED_EVENT_LABEL,
	LOCK_FAILED_EVENT_LABEL,
	LOCK_KEY_SENT_EVENT_LABEL,
	LOCK_KEY_VISITED_EVENT_LABEL,
	LOCK_SUCCEEDED_EVENT_LABEL,
	UNLOCK_FAILED_EVENT_LABEL,
	UNLOCK_KEY_SENT_EVENT_LABEL,
	UNLOCK_KEY_VISITED_EVENT_LABEL,
	UNLOCK_SUCCEEDED_EVENT_LABEL,
	VEHICLE_ASSIGNED_EVENT_LABEL,
} from 'src/app/shared/translations';

@Component({
	selector: 'app-booking-details-events',
	templateUrl: './booking-details-events.component.html',
	styleUrls: ['./booking-details-events.component.scss'],
})
export class BookingDetailsEventsComponent implements OnInit {
	@Input() booking: Booking;
	events?: Event[];
	current_locale: string;
	is_events_request_running: boolean = true;
	form_group: FormGroup = this.form_service.bookingEventsFilterFormGroup;
	vehicle_details_path: string = VEHICLES_DETAILS_PATH;

	constructor(
		private events_service: EventsService,
		private form_service: FormService,
		private local_manager: LocalManagerService,
	) {
		const current_station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		switch (current_station.settings.date_format) {
			case DATE_FORMATS[0]:
				this.current_locale = EN_LOCALE;
				break;
			case DATE_FORMATS[1]:
				this.current_locale = IT_LOCALE;
				break;
			default:
				this.current_locale = EN_LOCALE;
				break;
		}
	}

	ngOnInit(): void {
		this.listEvents();
	}

	onApplyFiltersClick(): void {
		const created_from: number = new Date(
			this.form_group.get('created_from')?.value,
		).getTime();
		const created_to: number = new Date(
			this.form_group.get('created_to')?.value,
		).getTime();
		this.listEvents(created_from, created_to);
	}

	onResetFiltersClick(): void {
		this.form_group.reset();
		this.listEvents();
	}

	listEvents(created_from?: number, created_to?: number): void {
		this.is_events_request_running = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.events_service
			.listEventsOfBooking(
				station.id,
				this.booking.id,
				created_from,
				created_to,
			)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						const events: Event[] = response;
						this.events = this.getCronologicalEvents(events).reverse();
						this.events = this.events.filter(e => {
							return BOOKING_EVENTS_WHITELIST.includes(e.type);
						});
						this.is_events_request_running = false;
					});
				},
				error: () => {
					this.events = [];
					this.is_events_request_running = false;
				},
			});
	}

	getCronologicalEvents(events: Event[]): Event[] {
		return events.sort((a, b) => {
			return b.created_at - a.created_at;
		});
	}

	getEventLabel(event: Event): string {
		switch (event.type) {
			case BOOKING_EVENTS_WHITELIST[0]:
				return CREATED_EVENT_LABEL;
			case BOOKING_EVENTS_WHITELIST[1]:
				return VEHICLE_ASSIGNED_EVENT_LABEL;
			case BOOKING_EVENTS_WHITELIST[2]:
				return UNLOCK_KEY_SENT_EVENT_LABEL;
			case BOOKING_EVENTS_WHITELIST[3]:
				return UNLOCK_KEY_VISITED_EVENT_LABEL;
			case BOOKING_EVENTS_WHITELIST[4]:
				return LOCK_KEY_SENT_EVENT_LABEL;
			case BOOKING_EVENTS_WHITELIST[5]:
				return LOCK_KEY_VISITED_EVENT_LABEL;
			case BOOKING_EVENTS_WHITELIST[6]:
				return UNLOCK_SUCCEEDED_EVENT_LABEL;
			case BOOKING_EVENTS_WHITELIST[7]:
				return UNLOCK_FAILED_EVENT_LABEL;
			case BOOKING_EVENTS_WHITELIST[8]:
				return LOCK_SUCCEEDED_EVENT_LABEL;
			case BOOKING_EVENTS_WHITELIST[9]:
				return LOCK_FAILED_EVENT_LABEL;
		}
	}

	isEventClickable(event: Event): boolean {
		const clickable_events: string[] = [
			'booking.vehicle_assigned',
			'unlock_command.succeeded',
			'unlock_command.failed',
			'lock_command.succeeded',
			'lock_command.failed',
		];
		return (
			clickable_events.includes(event.type) &&
			event.data &&
			event.data.vehicle_id
		);
	}

	getEventPrefix(event: Event): string {
		switch (event.type) {
			case 'booking.vehicle_assigned':
				return event.data.vehicle_plate;
			case 'unlock_command.succeeded':
				return 'BOOKING_DETAILS.VEHICLE_PREFIX_LABEL';
			case 'unlock_command.failed':
				return 'BOOKING_DETAILS.VEHICLE_PREFIX_ART_LABEL';
			case 'lock_command.succeeded':
				return 'BOOKING_DETAILS.VEHICLE_PREFIX_LABEL';
			case 'lock_command.failed':
				return 'BOOKING_DETAILS.VEHICLE_PREFIX_ART_LABEL';
			default:
				return '';
		}
	}
}
