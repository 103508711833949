<div class="lk-height__max-80vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x24-horiz lk-margin__x24-bottom">
		<p>
			Enter the email you want to reset the MFS config to and the phone number
			you want to associate.
		</p>

		<form
			[formGroup]="form_group"
			class="lk-row__space lk-wrap lk-margin__x24-top">
			<mat-form-field
				appearance="fill"
				class="lk-width__full lk-margin__x12-bottom lk-form-field">
				<mat-label> Email </mat-label>
				<input
					type="text"
					required
					matInput
					formControlName="email" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				floatLabel="always"
				class="lk-third-width lk-margin__x12-bottom lk-form-field">
				<mat-label>
					{{ 'GENERAL.PHONE_PREFIX_LABEL' | translate | titlecase }}
				</mat-label>

				<img
					*ngIf="current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-margin__x12-left lk-margin__x12-top"
					[src]="current_country_prefix.flag" />

				<img
					*ngIf="!current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-margin__x12-left lk-margin__x12-top"
					[src]="unknown_flag_src" />

				<input
					type="text"
					matInput
					formControlName="phone_prefix"
					required
					[matAutocomplete]="autocountry" />

				<mat-autocomplete
					autoActiveFirstOption
					#autocountry="matAutocomplete"
					(optionSelected)="onCountryPrefixSelect()">
					<mat-option
						*ngFor="let country_prefix of filtered_options | async"
						[value]="country_prefix.code">
						<div class="lk-row">
							<img
								style="min-width: 32px; height: 24px"
								class="lk-margin__x12-right"
								[src]="country_prefix.flag" />
							<div class="lk-column">
								<p
									style="max-width: calc(100% - 6px); line-height: 18px"
									class="lk-text__10 lk-primary-medium-color lk-text__ellipsized">
									{{ country_prefix.name }}
								</p>
								<p
									style="line-height: 18px"
									class="lk-text__ellipsized">
									{{ country_prefix.prefix }}
								</p>
							</div>
						</div>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-three-third-width lk-margin__x12-bottom lk-form-field">
				<mat-label>
					{{ 'GENERAL.PHONE_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					matInput
					type="tel"
					formControlName="phone"
					required />
			</mat-form-field>

			<app-button
				class="lk-width__full lk-margin__x24-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.CONFIRM_CTA' | translate"
				[is_disabled]="is_mfa_setup_running"
				[is_spinner_visible]="is_mfa_setup_running"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>
