import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';

@Component({
	selector: 'app-vehicle-details-commands-form',
	templateUrl: './vehicle-details-commands-form.component.html',
	styleUrl: './vehicle-details-commands-form.component.scss',
})
export class VehicleDetailsCommandsFormComponent {
	@Output() reset: EventEmitter<void> = new EventEmitter<void>();
	@Output() apply: EventEmitter<{ from: number; to: number }> =
		new EventEmitter<{ from: number; to: number }>();

	form_group: FormGroup = this.form_service.vehicleCommandsFilterFormGroup;

	constructor(private form_service: FormService) {}

	ngOnInit(): void {
		this.form_group.reset();
	}

	onReset(): void {
		this.form_group.reset();
		this.reset.emit();
	}

	onApply(): void {
		const start_date: Date = new Date(
			this.form_group.get('created_from')?.value,
		);
		const end_date: Date = new Date(this.form_group.get('created_to')?.value);
		end_date.setHours(23);
		end_date.setMinutes(59);

		const from: number = start_date.getTime();
		const to: number = end_date.getTime();

		this.apply.emit({ from, to });
	}
}
