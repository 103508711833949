<div
	class="lk-height__full lk-border-radius lk-shadow lk-padding__x24 lk-primary-background">
	<h3 class="lk-text__24">
		{{ 'VEHICLE_DETAILS.MEASURES_SECTION_LABEL' | translate }}
	</h3>

	<div class="lk-width__full lk-row__space lk-wrap lk-margin__x24-top">
		<app-vehicle-details-measures-item
			class="lk-width__half-x8 lk-width__full-md lk-margin__x16-bottom"
			[current_locale]="current_locale"
			[label]="'VEHICLE.ODOMETER_COLUMN_LABEL'"
			[value]="getOdometerValue(vehicle)"
			[um]="odometer_um"
			[updated_at]="
				vehicle?.stats?.distance_covered?.updated_at
			"></app-vehicle-details-measures-item>

		<app-vehicle-details-measures-item
			class="lk-width__half-x8 lk-width__full-md lk-margin__x16-bottom"
			[current_locale]="current_locale"
			[label]="'VEHICLE.FUEL_COLUMN_LABEL'"
			[value]="vehicle?.stats?.autonomy_percentage?.value"
			[um]="'%'"
			[updated_at]="
				vehicle?.stats?.autonomy_percentage?.updated_at
			"></app-vehicle-details-measures-item>

		<app-vehicle-details-measures-item
			class="lk-width__half-x8 lk-width__full-md lk-margin__x16-bottom"
			[current_locale]="current_locale"
			[label]="'VEHICLE.BATTERY_COLUMN_LABEL'"
			[value]="vehicle?.stats?.external_voltage?.value"
			[um]="'V'"
			[updated_at]="
				vehicle?.stats?.external_voltage?.updated_at
			"></app-vehicle-details-measures-item>
	</div>
</div>
