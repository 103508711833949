<div class="lk-row">
	<span class="lk-display__hidden-before-md"> {{ username }} </span>

	<button
		class="lk-display__hidden-after-md"
		(click)="onMenuClick()"
		mat-icon-button>
		<mat-icon> menu </mat-icon>
	</button>

	<button
		class="lk-display__hidden-before-md"
		mat-icon-button
		[matMenuTriggerFor]="menu"
		#menuTrigger="matMenuTrigger">
		<mat-icon> expand_more </mat-icon>
	</button>

	<mat-menu #menu="matMenu">
		<button
			mat-menu-item
			(click)="onProfileClick()">
			<mat-icon class="lk-icon__neutral-40"> person </mat-icon>
			<p>{{ 'PROFILE.TITLE' | translate }}</p>
		</button>
		<button
			mat-menu-item
			(click)="onSignoutClick()">
			<mat-icon class="lk-icon__neutral-40"> logout </mat-icon>
			<p>Logout</p>
		</button>
	</mat-menu>
</div>
