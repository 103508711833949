import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	Vehicle,
	VehicleOdometerUms,
	VehicleStatus,
} from '../../core/vehicle.service';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../core/form.service';
import {
	KM_TO_MILES_COEFF,
	LOCAL_STATION_KEY,
	VEHICLE_TABLE_COLUMNS,
	VEHICLE_TABLE_TURO_COLUMNS,
	DATE_FORMATS,
	EN_LOCALE,
	IT_LOCALE,
	ADAPTER_CRED_NOT_FOUND_ERROR_CODE,
	ADAPTER_FAILED_ERROR_CODE,
	ADAPTER_OFFLINE_ERROR_CODE,
} from 'src/app/shared/constants';
import { Station } from 'src/app/core/station.service';
import { VehicleDirectLinkDialogComponent } from '../vehicle-direct-link-dialog/vehicle-direct-link-dialog.component';
import { DialogService } from 'src/app/core/dialog.service';
import { VehicleDirectLinkConfirmDialogComponent } from '../vehicle-direct-link-confirm-dialog/vehicle-direct-link-confirm-dialog.component';
import { NavigationService } from 'src/app/core/navigation.service';
import {
	UnlockCommand,
	UnlockCommandsService,
	UnlockCommandStatus,
} from 'src/app/core/unlock-commands.service';
import { ErrorService } from 'src/app/core/error.service';
import {
	ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
	ADAPTER_FAILED_ERROR_MESSAGE,
	ADAPTER_GENERIC_ERROR_MESSAGE,
	ADAPTER_OFFLINE_ERROR_MESSAGE,
	GENERAL_ERROR_MESSAGE,
} from 'src/app/shared/translations';
import { SharedInteractionErrorDialogComponent } from 'src/app/shared/shared-interaction-error-dialog/shared-interaction-error-dialog.component';
import {
	LockCommand,
	LockCommandsService,
	LockCommandStatus,
} from 'src/app/core/lock-commands.service';

@Component({
	selector: 'app-vehicle-table',
	templateUrl: './vehicle-table.component.html',
	styleUrls: ['./vehicle-table.component.scss'],
})
export class VehicleTableComponent implements OnInit {
	@Input() vehicles: Vehicle[];

	@Output() filters_change: EventEmitter<void> = new EventEmitter<void>();
	@Output() search_change: EventEmitter<string> = new EventEmitter<string>();
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();

	form_group: FormGroup;
	update_time: number;
	odometer_um?: string;
	current_locale: string;
	displayed_columns: string[] = VEHICLE_TABLE_TURO_COLUMNS;

	is_command_running = false;

	constructor(
		private form_service: FormService,
		private dialog_service: DialogService,
		private navigation_service: NavigationService,
		private unlock_commands_service: UnlockCommandsService,
		private lock_commands_service: LockCommandsService,
		private error_service: ErrorService,
	) {
		this.form_group = this.form_service.vehicleSearchFormGroup;

		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		switch (station.settings.date_format) {
			case DATE_FORMATS[0]:
				this.current_locale = EN_LOCALE;
				break;
			case DATE_FORMATS[1]:
				this.current_locale = IT_LOCALE;
				break;
			default:
				this.current_locale = EN_LOCALE;
				break;
		}
	}

	ngOnInit() {
		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		this.setStation(station, this.vehicles);
	}

	onVehicleClick(vehicle: Vehicle) {
		this.navigation_service.toVehiclesDetails(vehicle.id);
	}

	onStartClick(vehicle: Vehicle): void {
		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		this.createUnlockCommand(station, vehicle);
	}

	onStopClick(vehicle: Vehicle): void {
		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		this.createLockCommand(station, vehicle);
	}

	onDirectLinkClick(vehicle: Vehicle): void {
		this.dialog_service
			.openDialog(VehicleDirectLinkDialogComponent, {
				vehicle,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result?.success) {
					this.dialog_service.openDialog(
						VehicleDirectLinkConfirmDialogComponent,
						{
							link: dialog_result.short_link,
							is_link_sent: dialog_result.is_sent,
						},
					);
				}

				if (!dialog_result?.success && dialog_result?.error_message) {
					this.dialog_service.openInfoDialog(
						dialog_result?.error_message || '',
					);
				}
			});
	}

	setStation(station: Station, vehicles: Vehicle[]): void {
		if (station.ext_email) {
			this.displayed_columns = VEHICLE_TABLE_TURO_COLUMNS;
		} else {
			this.displayed_columns = VEHICLE_TABLE_COLUMNS;
		}

		this.odometer_um = station.settings?.odometer_um || VehicleOdometerUms.mi;
		this.update_time = new Date().getTime();
		this.vehicles = vehicles;
	}

	createUnlockCommand(station: Station, vehicle: Vehicle): void {
		this.is_command_running = true;
		this.unlock_commands_service.create(station.id, vehicle.id).subscribe({
			next: response => {
				response.body.json().then(response => {
					const command: UnlockCommand = response as UnlockCommand;
					this.readUnlockCommand(station, command.id, vehicle.id);
				});
			},
			error: error => {
				this.is_command_running = false;

				const converted_error = this.error_service.convertError(error);

				if (converted_error.code === ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.dialog_service.openInfoDialog(
						ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
					);
				}

				if (converted_error.code != ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.error_service.processError(converted_error);
				}
			},
		});
	}

	createLockCommand(station: Station, vehicle: Vehicle): void {
		this.is_command_running = true;
		this.lock_commands_service.create(station.id, vehicle.id).subscribe({
			next: response => {
				response.body.json().then(response => {
					const command: LockCommand = response as LockCommand;
					this.readLockCommand(station, command.id, vehicle.id);
				});
			},
			error: error => {
				this.is_command_running = false;

				const converted_error = this.error_service.convertError(error);

				if (converted_error.code === ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.dialog_service.openInfoDialog(
						ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
					);
				}

				if (converted_error.code != ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.error_service.processError(converted_error);
				}
			},
		});
	}

	readUnlockCommand(
		station: Station,
		command_id: string,
		vehicle_id: string,
	): void {
		this.is_command_running = true;
		this.unlock_commands_service
			.read(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						const command: UnlockCommand = response as UnlockCommand;

						if (
							command.status === UnlockCommandStatus.PENDING &&
							this.unlock_commands_service.current_read_index <=
								this.unlock_commands_service.max_read_retry
						) {
							this.unlock_commands_service.current_read_index++;
							setTimeout(() => {
								this.readUnlockCommand(station, command_id, vehicle_id);
							}, 5000);
						}

						if (command.status == UnlockCommandStatus.SUCCEEDED) {
							this.unlock_commands_service.current_read_index = 0;
							this.refresh.emit();
							this.is_command_running = false;
						}

						if (command.status == UnlockCommandStatus.FAILED) {
							this.unlock_commands_service.current_read_index = 0;

							const command_error = command.error.code
								? command.error.code
								: command.error;
							this.manageCommandError(command_error as string);

							this.is_command_running = false;
						}
					});
				},
				error: () => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_command_running = false;
				},
			});
	}

	readLockCommand(
		station: Station,
		command_id: string,
		vehicle_id: string,
	): void {
		this.is_command_running = true;
		this.lock_commands_service
			.read(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						const command: LockCommand = response as LockCommand;

						if (
							command.status === LockCommandStatus.PENDING &&
							this.lock_commands_service.current_read_index <=
								this.lock_commands_service.max_read_retry
						) {
							this.lock_commands_service.current_read_index++;

							setTimeout(() => {
								this.readLockCommand(station, command_id, vehicle_id);
							}, 5000);
						}

						if (command.status == LockCommandStatus.SUCCEEDED) {
							this.lock_commands_service.current_read_index = 0;
							this.refresh.emit();
							this.is_command_running = false;
						}

						if (command.status == LockCommandStatus.FAILED) {
							this.lock_commands_service.current_read_index = 0;

							const command_error = command.error.code
								? command.error.code
								: command.error;
							this.manageCommandError(command_error as string);

							this.is_command_running = false;
						}
					});
				},
				error: () => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_command_running = false;
				},
			});
	}

	manageCommandError(command_error: string): void {
		switch (command_error) {
			case ADAPTER_FAILED_ERROR_CODE:
				this.dialog_service.openDialog(SharedInteractionErrorDialogComponent, {
					message: ADAPTER_FAILED_ERROR_MESSAGE,
				});
				break;
			case ADAPTER_OFFLINE_ERROR_CODE:
				this.dialog_service.openDialog(SharedInteractionErrorDialogComponent, {
					message: ADAPTER_OFFLINE_ERROR_MESSAGE,
				});
				break;
			default:
				this.dialog_service.openDialog(SharedInteractionErrorDialogComponent, {
					message: ADAPTER_GENERIC_ERROR_MESSAGE,
				});
				break;
		}
	}

	getOnlineClass(vehicle: Vehicle) {
		return {
			'lk-state-300-background':
				vehicle?.stats?.online && vehicle?.stats?.online.value,
			'lk-state-50-background':
				!vehicle?.stats?.online || !vehicle?.stats?.online.value,
		};
	}

	getVehicleStatusClass(vehicle: Vehicle) {
		return {
			'lk-vehicle-green-status':
				vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}

	getOdometerValue(vehicle: Vehicle): number {
		if (this.odometer_um && this.odometer_um == VehicleOdometerUms.km) {
			return vehicle.stats.distance_covered.value / 1000;
		}

		if (this.odometer_um && this.odometer_um == VehicleOdometerUms.mi) {
			return (vehicle.stats.distance_covered.value / 1000) * KM_TO_MILES_COEFF;
		}

		return vehicle.stats.distance_covered.value / 1000;
	}

	getPercentageValue(vehicle: Vehicle): number {
		return Math.round(vehicle.stats.autonomy_percentage.value);
	}
}
