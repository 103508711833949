import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Booking, BookingService } from 'src/app/core/booking.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { Station } from 'src/app/core/station.service';
import { UtilsService } from 'src/app/core/utils.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-booking-details-header',
	templateUrl: './booking-details-header.component.html',
	styleUrl: './booking-details-header.component.scss',
})
export class BookingDetailsHeaderComponent {
	@Input() booking?: Booking;
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();

	is_booking_loading = false;

	constructor(
		private navigation_service: NavigationService,
		private utils_service: UtilsService,
		private local_manager: LocalManagerService,
		private dialog_service: DialogService,
		private booking_service: BookingService,
	) {}

	onBackClick(): void {
		this.navigation_service.toBookings();
	}

	onBookingDeleteClick(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.deleteBooking(station.id);
	}

	deleteBooking(station_id: string): void {
		this.is_booking_loading = true;
		this.booking_service.delete(station_id, this.booking.id).subscribe({
			next: response => {
				response.body.json().then(() => {
					this.navigation_service.toBookings();
					this.is_booking_loading = false;
				});
			},
			error: () => {
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
				this.is_booking_loading = false;
			},
		});
	}

	getBookingStatusLabel(): string {
		return this.utils_service.getBookingStatusLabel(this.booking);
	}

	getBookingStatusClass(): { [key: string]: boolean } {
		return this.utils_service.getBookingStatusClass(this.booking);
	}

	getUserIdentifier(booking: Booking): string {
		if (booking?.user?.first_name && booking?.user?.last_name) {
			return `${booking.user.first_name} ${booking.user.last_name}`;
		} else if (booking.user?.phone?.prefix && booking.user?.phone?.value) {
			return '+' + booking.user.phone.prefix + booking.user.phone.value;
		} else if (booking?.user?.email) {
			return booking.user.email;
		}

		return (
			booking.id.substring(0, 3) +
			booking.id.substring(booking.id.length - 8, booking.id.length)
		);
	}
}
