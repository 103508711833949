import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StationRoutingModule } from './station-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { LoadingModule } from '../loading/loading.module';
import { ButtonModule } from '../button/button.module';

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { StationComponent } from './station.component';
import { StationEmptyComponent } from './station-empty/station-empty.component';
import { StationDeleteDialogComponent } from './station-delete-dialog/station-delete-dialog.component';
import { StationNavbarComponent } from './station-navbar/station-navbar.component';
import { StationBuilderDialogComponent } from './station-builder-dialog/station-builder-dialog.component';
import { StationAddTeamDialogComponent } from './station-add-team-dialog/station-add-team-dialog.component';
import { StationsNotifTemplatesItemAddDialogComponent } from './stations-notif-templates-item-add-dialog/stations-notif-templates-item-add-dialog.component';
import { StationsNotifTemplatesItemDialogComponent } from './stations-notif-templates-item-dialog/stations-notif-templates-item-dialog.component';
import { StationNotifTestDialogComponent } from './station-notif-test-dialog/station-notif-test-dialog.component';
import { StationMapComponent } from './station-map/station-map.component';
import { StationPanelComponent } from './station-panel/station-panel.component';
import { StationPanelLogoComponent } from './station-panel-logo/station-panel-logo.component';
import { StationPanelInfoComponent } from './station-panel-info/station-panel-info.component';
import { StationPanelMenuComponent } from './station-panel-menu/station-panel-menu.component';
import { StationPanelInfoMobileComponent } from './station-panel-info-mobile/station-panel-info-mobile.component';
import { StationSettingsComponent } from './station-settings/station-settings.component';
import { StationSettingsTemplatesComponent } from './station-settings-templates/station-settings-templates.component';
import { StationSettingsNotifComponent } from './station-settings-notif/station-settings-notif.component';
import { StationSettingsLockComponent } from './station-settings-lock/station-settings-lock.component';
import { StationSettingsUnlockComponent } from './station-settings-unlock/station-settings-unlock.component';
import { StationSettingsDataComponent } from './station-settings-data/station-settings-data.component';
import { StationSettingsTeamComponent } from './station-settings-team/station-settings-team.component';
import { StationSettingsTemplatesItemHeaderComponent } from './station-settings-templates-item-header/station-settings-templates-item-header.component';
import { StationSettingsTemplatesItemComponent } from './station-settings-templates-item/station-settings-templates-item.component';
import { StationSettingsTemplatesItemContainerComponent } from './station-settings-templates-item-container/station-settings-templates-item-container.component';

@NgModule({
	declarations: [
		StationComponent,
		StationEmptyComponent,
		StationDeleteDialogComponent,
		StationNavbarComponent,
		StationBuilderDialogComponent,
		StationAddTeamDialogComponent,
		StationsNotifTemplatesItemAddDialogComponent,
		StationsNotifTemplatesItemDialogComponent,
		StationNotifTestDialogComponent,
		StationMapComponent,
		StationPanelComponent,
		StationPanelLogoComponent,
		StationPanelInfoComponent,
		StationPanelMenuComponent,
		StationPanelInfoMobileComponent,
		StationSettingsComponent,
		StationSettingsTemplatesComponent,
		StationSettingsNotifComponent,
		StationSettingsLockComponent,
		StationSettingsUnlockComponent,
		StationSettingsDataComponent,
		StationSettingsTeamComponent,
		StationSettingsTemplatesItemHeaderComponent,
		StationSettingsTemplatesItemComponent,
		StationSettingsTemplatesItemContainerComponent,
	],
	imports: [
		CommonModule,
		StationRoutingModule,
		SharedModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule,
		ToolbarModule,
		ButtonModule,
		LoadingModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatExpansionModule,
		MatProgressSpinnerModule,
		MatMenuModule,
		MatChipsModule,
		MatInputModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatTabsModule,
		MatRadioModule,
		MatAutocompleteModule,
		MatTooltipModule,
		MatSnackBarModule,
	],
})
export class StationModule {}
