import { Injectable } from '@angular/core';
import { Vehicle } from './vehicle.service';
import { Settings } from './station.service';
import { from, Observable } from 'rxjs';
import { get, post, put, del } from 'aws-amplify/api';
import { environment } from '../../environments/environment';
import { User } from './user.service';

export const BOOKINGS_API_NAME: string = 'bookings';
export const BOOKINGS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/bookings/';

@Injectable({
	providedIn: 'root',
})
export class BookingService {
	create(station_id: string, booking: Booking): Observable<any> {
		const path: string = `stations/${station_id}`;

		const init = { response: true, body: booking };

		return from(
			post({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	read(station_id: string, booking_id: string): Observable<any> {
		const path: string = `${booking_id}/stations/${station_id}`;

		const init = { response: true };

		return from(
			get({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	update(station_id: string, booking: Booking): Observable<any> {
		const path: string = `${booking.id}/stations/${station_id}`;

		const init = { response: true, body: booking };

		return from(
			put({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	delete(station_id: string, booking_id: string): Observable<any> {
		const path: string = `${booking_id}/stations/${station_id}`;

		const init = { response: true };

		return from(
			del({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	_list(station_id: string, filters?: BookingFiltersConfig): Observable<any> {
		const path: string = `stations/${station_id}`;

		let query_params = {
			page: filters.page ? filters.page : 1,
			per_page: filters.per_page ? filters.per_page : 30,
		};
		if (filters.plate) {
			query_params['vehicle_plate'] = filters.plate;
		}
		if (filters.email) {
			query_params['user_email'] = filters.email;
		}
		if (filters.start_date) {
			query_params['start_date'] = filters.start_date;
		}
		if (filters.end_date) {
			query_params['end_date'] = filters.end_date;
		}
		if (filters.sort) {
			query_params['sort'] = filters.sort;
		}

		const init = { response: true, queryParams: query_params };

		return from(
			get({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	list(
		station_id: string,
		page?: number,
		plate?: string,
		email?: string,
		start_date?: number,
		end_date?: number,
		sort?: string,
	): Observable<any> {
		const BOOKINGS_PER_PAGE: number = 30;
		const path: string = `stations/${station_id}`;

		let query_params = {
			page: page ? page : 1,
			per_page: page ? BOOKINGS_PER_PAGE : 100000000,
		};
		if (plate) {
			query_params['vehicle_plate'] = plate;
		}
		if (email) {
			query_params['user_email'] = email;
		}
		if (start_date) {
			query_params['start_date'] = start_date;
		}
		if (end_date) {
			query_params['end_date'] = end_date;
		}
		if (sort) {
			query_params['sort'] = sort;
		}
		const init = { response: true, queryStringParameters: query_params };

		return from(
			get({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	assignVehicle(
		station_id: string,
		booking_id: string,
		vehicle_id: string,
	): Observable<any> {
		const path: string = `${booking_id}/stations/${station_id}/vehicles/${vehicle_id}`;

		const init = { response: true };

		return from(
			put({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	changeDates(
		station_id: string,
		booking_id: string,
		start_date: number,
		end_date: number,
	): Observable<any> {
		const path: string = `${booking_id}/stations/${station_id}/start/${start_date}/end/${end_date}`;

		const init = { response: true };

		return from(
			put({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	sendUnlockLink(
		station_id: string,
		booking_id: string,
		vehicle_id: string,
		user_id: string,
	): Observable<any> {
		const path: string = `${booking_id}/stations/${station_id}/vehicles/${vehicle_id}/users/${user_id}/unlock-link/send`;

		const init = { response: true };

		return from(
			post({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	sendLockLink(
		station_id: string,
		booking_id: string,
		vehicle_id: string,
		user_id: string,
	): Observable<any> {
		const path: string = `${booking_id}/stations/${station_id}/vehicles/${vehicle_id}/users/${user_id}/lock-link/send`;

		const init = { response: true };

		return from(
			post({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	setUser(
		booking_id: string,
		station_id: string,
		user_id: string,
	): Observable<any> {
		const path: string = `${booking_id}/stations/${station_id}/users/${user_id}`;

		const init = { response: true };

		return from(
			put({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	export(station_id: string): Observable<any> {
		const path: string = `/stations/${station_id}/export`;

		const init = {
			response: true,
			headers: {
				Accept: 'text/csv',
			},
			responseType: 'blob',
		};

		return from(
			get({
				apiName: BOOKINGS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}
}

export class BookingFiltersConfig {
	page?: number;
	per_page?: number;
	plate?: string;
	email?: string;
	start_date?: number;
	end_date?: number;
	sort?: string;
}

export class Booking {
	id: string;
	ext_id: string;
	user: User;
	user_id: string;
	vehicle: Vehicle;
	vehicle_id: string;
	errors: BookingStatus[];
	start_date: number;
	end_date: number;
	timezone: string;
	settings: Settings;
	media: Media[];
	is_user_vehicle_unlocked: boolean;
	is_user_vehicle_locked: boolean;
	unlock_key_id: string;
	lock_key_id: string;
}

export class Interval {
	start_date: number;
	end_date: number;
	timezone: string;
}

export class Media {
	type: MediaType;
	link: string;
}

export enum BookingStatus {
	PENDING = 'pending',
	IN_PROGRESS = 'in_progress',
	COMPLETED = 'completed',
	EXPIRED_WO_VEHICLE = 'expired_without_vehicle',
	EXPIRED_WO_START = 'expired_without_start',
	EXPIRED_WO_STOP = 'expired_without_stop',
}

export enum MediaType {
	PRE_REPORT = 'pre_report',
	POST_REPORT = 'post_report',
}
