<div
	style="position: relative"
	class="lk-border-radius lk-shadow lk-padding lk-full-height">
	<div
		id="lk-vehicle-details-map-header-layout"
		style="width: fit-content"
		class="lk-full-width lk-spaced-row">
		<h3 class="lk-no-margin lk-subtitle-size">
			{{ 'VEHICLE_DETAILS.MAP_SECTION_LABEL' | translate }}
		</h3>
	</div>

	<button
		style="position: absolute; bottom: 110px; left: 24px; z-index: 1"
		class="lk-icon__button-neutral-0"
		mat-icon-button
		(click)="onLayersClick()">
		<mat-icon [ngClass]="getLayersIconClass()"> layers </mat-icon>
	</button>

	<button
		style="position: absolute; bottom: 60px; left: 24px; z-index: 1"
		class="lk-icon__button-blue"
		mat-icon-button
		(click)="onDirectionsClick()">
		<mat-icon class="lk-primary-color"> directions </mat-icon>
	</button>

	<button
		style="position: absolute; bottom: 10px; left: 24px; z-index: 1"
		class="lk-icon__button-secondary-50"
		mat-icon-button
		(click)="onLocationClick()">
		<mat-icon class="lk-primary-color"> my_location </mat-icon>
	</button>

	<div
		id="lk-vehicle-details-map-container-layout"
		class="lk-full-height lk-full-width lk-border-radius">
		<div
			id="lk-vehicle-details-map-layout"
			class="lk-full-height lk-border-radius"></div>
	</div>
</div>
