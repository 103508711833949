import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Admin, AdminService } from 'src/app/core/admin.service';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { DialogService } from 'src/app/core/dialog.service';
import {
	Invitation,
	InvitationService,
	InvitationResponseType,
} from 'src/app/core/invitation.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { Station } from 'src/app/core/station.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import { StationAddTeamDialogComponent } from '../station-add-team-dialog/station-add-team-dialog.component';

@Component({
	selector: 'app-station-settings-team',
	templateUrl: './station-settings-team.component.html',
	styleUrl: './station-settings-team.component.scss',
})
export class StationSettingsTeamComponent {
	@Input() station?: Station;
	@Output() self_delete: EventEmitter<void> = new EventEmitter<void>();

	team: Admin[] = [];
	invitations: Invitation[] = [];
	is_team_loading: boolean = false;

	current_admin_email: string;

	constructor(
		private local_manager: LocalManagerService,
		private admin_service: AdminService,
		private invitation_service: InvitationService,
		private auth_service: AuthenticationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.listAdmins();
	}

	onReset(): void {
		this.listAdmins();
	}

	onAddTeammateClick(): void {
		this.dialog_service
			.openDialog(StationAddTeamDialogComponent, {
				station_id: this.station.id,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.listAdmins();
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onDeleteMemberClick(admin: Admin): void {
		this.is_team_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.admin_service.removeFromStation(station.id, [admin.id]).subscribe({
			next: () => {
				this.is_team_loading = false;
				this.listAdmins();

				if (admin.email == this.current_admin_email) {
					this.self_delete.emit();
				}
			},
			error: () => {
				this.is_team_loading = false;
			},
		});
	}

	onDeleteInvitationClick(invitation: Invitation): void {
		this.is_team_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.invitation_service.delete(station.id, invitation.id).subscribe({
			next: () => {
				this.is_team_loading = false;
				this.listAdmins();
			},
			error: () => {
				this.is_team_loading = false;
			},
		});
	}

	listAdmins(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_team_loading = true;
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				this.current_admin_email = response.signInDetails?.loginId;
				this.admin_service.listByStationId(station.id).subscribe({
					next: response => {
						response.body.json().then(response => {
							this.team = response.items;
							this.listInvitations();
							this.is_team_loading = false;
						});
					},
					error: () => {
						this.team = [];
						this.is_team_loading = false;
					},
				});
			},
			error: () => {
				this.is_team_loading = false;
				this.auth_service.signOut();
			},
		});
	}

	listInvitations(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_team_loading = true;
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				const current_admin_email: string = response.signInDetails?.loginId;
				this.invitation_service.listByStation(station.id).subscribe({
					next: response => {
						response.body.json().then(response => {
							this.invitations = response.filter((invitation: Invitation) => {
								return (
									invitation.response == InvitationResponseType.PENDING &&
									invitation.admin_email != current_admin_email
								);
							});
							this.is_team_loading = false;
						});
					},
					error: () => {
						this.invitations = [];
						this.is_team_loading = false;
					},
				});
			},
			error: () => {
				this.is_team_loading = false;
				this.auth_service.signOut();
			},
		});
	}

	isOwner(admin: Admin): boolean {
		return admin.email == this.current_admin_email;
	}
}
