import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import {
	BOOKING_DELETE_MESSAGE,
	GENERAL_ERROR_MESSAGE,
	NO_RESULT_FOUND_MESSAGE,
	TOOLBAR_BOOKINGS_NAV_ITEM_NAME,
} from '../shared/translations';
import { LocalManagerService } from '../core/local-manager.service';
import { Router } from '@angular/router';
import { Station } from '../core/station.service';
import {
	BOOKING_NOT_FOUND_ERROR_CODE,
	BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
	LOCAL_STATION_KEY,
} from '../shared/constants';
import {
	Booking,
	BookingFiltersConfig,
	BookingService,
} from '../core/booking.service';
import { DialogService } from '../core/dialog.service';
import { Vehicle } from '../core/vehicle.service';
import { BookingFiltersDialogComponent } from './booking-filters-dialog/booking-filters-dialog.component';
import { STATION_PATH } from '../core/navigation.service';
import { StationBuilderDialogComponent } from '../station/station-builder-dialog/station-builder-dialog.component';
import { BookingSummaryComponent } from './booking-summary/booking-summary.component';
import { ErrorService } from '../core/error.service';
import { BookingTableComponent } from './booking-table/booking-table.component';

@Component({
	selector: 'app-booking',
	templateUrl: './booking.component.html',
	styleUrls: ['./booking.component.scss'],
})
export class BookingComponent {
	@ViewChild(BookingSummaryComponent)
	summary_component?: BookingSummaryComponent;
	@ViewChild(BookingTableComponent) table_component?: BookingTableComponent;
	@ViewChild('loadMoreLayout') load_more_layout: ElementRef;

	current_item = TOOLBAR_BOOKINGS_NAV_ITEM_NAME;

	current_page = 1;
	filters_config?: BookingFiltersConfig;

	bookings?: Booking[];
	stored_bookings?: Booking[];

	is_bookings_loading = false;
	is_export_running = false;
	is_last_page = false;
	is_page_loading = false;

	constructor(
		private local_manager: LocalManagerService,
		private router: Router,
		private error_service: ErrorService,
		private booking_service: BookingService,
		private dialog_service: DialogService,
	) {}

	onStationChange(): void {
		this.onRefresh();
	}

	onRefresh() {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.prepareBookingsLoading();
		this.listBookings(station.id);
		this.summary_component?.onRefresh();
	}

	@HostListener('document:scroll', ['$event'])
	public onViewportScroll(): void {
		const window_height = window.innerHeight;
		const bounding_rect =
			this.load_more_layout?.nativeElement?.getBoundingClientRect();
		if (
			bounding_rect &&
			bounding_rect.top >= 0 &&
			bounding_rect.bottom <= window_height &&
			!this.is_page_loading
		) {
			this.onLoadPageClick();
		}
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuild(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onBookingCreate(booking: Booking): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.createBooking(station.id, booking);
	}

	onAssignVehicleClick(assign_result: { booking: Booking; vehicle: Vehicle }) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.assignVehicle(
			station.id,
			assign_result.booking,
			assign_result.vehicle,
		);
	}

	onDeleteBookingClick(booking: Booking) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.deleteBooking(station.id, booking.id);
	}

	onFiltersClick() {
		this.dialog_service
			.openDialog(BookingFiltersDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.filters) {
					const station: Station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);
					this.stored_bookings = this.bookings;
					this.prepareBookingsLoading();
					this.filters_config.plate = dialogResult.filters.plate;
					this.filters_config.email = dialogResult.filters.email;
					this.filters_config.start_date = dialogResult.filters.start_date;
					this.filters_config.end_date = dialogResult.filters.end_date;
					this.listBookings(station.id);
				}
			});
	}

	onSearchChange(email: string): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.stored_bookings = this.bookings;
		this.prepareBookingsLoading();
		this.filters_config['email'] = email;
		this.listBookings(station.id);
	}

	onLoadPageClick(): void {
		this.current_page++;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_page_loading = true;
		this.listBookings(station.id);
	}

	onSortStartDateClick(): void {
		this.is_bookings_loading = true;
		this.is_last_page = false;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.bookings = [];
		this.current_page = 1;
		this.listBookings(station.id);
	}

	prepareBookingsLoading(stopReset?: boolean): void {
		this.filters_config = new BookingFiltersConfig();
		this.is_bookings_loading = true;
		this.current_page = 1;
		this.bookings = stopReset ? this.bookings : [];
		this.is_last_page = false;
	}

	listBookings(station_id: string): void {
		this.filters_config['page'] = this.current_page;
		this.filters_config['sort'] = localStorage.getItem(
			BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
		);

		this.booking_service._list(station_id, this.filters_config).subscribe({
			next: response => {
				response.body.json().then(response => {
					const station: Station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);

					const bookings = response.items;

					this.bookings = this.bookings
						? this.bookings.concat(bookings)
						: bookings;

					this.is_last_page = response.total_items <= this.bookings.length;

					this.table_component?.setStation(station, this.bookings);

					this.is_bookings_loading = false;
					this.is_page_loading = false;

					setTimeout(() => {
						const window_height = window.innerHeight;
						const bounding_rect =
							this.load_more_layout?.nativeElement?.getBoundingClientRect();
						if (
							bounding_rect &&
							bounding_rect.top >= 0 &&
							bounding_rect.bottom <= window_height &&
							!this.is_page_loading
						) {
							this.onLoadPageClick();
						}
					}, 200);
				});
			},
			error: error => {
				this.manageListBookingsErrors(error);
			},
		});
	}

	createBooking(station_id: string, booking: Booking): void {
		this.is_bookings_loading = true;
		this.booking_service.create(station_id, booking).subscribe({
			next: response => {
				response.body.json().then(() => {
					this.prepareBookingsLoading();
					this.listBookings(station_id);
				});
			},
			error: () => {
				this.is_bookings_loading = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	assignVehicle(station_id: string, booking: Booking, vehicle: Vehicle): void {
		this.is_bookings_loading = true;
		this.booking_service
			.assignVehicle(station_id, booking.id, vehicle.id)
			.subscribe({
				next: response => {
					response.body.json().then(() => {
						this.onRefresh();
					});
				},
				error: error => {
					this.error_service.wrapError(error);
					this.onRefresh();
				},
			});
	}

	deleteBooking(station_id: string, booking_id: string): void {
		this.booking_service.delete(station_id, booking_id).subscribe({
			next: response => {
				response.body.json().then(() => {
					this.onRefresh();
					this.dialog_service.openInfoDialog(BOOKING_DELETE_MESSAGE);
				});
			},
			error: error => {
				this.error_service.wrapError(error);
			},
		});
	}

	manageListBookingsErrors(error: any): void {
		const converted_error = this.error_service.convertError(error);

		if (
			converted_error.code === BOOKING_NOT_FOUND_ERROR_CODE &&
			!this.areFiltersSet()
		) {
			this.is_last_page = true;
			this.bookings = this.bookings ? this.bookings.concat([]) : [];
		}

		if (
			converted_error.code === BOOKING_NOT_FOUND_ERROR_CODE &&
			this.areFiltersSet()
		) {
			this.is_last_page = true;
			this.dialog_service.openInfoDialog(NO_RESULT_FOUND_MESSAGE);
			this.bookings = this.stored_bookings;
		}

		if (converted_error.code != BOOKING_NOT_FOUND_ERROR_CODE) {
			this.error_service.processError(converted_error);
		}

		this.is_bookings_loading = false;
		this.is_page_loading = false;
	}

	areFiltersSet(): boolean | string | number {
		return (
			this.filters_config?.plate ||
			this.filters_config?.email ||
			this.filters_config?.start_date ||
			this.filters_config?.end_date
		);
	}
}
