import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
	Booking,
	BookingService,
	BookingStatus,
} from '../core/booking.service';
import { LocalManagerService } from '../core/local-manager.service';
import { Station } from '../core/station.service';
import {
	COMPLETED_UPDATE_ERROR_CODE,
	ERRORS_UPDATE_ERROR_CODE,
	ERROR_DESCRIPTION_500,
	PROD_STAGE,
	PROGRESS_UPDATE_ERROR_CODE,
	LOCAL_STATION_KEY,
	VEHICLE_ALREADY_ASSIGNED_ERROR_CODE,
	BOOKING_DETAILS_NAVBAR_EVENTS_ITEM_NAME,
	BOOKING_DETAILS_NAVBAR_GALLERY_ITEM_NAME,
	BOOKING_DETAILS_NAVBAR_GENERAL_ITEM_NAME,
	BOOKING_DETAILS_NAVBAR_LINKS_ITEM_NAME,
	BOOKING_DETAILS_NAVBAR_DATA_ITEM_NAME,
	BOOKING_DETAILS_NAVBAR_PATH_ITEM_NAME,
} from '../shared/constants';
import {
	BOOKING_COMPLETED_LABEL,
	BOOKING_ERROR_WO_START_LABEL,
	BOOKING_ERROR_WO_STOP_LABEL,
	BOOKING_ERROR_WO_VEHICLE_LABEL,
	BOOKING_IN_PROGRESS_LABEL,
	BOOKING_PENDING_LABEL,
	COMPLETED_UPDATE_ERROR_MESSAGE,
	ERRORS_UPDATE_ERROR_MESSAGE,
	GENERAL_ERROR_MESSAGE,
	PROGRESS_UPDATE_ERROR_MESSAGE,
	TOOLBAR_BOOKINGS_NAV_ITEM_NAME,
	USER_EMAIL_UPDATE_SUCCESS_MESSAGE,
	VEHICLE_ALREADY_ASSIGNED_ERROR_MESSAGE,
} from '../shared/translations';
import { Vehicle } from '../core/vehicle.service';
import { DialogService } from '../core/dialog.service';
import { zonedTimeToUtc } from 'date-fns-tz';
import { BookingPhoneBuilderDialogComponent } from '../booking/booking-phone-builder-dialog/booking-phone-builder-dialog.component';
import { User, UserService } from '../core/user.service';
import { BookingDetailsEmailDialogComponent } from './booking-details-email-dialog/booking-details-email-dialog.component';
import {
	BOOKINGS_PATH,
	STATION_PATH,
	VEHICLES_PATH,
} from '../core/navigation.service';
import { environment } from 'src/environments/environment';
import { StationBuilderDialogComponent } from '../station/station-builder-dialog/station-builder-dialog.component';
import { ErrorService } from '../core/error.service';

@Component({
	selector: 'app-booking-details',
	templateUrl: './booking-details.component.html',
	styleUrls: ['./booking-details.component.scss'],
})
export class BookingDetailsComponent implements OnInit {
	station?: Station;

	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	booking_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	booking_trigger: BehaviorSubject<Booking> = new BehaviorSubject<Booking>(
		null,
	);
	current_item: string = TOOLBAR_BOOKINGS_NAV_ITEM_NAME;
	navbar_items: string[] = [
		BOOKING_DETAILS_NAVBAR_GENERAL_ITEM_NAME,
		BOOKING_DETAILS_NAVBAR_LINKS_ITEM_NAME,
		BOOKING_DETAILS_NAVBAR_EVENTS_ITEM_NAME,
		BOOKING_DETAILS_NAVBAR_GALLERY_ITEM_NAME,
		BOOKING_DETAILS_NAVBAR_DATA_ITEM_NAME,
		BOOKING_DETAILS_NAVBAR_PATH_ITEM_NAME,
	];
	current_navbar_item: string = BOOKING_DETAILS_NAVBAR_GENERAL_ITEM_NAME;
	is_booking_loading: boolean = false;
	booking_id: string;
	booking: Booking;
	is_production: boolean;

	constructor(
		private local_manager: LocalManagerService,
		private router: Router,
		private dialog_service: DialogService,
		private booking_service: BookingService,
		private user_service: UserService,
		private route: ActivatedRoute,
		private error_service: ErrorService,
	) {
		this.booking_id = this.route.snapshot.paramMap.get('booking_id');
	}

	ngOnInit(): void {
		this.is_production = environment.STAGE == PROD_STAGE;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		if (station) {
			this.station_update_trigger.next(true);
			this.readBooking();
		} else {
			this.router.navigate([STATION_PATH]);
		}
	}

	onRefresh(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		if (station) {
			this.readBooking();
		} else {
			this.router.navigate([STATION_PATH]);
		}
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuild(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
					this.toolbar_refresh.next(true);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onStationChange(station: Station): void {
		if (this.station) {
			this.router.navigate([VEHICLES_PATH]);
		} else {
			this.station = station;
		}
	}

	onBookingDeleteClick(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_booking_loading = true;
		this.booking_service.delete(station.id, this.booking_id).subscribe({
			next: response => {
				response.body.json().then(() => {
					this.router.navigate([BOOKINGS_PATH]);
					this.is_booking_loading = false;
				});
			},
			error: () => {
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
				this.is_booking_loading = false;
			},
		});
	}

	onBackClick(): void {
		this.router.navigate([BOOKINGS_PATH]);
	}

	onBookingUpdateClick(booking: Booking): void {
		this.updateBooking(booking);
	}

	onAddVehicleClick(assignResult: {
		booking: Booking;
		vehicle: Vehicle;
	}): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_booking_loading = true;
		this.booking_service
			.assignVehicle(
				station.id,
				assignResult.booking.id,
				assignResult.vehicle.id,
			)
			.subscribe({
				next: response => {
					response.body.json().then(() => {
						this.onRefresh();
					});
				},
				error: error => {
					this.error_service.wrapError(error);
					this.onRefresh();
				},
			});
	}

	onUpdatePhoneClick(): void {
		this.dialog_service
			.openDialog(BookingPhoneBuilderDialogComponent, {
				user: this.booking.user,
			})
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.user) {
					// User is already added
					this.setUser(dialogResult.user);
				} else if (dialogResult && dialogResult.phone) {
					// User must be added
					this.booking.user.phone = {
						prefix: dialogResult.phone_prefix,
						value: dialogResult.phone,
					};
					this.is_booking_loading = true;
					const station: Station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);

					this.user_service.create(station.id, this.booking.user).subscribe({
						next: response => {
							response.body.json().then(response => {
								this.setUser(response as User);
							});
						},
						error: error => {
							this.error_service.wrapError(error);
							this.is_booking_loading = false;
						},
					});
				}
			});
	}

	onUpdateEmailClick(): void {
		this.dialog_service
			.openDialog(BookingDetailsEmailDialogComponent, {
				user: this.booking.user,
			})
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.success) {
					this.dialog_service.openInfoDialog(USER_EMAIL_UPDATE_SUCCESS_MESSAGE);
					this.readBooking();
				} else if (dialogResult && dialogResult.error) {
					this.dialog_service.openInfoDialog(dialogResult.error);
				}
			});
	}

	onNavbarItemChange(item_name: string): void {
		this.current_navbar_item = item_name;
	}

	setUser(user: User): void {
		this.is_booking_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.booking_service
			.setUser(this.booking.id, station.id, user.id)
			.subscribe({
				next: response => {
					response.body.json().then(() => {
						this.readBooking();
					});
				},
				error: error => {
					this.error_service.wrapError(error);
					this.is_booking_loading = false;
				},
			});
	}

	readBooking(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.is_booking_loading = true;
		this.booking_service.read(station.id, this.booking_id).subscribe({
			next: response => {
				response.body.json().then(response => {
					this.is_booking_loading = false;
					this.booking = response as Booking;
					this.booking_update_trigger.next(true);
					this.booking_trigger.next(this.booking);
				});
			},
			error: () => {
				this.is_booking_loading = false;
			},
		});
	}

	updateBooking(booking: Booking): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_booking_loading = true;
		this.booking_service
			.changeDates(station.id, booking.id, booking.start_date, booking.end_date)
			.subscribe({
				next: response => {
					response.body.json().then(() => {
						this.onRefresh();
					});
				},
				error: error => {
					this.is_booking_loading = false;
					this.error_service.wrapError(error);
					this.onRefresh();
				},
			});
	}

	getBookingStatusLabel(booking: Booking): string {
		if (!booking.errors || !booking.errors.length) {
			if (this.isPendingBooking(booking)) {
				return BOOKING_PENDING_LABEL;
			} else if (this.isCompletedBooking(booking)) {
				return BOOKING_COMPLETED_LABEL;
			} else {
				return BOOKING_IN_PROGRESS_LABEL;
			}
		} else {
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_VEHICLE)) {
				return BOOKING_ERROR_WO_VEHICLE_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_START)) {
				return BOOKING_ERROR_WO_START_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_STOP)) {
				return BOOKING_ERROR_WO_STOP_LABEL;
			}
		}
	}

	getStatusClass(booking: Booking): { [key: string]: boolean } {
		if (booking) {
			let statusLabel: string;
			switch (this.getBookingStatusLabel(booking)) {
				case BOOKING_PENDING_LABEL:
					statusLabel = BookingStatus.PENDING;
					break;
				case BOOKING_IN_PROGRESS_LABEL:
					statusLabel = BookingStatus.IN_PROGRESS;
					break;
				case BOOKING_COMPLETED_LABEL:
					statusLabel = BookingStatus.COMPLETED;
					break;
				case BOOKING_ERROR_WO_VEHICLE_LABEL:
					statusLabel = BookingStatus.EXPIRED_WO_VEHICLE;
					break;
				case BOOKING_ERROR_WO_START_LABEL:
					statusLabel = BookingStatus.EXPIRED_WO_START;
					break;
				case BOOKING_ERROR_WO_STOP_LABEL:
					statusLabel = BookingStatus.EXPIRED_WO_STOP;
					break;
				default:
					statusLabel = BookingStatus.PENDING;
					break;
			}
			return {
				'lk-orange-status': statusLabel == BookingStatus.PENDING,
				'lk-green-status': statusLabel == BookingStatus.COMPLETED,
				'lk-blue-status': statusLabel == BookingStatus.IN_PROGRESS,
				'lk-red-status':
					statusLabel == BookingStatus.EXPIRED_WO_VEHICLE ||
					statusLabel == BookingStatus.EXPIRED_WO_START ||
					statusLabel == BookingStatus.EXPIRED_WO_STOP,
			};
		}
	}

	getUserIdentifier(booking: Booking): string {
		if (booking.user) {
			if (booking.user.first_name && booking.user.last_name) {
				return booking.user.first_name + ' ' + booking.user.last_name;
			} else if (
				booking.user.phone &&
				booking.user.phone.prefix &&
				booking.user.phone.value
			) {
				return '+' + booking.user.phone.prefix + booking.user.phone.value;
			} else if (booking.user.email) {
				return booking.user.email;
			}
			return (
				booking.id.substring(0, 3) +
				booking.id.substring(booking.id.length - 8, booking.id.length)
			);
		}
	}

	isPendingBooking(booking: Booking): boolean {
		const isUnlocked: boolean = booking.is_user_vehicle_unlocked;
		return !isUnlocked;
	}

	isCompletedBooking(booking: Booking): boolean {
		const lockAfterTime: number =
			booking.settings.lock_settings
				.time_to_lock_availability_after_booking_end;
		const currentTimezone: string =
			Intl.DateTimeFormat().resolvedOptions().timeZone;
		const isInTime: boolean =
			new Date().getTime() >
			zonedTimeToUtc(
				booking.end_date + lockAfterTime,
				currentTimezone,
			).getTime();
		const isLocked: boolean = booking.is_user_vehicle_locked;
		return isInTime || isLocked;
	}
}
