import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { Station } from 'src/app/core/station.service';
import { Vehicle, VehicleOdometerUms } from 'src/app/core/vehicle.service';
import {
	KM_TO_MILES_COEFF,
	LOCAL_STATION_KEY,
	DATE_FORMATS,
	EN_LOCALE,
	IT_LOCALE,
} from 'src/app/shared/constants';

@Component({
	selector: 'app-vehicle-details-measures',
	templateUrl: './vehicle-details-measures.component.html',
	styleUrls: ['./vehicle-details-measures.component.scss'],
})
export class VehicleDetailsMeasuresComponent {
	@Input() vehicle: Vehicle;

	odometer_um?: string;
	current_locale: string;

	constructor(private local_manager: LocalManagerService) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.odometer_um = station.settings?.odometer_um || VehicleOdometerUms.mi;

		switch (station.settings.date_format) {
			case DATE_FORMATS[0]:
				this.current_locale = EN_LOCALE;
				break;
			case DATE_FORMATS[1]:
				this.current_locale = IT_LOCALE;
				break;
			default:
				this.current_locale = EN_LOCALE;
				break;
		}
	}

	getOdometerValue(vehicle: Vehicle): number {
		if (!vehicle?.stats?.distance_covered?.value) return undefined;

		if (this.odometer_um && this.odometer_um == VehicleOdometerUms.km) {
			return vehicle.stats.distance_covered.value / 1000;
		}

		if (this.odometer_um && this.odometer_um == VehicleOdometerUms.mi) {
			return (vehicle.stats.distance_covered.value / 1000) * KM_TO_MILES_COEFF;
		}

		return vehicle.stats.distance_covered.value / 1000;
	}
}
