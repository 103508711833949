<div class="lk-height__max-80vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x12-horiz lk-hide-scrollbar">
		<h3>{{ 'BOOKING.ADD_TIME_DIALOG_TITLE' | translate }}.</h3>

		<form
			class="lk-width__full lk-row__space lk-wrap lk-margin__x24-vert"
			[formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				class="lk-width__half-x8 lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'BOOKING.START_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="start_date"
					required
					[matDatepicker]="startPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="startPicker"></mat-datepicker-toggle>
				<mat-datepicker #startPicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-form-field lk-margin__x12-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.START_HOUR_LABEL' | translate }} </mat-label>
				<mat-select
					formControlName="start_hour"
					required>
					<mat-option
						*ngFor="let hour of hours"
						[value]="hour">
						{{ hour }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-form-field lk-margin__x12-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.START_MINUTE_LABEL' | translate }} </mat-label>
				<mat-select
					id="lk-booking-time-builder-start-minute-select"
					formControlName="start_minute"
					required>
					<mat-option
						*ngFor="let minute of minutes"
						[value]="minute">
						{{ minute }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-width__half-x8 lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'BOOKING.END_COLUMN_LABEL' | translate }} </mat-label>
				<input
					type="text"
					matInput
					formControlName="end_date"
					required
					[matDatepicker]="endPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="endPicker"></mat-datepicker-toggle>
				<mat-datepicker #endPicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-form-field lk-margin__x12-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.END_HOUR_LABEL' | translate }} </mat-label>
				<mat-select
					id="lk-booking-time-builder-end-hour-select"
					formControlName="end_hour"
					required>
					<mat-option
						*ngFor="let hour of hours"
						[value]="hour">
						{{ hour }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-form-field lk-margin__x12-bottom lk-booking-time-builder-date-field">
				<mat-label> {{ 'BOOKING.END_MINUTE_LABEL' | translate }} </mat-label>
				<mat-select
					id="lk-booking-time-builder-end-minute-select"
					formControlName="end_minute"
					required>
					<mat-option
						*ngFor="let minute of minutes"
						[value]="minute">
						{{ minute }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-width__full lk-form-field lk-margin__x12-bottom">
				<mat-label> Timezone </mat-label>
				<mat-select
					formControlName="timezone"
					required>
					<mat-option
						*ngFor="let timezone of timezones"
						[value]="timezone">
						({{ getTimezoneOffset(timezone) }})
						{{ timezone }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<app-button
				class="lk-width__full lk-margin__x24-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.CONFIRM_CTA' | translate"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>
