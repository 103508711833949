import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';
import { Command, EventsService } from 'src/app/core/events.service';
import { FormService } from 'src/app/core/form.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { LockCommandStatus } from 'src/app/core/lock-commands.service';
import { BOOKING_DETAILS_PATH } from 'src/app/core/navigation.service';
import { Station } from 'src/app/core/station.service';
import { Vehicle } from 'src/app/core/vehicle.service';
import {
	IT_LOCALE,
	EN_LOCALE,
	LOCAL_STATION_KEY,
	DATE_FORMATS,
} from 'src/app/shared/constants';
import {
	COMMAND_HOST_UNLOCK_SUCCEEDED,
	COMMAND_HOST_LOCK_SUCCEEDED,
	COMMAND_GUEST_UNLOCK_SUCCEEDED,
	COMMAND_GUEST_LOCK_SUCCEEDED,
	COMMAND_GUEST_LOCK_FAILED,
	COMMAND_GUEST_UNLOCK_FAILED,
	COMMAND_HOST_LOCK_FAILED,
	COMMAND_HOST_UNLOCK_FAILED,
} from 'src/app/shared/translations';

@Component({
	selector: 'app-vehicle-details-commands',
	templateUrl: './vehicle-details-commands.component.html',
	styleUrls: ['./vehicle-details-commands.component.scss'],
})
export class VehicleDetailsCommandsComponent implements OnInit {
	@Input() vehicle: Vehicle;
	@Input() _vehicle: BehaviorSubject<Vehicle>;

	commands?: Command[];
	is_commands_request_running = false;

	current_locale?: string;
	form_group: FormGroup = this.form_service.vehicleCommandsFilterFormGroup;

	constructor(
		private events_service: EventsService,
		private form_service: FormService,
		private local_manager: LocalManagerService,
		private date_adapter: DateAdapter<any>,
	) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		switch (station.settings.date_format) {
			case DATE_FORMATS[0]:
				this.current_locale = EN_LOCALE;
				this.date_adapter.setLocale(EN_LOCALE);
				break;
			case DATE_FORMATS[1]:
				this.current_locale = IT_LOCALE;
				this.date_adapter.setLocale(IT_LOCALE);
				break;
			default:
				this.current_locale = EN_LOCALE;
				this.date_adapter.setLocale(EN_LOCALE);
				break;
		}
	}

	ngOnInit(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.listEvents(station.id);
	}

	onFiltersApply(filters: { from: number; to: number }): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.listEvents(station.id, filters.from, filters.to);
	}

	onFiltersReset(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.listEvents(station.id);
	}

	listEvents(
		station_id: string,
		created_from?: number,
		created_to?: number,
	): void {
		this.is_commands_request_running = true;
		this.events_service
			.listCommandsOfVehicle(
				station_id,
				this.vehicle.id,
				created_from,
				created_to,
			)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						this.commands = this.getCronologicalCommands(response);
						this.is_commands_request_running = false;
					});
				},
				error: () => {
					this.commands = [];
					this.is_commands_request_running = false;
				},
			});
	}

	getCronologicalCommands(commands: Command[]): Command[] {
		return commands.sort((a, b) => {
			return b.created_at - a.created_at;
		});
	}

	getCommandLabel(command: Command): string {
		if (command.status == LockCommandStatus.SUCCEEDED) {
			switch (command.object) {
				case 'unlock_host_command':
					return COMMAND_HOST_UNLOCK_SUCCEEDED;
				case 'lock_host_command':
					return COMMAND_HOST_LOCK_SUCCEEDED;
				case 'unlock_command':
					return COMMAND_GUEST_UNLOCK_SUCCEEDED;
				case 'lock_command':
					return COMMAND_GUEST_LOCK_SUCCEEDED;
			}
		} else if (command.status == LockCommandStatus.FAILED) {
			switch (command.object) {
				case 'unlock_host_command':
					return COMMAND_HOST_UNLOCK_FAILED;
				case 'lock_host_command':
					return COMMAND_HOST_LOCK_FAILED;
				case 'unlock_command':
					return COMMAND_GUEST_UNLOCK_FAILED;
				case 'lock_command':
					return COMMAND_GUEST_LOCK_FAILED;
			}
		}
		return '';
	}

	isCommandClickable(command: Command): boolean {
		return (command.object == 'unlock_command' ||
			command.object == 'lock_command') &&
			command.status == LockCommandStatus.SUCCEEDED &&
			command.booking_id
			? true
			: false;
	}

	getCommandBookingRoute(command: Command): string {
		return '/' + BOOKING_DETAILS_PATH + '/' + command.booking_id;
	}

	getCommandClass(command: Command): { [key: string]: boolean } {
		return {
			'': command.status == LockCommandStatus.SUCCEEDED,
			'lk-warn-color': command.status == LockCommandStatus.FAILED,
		};
	}
}
