import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { AuthForgotPasswordDialogComponent } from '../auth-forgot-password-dialog/auth-forgot-password-dialog.component';
import { AuthForgotPasswordConfirmDialogComponent } from '../auth-forgot-password-confirm-dialog/auth-forgot-password-confirm-dialog.component';
import { PASSWORD_RESET_SUCCESS_MESSAGE } from 'src/app/shared/translations';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { AUTH_MFA_SETUP_ERROR_CODE } from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-auth-signin-form',
	templateUrl: './auth-signin-form.component.html',
	styleUrl: './auth-signin-form.component.scss',
})
export class AuthSigninFormComponent {
	@Input() logo_src?: string;

	signin_form_group: FormGroup;
	is_password_visible: boolean = false;
	is_signin_running: boolean = false;

	constructor(
		private form_service: FormService,
		private dialog_service: DialogService,
		private auth_service: AuthenticationService,
		private navigation_service: NavigationService,
	) {
		this.signin_form_group = this.form_service.signin_form_group;
	}

	onForgotPssClick(): void {
		this.dialog_service
			.openDialog(AuthForgotPasswordDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.success) {
					this.onForgotPssRequestSent(dialogResult.email);
				} else if (dialogResult && !dialogResult.success) {
					this.dialog_service.openInfoDialog(dialogResult.error);
				}
			});
	}

	onForgotPssRequestSent(email: string): void {
		this.dialog_service
			.openDialog(AuthForgotPasswordConfirmDialogComponent, { email: email })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.success) {
					this.dialog_service.openInfoDialog(PASSWORD_RESET_SUCCESS_MESSAGE);
				} else if (dialogResult && !dialogResult.success) {
					this.dialog_service.openInfoDialog(dialogResult.error);
				}
			});
	}

	onSigninClick(): void {
		if (this.form_service.validateForm(this.signin_form_group)) {
			const username: string = this.signin_form_group.get('username').value;
			const password: string = this.signin_form_group.get('password').value;
			this.signin(username, password);
		}
	}

	signin(username: string, password: string): void {
		this.is_signin_running = true;
		this.auth_service.signIn(username, password).subscribe({
			next: response => {
				const { nextStep } = response as any;
				this.manageCognitoChallange(
					username,
					nextStep.signInStep,
					nextStep.totpSetupDetails,
					nextStep.codeDeliveryDetails,
				);
			},
			error: error => {
				this.is_signin_running = false;
				if (error.code == AUTH_MFA_SETUP_ERROR_CODE) {
					this.auth_service.mfa_setup_mechanism = 'sms';
					this.navigation_service.toAuthMfaSetup();
				} else {
					this.dialog_service.openInfoDialog(error.message);
				}
			},
		});
	}

	manageCognitoChallange(
		username: string,
		challenge_name: string,
		totp_setup_details?: any,
		code_delivery_details?: any,
	): void {
		switch (challenge_name) {
			case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
				const setup_uri_name = `Linkey ${
					environment.STAGE === 'prod' ? '' : '- ' + environment.STAGE
				}: ${username}`;
				this.auth_service.mfa_setup_mechanism = 'totp';
				this.auth_service.totp_setup_secret = totp_setup_details.sharedSecret;
				this.auth_service.totp_setup_uri =
					totp_setup_details?.getSetupUri(setup_uri_name);
				this.navigation_service.toAuthMfaSetup();
				break;
			case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
				this.auth_service.mfa_setup_mechanism = 'totp';
				this.navigation_service.toAuthMfaVerify();
				this.is_signin_running = false;
				break;
			case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
				this.auth_service.mfa_setup_mechanism = 'sms';
				this.auth_service.sms_delivery_destination =
					code_delivery_details.destination;
				this.navigation_service.toAuthMfaVerify();
				this.is_signin_running = false;
				break;
			default:
				this.dialog_service.openInfoDialog(
					`Unknown challenge: ${challenge_name}`,
				);
				this.is_signin_running = false;
				break;
		}
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.signin_form_group);
	}
}
