import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { from, Observable } from 'rxjs';
import { get, post, put, del } from 'aws-amplify/api';
import { HttpClient } from '@angular/common/http';

export const VEHICLES_API_NAME: string = 'vehicles';
export const VEHICLES_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/vehicles/';

@Injectable({
	providedIn: 'root',
})
export class VehicleService {
	constructor(private http: HttpClient) {}

	create(station_id: string, vehicle: Vehicle): Observable<any> {
		const path: string = `stations/${station_id}`;

		const init = { response: true, body: vehicle };

		return from(
			post({
				apiName: VEHICLES_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	read(station_id: string, vehicle_id: string): Observable<any> {
		const path: string = `${vehicle_id}/stations/${station_id}`;

		const init = { response: true };

		return from(
			get({
				apiName: VEHICLES_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	update(station_id: string, vehicle: Vehicle): Observable<any> {
		const path: string = `${vehicle.id}/stations/${station_id}`;

		const init = { response: true, body: vehicle };

		return from(
			put({
				apiName: VEHICLES_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	delete(station_id: string, vehicle_id: string): Observable<any> {
		const path: string = `${vehicle_id}/stations/${station_id}`;

		const init = { response: true };

		return from(
			del({
				apiName: VEHICLES_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	_list(
		station_id: string,
		filters?: VehicleFiltersConfig,
		is_map_search?: boolean,
	): Observable<any> {
		const path: string = `stations/${station_id}`;

		let query_params = {
			page: filters.page ? filters.page : 1,
			per_page: filters.per_page ? filters.per_page : 30,
		};
		if (filters.plate) {
			query_params['plate'] = filters.plate;
		}
		if (filters.category) {
			query_params['category'] = filters.category;
		}
		if (filters.brand) {
			query_params['brand'] = filters.brand;
		}
		if (filters.status) {
			query_params['status'] = filters.status;
		}

		if (is_map_search) {
			query_params['map_search'] = is_map_search;
		}

		const init = { response: true, queryParams: query_params };

		return from(
			get({
				apiName: VEHICLES_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	list(
		station_id: string,
		page?: number,
		plate?: string,
		category?: string,
		brand?: string,
		status?: string,
		per_page?: number,
		is_map_search?: boolean,
	): Observable<any> {
		const VEHICLES_PER_PAGE: number = per_page ? per_page : 30;
		const path: string = `stations/${station_id}`;

		let query_params = {
			page: page ? page : 1,
			per_page: page ? VEHICLES_PER_PAGE : 100000000,
		};
		if (plate) {
			query_params['plate'] = plate;
		}
		if (category) {
			query_params['category'] = category;
		}
		if (brand) {
			query_params['brand'] = brand;
		}
		if (status) {
			query_params['status'] = status;
		}
		if (is_map_search) {
			query_params['map_search'] = is_map_search;
		}
		const init = { response: true, queryStringParameters: query_params };

		return from(
			get({
				apiName: VEHICLES_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	startVehicle(station_id: string, vehicle: Vehicle): Observable<any> {
		const path: string = `${vehicle.id}/stations/${station_id}/start`;

		const init = { response: true };

		return from(
			post({
				apiName: VEHICLES_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	stopVehicle(station_id: string, vehicle: Vehicle): Observable<any> {
		const path: string = `${vehicle.id}/stations/${station_id}/stop`;

		const init = { response: true };

		return from(
			post({
				apiName: VEHICLES_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	sendRequest(station_id: string, request: VehicleRequest): Observable<any> {
		const path: string = `stations/${station_id}/send-request`;

		const init = { response: true, body: request };

		return from(
			post({
				apiName: VEHICLES_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	export(station_id: string): Observable<any> {
		const path: string = `stations/${station_id}/export`;

		const init = {
			response: true,
			headers: {
				Accept: 'text/csv',
			},
			responseType: 'blob',
		};

		return from(
			get({
				apiName: VEHICLES_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	sendDirectLink(
		vehicle_id: string,
		station_id: string,
		nbf: number,
		exp: number,
		full_phone?: string,
		type?: string,
	): Observable<any> {
		const path: string = `${vehicle_id}/stations/${station_id}/send-direct-link`;

		const init = {
			response: true,
			body: {
				nbf,
				exp,
				full_phone,
				type,
			},
		};

		return from(
			get({
				apiName: VEHICLES_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}
}

export class VehicleFiltersConfig {
	page?: number;
	per_page?: number;
	plate?: string;
	category?: string;
	brand?: string;
	status?: string;
}

export class VehicleRequest {
	station_name?: string;
	subject?: string;
	description?: string;
	email?: string;
	phone?: string;
}

export class Vehicle {
	id: string;
	plate: string;
	reference_code: string;
	status: VehicleStatus;
	category: string;
	brand: string;
	model: string;
	color: string;
	year: number;
	img_urls: string[];
	damages: VehicleDamage[];
	intervations: VehicleIntervention[];
	capabilities: string[];
	stats: VehicleStats;
	adapter: AdapterVehicle;
	ext_id: string;
	station_id?: string;
}

export enum VehicleStatus {
	UNLOCKED = 'unlocked',
	LOCKED = 'locked',
	MOVING = 'moving',
}

export class VehicleDamage {
	date: number;
	description: string;
	image: string;
	booking_id: string;
	reporter: any;
	is_user_report: boolean;
}

export class VehicleIntervention {
	date: number;
	description: string;
	km: number;
	operator: any;
}

export class VehicleAutonomy {
	distance: number;
	percentage: number;
}

export type VehicleStats = {
	online: VehicleStatsOnline;
	distance_covered: VehicleStatsDistance;
	position: VehicleStatsPosition;
	autonomy_percentage: VehicleStatsAutonomyPercentage;
	autonomy_meters: VehicleStatsAutonomyMeters;
	external_voltage: VehicleStatsExternalVoltage;
	updated_at: number;
};

export type VehicleStatsDistance = {
	value: number;
	updated_at: number;
};

export type VehicleStatsOnline = {
	value: boolean;
	updated_at: number;
};

export type VehicleStatsPosition = {
	value: { latitude: number; longitude: number };
	updated_at: number;
};

export type VehicleStatsAutonomyMeters = {
	value: number;
	updated_at: number;
};

export type VehicleStatsAutonomyPercentage = {
	value: number;
	updated_at: number;
};

export type VehicleStatsExternalVoltage = {
	value: number;
	updated_at: number;
};

export class AdapterVehicle {
	id?: string;
	subscription_id?: string;
	qrcode?: string;
	profile?: string;
	topic?: string;
	imei?: string;
	oem?: string;
}

export enum VehicleCategory {
	COMMERCIAL = 'commercial',
	LARGE = 'large',
	VAN = 'van',
	WAGON = 'wagon',
	MEDIUM = 'medium',
	PREMIUM = 'premium',
	SMALL = 'small',
	SUV = 'suv',
}

export enum VehicleOdometerUms {
	mi = 'mi',
	km = 'km',
}
