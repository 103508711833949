<header>
	<mat-toolbar
		[ngClass]="getToolbarShadowClass()"
		class="lk-shared-toolbar lk-primary-background">
		<mat-toolbar-row class="lk-spaced-row lk-shared-toolbar-row">
			<div class="lk-row">
				<div
					style="max-width: 175px"
					class="lk-row">
					<mat-spinner
						color="accent"
						diameter="20"
						*ngIf="!logo"></mat-spinner>

					<div
						(click)="onHomeClick()"
						*ngIf="logo"
						[ngStyle]="{ 'background-image': 'url(' + logo + ')' }"
						class="lk-toolbar-logo-container lk-cursor-pointer lk-primary-background"></div>

					<span
						*ngIf="station"
						[matMenuTriggerFor]="stations_menu"
						#menuTrigger="matMenuTrigger"
						class="lk-ellipsized-text lk-cursor-pointer lk-half-margin-left"
						style="font-size: 12px">
						{{ station.name }}
					</span>
					<mat-icon
						[matMenuTriggerFor]="stations_menu"
						#menuTrigger="matMenuTrigger"
						class="lk-cursor-pointer">
						keyboard_arrow_down
					</mat-icon>

					<mat-menu #stations_menu="matMenu">
						<button
							(click)="onStationBuildClick()"
							mat-menu-item>
							<mat-icon> add </mat-icon>
							<span> {{ 'STATION.BUILDER_CTA' | translate }} </span>
						</button>
						<button
							mat-menu-item
							*ngFor="let station of stations"
							(click)="onStationClick(station)">
							<div class="lk-row">
								<div
									[ngStyle]="{
										'background-image': 'url(' + getStationLogo(station) + ')'
									}"
									class="lk-toolbar-logo-container"></div>
								<span class="lk-half-margin-left"> {{ station.name }} </span>
							</div>
						</button>
					</mat-menu>
				</div>

				<div
					*ngIf="station"
					class="lk-row lk-hidden-before-md">
					<span
						(click)="onNavItemClick(item)"
						[ngClass]="getNavItemClass(item)"
						class="lk-cursor-pointer lk-margin-left lk-toolbar-nav-item lk-accent-color-hover"
						*ngFor="let item of nav_items">
						{{ item.name | translate }}
					</span>
				</div>
			</div>

			<div class="lk-row">
				<span id="lk-shared-toolbar-username"> {{ username }} </span>
				<button
					id="lk-shared-toolbar-menu-ham-button"
					(click)="onMenuClick()"
					mat-icon-button>
					<mat-icon id="lk-shared-toolbar-menu-ham-button"> menu </mat-icon>
				</button>

				<button
					id="lk-shared-toolbar-menu-arrow-button"
					mat-icon-button
					[matMenuTriggerFor]="menu"
					#menuTrigger="matMenuTrigger">
					<mat-icon> expand_more </mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button
						class="lk-shared-toolbar-menu-item"
						mat-menu-item
						*ngFor="let item of nav_items"
						(click)="onNavItemClick(item)">
						<mat-icon> {{ item.icon }} </mat-icon>
						{{ item.name | translate }}
					</button>
					<button
						mat-menu-item
						(click)="onProfileclick()">
						<mat-icon> person </mat-icon>
						{{ 'PROFILE.TITLE' | translate }}
					</button>
					<button
						mat-menu-item
						(click)="onSignoutClick()">
						<mat-icon> logout </mat-icon>
						Logout
					</button>
				</mat-menu>
			</div>
		</mat-toolbar-row>
	</mat-toolbar>
</header>
