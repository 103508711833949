<div class="lk-height__max-80vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x12-horiz">
		<h3>{{ 'BOOKING.ADD_USER_DIALOG_TITLE' | translate }}.</h3>

		<form
			class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-top lk-margin-bottom"
			[formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-width__half-x8 lk-form-field lk-half-margin-bottom">
				<mat-label>
					{{ 'BOOKING.USER_FIRST_NAME_LABEL' | translate }}
				</mat-label>
				<input
					id="lk-booking-user-builder-firstname-input"
					matInput
					type="text"
					formControlName="first_name"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-width__half-x8 lk-form-field lk-half-margin-bottom">
				<mat-label>
					{{ 'BOOKING.USER_LAST_NAME_LABEL' | translate }}
				</mat-label>
				<input
					id="lk-booking-user-builder-lastname-input"
					matInput
					type="text"
					formControlName="last_name"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-form-field lk-half-margin-bottom">
				<mat-label>
					{{ 'GENERAL.EMAIL_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					id="lk-booking-user-builder-email-input"
					matInput
					type="email"
					formControlName="email" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-full-width lk-form-field lk-half-margin-bottom">
				<mat-label> {{ 'BOOKING.USER_TAX_CODE_LABEL' | translate }} </mat-label>
				<input
					id="lk-booking-user-builder-tax-code-input"
					matInput
					type="text"
					formControlName="tax_code" />
			</mat-form-field>

			<app-button
				class="lk-width__full lk-margin__x24-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.CONFIRM_CTA' | translate"
				[is_disabled]="is_request_loading"
				[is_spinner_visible]="is_request_loading"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>
