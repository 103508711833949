import { Component, OnInit } from '@angular/core';
import { FormService } from '../../core/form.service';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
	InvitationResponseType,
	InvitationService,
} from 'src/app/core/invitation.service';
import { DialogService } from 'src/app/core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import {
	GENERAL_ERROR_MESSAGE,
	STATION_BUILDER_TEAM_STEP,
	TOOLBAR_STATION_NAV_ITEM_NAME,
} from 'src/app/shared/translations';
import { Admin, AdminService } from 'src/app/core/admin.service';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication.service';
import {
	STATION_BUILDER_SETTINGS_PATH,
	STATION_PATH,
} from 'src/app/core/navigation.service';
import { ERROR_DESCRIPTION_500 } from 'src/app/shared/constants';
import { ErrorService } from 'src/app/core/error.service';

@Component({
	selector: 'app-station-builder-team',
	templateUrl: './station-builder-team.component.html',
	styleUrls: ['./station-builder-team.component.scss'],
})
export class StationBuilderTeamComponent implements OnInit {
	station_id: string;
	admins: Admin[];
	invites: string[];
	form_group: FormGroup;
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	current_item: string = TOOLBAR_STATION_NAV_ITEM_NAME;
	update_mode: boolean = false;
	is_request_running: boolean = false;
	is_team_loading: boolean = false;
	currentStep: string = STATION_BUILDER_TEAM_STEP;

	constructor(
		private form_service: FormService,
		private route: ActivatedRoute,
		private invitation_service: InvitationService,
		private admin_service: AdminService,
		private router: Router,
		private auth_service: AuthenticationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private error_service: ErrorService,
	) {
		this.dialog_service.dialog = this.dialog;
		this.form_group = form_service.stationBuilderTeamFormGroup;
		this.station_id = this.route.snapshot.paramMap.get('station_id');
		this.route.queryParams.subscribe(params => {
			if (params && params.update) {
				this.update_mode = params.update;
			}
		});
		if (!this.station_id) {
			this.router.navigate([STATION_PATH]);
		}
	}

	ngOnInit() {
		this.station_update_trigger.next(false);
		if (this.station_id) {
			this.listAdmins();
		}
	}

	onEmailChange() {
		if (this.form_service.validateForm(this.form_group)) {
			this.inviteTeammates([this.form_group.get('email').value]);
			this.form_group.reset();
		}
	}

	onTeammateRemoveClick(admin: Admin) {
		this.removeAdmins([admin]);
	}

	onNextClick() {
		this.router.navigate([
			STATION_PATH + '/' + this.station_id + STATION_BUILDER_SETTINGS_PATH,
		]);
	}

	onUpdateClick() {
		this.router.navigate([STATION_PATH]);
	}

	listAdmins() {
		this.is_request_running = true;
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				let currentAdmin: string = response.signInDetails?.loginId;
				this.admin_service.listByStationId(this.station_id).subscribe({
					next: response => {
						this.admins = response.data.items.filter(admin => {
							return admin.email != currentAdmin;
						});
						this.listInvites();
					},
					error: () => {
						this.admins = [];
						this.is_request_running = false;
					},
				});
			},
			error: () => {
				this.is_request_running = false;
				this.auth_service.signOut();
			},
		});
	}

	listInvites() {
		this.is_request_running = true;
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				let currentAdmin: string = response.signInDetails?.loginId;
				this.invitation_service.listByStation(this.station_id).subscribe({
					next: response => {
						this.invites = response.data
							.filter(invitation => {
								return invitation.response == InvitationResponseType.PENDING;
							})
							.map(a => a.admin_email)
							.filter(email => {
								return email != currentAdmin;
							});
						this.is_request_running = false;
					},
					error: () => {
						this.invites = [];
						this.is_request_running = false;
					},
				});
			},
			error: () => {
				this.is_request_running = false;
				this.auth_service.signOut();
			},
		});
	}

	inviteTeammates(invites: string[]) {
		this.is_team_loading = true;
		this.invitation_service.batchCreate(this.station_id, invites).subscribe({
			next: () => {
				this.is_team_loading = false;
				if (this.invites) {
					this.invites.push(invites[0]);
				} else {
					this.invites = invites;
				}
			},
			error: error => {
				this.is_team_loading = false;
				this.error_service.wrapError(error);
			},
		});
	}

	removeAdmins(admins: Admin[]) {
		this.is_team_loading = true;
		const adminIDs: string[] = admins.map(a => a.id);
		this.admin_service.removeFromStation(this.station_id, adminIDs).subscribe({
			next: () => {
				this.is_team_loading = false;
				const index = this.admins.map(a => a.email).indexOf(admins[0].email);
				if (index != -1) {
					this.admins.splice(index, 1);
				}
			},
			error: error => {
				this.is_team_loading = false;
				this.error_service.wrapError(error);
			},
		});
	}

	isStepTextVisible(step: string) {
		return step == this.currentStep;
	}

	getBarClass(step: string) {
		return {
			'lk-accent-background': step == this.currentStep,
			'lk-primary-medium-background': step != this.currentStep,
		};
	}
}
