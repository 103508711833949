<div class="lk-width__max-450 lk-width__min-350 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x24">
		<div class="lk-row">
			<img
				class="lk-flag lk-margin__0"
				src="{{ getStationFlag() }}" />
			<p class="lk-margin__x12-left">
				{{ getTemplateLabel() | translate }}
			</p>
		</div>

		<form
			*ngIf="template_type == sms_type"
			class="lk-width__full lk-margin__x24-top lk-row lk-wrap"
			[formGroup]="template_add_item_form_group">
			<mat-form-field
				style="width: calc(100% - 52px)"
				class="lk-width__full lk-form-field lk-form-field__params-select">
				<mat-select
					(selectionChange)="onParameterSelection($event)"
					matInput
					type="text"
					placeholder="{{ 'STATION.PARAMETERS_LIST_LABEL' | translate }}"
					formControlName="current_parameter">
					<mat-option
						[value]="param"
						*ngFor="let param of params_list">
						{{ param }}
					</mat-option>
				</mat-select>

				<button
					mat-icon-button
					matSuffix>
					<mat-icon class="lk-primary-medium-color"> add </mat-icon>
				</button>
			</mat-form-field>

			<button
				mat-icon-button
				matTooltip="{{ 'STATION.PARAMETERS_LIST_TOOLTIP' | translate }}"
				class="lk-background-icon lk-half-margin-left lk-hidden-before-lg">
				<mat-icon class="lk-accent-color"> info_outlined </mat-icon>
			</button>

			<button
				(click)="onInfoClick()"
				mat-icon-button
				class="lk-background-icon lk-half-margin-left lk-hidden-after-lg">
				<mat-icon class="lk-accent-color"> info_outlined </mat-icon>
			</button>

			<mat-form-field class="lk-width__full lk-margin__x12-top lk-form-field">
				<mat-label> content </mat-label>
				<textarea
					style="min-height: 150px"
					type="text"
					matInput
					formControlName="text"></textarea>
			</mat-form-field>
		</form>

		<div
			*ngIf="template_type == email_type"
			class="lk-margin__x24-top">
			<hr class="lk-stroke-dashed lk-width__full" />
			<span>
				{{ 'STATION.NOTIF_EMAIL_FILE_UPLOAD_CTA' | translate }}
			</span>
			<hr class="lk-stroke-dashed lk-width__full" />
		</div>

		<button
			*ngIf="template_type == email_type"
			(click)="upload_input.click()"
			style="min-height: 100px"
			class="lk-width__full lk-button__neutral lk-border-radius"
			mat-button>
			<mat-icon
				*ngIf="!this.template?.content"
				class="lk-accent-color">
				upload
			</mat-icon>
			<span
				*ngIf="this.template?.content"
				class="lk-accent-color">
				uploaded
			</span>
			<input
				#upload_input
				type="file"
				accept="text/*"
				(change)="onUploadClick($event)"
				style="display: none" />
		</button>

		<app-button
			class="lk-width__full lk-margin__x24-top lk-display__block"
			[button_class]="'lk-button__secondary lk-width__full'"
			[spinner_class]="'lk-button__spinner-white'"
			[cta]="'GENERAL.CONFIRM_CTA' | translate"
			[is_disabled]="!isFormValid() || is_create_running"
			[is_spinner_visible]="is_create_running"
			(button_click)="onConfirmClick()"></app-button>
	</div>
</div>
