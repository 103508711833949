<div
	id="lk-booking-details-vehicle-root-layout"
	class="lk-border-radius lk-shadow lk-padding lk-primary-background lk-hide-scrollbar lk-full-height"
	[ngClass]="getProductionLayoutClass()">
	<div class="lk-full-width lk-spaced-row">
		<h2 class="lk-no-margin lk-subtitle-size">
			{{ 'BOOKING_DETAILS.VEHICLE_SECTION_LABEL' | translate }}
		</h2>

		<button
			*ngIf="isAddVehicleVisible(booking)"
			(click)="onVehicleUpdateClick()"
			mat-icon-button
			class="lk-icon__button-neutral-40"
			matTooltip="{{ 'BOOKING_DETAILS.UPDATE_VEHICLE_CTA' | translate }}">
			<mat-icon class="lk-accent-color"> create </mat-icon>
		</button>
	</div>

	<div class="lk-half-margin-top">
		<div class="lk-double-margin-top">
			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					id="lk-booking-details-vehicle-plate-text"
					class="lk-no-margin lk-cursor-pointer lk-cursor-link-hover lk-half-margin-left lk-right-text lk-ellipsized-text"
					*ngIf="booking.vehicle"
					(click)="onVehicleClick()">
					{{ booking.vehicle.plate }}
				</p>
				<p
					id="lk-booking-details-vehicle-plate-empty-text"
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.MODEL_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text"
					*ngIf="booking.vehicle">
					{{ booking.vehicle.brand }} {{ booking.vehicle.model }}
				</p>
				<p
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.COLOR_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text"
					*ngIf="booking.vehicle">
					{{ booking.vehicle.color }}
				</p>
				<p
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.CATEGORY_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text"
					*ngIf="booking.vehicle">
					{{ booking.vehicle.category }}
				</p>
				<p
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.YEAR_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text"
					*ngIf="booking.vehicle">
					{{ booking.vehicle.year }}
				</p>
				<p
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color"
					style="font-size: 12px">
					{{ 'VEHICLE.STATUS_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<div
					class="lk-border-radius"
					*ngIf="booking.vehicle"
					[ngClass]="getVehicleStatusClass(booking.vehicle)"
					style="padding: 2px 12px; width: fit-content">
					<span *ngIf="booking.vehicle.status">
						{{ booking.vehicle.status | lowercase }}
					</span>
					<span *ngIf="!booking.vehicle.status"> unknown </span>
				</div>
				<p
					class="lk-no-margin"
					*ngIf="!booking.vehicle">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-row__space lk-wrap lk-margin__x24-top">
				<app-button
					class="lk-width__half-x8 lk-width__full-md lk-margin__x12-bottom lk-display__block"
					[button_class]="'lk-button__secondary lk-width__full'"
					[spinner_class]="'lk-button__spinner-white'"
					[icon]="'lock_open'"
					[cta]="'VEHICLE_DETAILS.UNLOCK_VEHICLE' | translate"
					[is_disabled]="is_start_vehicle_running"
					[is_spinner_visible]="is_start_vehicle_running"
					(button_click)="onStartVehicleClick(booking.vehicle)"></app-button>

				<app-button
					class="lk-width__half-x8 lk-width__full-md lk-margin__x12-bottom lk-display__block"
					[button_class]="'lk-button__secondary lk-width__full'"
					[spinner_class]="'lk-button__spinner-white'"
					[icon]="'lock'"
					[cta]="'VEHICLE_DETAILS.LOCK_VEHICLE' | translate"
					[is_disabled]="is_stop_vehicle_running"
					[is_spinner_visible]="is_stop_vehicle_running"
					(button_click)="onStopVehicleClick(booking.vehicle)"></app-button>
			</div>
		</div>
	</div>
</div>
