import { Component, OnInit } from '@angular/core';
import {
	BookingsSummary,
	BookingsSummaryService,
} from 'src/app/core/bookings-summary.service';
import { Station } from 'src/app/core/station.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';

@Component({
	selector: 'app-booking-summary',
	templateUrl: './booking-summary.component.html',
	styleUrls: ['./booking-summary.component.scss'],
})
export class BookingSummaryComponent implements OnInit {
	is_summary_loading: boolean = false;
	bookings_summary: BookingsSummary;

	constructor(private bookings_summary_service: BookingsSummaryService) {}

	ngOnInit(): void {
		if (
			localStorage.getItem(LOCAL_STATION_KEY) &&
			localStorage.getItem(LOCAL_STATION_KEY) != 'undefined'
		) {
			const station = JSON.parse(
				localStorage.getItem(LOCAL_STATION_KEY),
			) as Station;

			this.readBookingsSummary(station.id);
		}
	}

	onRefresh(): void {
		if (
			localStorage.getItem(LOCAL_STATION_KEY) &&
			localStorage.getItem(LOCAL_STATION_KEY) != 'undefined'
		) {
			const station = JSON.parse(
				localStorage.getItem(LOCAL_STATION_KEY),
			) as Station;

			this.readBookingsSummary(station.id);
		}
	}

	readBookingsSummary(station_id: string): void {
		this.is_summary_loading = true;
		this.bookings_summary_service.read(station_id).subscribe({
			next: response => {
				response.body.json().then(response => {
					this.bookings_summary = response;
					this.is_summary_loading = false;
				});
			},
			error: () => {
				this.is_summary_loading = false;
				this.bookings_summary = {
					total: 0,
					bookings_in_pending: 0,
					bookings_in_progress: 0,
					bookings_with_errors: 0,
					bookings_closed: 0,
				};
			},
		});
	}
}
