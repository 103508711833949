<app-toolbar
	(no_station_found)="onNoStationFound()"
	(station_build)="onStationBuild(false)"
	(station_change)="onStationChange($event)"></app-toolbar>

<section class="lk-page__with-toolbar lk-padding__x24">
	<app-hidden-stations-header
		(refresh)="onRefresh()"
		(search_change)="onSearchChange($event)"
		(alias_search_change)="onSearchChange($event, true)">
	</app-hidden-stations-header>

	<div
		*ngIf="is_request_running"
		class="lk-height__full lk-margin__x24-top lk-hidden-stations__grid-loading">
		<app-loading
			*ngFor="let loading of loadings"
			class="lk-width__full lk-height__full"></app-loading>
	</div>

	<div
		*ngIf="!is_request_running && stations?.length"
		class="lk-height__full lk-margin__x24-top lk-hidden-stations__grid">
		<app-hidden-stations-list-item
			*ngFor="let station of stations"
			class="lk-display__block"
			[station]="station"
			[station_logo]="station.logo_url"
			[station_name]="station.name"
			[station_id]="station.id"
			[is_current_station]="isCurrentStation(station)"
			(refresh)="onRefresh()"
			(station_click)="onStationChangeClick(station)">
		</app-hidden-stations-list-item>
	</div>

	<div
		*ngIf="
			!is_request_running && stations?.length && total_items > stations.length
		"
		class="lk-width__full lk-margin__x24-top lk-centered-column">
		<hr class="lk-list-separator lk-width__full" />
		<app-button
			[button_class]="'lk-button__secondary'"
			[spinner_class]="'lk-button__spinner-white'"
			[cta]="'GENERAL.LOAD_MORE_CTA' | translate"
			[is_disabled]="is_load_more_running"
			[is_spinner_visible]="is_load_more_running"
			(button_click)="onLoadMoreClick()"></app-button>
		<hr class="lk-list-separator lk-width__full" />
	</div>

	<div
		*ngIf="!is_request_running && !stations?.length"
		class="lk-width__full lk-margin__x24-top lk-centered-column">
		<hr class="lk-list-separator lk-width__full" />
		<span class="lk-text__center lk-margin__x12-bottom">
			<i>{{ 'STATION.NO_STATIONS_FOUND_TITLE' | translate }}</i>
		</span>
		<app-button
			style="min-width: 200px"
			[button_class]="'lk-button__secondary lk-width__full'"
			[spinner_class]="'lk-button__spinner-white'"
			[cta]="'Refresh' | translate"
			(button_click)="onRefresh()"></app-button>
		<hr class="lk-list-separator lk-width__full" />
	</div>
</section>
