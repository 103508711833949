<div style="min-width: 350px; max-height: 500px">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x12-horiz lk-margin__x24-bottom">
		<h2>Super Admins</h2>

		<hr class="lk-list-separator" />
		<div
			*ngIf="!is_list_running"
			class="lk-width__full"
			style="max-height: 200px; overflow-y: scroll">
			<div
				*ngFor="let admin of super_admins"
				class="lk-row__space">
				<span>
					{{ admin.email }}
				</span>

				<button
					mat-icon-button
					(click)="onRemoveClick(admin)">
					<mat-icon>cancel</mat-icon>
				</button>
			</div>

			<p
				*ngIf="!super_admins.length"
				class="lk-text__center">
				No super admin found
			</p>
		</div>

		<div
			*ngIf="is_list_running"
			class="lk-width__full lk-row__center">
			<mat-spinner
				[diameter]="20"
				class="lk-button__spinner-accent"></mat-spinner>
		</div>
		<hr class="lk-list-separator" />

		<form
			[formGroup]="form_group"
			class="lk-margin__x24-top">
			<mat-form-field
				appearance="fill"
				class="lk-width__full lk-form-field"
				color="accent">
				<mat-label> Email </mat-label>
				<input
					type="text"
					matInput
					formControlName="email" />
			</mat-form-field>

			<app-button
				class="lk-width__full lk-margin__x24-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'Add super admin'"
				[is_disabled]="is_save_running"
				[is_spinner_visible]="is_save_running"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>
