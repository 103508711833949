import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Station } from 'src/app/core/station.service';
import { VehicleService } from 'src/app/core/vehicle.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import { saveAs } from 'file-saver';
import { NavigationService } from 'src/app/core/navigation.service';
import { DialogService } from 'src/app/core/dialog.service';
import { VEHICLE_MANUAL_REQUEST_SENT_MESSAGE } from 'src/app/shared/translations';
import { VehicleManualContactsDialogComponent } from '../vehicle-manual-contacts-dialog/vehicle-manual-contacts-dialog.component';
import { VehicleManualInfoDialogComponent } from '../vehicle-manual-info-dialog/vehicle-manual-info-dialog.component';

@Component({
	selector: 'app-vehicle-header',
	templateUrl: './vehicle-header.component.html',
	styleUrl: './vehicle-header.component.scss',
})
export class VehicleHeaderComponent {
	@Input() is_vehicle_map_visible = false;
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();

	is_export_running: boolean = false;

	constructor(
		private vehicle_service: VehicleService,
		private navigation_service: NavigationService,
		private dialog_service: DialogService,
	) {}

	onMapClick(): void {
		this.navigation_service.toVehiclesMap();
	}

	onVehiclesDownloadClick(): void {
		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		this.export(station.id);
	}

	onRequestManualClick(): void {
		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		this.dialog_service
			.openDialog(VehicleManualContactsDialogComponent, {
				station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					const station_name: string = dialog_result.station_name;
					const station_email: string = dialog_result.station_email;
					this.onRequestManualSend(station, station_name, station_email);
				}
			});
	}

	onRequestManualSend(
		station: Station,
		station_name: string,
		station_email: string,
	): void {
		this.dialog_service
			.openDialog(VehicleManualInfoDialogComponent, {
				station,
				station_name,
				station_email,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.dialog_service.openInfoDialog(
						VEHICLE_MANUAL_REQUEST_SENT_MESSAGE,
					);
				}

				if (dialog_result && !dialog_result.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	export(station_id: string): void {
		this.is_export_running = true;
		this.vehicle_service.export(station_id).subscribe({
			next: response => {
				response.body.blob().then(response => {
					saveAs(response, 'vehicles(' + new Date().toLocaleString() + ').csv');
					this.is_export_running = false;
				});
			},
			error: () => {
				this.is_export_running = false;
			},
		});
	}
}
