import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Station, StationService } from 'src/app/core/station.service';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-station-delete-dialog',
	templateUrl: './station-delete-dialog.component.html',
	styleUrls: ['./station-delete-dialog.component.scss'],
})
export class StationDeleteDialogComponent implements OnInit {
	station: Station;
	is_station_deleting: boolean = false;

	constructor(
		private dialogRef: MatDialogRef<StationDeleteDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private station_service: StationService,
	) {
		this.station = data.station;
	}

	ngOnInit(): void {}

	onCloseClick() {
		this.dialogRef.close();
	}

	onConfirmClick() {
		this.deleteStation(this.station);
	}

	deleteStation(station: Station): void {
		this.is_station_deleting = true;
		this.station_service.delete(station.id).subscribe({
			next: response => {
				this.is_station_deleting = false;
				this.dialogRef.close({ success: true });
			},
			error: error => {
				this.is_station_deleting = false;
				this.dialogRef.close({ error_message: GENERAL_ERROR_MESSAGE });
			},
		});
	}

	getDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': this.is_station_deleting,
			'lk-main-button': !this.is_station_deleting,
		};
	}
}
