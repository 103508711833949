import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrl: './button.component.scss',
})
export class ButtonComponent {
	@Input() button_class: string = 'lk-button__secondary';
	@Input() spinner_class?: string = 'lk-button__spinner-white';
	@Input() cta: string = '';
	@Input() icon?: string;
	@Input() icon_class?: string;
	@Input() text_class?: string;
	@Input() is_icon_suffix?: boolean;
	@Input() is_disabled?: boolean;
	@Input() is_spinner_visible?: boolean;
	@Output() button_click: EventEmitter<void> = new EventEmitter<void>();
}
