import { Component, Inject, OnInit } from '@angular/core';
import { Station } from '../../core/station.service';
import {
	LOCAL_STATION_KEY,
	VEHICLE_NOT_FOUND_ERROR_CODE,
} from '../../shared/constants';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../core/form.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalManagerService } from '../../core/local-manager.service';
import {
	Vehicle,
	VehicleService,
	VehicleStatus,
} from '../../core/vehicle.service';
import { DialogService } from 'src/app/core/dialog.service';
import { map, Observable, startWith } from 'rxjs';
import { NavigationService } from 'src/app/core/navigation.service';
import { ErrorService } from 'src/app/core/error.service';

@Component({
	selector: 'app-booking-add-vehicle-dialog',
	templateUrl: './booking-add-vehicle-dialog.component.html',
	styleUrls: ['./booking-add-vehicle-dialog.component.scss'],
})
export class BookingAddVehicleDialogComponent implements OnInit {
	form_group: FormGroup;
	is_request_loading: boolean = false;
	vehicle: Vehicle;
	vehicles: Vehicle[];
	filtered_vehicles: Observable<Vehicle[]>;
	is_request_running: boolean = false;

	constructor(
		private form_service: FormService,
		private vehicle_service: VehicleService,
		private error_service: ErrorService,
		private navigation_service: NavigationService,
		private dialog_ref: MatDialogRef<BookingAddVehicleDialogComponent>,
		private local_manager: LocalManagerService,
	) {
		this.form_group = this.form_service.bookingAddVehicleFormGroup;
	}

	ngOnInit() {
		this.form_group.reset();
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.listVehicles(station.id);

		this.filtered_vehicles = this.form_group.get('plate').valueChanges.pipe(
			startWith(''),
			map(value => this.filterVehicles(value || '')),
		);
	}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onConfirmClick() {
		const vehicle: Vehicle = this.vehicles.filter(v => {
			return (
				v.plate.toLowerCase() ==
				this.form_group.get('plate').value?.toLowerCase()
			);
		})[0];
		this.dialog_ref.close({ vehicle: vehicle });
	}

	listVehicles(station_id: string) {
		const filters = { page: 1, per_page: 1000000 };

		this.is_request_running = true;
		this.vehicle_service._list(station_id, filters).subscribe({
			next: response => {
				response.body.json().then(response => {
					this.vehicles = response.items;
					this.is_request_running = false;
				});
			},
			error: error => {
				const converted_error = this.error_service.convertError(error);

				if (converted_error.code === VEHICLE_NOT_FOUND_ERROR_CODE) {
					this.vehicles = [];
					this.is_request_running = false;
					this.navigation_service.toVehicles();
				}

				if (converted_error.code != VEHICLE_NOT_FOUND_ERROR_CODE) {
					this.error_service.processError(converted_error);
				}
			},
		});
	}

	filterVehicles(value: string) {
		const filterValue = value.toLowerCase();
		return this.vehicles.filter(option =>
			option.plate.toLowerCase().includes(filterValue),
		);
	}

	getSearchDisabledClass() {
		return {
			'lk-disabled-button': this.is_request_loading,
			'lk-main-button': !this.is_request_loading,
		};
	}

	getNextDisabledClass() {
		return {
			'lk-disabled-button': !this.getNextDisabledCondition(),
			'lk-main-button': this.getNextDisabledCondition(),
		};
	}

	getNextDisabledCondition(): boolean {
		return this.vehicles
			.map(v => v.plate.toLowerCase())
			.includes(this.form_group.get('plate').value?.toLowerCase());
	}

	getVehicleStatusClass(vehicle: Vehicle) {
		return {
			'lk-vehicle-green-status':
				vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}

	getOnlineClass(vehicle: Vehicle) {
		return {
			'lk-state-300-background':
				vehicle?.stats?.online && vehicle?.stats?.online?.value,
			'lk-state-50-background':
				!vehicle?.stats?.online || !vehicle?.stats?.online?.value,
		};
	}
}
