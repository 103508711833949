import { Component, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { DialogService } from 'src/app/core/dialog.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { Station } from 'src/app/core/station.service';
import { SharedMenuComponent } from 'src/app/shared/shared-menu/shared-menu.component';

@Component({
	selector: 'app-toolbar-profile',
	templateUrl: './toolbar-profile.component.html',
	styleUrl: './toolbar-profile.component.scss',
})
export class ToolbarProfileComponent {
	@Input() station?: Station;
	username?: string;

	constructor(
		private auth_service: AuthenticationService,
		private dialog_service: DialogService,
		private navigation_service: NavigationService,
	) {}

	ngOnInit(): void {
		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				this.username = response.signInDetails?.loginId;
			},
		});
	}

	onMenuClick(): void {
		this.dialog_service.openDialog(SharedMenuComponent, {
			station: this.station,
		});
	}

	onProfileClick(): void {
		this.navigation_service.toProfile();
	}

	onSignoutClick(): void {
		this.auth_service.signOut().subscribe();
	}
}
