<div
	*ngIf="!logo"
	class="lk-shared-logo lk-row__center">
	<mat-spinner
		class="lk-button__spinner-accent"
		diameter="20"></mat-spinner>
</div>

<div
	(click)="logo_click.emit()"
	*ngIf="logo"
	[ngStyle]="{ 'background-image': 'url(' + logo + ')' }"
	[ngClass]="getLogoClass()"
	class="lk-shared-logo lk-background__light-neutral-10 lk-cursor__pointer"></div>
