<div class="lk-station-panel lk-padding__x24 lk-margin__auto">
	<app-loading
		*ngIf="!station"
		class="lk-width__full lk-height__full"></app-loading>

	<div
		*ngIf="station"
		style="position: relative"
		class="lk-padding__x24-after-md lk-height__full lk-border-radius lk-shadow lk-background__light-neutral-0 lk-row">
		<app-station-panel-logo
			[logo]="logo"
			[station]="station"></app-station-panel-logo>

		<app-station-panel-info
			class="lk-display__hidden-before-md"
			[station]="station"
			(update_click)="onUpdateClick()"
			(delete_click)="onDeleteClick()"></app-station-panel-info>
	</div>
</div>

<app-station-panel-info-mobile
	*ngIf="station"
	class="lk-display__hidden-after-md"
	[station]="station"
	(update_click)="onUpdateClick()"
	(delete_click)="onDeleteClick()"></app-station-panel-info-mobile>
