<app-toolbar
	[current_nav_item]="current_item"
	(no_station_found)="onNoStationFound()"
	(station_build)="onStationBuild(false)"
	(station_change)="onStationChange()"></app-toolbar>

<section class="lk-page__with-toolbar lk-padding__x24">
	<app-booking-header
		(refresh)="onRefresh()"
		(create_booking)="onBookingCreate($event)"></app-booking-header>

	<app-booking-summary></app-booking-summary>

	<app-booking-table
		*ngIf="!is_bookings_loading"
		class="lk-display__hidden-before-md"
		[bookings]="bookings"
		(search_change)="onSearchChange($event)"
		(filters_change)="onFiltersClick()"
		(delete_click)="onDeleteBookingClick($event)"
		(sort_change)="onSortStartDateClick()"
		(vehicle_add)="onAssignVehicleClick($event)"></app-booking-table>

	<app-booking-list
		*ngIf="!is_bookings_loading"
		class="lk-display__hidden-after-md"
		[bookings]="bookings"
		(search_change)="onSearchChange($event)"
		(filters_change)="onFiltersClick()"
		(delete_click)="onDeleteBookingClick($event)"
		(sort_change)="onSortStartDateClick($event)"
		(vehicle_add)="onAssignVehicleClick($event)"></app-booking-list>

	<div
		#loadMoreLayout
		class="lk-width__full lk-row__center lk-margin__x12-top"
		*ngIf="!is_bookings_loading && !is_last_page">
		<app-button
			[button_class]="'lk-button__secondary lk-width__full'"
			[spinner_class]="'lk-button__spinner-white'"
			[cta]="'GENERAL.LOAD_MORE_CTA' | translate"
			[is_disabled]="is_page_loading"
			[is_spinner_visible]="is_page_loading"
			(button_click)="onLoadPageClick()"></app-button>
	</div>

	<app-loading
		class="lk-width__full lk-display__block"
		style="height: 1000px"
		*ngIf="is_bookings_loading"></app-loading>
</section>
