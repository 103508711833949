<button
	style="position: absolute; top: 24px; right: 24px"
	mat-icon-button
	class="lk-icon__button-neutral-40"
	[matMenuTriggerFor]="menu"
	#menuTrigger="matMenuTrigger">
	<mat-icon class="lk-accent-color"> more_horiz </mat-icon>
</button>

<mat-menu #menu="matMenu">
	<button
		mat-menu-item
		(click)="update_click.emit()">
		<mat-icon class="lk-accent-color"> create </mat-icon>
		<p>{{ 'STATION.UPDATE_STATION_CTA' | translate }}</p>
	</button>
	<button
		mat-menu-item
		(click)="delete_click.emit()">
		<mat-icon class="lk-warn-color"> delete </mat-icon>
		<p>{{ 'STATION.DELETE_STATION_CTA' | translate }}</p>
	</button>
</mat-menu>
