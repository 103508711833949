<header (window:resize)="menuTrigger.closeMenu(); onCloseDialog()">
	<mat-toolbar class="lk-shared-toolbar lk-primary-medium-background">
		<mat-toolbar-row class="lk-spaced-row lk-shared-toolbar-row">
			<div class="lk-centered-row">
				<img
					(click)="onLogoClick()"
					class="lk-shared-toolbar-logo lk-cursor-pointer"
					[src]="logo"
					*ngIf="logo" />

				<mat-spinner
					color="accent"
					diameter="20"
					*ngIf="!logo"></mat-spinner>

				<div
					class="lk-shared-toolbar-nav-layout lk-centered-row lk-margin-left">
					<div
						class="lk-margin-right lk-cursor-pointer lk-cursor-link-hover"
						[ngClass]="getNavItemClass(item)"
						*ngFor="let item of nav_items"
						(click)="onNavItemClick(item)">
						<span> {{ item.name | translate }} </span>
					</div>
				</div>
			</div>

			<div class="lk-centered-row">
				<span id="lk-shared-toolbar-username"> {{ username }} </span>
				<button
					id="lk-shared-toolbar-menu-ham-button"
					mat-icon-button
					(click)="onMenuClick()">
					<mat-icon id="lk-shared-toolbar-menu-ham-button"> menu </mat-icon>
				</button>

				<button
					id="lk-shared-toolbar-menu-arrow-button"
					mat-icon-button
					[matMenuTriggerFor]="menu"
					#menuTrigger="matMenuTrigger">
					<mat-icon id="lk-shared-toolbar-menu-arrow-button">
						expand_more
					</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button
						class="lk-shared-toolbar-menu-item"
						mat-menu-item
						*ngFor="let item of nav_items"
						(click)="onNavItemClick(item)">
						<mat-icon> {{ item.icon }} </mat-icon>
						{{ item.name | translate }}
					</button>
					<button
						mat-menu-item
						(click)="onSignoutClick()">
						<mat-icon> logout </mat-icon>
						Logout
					</button>
				</mat-menu>
			</div>
		</mat-toolbar-row>
	</mat-toolbar>
</header>
