<div class="lk-padding__x24-horiz">
	<div
		style="position: relative"
		class="lk-height__full lk-border-radius lk-shadow lk-background__light-neutral-0 lk-row">
		<app-station-panel-info
			[station]="station"
			(update_click)="update_click.emit()"
			(delete_click)="delete_click.emit()"></app-station-panel-info>
	</div>
</div>
