import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { UtilsService } from 'src/app/core/utils.service';
import { CODE_MISMATCH_ERROR_CODE } from 'src/app/shared/constants';
import { CODE_MISMATCH_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-auth-mfa-setup-totp',
	templateUrl: './auth-mfa-setup-totp.component.html',
	styleUrls: ['./auth-mfa-setup-totp.component.scss'],
})
export class AuthMfaSetupTotpComponent implements OnInit {
	@Input() logo_src: string = '../../../assets/imgs/lk-logo.svg';

	is_app_setup?: boolean = false;
	is_mfa_setup_running: boolean = false;

	form_group?: FormGroup;

	setup_secret?: string;
	setup_uri?: any;

	constructor(
		private form_service: FormService,
		private auth_service: AuthenticationService,
		private dialog_service: DialogService,
		private navigation_service: NavigationService,
		private utils_service: UtilsService,
	) {
		this.form_group = this.form_service.mfa_setup_totp_form_group;
	}

	ngOnInit(): void {
		this.setup_secret = this.auth_service.totp_setup_secret;
		this.setup_uri = this.auth_service.totp_setup_uri;
		this.form_group.reset();
	}

	onMfaSetupClick(): void {
		if (this.isFormValid() && !this.is_mfa_setup_running) {
			const token = this.form_group.get('token')?.value;
			this.confirmSignin(token);
		}
	}

	onAppSetupClick(): void {
		this.is_app_setup = true;
	}

	onBackClick(): void {
		this.is_app_setup = false;
	}

	onCopyClick(content?: string): void {
		this.utils_service.contentCopy(content);
	}

	confirmSignin(code: string): void {
		this.is_mfa_setup_running = true;
		this.auth_service.confirmSignin(code).subscribe({
			next: () => {
				this.navigation_service.toVehicles();
				this.is_mfa_setup_running = false;
			},
			error: error => {
				if (error.code == CODE_MISMATCH_ERROR_CODE) {
					this.dialog_service.openInfoDialog(CODE_MISMATCH_ERROR_MESSAGE);
				} else {
					this.dialog_service.openInfoDialog(error.message);
				}
				this.is_mfa_setup_running = false;
			},
		});
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.form_group);
	}
}
