<div class="lk-width__max-450">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x24-horiz lk-margin__x24-top">
		<p class="lk-margin__x24-bottom">
			{{ 'AUTHENTICATION.FORGOT_PSS_MESSAGE' | translate }}
		</p>

		<form [formGroup]="form_group">
			<mat-form-field
				class="lk-width__full lk-margin__x24-bottom lk-form-field">
				<mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
				<input
					type="email"
					matInput
					formControlName="username"
					placeholder="address@email.com"
					required />
			</mat-form-field>

			<app-button
				class="lk-width__full lk-margin__x24-bottom lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.NEXT_STEP_BUTTON_CTA' | translate"
				[is_disabled]="!isFormValid() || is_forgot_pss_running"
				[is_spinner_visible]="is_forgot_pss_running"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>
