import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/auth.guard';
import { StationComponent } from './station.component';
import { STATION_EMPTY_PATH } from '../core/navigation.service';
import { StationEmptyComponent } from './station-empty/station-empty.component';

const routes: Routes = [
	{
		path: '',
		component: StationComponent,
		canActivate: [AuthGuard],
	},
	{
		path: STATION_EMPTY_PATH,
		component: StationEmptyComponent,
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class StationRoutingModule {}
