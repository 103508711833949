<app-vehicle-table-controls
	class="lk-display__hidden-before-md"
	[update_time]="update_time"
	[current_locale]="current_locale"
	(search_change)="search_change.emit(form_group.get('plate').value)"
	(filter_change)="filters_change.emit()"></app-vehicle-table-controls>

<div class="lk-vehicle-table__layout lk-hide-scrollbar">
	<table
		mat-table
		[dataSource]="vehicles"
		class="lk-width__full">
		<ng-container matColumnDef="id">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				Linkey ID
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				<div class="lk-row">
					{{
						vehicle.id.substr(0, 3) +
							vehicle.id.substr(vehicle.id.length - 8, vehicle.id.length)
					}}
					<img
						id="lk-vehicle-table-turo-label"
						style="width: 50px"
						*ngIf="vehicle.ext_id"
						src="../../../assets/imgs/lk-turo-logo.png" />
				</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="ext_id">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				Turo ID
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				<span *ngIf="vehicle.ext_id"> {{ vehicle.ext_id }} </span>
				<span *ngIf="!vehicle.ext_id"> - </span>
			</td>
		</ng-container>

		<ng-container matColumnDef="online">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header lk-vehicle-table-header-top-left-border-radius">
				{{ 'VEHICLE.ONLINE_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				<div
					class="lk-vehicle-table-online-circle"
					[ngClass]="getOnlineClass(vehicle)"></div>
			</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'VEHICLE.STATUS_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle"
				style="min-width: 120px">
				<div class="lk-vehicle-table-online-circle">
					<div
						class="lk-border-radius"
						[ngClass]="getVehicleStatusClass(vehicle)"
						style="padding: 2px 12px; width: fit-content">
						<span *ngIf="vehicle.status">
							{{ vehicle.status | lowercase }}
						</span>
						<span *ngIf="!vehicle.status"> unknown </span>
					</div>
				</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="plate">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				{{ vehicle.plate }}
			</td>
		</ng-container>

		<ng-container matColumnDef="category">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'VEHICLE.CATEGORY_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				{{ vehicle.category }}
			</td>
		</ng-container>

		<ng-container matColumnDef="brand">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'VEHICLE.BRAND_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				{{ vehicle.brand }}
			</td>
		</ng-container>

		<ng-container matColumnDef="model">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'VEHICLE.MODEL_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				{{ vehicle.model }}
			</td>
		</ng-container>

		<ng-container matColumnDef="color">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'VEHICLE.COLOR_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				{{ vehicle.color }}
			</td>
		</ng-container>

		<ng-container matColumnDef="year">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'VEHICLE.YEAR_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				{{ vehicle.year }}
			</td>
		</ng-container>

		<ng-container matColumnDef="odometer">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'VEHICLE.ODOMETER_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				<span
					*ngIf="
						vehicle.stats &&
						vehicle.stats.distance_covered &&
						vehicle.stats.distance_covered.value
					">
					{{ getOdometerValue(vehicle) | number: '0.2-2' }}
					{{ odometer_um }}
				</span>
				<span
					*ngIf="
						!vehicle.stats ||
						!vehicle.stats.distance_covered ||
						!vehicle.stats.distance_covered.value
					">
					NA
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="fuel">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'VEHICLE.FUEL_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				<span
					*ngIf="
						vehicle.stats &&
						vehicle.stats.autonomy_percentage &&
						vehicle.stats.autonomy_percentage.value
					">
					{{ getPercentageValue(vehicle) }} %
				</span>
				<span
					*ngIf="
						!vehicle.stats ||
						!vehicle.stats.autonomy_percentage ||
						!vehicle.stats.autonomy_percentage.value
					">
					NA
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="battery">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'VEHICLE.BATTERY_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				<span
					*ngIf="
						vehicle.stats &&
						vehicle.stats.external_voltage &&
						vehicle.stats.external_voltage.value
					">
					{{ vehicle.stats.external_voltage.value | number: '0.2-2' }} V
				</span>
				<span
					*ngIf="
						!vehicle.stats ||
						!vehicle.stats.external_voltage ||
						!vehicle.stats.external_voltage.value
					">
					NA
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header lk-vehicle-table-header-top-right-border-radius">
				{{ 'VEHICLE.ACTIONS_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let vehicle">
				<div class="lk-row">
					<button
						class="lk-icon__button-neutral-10 lk-margin__x6-left"
						mat-icon-button
						(click)="onStartClick(vehicle); $event.stopPropagation()"
						matTooltip="{{ 'VEHICLE_DETAILS.UNLOCK_VEHICLE' | translate }}">
						<mat-icon class="lk-accent-color"> lock_open </mat-icon>
					</button>

					<button
						class="lk-icon__button-neutral-10 lk-margin__x6-left"
						mat-icon-button
						(click)="onStopClick(vehicle); $event.stopPropagation()"
						matTooltip="{{ 'VEHICLE_DETAILS.LOCK_VEHICLE' | translate }}">
						<mat-icon class="lk-accent-color"> lock </mat-icon>
					</button>

					<button
						class="lk-icon__button-neutral-10 lk-margin__x6-left"
						mat-icon-button
						(click)="onDirectLinkClick(vehicle); $event.stopPropagation()"
						matTooltip="{{ 'VEHICLE_DETAILS.DIRECT_LINK_CTA' | translate }}">
						<mat-icon class="lk-accent-color"> link </mat-icon>
					</button>
				</div>
			</td>
		</ng-container>

		<tr
			mat-header-row
			*matHeaderRowDef="displayed_columns"></tr>
		<tr
			mat-row
			*matRowDef="let row; columns: displayed_columns"
			(click)="onVehicleClick(row)"></tr>
	</table>
</div>
