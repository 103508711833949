import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { DialogService } from 'src/app/core/dialog.service';
import { FormErrorStateMatcher, FormService } from 'src/app/core/form.service';
import { NavigationService } from 'src/app/core/navigation.service';
import {
	SIGNUP_PASSWORD_MISMATCH_ERROR,
	SIGNUP_SUCCESS_MESSAGE,
	SIGNUP_SUCCESS_TITLE,
} from 'src/app/shared/translations';

@Component({
	selector: 'app-auth-signup-form',
	templateUrl: './auth-signup-form.component.html',
	styleUrl: './auth-signup-form.component.scss',
})
export class AuthSignupFormComponent {
	@Input() logo_src?: string;

	signup_form_group: FormGroup;
	matcher: FormErrorStateMatcher;
	is_password_visible: boolean = false;
	is_signup_running: boolean = false;

	constructor(
		private form_service: FormService,
		private dialog_service: DialogService,
		private auth_service: AuthenticationService,
		private navigation_service: NavigationService,
	) {
		this.signup_form_group = this.form_service.signup_form_group;
		this.matcher = this.form_service.matcher;
	}

	onSignupClick(): void {
		if (this.isFormValid() && !this.is_signup_running) {
			const username: string = this.signup_form_group.get('username').value;
			const password: string = this.signup_form_group.get('password').value;
			const passwordConfirm: string =
				this.signup_form_group.get('password_confirm').value;

			if (password != passwordConfirm) {
				this.dialog_service.openInfoDialog(SIGNUP_PASSWORD_MISMATCH_ERROR);
			} else {
				this.signup(username, password);
			}
		}
	}

	signup(username: string, password: string): void {
		this.is_signup_running = true;
		this.auth_service.signUp(username, password).subscribe({
			next: () => {
				this.dialog_service.openInfoDialog(
					SIGNUP_SUCCESS_MESSAGE,
					SIGNUP_SUCCESS_TITLE,
					'../../../assets/imgs/lk-rocket-ill.png',
				);
				this.navigation_service.toAuthSignin();
				this.is_signup_running = false;
			},
			error: error => {
				this.is_signup_running = false;
				this.dialog_service.openInfoDialog(error.message);
			},
		});
	}

	getPssFormFieldErrorClass(password_label: string): string {
		return this.auth_service.getPssFormFieldErrorClass(
			this.signup_form_group,
			password_label,
		);
	}

	isPssLengthWrong(password_label: string): boolean | undefined {
		return this.auth_service.isPssLengthWrong(
			this.signup_form_group,
			password_label,
		);
	}

	isPssPatternWrong(password_label: string): boolean | undefined {
		return this.auth_service.isPssPatternWrong(
			this.signup_form_group,
			password_label,
		);
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.signup_form_group);
	}
}
