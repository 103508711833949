import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vehicle } from '../../core/vehicle.service';
import { VehicleBuilderDialogComponent } from '../../vehicle/vehicle-builder-dialog/vehicle-builder-dialog.component';
import { DialogService } from '../../core/dialog.service';

@Component({
	selector: 'app-vehicle-details-general',
	templateUrl: './vehicle-details-general.component.html',
	styleUrls: ['./vehicle-details-general.component.scss'],
})
export class VehicleDetailsGeneralComponent {
	@Input() vehicle: Vehicle;

	@Output() vehicle_update_click: EventEmitter<Vehicle> =
		new EventEmitter<Vehicle>();

	constructor(private dialog_service: DialogService) {}

	onUpdateClick() {
		this.dialog_service
			.openDialog(VehicleBuilderDialogComponent, { vehicle: this.vehicle })
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.vehicle) {
					this.vehicle_update_click.emit(dialog_result.vehicle);
				}
			});
	}

	getOnlineClass(vehicle: Vehicle) {
		if (vehicle) {
			return {
				'lk-state-300-background':
					vehicle?.stats?.online && vehicle?.stats?.online?.value,
				'lk-state-50-background':
					!vehicle?.stats?.online || !vehicle?.stats?.online?.value,
			};
		}
	}
}
