import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from 'src/app/core/dialog.service';
import { ErrorService } from 'src/app/core/error.service';
import {
	LockCommandsService,
	LockCommand,
	LockCommandStatus,
} from 'src/app/core/lock-commands.service';
import { Station } from 'src/app/core/station.service';
import {
	UnlockCommand,
	UnlockCommandsService,
	UnlockCommandStatus,
} from 'src/app/core/unlock-commands.service';
import { Vehicle } from 'src/app/core/vehicle.service';
import {
	ADAPTER_CRED_NOT_FOUND_ERROR_CODE,
	ADAPTER_FAILED_ERROR_CODE,
	ADAPTER_OFFLINE_ERROR_CODE,
	LOCAL_STATION_KEY,
} from 'src/app/shared/constants';
import { SharedInteractionErrorDialogComponent } from 'src/app/shared/shared-interaction-error-dialog/shared-interaction-error-dialog.component';
import {
	ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
	ADAPTER_FAILED_ERROR_MESSAGE,
	ADAPTER_GENERIC_ERROR_MESSAGE,
	ADAPTER_OFFLINE_ERROR_MESSAGE,
	GENERAL_ERROR_MESSAGE,
} from 'src/app/shared/translations';

@Component({
	selector: 'app-vehicle-details-controls',
	templateUrl: './vehicle-details-controls.component.html',
	styleUrl: './vehicle-details-controls.component.scss',
})
export class VehicleDetailsControlsComponent {
	@Input() vehicle?: Vehicle;
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();

	is_unlock_running = false;
	is_lock_running = false;

	constructor(
		private unlock_commands_service: UnlockCommandsService,
		private lock_commands_service: LockCommandsService,
		private dialog_service: DialogService,
		private error_service: ErrorService,
	) {}

	onStartClick(): void {
		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		this.createUnlockCommand(station, this.vehicle);
	}

	onStopClick(): void {
		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		this.createLockCommand(station, this.vehicle);
	}

	createUnlockCommand(station: Station, vehicle: Vehicle): void {
		this.is_unlock_running = true;
		this.unlock_commands_service.create(station.id, vehicle.id).subscribe({
			next: response => {
				response.body.json().then(response => {
					const command: UnlockCommand = response as UnlockCommand;
					this.readUnlockCommand(station, command.id, vehicle.id);
				});
			},
			error: error => {
				this.is_unlock_running = false;

				const converted_error = this.error_service.convertError(error);

				if (converted_error.code === ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.dialog_service.openInfoDialog(
						ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
					);
				}

				if (converted_error.code != ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.error_service.processError(converted_error);
				}
			},
		});
	}

	createLockCommand(station: Station, vehicle: Vehicle): void {
		this.is_lock_running = true;
		this.lock_commands_service.create(station.id, vehicle.id).subscribe({
			next: response => {
				response.body.json().then(response => {
					const command: LockCommand = response as LockCommand;
					this.readLockCommand(station, command.id, vehicle.id);
				});
			},
			error: error => {
				this.is_lock_running = false;

				const converted_error = this.error_service.convertError(error);

				if (converted_error.code === ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.dialog_service.openInfoDialog(
						ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
					);
				}

				if (converted_error.code != ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.error_service.processError(converted_error);
				}
			},
		});
	}

	readUnlockCommand(
		station: Station,
		command_id: string,
		vehicle_id: string,
	): void {
		this.is_unlock_running = true;
		this.unlock_commands_service
			.read(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						const command: UnlockCommand = response as UnlockCommand;

						if (
							command.status === UnlockCommandStatus.PENDING &&
							this.unlock_commands_service.current_read_index <=
								this.unlock_commands_service.max_read_retry
						) {
							this.unlock_commands_service.current_read_index++;
							setTimeout(() => {
								this.readUnlockCommand(station, command_id, vehicle_id);
							}, 5000);
						}

						if (command.status == UnlockCommandStatus.SUCCEEDED) {
							this.unlock_commands_service.current_read_index = 0;
							this.refresh.emit();
							this.is_unlock_running = false;
						}

						if (command.status == UnlockCommandStatus.FAILED) {
							this.unlock_commands_service.current_read_index = 0;

							const command_error = command.error.code
								? command.error.code
								: command.error;
							this.manageCommandError(command_error as string);

							this.is_unlock_running = false;
						}
					});
				},
				error: () => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_unlock_running = false;
				},
			});
	}

	readLockCommand(
		station: Station,
		command_id: string,
		vehicle_id: string,
	): void {
		this.is_lock_running = true;
		this.lock_commands_service
			.read(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						const command: LockCommand = response as LockCommand;

						if (
							command.status === LockCommandStatus.PENDING &&
							this.lock_commands_service.current_read_index <=
								this.lock_commands_service.max_read_retry
						) {
							this.lock_commands_service.current_read_index++;

							setTimeout(() => {
								this.readLockCommand(station, command_id, vehicle_id);
							}, 5000);
						}

						if (command.status == LockCommandStatus.SUCCEEDED) {
							this.lock_commands_service.current_read_index = 0;
							this.refresh.emit();
							this.is_lock_running = false;
						}

						if (command.status == LockCommandStatus.FAILED) {
							this.lock_commands_service.current_read_index = 0;

							const command_error = command.error.code
								? command.error.code
								: command.error;
							this.manageCommandError(command_error as string);

							this.is_lock_running = false;
						}
					});
				},
				error: () => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_lock_running = false;
				},
			});
	}

	manageCommandError(command_error: string): void {
		switch (command_error) {
			case ADAPTER_FAILED_ERROR_CODE:
				this.dialog_service.openDialog(SharedInteractionErrorDialogComponent, {
					message: ADAPTER_FAILED_ERROR_MESSAGE,
				});
				break;
			case ADAPTER_OFFLINE_ERROR_CODE:
				this.dialog_service.openDialog(SharedInteractionErrorDialogComponent, {
					message: ADAPTER_OFFLINE_ERROR_MESSAGE,
				});
				break;
			default:
				this.dialog_service.openDialog(SharedInteractionErrorDialogComponent, {
					message: ADAPTER_GENERIC_ERROR_MESSAGE,
				});
				break;
		}
	}
}
