import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LocalManagerService, LocalStationType } from './local-manager.service';
import { AUTH_SIGNIN_PATH } from './navigation.service';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
	signUp,
	signIn,
	getCurrentUser,
	signOut,
	resetPassword,
	confirmResetPassword,
	confirmSignIn,
	setUpTOTP,
	verifyTOTPSetup,
	updateMFAPreference,
	fetchMFAPreference,
	fetchUserAttributes,
	updateUserAttributes,
	SignInOutput,
	SignUpOutput,
	GetCurrentUserOutput,
	ResetPasswordOutput,
} from 'aws-amplify/auth';
import { FormGroup } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	public logged_in: BehaviorSubject<boolean>;

	totp_setup_uri?: any;
	totp_setup_secret?: string;

	sms_delivery_destination?: string;

	mfa_setup_mechanism?: 'sms' | 'totp';

	constructor(
		private router: Router,
		private localManager: LocalManagerService,
	) {
		this.logged_in = new BehaviorSubject<boolean>(false);
	}

	signUp(username: string, password: string): Observable<SignUpOutput> {
		return from(
			signUp({
				username,
				password,
				options: { userAttributes: { email: username } },
			}),
		);
	}

	signIn(username: string, password: string): Observable<SignInOutput> {
		return from(signIn({ username, password })).pipe(
			tap(() => {
				this.logged_in?.next(true);
			}),
		);
	}

	getAuthenticatedUser(): Observable<GetCurrentUserOutput> {
		return from(getCurrentUser());
	}

	confirmSignin(otp: string): Observable<any> {
		return from(confirmSignIn({ challengeResponse: otp }));
	}

	isAuthenticated(): Observable<boolean> {
		return from(getCurrentUser()).pipe(
			map(() => {
				this.logged_in?.next(true);
				return true;
			}),
			catchError(() => {
				this.logged_in?.next(false);
				return of(false);
			}),
		);
	}

	signOut(): Observable<void> {
		return from(signOut()).pipe(
			tap(() => {
				this.logged_in?.next(false);
				this.localManager.eraseLocalStation(LocalStationType.BUILDER);
				this.localManager.eraseLocalStation(LocalStationType.STATION);
				this.router.navigate([AUTH_SIGNIN_PATH]);
			}),
		);
	}

	forgotPassword(username: string): Observable<ResetPasswordOutput> {
		return from(resetPassword({ username }));
	}

	forgotPasswordSubmit(
		username: string,
		code: string,
		new_password: string,
	): Observable<void> {
		return from(
			confirmResetPassword({
				username,
				newPassword: new_password,
				confirmationCode: code,
			}),
		);
	}

	setupTOTP(): Observable<any> {
		return from(setUpTOTP());
	}

	verifyTOTP(code: string): Observable<any> {
		return from(verifyTOTPSetup({ code }));
	}

	setupMFAPreference(
		mfa_method: 'TOTP' | 'SMS' | 'NOMFA' | 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA',
	): Observable<any> {
		if (mfa_method === 'SMS_MFA') {
			return from(updateMFAPreference({ sms: 'PREFERRED', totp: 'ENABLED' }));
		}

		if (mfa_method === 'SOFTWARE_TOKEN_MFA') {
			return from(updateMFAPreference({ sms: 'ENABLED', totp: 'PREFERRED' }));
		}
	}

	getMFAPreference(): Observable<any> {
		return from(fetchMFAPreference());
	}

	getUserAttributes(): Observable<any> {
		return from(fetchUserAttributes());
	}

	updateUserAttributes(attributes: object): Observable<any> {
		return from(updateUserAttributes({ userAttributes: attributes }));
	}

	isPssLengthWrong(
		form_group: FormGroup,
		password_label: string,
	): boolean | undefined {
		return (
			form_group.get(password_label)?.hasError('minlength') &&
			!form_group.get(password_label)?.hasError('required')
		);
	}

	isPssPatternWrong(
		form_group: FormGroup,
		password_label: string,
	): boolean | undefined {
		return (
			form_group.get(password_label)?.hasError('pattern') &&
			!form_group.get(password_label)?.hasError('minlength') &&
			!form_group.get(password_label)?.hasError('required')
		);
	}

	getPssFormFieldErrorClass(
		form_group: FormGroup,
		password_label: string,
	): string {
		if (
			this.isPssLengthWrong(form_group, password_label) ||
			this.isPssPatternWrong(form_group, password_label)
		) {
			return 'lk-form-field__with-error';
		}
		return '';
	}
}
