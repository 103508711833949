<div class="lk-vehicle-details-controls__row lk-row lk-wrap">
	<app-button
		class="lk-vehicle-details-controls lk-margin__x8-horiz lk-display__block"
		[button_class]="'lk-button__secondary lk-width__full'"
		[spinner_class]="'lk-button__spinner-white'"
		[icon]="'lock_open'"
		[cta]="'VEHICLE_DETAILS.UNLOCK_VEHICLE' | translate"
		[is_disabled]="is_unlock_running"
		[is_spinner_visible]="is_unlock_running"
		(button_click)="onStartClick()"></app-button>

	<app-button
		class="lk-vehicle-details-controls lk-margin__x8-horiz lk-display__block"
		[button_class]="'lk-button__secondary lk-width__full'"
		[spinner_class]="'lk-button__spinner-white'"
		[icon]="'lock'"
		[cta]="'VEHICLE_DETAILS.LOCK_VEHICLE' | translate"
		[is_disabled]="is_lock_running"
		[is_spinner_visible]="is_lock_running"
		(button_click)="onStopClick()"></app-button>
</div>
