import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorService } from 'src/app/core/error.service';
import { FormService } from 'src/app/core/form.service';
import { User, UserService } from 'src/app/core/user.service';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-booking-details-email-dialog',
	templateUrl: './booking-details-email-dialog.component.html',
	styleUrls: ['./booking-details-email-dialog.component.scss'],
})
export class BookingDetailsEmailDialogComponent implements OnInit {
	user: User;
	form_group: FormGroup;
	is_request_loading: boolean = false;

	constructor(
		private dialog_ref: MatDialogRef<BookingDetailsEmailDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private user_service: UserService,
		private form_service: FormService,
		private error_service: ErrorService,
	) {
		this.user = data.user;
		this.form_group = this.form_service.updateEmailDialogFormGroup;
	}

	ngOnInit(): void {
		this.form_group.get('email').setValue(this.user.email);
	}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onConfirmClick() {
		if (this.form_service.validateForm(this.form_group)) {
			const email: string = this.form_group.get('email').value;
			this.is_request_loading = true;
			this.user_service.setEmail(this.user.id, email).subscribe({
				next: () => {
					this.dialog_ref.close({ success: true });
					this.is_request_loading = false;
				},
				error: error => {
					const converted_error = this.error_service.convertError(error);
					this.dialog_ref.close({ error: converted_error.message });
					this.is_request_loading = false;
				},
			});
		}
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_request_loading,
			'lk-main-button': !this.is_request_loading,
		};
	}
}
