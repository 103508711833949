import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { Station } from 'src/app/core/station.service';
import {
	LOCAL_STATION_KEY,
	SMS_MFA,
	SOFTWARE_TOKEN_MFA,
} from 'src/app/shared/constants';
import { StationBuilderDialogComponent } from 'src/app/station/station-builder-dialog/station-builder-dialog.component';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	sms_value = SMS_MFA;
	totp_value = SOFTWARE_TOKEN_MFA;

	email?: string;
	phone?: string;
	mfa_preference?: string;

	is_profile_loading = false;

	constructor(
		private auth_service: AuthenticationService,
		private local_manager: LocalManagerService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.is_profile_loading = true;
		this.auth_service.getUserAttributes().subscribe({
			next: response => {
				this.email = response.email;
				this.phone = response.phone_number;
				this.is_profile_loading = false;
			},
			error: () => {
				this.auth_service.signOut();
				this.is_profile_loading = false;
			},
		});

		this.auth_service.getMFAPreference().subscribe({
			next: response => {
				this.mfa_preference = response.preferred;
			},
		});
	}

	onNoStationFound(): void {}

	onStationBuild(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
					this.toolbar_refresh.next(true);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onStationChange(station: Station): void {}
}
