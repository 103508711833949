import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/core/authentication.service';
import {
	SMS_MFA,
	SOFTWARE_TOKEN_MFA,
	UNKNOWN_FLAG,
} from 'src/app/shared/constants';
import { FormService } from 'src/app/core/form.service';
import { FormGroup } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import {
	CountriesService,
	CountryPrefix,
} from 'src/app/core/countries.service';

@Component({
	selector: 'app-auth-mfa-reset-dialog',
	templateUrl: './auth-mfa-reset-dialog.component.html',
	styleUrls: ['./auth-mfa-reset-dialog.component.scss'],
})
export class AuthMfaResetDialogComponent implements OnInit {
	sms_value = SMS_MFA;
	totp_value = SOFTWARE_TOKEN_MFA;
	form_group: FormGroup = this.form_service.mfa_host_reset_form_group;
	phone_form_group: FormGroup =
		this.form_service.mfa_host_phone_reset_form_group;
	is_reset_running: boolean = false;
	user_attributes?: any;

	country_prefixes: CountryPrefix[];
	filtered_options: Observable<CountryPrefix[]>;
	current_country_prefix: CountryPrefix;
	unknown_flag_src: string = UNKNOWN_FLAG;

	constructor(
		private dialog_ref: MatDialogRef<AuthMfaResetDialogComponent>,
		private auth_service: AuthenticationService,
		private form_service: FormService,
		private countries: CountriesService,
	) {}

	ngOnInit(): void {
		this.form_group.reset();
		this.phone_form_group.reset();

		this.country_prefixes = this.countries.getCountryPrefixList();
		this.filtered_options = this.phone_form_group
			.get('phone_prefix')
			.valueChanges.pipe(
				startWith(''),
				map(value => this.filterPrefix(value || '')),
			);

		this.auth_service.getUserAttributes().subscribe({
			next: response => {
				this.user_attributes = response;
			},
			error: () => {
				this.auth_service.signOut();
			},
		});
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onCountryPrefixSelect(): void {
		const code: string = this.phone_form_group.get('phone_prefix').value;
		this.current_country_prefix = this.getCountryPrefixFromCode(code);
		this.phone_form_group
			.get('phone_prefix')
			.setValue(this.current_country_prefix.prefix);
	}

	onConfirmClick(): void {
		if (this.isSmsEnabled() && this.isFormValid() && this.isPhoneFormValid()) {
			let phone_prefix = this.phone_form_group.get('phone_prefix')?.value;
			const phone_value = this.phone_form_group.get('phone')?.value;

			if (!phone_prefix.includes('+')) {
				phone_prefix = '+' + phone_prefix;
			}

			this.setupPhoneNumber(`${phone_prefix}${phone_value}`);
		}

		if (this.isTotpEnabled() && this.isFormValid()) {
			this.setupMfaPreference();
		}
	}

	setupPhoneNumber(phone_number: string): void {
		this.is_reset_running = true;
		this.user_attributes['phone_number'] = phone_number;
		this.auth_service.updateUserAttributes(this.user_attributes).subscribe({
			next: () => {
				this.setupMfaPreference();
			},
			error: error => {
				this.is_reset_running = false;
				this.dialog_ref.close({ success: false, error: error.message });
			},
		});
	}

	setupMfaPreference(): void {
		this.is_reset_running = true;
		const preference = this.isSmsEnabled() ? SMS_MFA : SOFTWARE_TOKEN_MFA;
		this.auth_service.setupMFAPreference(preference).subscribe({
			next: () => {
				if (this.isSmsEnabled()) {
					this.is_reset_running = false;
					this.dialog_ref.close({ success: true });
				} else {
					this.setupTOTP();
				}
			},
			error: error => {
				this.is_reset_running = false;
				this.dialog_ref.close({ success: false, error: error.message });
			},
		});
	}

	setupTOTP(): void {
		this.is_reset_running = true;
		this.auth_service.setupTOTP().subscribe({
			next: response => {
				this.is_reset_running = false;
				this.auth_service.mfa_setup_mechanism = 'totp';
				this.auth_service.totp_setup_secret = response;
				this.dialog_ref.close({ success: true, redirect_totp: true });
			},
			error: error => {
				this.is_reset_running = false;
				this.dialog_ref.close({ success: false, error: error.message });
			},
		});
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.form_group);
	}

	isPhoneFormValid(): boolean {
		return this.form_service.validateForm(this.phone_form_group);
	}

	isButtonDisabled(): boolean {
		return (
			this.is_reset_running ||
			!this.isFormValid() ||
			(this.isSmsEnabled() && !this.isPhoneFormValid())
		);
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.isButtonDisabled(),
			'lk-main-button': !this.isButtonDisabled(),
		};
	}

	isSmsEnabled(): boolean {
		return this.form_group.get('mfa_type')?.value == this.sms_value;
	}

	isTotpEnabled(): boolean {
		return this.form_group.get('mfa_type')?.value == this.totp_value;
	}

	filterPrefix(value: string) {
		const filter_value = value.toLowerCase();
		return this.country_prefixes.filter(option => {
			return option.prefix.toLowerCase().includes(filter_value);
		});
	}

	getCountryPrefixFromCode(code: string): CountryPrefix {
		return this.country_prefixes.filter(cp => {
			return cp.code == code;
		})[0];
	}
}
