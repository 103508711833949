<app-toolbar
	[current_nav_item]="current_item"
	(no_station_found)="onNoStationFound()"
	(station_build)="onStationBuild(false)"
	(station_change)="onStationChange($event)"></app-toolbar>

<section
	class="lk-page__with-toolbar lk-padding__x24"
	[ngClass]="
		is_booking_loading
			? 'lk-booking-details__loading-grid'
			: 'lk-booking-details__grid'
	">
	<app-loading
		*ngIf="is_booking_loading"
		class="lk-width__full lk-height__full"></app-loading>

	<app-loading
		*ngIf="is_booking_loading"
		class="lk-width__full lk-height__full"></app-loading>

	<app-booking-details-header
		*ngIf="!is_booking_loading && booking"
		[booking]="booking"></app-booking-details-header>

	<app-booking-details-navbar
		*ngIf="!is_booking_loading && booking"
		(navbar_item_change)="onNavbarItemChange($event)">
	</app-booking-details-navbar>

	<div
		*ngIf="!is_booking_loading && booking"
		class="lk-width__full">
		<div
			*ngIf="current_navbar_item == navbar_items[0]"
			class="lk-width__full lk-height__full lk-row__center lk-row__align-start lk-bookings-details__general-grid">
			<app-booking-details-general
				class="lk-booking-details__content-layout lk-height__full"
				[booking]="booking"
				(bookingUpdateClick)="onBookingUpdateClick($event)"
				(updateEmailClick)="onUpdateEmailClick()"
				(updatePhoneClick)="onUpdatePhoneClick()"></app-booking-details-general>

			<app-booking-details-vehicle
				class="lk-booking-details__content-layout lk-height__full"
				[booking]="booking"
				(addVehicleClick)="onAddVehicleClick($event)"
				(refresh)="onRefreshClick()"></app-booking-details-vehicle>
		</div>

		<div
			*ngIf="booking && current_navbar_item == navbar_items[1]"
			class="lk-width__full lk-height__full lk-row__center lk-row__align-start lk-bookings-details__general-grid">
			<app-booking-details-unlock-link
				class="lk-booking-details__content-layout lk-height__full"
				[booking_trigger]="booking_trigger"
				[booking_update_trigger]="booking_update_trigger">
			</app-booking-details-unlock-link>

			<app-booking-details-lock-link
				class="lk-booking-details__content-layout lk-height__full"
				[booking_trigger]="booking_trigger"
				[booking_update_trigger]="booking_update_trigger">
			</app-booking-details-lock-link>
		</div>

		<div
			*ngIf="booking && current_navbar_item == navbar_items[2]"
			class="lk-width__full lk-height__full lk-row__center lk-row__align-start lk-bookings-details__general-grid">
			<app-booking-details-events
				class="lk-booking-details__content-layout lk-height__full"
				[booking]="booking"></app-booking-details-events>
		</div>

		<div
			*ngIf="booking && current_navbar_item == navbar_items[4]"
			style="align-items: flex-start; flex-wrap: wrap"
			class="lk-width__full lk-height__full lk-row__center lk-row__align-start lk-bookings-details__general-grid">
			<app-booking-details-data
				class="lk-booking-details__content-layout lk-height__full"
				[booking]="booking"></app-booking-details-data>
		</div>

		<div
			*ngIf="booking && current_navbar_item == navbar_items[5]"
			style="align-items: flex-start; flex-wrap: wrap; overflow-y: visible"
			class="lk-width__full lk-height__full lk-row__center lk-row__align-start lk-bookings-details__general-grid">
			<app-booking-details-path
				id="lk-booking-details-path-layout"
				class="lk-full-height"
				[booking]="booking"></app-booking-details-path>
		</div>
	</div>
</section>
