<div
	*ngIf="!is_logo_loading"
	[ngStyle]="{ 'background-image': 'url(' + logo + ')' }"
	(click)="fileInput.click()"
	class="lk-station-panel-logo lk-cursor__pointer lk-border-radius lk-background__light-neutral-10"></div>

<input
	#fileInput
	type="file"
	accept="image/png"
	(change)="onLogoClick($event)"
	style="display: none" />

<div
	*ngIf="is_logo_loading"
	class="lk-station-panel-logo lk-row__center lk-border-radius lk-background__light-neutral-10">
	<mat-spinner
		diameter="20"
		class="lk-button__spinner-accent"></mat-spinner>
</div>
