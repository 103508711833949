<div
	class="lk-width__full lk-row__center"
	style="margin-left: -12px">
	<div class="lk-row lk-overflow__x-scroll">
		<div
			*ngFor="let item of items"
			(click)="onItemClick(item)"
			[ngClass]="getItemBackgroundClass(item)"
			class="lk-margin__x12-horiz lk-cursor__pointer">
			<span>
				{{ getItemLabel(item) | translate | titlecase }}
			</span>
		</div>
	</div>
</div>
