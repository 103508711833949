import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {
	CUSTOM_FAVICON_PATH,
	DEFAULT_LANGUAGE,
	SUPPORTED_LANGUAGES,
} from './shared/constants';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import Quill from 'quill';
import { DialogService } from './core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
declare var zE: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	environment = environment;
	favicon: any = document.querySelector('#lk-favicon');

	constructor(
		private domSanitizer: DomSanitizer,
		private registry: MatIconRegistry,
		private translate: TranslateService,
		private title: Title,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.setupSystemIcons();

		this.translate.setDefaultLang(DEFAULT_LANGUAGE);
		const browserLang = this.translate.getBrowserLang();
		if (SUPPORTED_LANGUAGES.includes(browserLang)) {
			this.translate.use(browserLang);
		} else {
			this.translate.use(DEFAULT_LANGUAGE);
		}

		this.dialog_service.dialog = this.dialog;

		//Quill.register('modules/blotFormatter', null);
	}

	ngOnInit(): void {
		const base_path = location.origin
			.replace('http://', '')
			.replace('https://', '');

		const dot_index = base_path.indexOf('.');
		const double_dot_index = base_path.indexOf(':');
		let title: string;

		if (dot_index != -1) {
			title = base_path.substring(0, dot_index);
		} else if (double_dot_index != -1) {
			title = base_path.substring(0, double_dot_index);
		}

		console.log(title);

		if (title && title != 'manage') {
			const hostname = window.location.hostname;
			this.title.setTitle(
				hostname.includes('okmobility.com')
					? 'Ok Mobility'
					: this.toTitleCase(title),
			);
		} else {
			this.title.setTitle('Linkey');
		}

		if (title && title != 'manage') {
			this.favicon.href = CUSTOM_FAVICON_PATH(environment.STAGE, title);
		}
	}

	onZendeskClick(): void {
		zE('messenger', 'open');
	}

	setupSystemIcons() {
		this.registry.addSvgIcon(
			'lk-stations',
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/imgs/lk-menu-icons/lk-stations-icon.svg',
			),
		);
		this.registry.addSvgIcon(
			'lk-vehicles',
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/imgs/lk-menu-icons/lk-vehicles-icon.svg',
			),
		);
		this.registry.addSvgIcon(
			'lk-bookings',
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/imgs/lk-menu-icons/lk-bookings-icon.svg',
			),
		);
		this.registry.addSvgIcon(
			'lk-settings',
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/imgs/lk-menu-icons/lk-settings-icon.svg',
			),
		);
		this.registry.addSvgIcon(
			'lk-dashboard',
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				'../assets/imgs/lk-menu-icons/lk-dashboard-icon.svg',
			),
		);
	}

	toTitleCase(title: string): string {
		return title.replace(
			/\w\S*/g,
			text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
		);
	}
}
