<div class="lk-padding__x24-horiz">
	<div
		*ngIf="navbar_item === notif_item_name"
		class="lk-station-settings__notif">
		<app-station-settings-templates
			[station]="station"></app-station-settings-templates>

		<app-station-settings-notif
			[station]="station"
			(refresh)="onRefresh(station)"></app-station-settings-notif>
	</div>

	<div
		class="lk-station-settings__layout"
		*ngIf="navbar_item === unlock_item_name">
		<app-station-settings-unlock
			class="lk-station-settings__layout-content"
			[station]="station"></app-station-settings-unlock>
	</div>

	<div
		class="lk-station-settings__layout"
		*ngIf="navbar_item === lock_item_name">
		<app-station-settings-lock
			class="lk-station-settings__layout-content"
			[station]="station"></app-station-settings-lock>
	</div>

	<div
		class="lk-station-settings__layout"
		*ngIf="navbar_item === data_item_name">
		<app-station-settings-data
			class="lk-station-settings__layout-content"
			[station]="station"></app-station-settings-data>
	</div>

	<div
		class="lk-station-settings__layout"
		*ngIf="navbar_item === team_item_name">
		<app-station-settings-team
			class="lk-station-settings__layout-content"
			[station]="station"></app-station-settings-team>
	</div>
</div>
