<div class="lk-height__max-80vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x24-horiz">
		<h3 class="lk-margin__x24-top">
			{{ 'VEHICLE.REQUEST_MANUAL_CONTACTS_TITLE' | translate }}
		</h3>

		<p class="lk-primary-medium-color">
			{{ 'VEHICLE.REQUEST_MANUAL_CONTACTS_SUBTITLE' | translate }}
		</p>

		<form
			[formGroup]="vehicle_request_manual_contacts_form_group"
			class="lk-margin__x48-bottom lk-margin__x24-top">
			<mat-form-field
				class="lk-width__full lk-margin__x12-bottom lk-form-field">
				<mat-label>
					{{ 'GENERAL.STATION_NAME_LABEL' | translate }}
				</mat-label>
				<input
					matInput
					type="text"
					formControlName="station_name"
					required />
			</mat-form-field>

			<mat-form-field
				class="lk-width__full lk-margin__x48-bottom lk-form-field">
				<mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="station_email"
					required />
			</mat-form-field>

			<app-button
				class="lk-width__full lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.NEXT_STEP_BUTTON_CTA' | translate"
				(button_click)="onNextClick()"></app-button>
		</form>
	</div>
</div>
