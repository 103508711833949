import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VehicleComponent } from './vehicle.component';
import { VehicleMapComponent } from './vehicle-map/vehicle-map.component';
import { AuthGuard } from '../shared/auth.guard';
import {
	VEHICLES_EMPTY_PATH,
	VEHICLES_MAP_PATH,
} from '../core/navigation.service';
import { VehicleEmptyComponent } from './vehicle-empty/vehicle-empty.component';

const routes: Routes = [
	{
		path: '',
		component: VehicleComponent,
		canActivate: [AuthGuard],
	},
	{
		path: VEHICLES_MAP_PATH,
		component: VehicleMapComponent,
		canActivate: [AuthGuard],
	},
	{
		path: VEHICLES_EMPTY_PATH,
		component: VehicleEmptyComponent,
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class VehicleRoutingModule {}
