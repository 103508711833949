import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../core/form.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User, UserService } from '../../core/user.service';
import { LOCAL_STATION_KEY } from '../../shared/constants';
import { LocalManagerService } from '../../core/local-manager.service';
import { Station } from '../../core/station.service';
import { ErrorService } from 'src/app/core/error.service';

@Component({
	selector: 'app-booking-builder-dialog',
	templateUrl: './booking-user-builder-dialog.component.html',
	styleUrls: ['./booking-user-builder-dialog.component.scss'],
})
export class BookingUserBuilderDialogComponent implements OnInit {
	form_group?: FormGroup;
	phone?: string;
	phone_prefix?: string;
	is_request_loading = false;

	constructor(
		private dialog_ref: MatDialogRef<BookingUserBuilderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private form_service: FormService,
		private user_service: UserService,
		private local_manager: LocalManagerService,
		private error_service: ErrorService,
	) {
		this.form_group = this.form_service.bookingUserBuilderFormGroup;
		this.phone = data.phone;
		this.phone_prefix = data.phone_prefix;
	}

	ngOnInit(): void {
		this.form_group.reset();
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		if (this.form_service.validateForm(this.form_group)) {
			const user: User = this.buildUser();

			const station: Station =
				this.local_manager.getLocalObject(LOCAL_STATION_KEY);

			this.createUser(station.id, user);
		}
	}

	createUser(station_id: string, user: User): void {
		this.is_request_loading = true;
		this.user_service.create(station_id, user).subscribe({
			next: response => {
				response.body.json().then(response => {
					this.dialog_ref.close({ user: response as User });
					this.is_request_loading = false;
				});
			},
			error: error => {
				const converted_error = this.error_service.convertError(error);
				this.error_service.processError(converted_error);
				this.is_request_loading = false;
			},
		});
	}

	buildUser(): User {
		const user = new User();

		user.email = this.form_group.get('email').value;
		user.first_name = this.form_group.get('first_name').value;
		user.last_name = this.form_group.get('last_name').value;
		user.tax_code = this.form_group.get('tax_code').value;
		user.phone = {
			prefix: this.phone_prefix,
			value: this.phone,
		};

		return user;
	}
}
