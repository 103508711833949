import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from 'src/app/core/form.service';
import {
	Station,
	StationChecklist,
	StationChecklistMessage,
	StationService,
} from 'src/app/core/station.service';
import {
	EN_FLAG,
	ES_FLAG,
	IT_FLAG,
	UNKNOWN_FLAG,
} from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-hidden-stations-checklist-dialog',
	templateUrl: './hidden-stations-checklist-dialog.component.html',
	styleUrls: ['./hidden-stations-checklist-dialog.component.scss'],
})
export class HiddenStationsChecklistDialogComponent implements OnInit {
	station?: Station;
	checklist?: StationChecklist;

	form_group: FormGroup = this.form_service.checklist_message_form_group;

	is_save_running = false;

	constructor(
		private form_service: FormService,
		private station_service: StationService,
		private translate: TranslateService,
		private dialog_ref: MatDialogRef<HiddenStationsChecklistDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.station = data.station;
		this.checklist = this.station.settings.checklist;
	}

	ngOnInit(): void {
		this.form_group
			.get('current_lang')
			.setValue(this.translate.getBrowserLang());
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onAddMessageClick(): void {
		const lang = this.form_group.get('current_lang').value;
		const message = this.form_group.get('current_message').value;
		const checklist_message: StationChecklistMessage = {
			en: lang == 'en' ? message : undefined,
			es: lang == 'es' ? message : undefined,
			it: lang == 'it' ? message : undefined,
		};

		if (this.checklist && this.checklist.messages?.length) {
			this.checklist.messages.push(checklist_message);
		} else {
			this.checklist = {
				messages: [checklist_message],
			};
		}

		this.form_group.reset();
		this.form_group
			.get('current_lang')
			.setValue(this.translate.getBrowserLang());
	}

	onClearChecklistClick(): void {
		this.checklist = {
			messages: [],
		};
	}

	onConfirmClick(): void {
		this.station.settings.checklist = this.checklist;
		this.updateStation(this.station);
	}

	updateStation(station: Station): void {
		this.is_save_running = true;
		this.station_service.update(station).subscribe({
			next: () => {
				this.dialog_ref.close({ success: true });
				this.is_save_running = false;
			},
			error: () => {
				this.is_save_running = false;
				this.dialog_ref.close({ error_message: GENERAL_ERROR_MESSAGE });
			},
		});
	}

	getCurrentLangPrefixImg(lang?: string): string {
		const current_lang = lang
			? lang
			: this.form_group.get('current_lang').value;
		switch (current_lang) {
			case 'en':
				return EN_FLAG;
			case 'es':
				return ES_FLAG;
			case 'it':
				return IT_FLAG;
			default:
				return UNKNOWN_FLAG;
		}
	}
}
