import { Injectable } from '@angular/core';
import {
	ADAPTER_CRED_NOT_FOUND_ERROR_CODE,
	COMPLETED_UPDATE_ERROR_CODE,
	ERROR_DESCRIPTION_500,
	ERRORS_UPDATE_ERROR_CODE,
	PROGRESS_UPDATE_ERROR_CODE,
	UNKNOWN_ERROR_CODE,
	VEHICLE_ALREADY_ASSIGNED_ERROR_CODE,
} from '../shared/constants';
import {
	ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
	COMPLETED_UPDATE_ERROR_MESSAGE,
	ERRORS_UPDATE_ERROR_MESSAGE,
	GENERAL_ERROR_MESSAGE,
	PROGRESS_UPDATE_ERROR_MESSAGE,
	VEHICLE_ALREADY_ASSIGNED_ERROR_MESSAGE,
} from '../shared/translations';
import { DialogService } from './dialog.service';

@Injectable({
	providedIn: 'root',
})
export class ErrorService {
	managed_error_codes = [
		VEHICLE_ALREADY_ASSIGNED_ERROR_CODE,
		PROGRESS_UPDATE_ERROR_CODE,
		COMPLETED_UPDATE_ERROR_CODE,
		ERRORS_UPDATE_ERROR_CODE,
		ADAPTER_CRED_NOT_FOUND_ERROR_CODE,
	];

	constructor(private dialog_service: DialogService) {}

	wrapError(error: any): void {
		const converted_error = this.convertError(error);
		this.processError(converted_error);
	}

	convertError(error: any): LinkeyError {
		const error_body = error?.response?.body;

		if (!error_body) {
			return {
				code: UNKNOWN_ERROR_CODE,
				message: GENERAL_ERROR_MESSAGE,
			};
		}

		const parsed_error = JSON.parse(error_body);

		if (
			!parsed_error?.error?.code ||
			!parsed_error?.error?.description ||
			parsed_error?.error?.description === ERROR_DESCRIPTION_500
		) {
			return {
				code: UNKNOWN_ERROR_CODE,
				message: GENERAL_ERROR_MESSAGE,
			};
		}

		return {
			code: parsed_error.error.code,
			message: parsed_error.error.description,
		};
	}

	processError(error: LinkeyError): void {
		if (this.managed_error_codes.includes(error.code)) {
			this.manageError(error.code);
			return;
		}

		if (error.message) {
			this.dialog_service.openInfoDialog(error.message);
			return;
		}

		this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
	}

	manageError(code: string): void {
		switch (code) {
			case VEHICLE_ALREADY_ASSIGNED_ERROR_CODE:
				this.dialog_service.openInfoDialog(
					VEHICLE_ALREADY_ASSIGNED_ERROR_MESSAGE,
				);
				break;
			case PROGRESS_UPDATE_ERROR_CODE:
				this.dialog_service.openInfoDialog(PROGRESS_UPDATE_ERROR_MESSAGE);
				break;
			case COMPLETED_UPDATE_ERROR_CODE:
				this.dialog_service.openInfoDialog(COMPLETED_UPDATE_ERROR_MESSAGE);
				break;
			case ERRORS_UPDATE_ERROR_CODE:
				this.dialog_service.openInfoDialog(ERRORS_UPDATE_ERROR_MESSAGE);
				break;
			case ADAPTER_CRED_NOT_FOUND_ERROR_CODE:
				this.dialog_service.openInfoDialog(
					ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
				);
				break;
			default:
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
				break;
		}
	}
}

export interface LinkeyError {
	code: string;
	message: string;
}
