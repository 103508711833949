import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StationChecklist } from 'src/app/core/station.service';

@Component({
	selector: 'app-hidden-stations-checklist-dialog-header',
	templateUrl: './hidden-stations-checklist-dialog-header.component.html',
	styleUrls: ['./hidden-stations-checklist-dialog-header.component.scss'],
})
export class HiddenStationsChecklistDialogHeaderComponent {
	@Input() checklist?: StationChecklist;
	@Output() clear: EventEmitter<void> = new EventEmitter<void>();
	current_lang: string = this.translate.getBrowserLang();

	constructor(private translate: TranslateService) {}

	onLangItemClick(lang: string): void {
		this.current_lang = lang;
	}

	getCurrentLangMessages(): string[] {
		return this.checklist?.messages.map(m => m[this.current_lang]);
	}

	getCurrentLangClass(lang: string): string {
		if (lang == this.current_lang) {
			return 'lk-main-button';
		}

		return '';
	}

	escapeMessage(message?: string): string {
		return message?.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
	}
}
