<div
	class="lk-border-radius lk-shadow lk-padding__x24 lk-height__full lk-primary-background">
	<div class="lk-width__full lk-row__space">
		<h3 class="lk-no-margin lk-subtitle-size">
			{{ 'VEHICLE_DETAILS.GALLERY_SECTION_LABEL' | translate }}
		</h3>

		<button
			mat-icon-button
			(click)="onAddImageClick()"
			class="lk-icon__button-neutral-40"
			matTooltip="{{ 'VEHICLE_DETAILS.ADD_IMAGE_CTA' | translate }}">
			<mat-icon class="lk-accent-color"> add </mat-icon>
		</button>
	</div>

	<app-button
		*ngIf="vehicle && (!vehicle.img_urls || vehicle.img_urls.length <= 0)"
		class="lk-width__full lk-margin__x24-top lk-display__block"
		[button_class]="'lk-button__secondary lk-width__full'"
		[spinner_class]="'lk-button__spinner-white'"
		[cta]="'VEHICLE_DETAILS.ADD_IMAGE_CTA' | translate"
		(button_click)="onAddImageClick()"></app-button>

	<div
		class="lk-full-width lk-spaced-row lk-wrapped-flex"
		*ngIf="vehicle && vehicle.img_urls && vehicle.img_urls.length > 0">
		<div
			class="lk-margin-top lk-half-width"
			style="position: relative"
			*ngFor="let img of images; let i = index">
			<img
				class="lk-full-width lk-border-radius"
				[src]="img.result" />
			<button
				id="lk-vehicle-details-gallery-remove-button"
				class="lk-primary-medium-background"
				mat-icon-button
				(click)="onRemoveImageClick(img)">
				<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
			</button>
		</div>
	</div>
</div>
