import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorService } from 'src/app/core/error.service';
import { FormService } from 'src/app/core/form.service';
import { Service, Station, StationService } from 'src/app/core/station.service';
import { ERROR_DESCRIPTION_500 } from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-hidden-stations-credentials-dialog',
	templateUrl: './hidden-stations-credentials-dialog.component.html',
	styleUrls: ['./hidden-stations-credentials-dialog.component.scss'],
})
export class HiddenStationsCredentialsDialogComponent implements OnInit {
	station?: Station;
	form_group: FormGroup;
	is_save_running: boolean = false;

	constructor(
		private form_service: FormService,
		private station_service: StationService,
		private error_service: ErrorService,
		private dialog_ref: MatDialogRef<HiddenStationsCredentialsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.station = data.station;
		this.form_group =
			this.form_service.hiddenSettingsAdapterCredentialsFormGroup;
	}

	ngOnInit(): void {
		if (this.station) {
			this.setStation();
		}
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onCredentialsUpdateClick() {
		if (this.form_service.validateForm(this.form_group) && this.station) {
			this.is_save_running = true;
			const servicesToBeRemoved: Service[] = this.station.services.filter(s => {
				return (
					s.type == 'ADAPTER_SYNC_CLIENT_ID' ||
					s.type == 'ADAPTER_SYNC_CLIENT_SECRET'
				);
			});
			this.station_service
				.removeServices(this.station.id, servicesToBeRemoved)
				.subscribe({
					next: response => {
						this.addServices();
					},
					error: error => {
						this.addServices();
					},
				});
		}
	}

	setStation() {
		const clientId: string = this.station.services
			? this.station.services.filter(s => {
					return s.type == 'ADAPTER_SYNC_CLIENT_ID';
			  })[0]?.value
			: '';
		const clientSecret: string = this.station.services
			? this.station.services.filter(s => {
					return s.type == 'ADAPTER_SYNC_CLIENT_SECRET';
			  })[0]?.value
			: '';
		this.form_group.get('client_id').setValue(clientId);
		this.form_group.get('client_secret').setValue(clientSecret);
	}

	addServices() {
		const services: Service[] = [
			{
				type: 'ADAPTER_SYNC_CLIENT_ID',
				value: this.form_group.get('client_id').value,
			},
			{
				type: 'ADAPTER_SYNC_CLIENT_SECRET',
				value: this.form_group.get('client_secret').value,
			},
		];

		this.is_save_running = true;
		this.station_service.addServices(this.station.id, services).subscribe({
			next: () => {
				this.dialog_ref.close({ success: true });
				this.is_save_running = false;
			},
			error: error => {
				this.is_save_running = false;
				this.error_service.wrapError(error);
			},
		});
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_save_running,
			'lk-main-button': !this.is_save_running,
		};
	}
}
