<div class="lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x24-horiz lk-margin__x48-bottom">
		<div class="lk-row">
			<img
				class="lk-flag lk-no-margin"
				[src]="getStationFlag()" />
			<h3 class="lk-margin__x12-left">
				{{ getTemplateLabel() | translate }}
			</h3>
		</div>

		<div
			*ngIf="!is_update_enabled"
			class="lk-full-width">
			<hr class="lk-stroke-dashed lk-full-width lk-margin-top" />
			<p
				*ngIf="!is_template_loading"
				style="white-space: pre-wrap"
				class="lk-no-margin">
				{{ getTemplateContent() }}
			</p>
			<div
				*ngIf="is_template_loading"
				class="lk-padding lk-centered-row">
				<mat-spinner
					color="accent"
					diameter="30"></mat-spinner>
			</div>
			<hr class="lk-stroke-dashed lk-full-width lk-margin-bottom" />
		</div>

		<form
			*ngIf="is_update_enabled"
			class="lk-full-width lk-margin-top"
			[formGroup]="template_item_form_group">
			<div
				*ngIf="is_template_loading"
				class="lk-padding lk-centered-row">
				<mat-spinner
					color="accent"
					diameter="30"></mat-spinner>
			</div>

			<div *ngIf="!is_template_loading">
				<mat-form-field
					style="width: calc(100% - 52px)"
					class="lk-full-width lk-form-field__params-select"
					appearance="fill"
					color="accent">
					<mat-select
						(selectionChange)="onParameterSelection($event)"
						matInput
						type="text"
						placeholder="{{ 'STATION.PARAMETERS_LIST_LABEL' | translate }}"
						formControlName="current_parameter">
						<mat-option
							[value]="param"
							*ngFor="let param of params_list">
							{{ param }}
						</mat-option>
					</mat-select>

					<button
						mat-icon-button
						matSuffix>
						<mat-icon class="lk-primary-medium-color"> add </mat-icon>
					</button>
				</mat-form-field>

				<button
					mat-icon-button
					matTooltip="{{ 'STATION.PARAMETERS_LIST_TOOLTIP' | translate }}"
					class="lk-background-icon lk-half-margin-left lk-hidden-before-lg">
					<mat-icon class="lk-accent-color"> info_outlined </mat-icon>
				</button>

				<button
					(click)="onInfoClick()"
					mat-icon-button
					class="lk-background-icon lk-half-margin-left lk-hidden-after-lg">
					<mat-icon class="lk-accent-color"> info_outlined </mat-icon>
				</button>
			</div>

			<mat-form-field
				*ngIf="!is_template_loading"
				class="lk-full-width lk-half-margin-top lk-stations-notif-templates-item-add-dialog-field"
				appearance="fill"
				color="accent">
				<mat-label> content </mat-label>
				<textarea
					style="min-height: 150px"
					type="text"
					matInput
					formControlName="text"
					required></textarea>
			</mat-form-field>

			<app-button
				class="lk-width__full lk-margin__x24-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.CONFIRM_CTA' | translate"
				[is_disabled]="!isFormValid() || is_station_loading"
				[is_spinner_visible]="is_station_loading"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>
