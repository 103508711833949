<div style="min-width: 350px">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		id="lk-hidden-stations-settings-dialog-form"
		class="lk-horiz-padding lk-hidden-stations-settings-dialog-toggle-layout">
		<form
			[formGroup]="form_group"
			class="lk-column">
			<h3 class="lk-margin__x12-bottom">Frontoffice</h3>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="unlock_confirmation">
				Enable/Disable unlock confirmation
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="area_control">
				Enable/Disable area control
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="interact_after_close">
				Enable/Disable checklist
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="parking_input">
				Enable/Disable parking input
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="custom_logo">
				Enable/Disable custom logo
			</mat-slide-toggle>

			<div
				*ngIf="isCustomLogoEnabled()"
				id="lk-hidden-station-settings-logo-layout">
				<mat-spinner
					*ngIf="!custom_logo"
					color="accent"
					diameter="20"></mat-spinner>
				<img
					*ngIf="custom_logo"
					class="lk-full-width lk-cursor-pointer"
					[src]="custom_logo"
					(click)="fileInput.click()" />

				<input
					#fileInput
					type="file"
					accept="image/png"
					(change)="onLogoUpdateClick($event)"
					style="display: none" />
			</div>

			<h3 class="lk-margin__x24-top lk-margin__x12-bottom">Notifications</h3>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="auto_close">
				Enable/Disable auto close
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="booking_closure_notif">
				Enable/Disable booking closure notif.
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="booking_closure_custom_notif">
				Enable/Disable customization for the booking closure notif.
			</mat-slide-toggle>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="vehicle_change_custom_notif">
				Enable/Disable customization for the vehicle change notif.
			</mat-slide-toggle>

			<mat-form-field
				appearance="fill"
				class="lk-full-width lk-half-margin-bottom lk-form-field">
				<mat-label> SMS sender alias (11 chars max) </mat-label>
				<input
					type="text"
					matInput
					formControlName="sms_alias"
					[errorStateMatcher]="matcher" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-full-width lk-half-margin-bottom lk-form-field">
				<mat-label> EMAIL sender alias </mat-label>
				<input
					type="text"
					matInput
					formControlName="email_alias" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-full-width lk-form-field">
				<mat-label> Low voltage notification threshold [V] </mat-label>
				<input
					type="number"
					matInput
					formControlName="low_voltage_threshold" />
			</mat-form-field>

			<h3 class="lk-margin__x24-top lk-margin__x12-bottom">Station navbar</h3>

			<mat-slide-toggle
				class="lk-margin-bottom"
				formControlName="team_mgmt">
				Enable/Disable station team management
			</mat-slide-toggle>

			<app-button
				class="lk-width__full lk-margin__x24-vert lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.SAVE_BUTTON_CTA' | translate"
				[is_disabled]="!isFormValid() || is_save_running"
				[is_spinner_visible]="is_save_running"
				(button_click)="onSettingsUpdateClick()"></app-button>
		</form>
	</div>
</div>
