<header>
	<mat-toolbar class="lk-toolbar">
		<mat-toolbar-row class="lk-row__space">
			<mat-spinner
				*ngIf="is_stations_list_running"
				class="lk-button__spinner-accent"
				diameter="20"></mat-spinner>

			<app-toolbar-station
				*ngIf="!is_stations_list_running"
				[current_nav_item]="current_nav_item"
				[station]="station"
				[nav_items]="nav_items"
				[logo]="logo"
				(station_change)="onStationChange($event)"
				(no_station_found)="no_station_found.emit()"
				(station_build)="station_build.emit()"></app-toolbar-station>

			<app-toolbar-profile
				*ngIf="!is_stations_list_running"
				[station]="station"></app-toolbar-profile>
		</mat-toolbar-row>
	</mat-toolbar>
</header>
