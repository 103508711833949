import { Injectable } from '@angular/core';
import { get } from 'aws-amplify/api';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export const BOOKINGS_SUMMARY_API_NAME: string = 'bookings_summary';
export const BOOKINGS_SUMMARY_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/bookings-summaries/';

@Injectable({
	providedIn: 'root',
})
export class BookingsSummaryService {
	read(station_id: string): Observable<any> {
		const path: string = `stations/${station_id}`;

		const init = { response: true };

		return from(
			get({
				apiName: BOOKINGS_SUMMARY_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}
}

export class BookingsSummary {
	total: number;
	bookings_in_pending: number;
	bookings_in_progress: number;
	bookings_with_errors: number;
	bookings_closed: number;
}
