<div class="lk-height__max-80vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<form
		[formGroup]="form_group"
		class="lk-width__full lk-padding__x24">
		<div class="lk-row__space lk-wrap">
			<mat-form-field
				class="lk-width__full lk-margin__x24-bottom lk-form-field">
				<mat-label> {{ 'GENERAL.STATION_NAME_LABEL' | translate }} </mat-label>
				<input
					type="text"
					matInput
					formControlName="name"
					required />
			</mat-form-field>

			<mat-form-field
				class="lk-width__full lk-margin__x24-bottom lk-form-field">
				<mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
				<input
					type="email"
					matInput
					formControlName="email"
					required />
			</mat-form-field>

			<mat-form-field
				floatLabel="always"
				class="lk-third-width lk-margin__x24-bottom lk-form-field">
				<mat-label> {{ 'GENERAL.PHONE_PREFIX_LABEL' | translate }} </mat-label>

				<img
					*ngIf="current_country_prefix"
					matPrefix
					class="lk-flag lk-margin__x12-left lk-margin__x12-top"
					[src]="current_country_prefix.flag" />

				<img
					*ngIf="!current_country_prefix"
					matPrefix
					class="lk-flag lk-margin__x12-left lk-margin__x12-top"
					[src]="unknown_flag_src" />

				<input
					type="text"
					matInput
					formControlName="phone_prefix"
					required
					[matAutocomplete]="autocountry" />

				<mat-autocomplete
					autoActiveFirstOption
					#autocountry="matAutocomplete"
					(optionSelected)="onCountryPrefixSelect()">
					<mat-option
						*ngFor="let country_prefix of filtered_options | async"
						[value]="country_prefix.code">
						<div class="lk-row">
							<img
								class="lk-flag__large lk-margin__x12-left"
								[src]="country_prefix.flag" />
							<div class="lk-column">
								<p
									style="
										max-width: calc(100% - 6px);
										font-size: 10px;
										line-height: 18px;
									"
									class="lk-no-margin lk-primary-medium-color lk-ellipsized-text">
									{{ country_prefix.name }}
								</p>
								<p
									style="line-height: 18px"
									class="lk-no-margin lk-ellipsized-text">
									{{ country_prefix.prefix }}
								</p>
							</div>
						</div>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field
				class="lk-three-third-width lk-margin__x24-bottom lk-form-field">
				<mat-label> {{ 'GENERAL.PHONE_LABEL' | translate }} </mat-label>
				<input
					type="text"
					matInput
					formControlName="phone"
					required />
			</mat-form-field>

			<mat-form-field
				class="lk-width__full lk-margin__x24-bottom lk-form-field">
				<mat-label> {{ 'GENERAL.ADDRESS_LABEL' | translate }} </mat-label>
				<input
					(onAddressChange)="onAddressChange($event)"
					type="text"
					matInput
					formControlName="address"
					required />
			</mat-form-field>

			<mat-expansion-panel
				class="lk-width__full lk-margin__x24-top"
				style="box-shadow: none">
				<mat-expansion-panel-header class="lk-expansion-panel-header__padding">
					<mat-panel-title class="lk-row__end">
						<span class="lk-primary-medium-color lk-text__12">
							{{ 'STATION_BUILDER.TURO_STATION_LINK_CTA' | translate }}
						</span>

						<img
							style="width: 50px"
							src="../../../assets/imgs/lk-turo-logo.png" />

						<p
							class="lk-text__12 lk-right-text"
							style="margin-left: -4px !important">
							?
						</p>
					</mat-panel-title>
				</mat-expansion-panel-header>

				<div class="lk-width__full lk-row__center lk-margin__x24-top">
					<form
						[formGroup]="form_group"
						class="lk-width__full">
						<mat-form-field
							class="lk-width__full lk-margin__x24-bottom lk-form-field">
							<mat-label> Turo email </mat-label>
							<input
								type="email"
								matInput
								formControlName="ext_email" />
						</mat-form-field>

						<mat-form-field
							appearance="fill"
							color="accent"
							class="lk-width__full lk-margin__x24-bottom lk-form-field">
							<mat-label> Timezone </mat-label>
							<mat-select formControlName="ext_timezone">
								<mat-option
									*ngFor="let timezone of timezones"
									[value]="timezone">
									({{ getTimezoneOffset(timezone) }})
									{{ timezone }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field
							class="lk-width__full lk-margin__x24-bottom lk-form-field">
							<mat-label> Date format </mat-label>
							<mat-select formControlName="ext_time_format">
								<mat-option
									*ngFor="let date of date_formats"
									[value]="date.format">
									{{ date.label }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</form>
				</div>
			</mat-expansion-panel>

			<app-button
				*ngIf="!update_mode"
				class="lk-width__full lk-margin__x48-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.NEXT_STEP_BUTTON_CTA' | translate"
				[is_disabled]="!isFormValid() || is_station_loading"
				[is_spinner_visible]="is_station_loading"
				(button_click)="onNextClick()"></app-button>

			<app-button
				*ngIf="update_mode"
				class="lk-width__full lk-margin__x48-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.NEXT_STEP_BUTTON_CTA' | translate"
				[is_disabled]="!isFormValid() || is_station_loading"
				[is_spinner_visible]="is_station_loading"
				(button_click)="onUpdateClick()"></app-button>
		</div>
	</form>
</div>
