<app-toolbar
	(no_station_found)="onNoStationFound()"
	(station_build)="onStationBuild(false)"
	(station_change)="onStationChange($event)"></app-toolbar>

<section class="lk-section-with-toolbar">
	<div
		class="lk-padding-md"
		style="padding-bottom: 0">
		<h1
			class="lk-no-margin lk-centered-text lk-ellipsized-text"
			*ngIf="email"
			style="margin-bottom: 6px !important">
			{{ email }}
		</h1>

		<app-loading
			*ngIf="is_profile_loading"
			style="width: 500px; height: 450px"
			class="lk-display__block lk-margin__auto lk-margin__x24-top"></app-loading>

		<div
			*ngIf="!is_profile_loading"
			style="overflow-y: scroll; max-width: 500px; margin: 0 auto"
			class="lk-margin-top lk-border-radius lk-shadow lk-padding lk-full-height lk-primary-background lk-hide-scrollbar">
			<app-profile-admin-info
				[email]="email"
				[phone]="phone"></app-profile-admin-info>

			<app-profile-settings
				*ngIf="mfa_preference"
				[mfa_preference]="mfa_preference"></app-profile-settings>
		</div>
	</div>
</section>
