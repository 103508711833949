<form
	[formGroup]="mfa_verify_form_group"
	class="lk-row__center lk-wrap lk-z-index">
	<p *ngIf="mfa_setup_mechanism == 'totp'">
		{{ 'AUTHENTICATION.MFA_VERIFY_TOTP_CTA' | translate }}
	</p>

	<p *ngIf="mfa_setup_mechanism == 'sms'">
		{{
			'AUTHENTICATION.MFA_VERIFY_SMS_CTA'
				| translate: { phone: code_destination }
		}}
	</p>

	<mat-form-field
		class="lk-width__full lk-margin__x12-bottom lk-margin__x24-top lk-form-field lk-form-field__large">
		<mat-label>
			{{ 'GENERAL.CODE_LABEL' | translate | titlecase }}
		</mat-label>
		<input
			matInput
			type="text"
			formControlName="code"
			required />
	</mat-form-field>

	<app-button
		class="lk-width__full lk-margin__x48-top"
		[button_class]="'lk-button__secondary lk-width__full'"
		[spinner_class]="'lk-button__spinner-white'"
		[cta]="'GENERAL.CONFIRM_CTA' | translate"
		[is_disabled]="!isFormValid() || is_mfa_verify_running"
		[is_spinner_visible]="is_mfa_verify_running"
		(button_click)="onMfaVerifyClick()"></app-button>
</form>
