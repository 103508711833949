import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from 'src/app/core/dialog.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { MetabaseService } from 'src/app/core/metabase.service';
import { STATION_PATH } from 'src/app/core/navigation.service';
import { Station } from 'src/app/core/station.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import {
	GENERAL_ERROR_MESSAGE,
	TOOLBAR_METABASE_NAV_ITEM_NAME,
} from 'src/app/shared/translations';
import { StationBuilderDialogComponent } from 'src/app/station/station-builder-dialog/station-builder-dialog.component';

@Component({
	selector: 'app-metabase',
	templateUrl: './metabase.component.html',
	styleUrls: ['./metabase.component.scss'],
})
export class MetabaseComponent implements OnInit {
	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	current_item: string = TOOLBAR_METABASE_NAV_ITEM_NAME;
	metabase_address?: SafeUrl;
	is_metabase_loading: boolean = false;

	constructor(
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private router: Router,
		private local_manager: LocalManagerService,
		private metabase_service: MetabaseService,
		protected dom_sanitizer: DomSanitizer,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.readMetabaseDashboard();
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuildClick(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
					this.toolbar_refresh.next(true);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onStationChangeClick(station: Station): void {
		this.readMetabaseDashboard();
	}

	readMetabaseDashboard(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		if (station?.id) {
			this.is_metabase_loading = true;
			this.metabase_service.getMetabaseAddress(station.id).subscribe({
				next: response => {
					response.body.json().then(response => {
						this.metabase_address =
							this.dom_sanitizer.bypassSecurityTrustResourceUrl(response.url);
						this.is_metabase_loading = false;
					});
				},
				error: () => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_metabase_loading = false;
				},
			});
		}
	}
}
