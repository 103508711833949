import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { CODE_MISMATCH_ERROR_CODE } from 'src/app/shared/constants';
import { CODE_MISMATCH_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-auth-mfa-verify-form',
	templateUrl: './auth-mfa-verify-form.component.html',
	styleUrl: './auth-mfa-verify-form.component.scss',
})
export class AuthMfaVerifyFormComponent {
	@Input() mfa_setup_mechanism?: string;
	@Input() code_destination?: string;

	is_mfa_verify_running: boolean = false;
	mfa_verify_form_group: FormGroup;

	constructor(
		private form_service: FormService,
		private auth_service: AuthenticationService,
		private navigation_service: NavigationService,
		private dialog_service: DialogService,
	) {
		this.mfa_verify_form_group = this.form_service.mfa_verify_form_group;
	}

	onMfaVerifyClick(): void {
		if (this.isFormValid() && !this.is_mfa_verify_running) {
			const otp = this.mfa_verify_form_group.get('code')?.value;
			this.confirmSignin(otp);
		}
	}

	confirmSignin(otp: string): void {
		this.is_mfa_verify_running = true;
		this.auth_service.confirmSignin(otp).subscribe({
			next: () => {
				this.navigation_service.toVehicles();
				this.is_mfa_verify_running = false;
			},
			error: error => {
				if (error.code == CODE_MISMATCH_ERROR_CODE) {
					this.dialog_service.openInfoDialog(CODE_MISMATCH_ERROR_MESSAGE);
				} else {
					this.dialog_service.openInfoDialog(error.message);
				}
				this.is_mfa_verify_running = false;
			},
		});
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.mfa_verify_form_group);
	}
}
