import { Injectable } from '@angular/core';
import { Address, Phone } from './core.module';
import { NotificationType } from './notification.service';
import { from, Observable } from 'rxjs';
import { get, post, put, del, patch } from 'aws-amplify/api';
import { environment } from '../../environments/environment';

export const STATIONS_API_NAME: string = 'stations';
export const STATIONS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/stations/';

@Injectable({
	providedIn: 'root',
})
export class StationService {
	create(station: Station): Observable<any> {
		const path: string = ``;

		const init = { response: true, body: station };

		return from(
			post({
				apiName: STATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	read(station_id: string): Observable<any> {
		const path: string = station_id;

		const init = { response: true };

		return from(
			get({
				apiName: STATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	update(station: Station): Observable<any> {
		const path: string = station.id;

		const init = { response: true, body: station };

		return from(
			put({
				apiName: STATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	delete(station_id: string): Observable<any> {
		const path: string = station_id;

		const init = { response: true };

		return from(
			del({
				apiName: STATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	_list(filters?: StationFiltersConfig): Observable<any> {
		const path = '';

		let query_params = {
			page: filters?.page ? filters.page : 1,
			per_page: filters?.per_page ? filters.per_page : 30,
		};
		if (filters?.name) {
			query_params['name'] = filters.name;
		}
		if (filters?.alias) {
			query_params['alias'] = filters.alias;
		}

		const init = { response: true, queryParams: query_params };

		return from(
			get({
				apiName: STATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	list(
		page?: number,
		per_page?: number,
		query_params?: StationQueryParams,
	): Observable<any> {
		const STATIONS_PER_PAGE: number = per_page ? per_page : 36;

		const path: string = ``;

		const init = {
			response: true,
			queryStringParameters: {
				page: page ? page : 1,
				per_page: page ? STATIONS_PER_PAGE : 100000000,
				...query_params,
			},
		};

		return from(
			get({
				apiName: STATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	setLogo(station_id: string, logo: string): Observable<any> {
		const path: string = `${station_id}/logo`;

		const init = { response: true, body: { logo: logo } };

		return from(
			patch({
				apiName: STATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	setCustomLogo(station_id: string, logo: string): Observable<any> {
		const path: string = `${station_id}/custom-guest-logo`;

		const init = { response: true, body: { logo: logo } };

		return from(
			patch({
				apiName: STATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	addServices(station_id: string, services: Service[]): Observable<any> {
		const path: string = `${station_id}/services/add`;

		const init = { response: true, body: services };

		return from(
			post({
				apiName: STATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	removeServices(station_id: string, services: Service[]): Observable<any> {
		const path: string = `${station_id}/services/remove`;

		const init = { response: true, body: services };

		return from(
			post({
				apiName: STATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	setAlias(station_id: string, alias: string): Observable<any> {
		const path: string = `${station_id}/alias`;

		const init = { response: true, body: { alias } };

		return from(
			patch({
				apiName: STATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}
}

export interface StationFiltersConfig {
	page?: number;
	per_page?: number;
	name?: string;
	alias?: string;
}

export interface StationQueryParams {
	name?: string;
	alias?: string;
}

export class Station {
	id: string;
	name: string;
	alias: string;
	email: string;
	ext_email: string;
	ext_timezone: string;
	ext_time_format: string;
	address: Address;
	logo: string;
	logo_url: string;
	notification_logo_url: string;
	logo_path: string;
	phone: Phone;
	services: Service[];
	settings: Settings;
	meta_dashboard: number;
	vehicle_collection_length?: number;
}

export class Service {
	type: string;
	value: string;
}

export class Settings {
	default_lang: string;
	default_notification_type: NotificationType;
	pictures_policy: PicturePolicy;
	lock_settings: LockSettings;
	unlock_settings: UnlockSettings;
	flow: Flow;
	odometer_um: string;
	date_format: string;
	is_area_control_enabled?: boolean;
	is_interact_after_close_enabled?: boolean;
	is_parking_input_enabled?: boolean;
	is_unlock_confirmation_enabled?: boolean;
	is_custom_logo_enabled?: boolean;
	is_auto_close_enabled?: boolean;
	is_booking_complete_notif_enabled?: boolean;
	is_booking_complete_custom_template_enabled?: boolean;
	is_booking_vehicle_changed_custom_template_enabled?: boolean;
	is_team_management_enabled?: boolean;
	checklist: StationChecklist;
	custom_sms_sender_alias: string;
	custom_email_sender_address: string;
	custom_guest_logo: string;
	low_voltage_threshold?: number;
}

export class StationChecklist {
	messages: StationChecklistMessage[];
}

export class StationChecklistMessage {
	en?: string;
	es?: string;
	it?: string;
}

export class LockSettings {
	is_link_invalid: boolean;
	is_lockable_after_vehicle_unlock: boolean;
	time_to_lock_link_before_booking_end: number;
	//time_to_reminder_before_booking_end         : number;
	time_to_lock_availability_before_booking_end: number;
	time_to_lock_availability_after_booking_end: number;
}

export class UnlockSettings {
	is_link_invalid: boolean;
	is_unlockable_on_vehicle_assignment: boolean;
	is_unlockable_until_booking_end_date: boolean;
	time_to_unlock_link_before_booking_start: number;
	time_to_unlock_availability_before_booking_start: number;
	time_to_unlock_availability_after_booking_start: number;
}

export class Flow {
	booking_start_constraints: Constraint[];
	booking_stop_constraints: Constraint[];
}

export class Constraint {
	type: ConstraintType;
	index: number;
}

export enum PicturePolicy {
	MANDATORY = 'mandatory',
	MANDATORY_PRE = 'mandatory_pre',
	MANDATORY_POST = 'mandatory_post',
	OPTIONAL = 'optional',
	DISABLED = 'disabled',
}

export enum ConstraintType {
	DOCUMENTS = 'documents',
	PAYMENT = 'payment',
	START_CONTRACT_SIGN = 'start_contract_sign',
	END_CONTRACT_SIGN = 'end_contract_sign',
}
