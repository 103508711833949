<div
	class="lk-row lk-overflow__x-scroll lk-hide-scrollbar lk-margin__x24-vert"
	style="width: calc(100% + 12px)">
	<div
		*ngIf="!is_summary_loading && vehicles_summary"
		class="lk-summary lk-border-radius lk-background__light-neutral-0">
		<p class="lk-no-margin lk-primary-medium-color">
			{{ 'VEHICLE.SUMMARY_ALL_LABEL' | translate | titlecase }}
		</p>

		<h1 class="lk-no-margin">
			{{ vehicles_summary.total }}
		</h1>
	</div>

	<div
		*ngIf="!is_summary_loading && vehicles_summary"
		class="lk-summary lk-border-radius lk-background__light-neutral-0">
		<p class="lk-no-margin lk-primary-medium-color">
			{{ 'VEHICLE.SUMMARY_LOCKED_LABEL' | translate | titlecase }}
		</p>

		<h1 class="lk-no-margin">
			{{ vehicles_summary.vehicles_in_locked }}
		</h1>
	</div>

	<div
		*ngIf="!is_summary_loading && vehicles_summary"
		class="lk-summary lk-border-radius lk-background__light-neutral-0">
		<p class="lk-no-margin lk-primary-medium-color">
			{{ 'VEHICLE.SUMMARY_UNLOCKED_LABEL' | translate | titlecase }}
		</p>

		<h1 class="lk-no-margin">
			{{ vehicles_summary.vehicles_in_unlocked }}
		</h1>
	</div>

	<div
		*ngIf="!is_summary_loading && vehicles_summary"
		class="lk-summary lk-border-radius lk-background__light-neutral-0">
		<p class="lk-no-margin lk-primary-medium-color">
			{{ 'VEHICLE.SUMMARY_OFFLINE_LABEL' | translate | titlecase }}
		</p>

		<h1 class="lk-no-margin">
			{{ vehicles_summary.vehicles_offline }}
		</h1>
	</div>

	<div
		*ngIf="!is_summary_loading && vehicles_summary"
		style="margin-right: 12px"
		class="lk-summary lk-border-radius lk-background__light-neutral-0">
		<p class="lk-no-margin lk-primary-medium-color">
			{{ 'VEHICLE.SUMMARY_ONLINE_LABEL' | translate | titlecase }}
		</p>

		<h1 class="lk-no-margin">
			{{ vehicles_summary.vehicles_online }}
		</h1>
	</div>

	<app-loading
		*ngIf="is_summary_loading || !vehicles_summary"
		class="lk-summary__loading lk-border-radius"></app-loading>

	<app-loading
		*ngIf="is_summary_loading || !vehicles_summary"
		class="lk-summary__loading lk-border-radius"></app-loading>

	<app-loading
		*ngIf="is_summary_loading || !vehicles_summary"
		class="lk-summary__loading lk-border-radius"></app-loading>

	<app-loading
		*ngIf="is_summary_loading || !vehicles_summary"
		class="lk-summary__loading lk-border-radius"></app-loading>

	<app-loading
		*ngIf="is_summary_loading || !vehicles_summary"
		style="margin-right: 12px"
		class="lk-summary__loading lk-border-radius"></app-loading>
</div>
