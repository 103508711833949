import {
	Component,
	EventEmitter,
	Input,
	Output,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { DialogService } from 'src/app/core/dialog.service';
import {
	Notification,
	NotificationService,
	NotificationType,
	TemplateItem,
} from 'src/app/core/notification.service';
import { Station } from 'src/app/core/station.service';
import { StationsNotifTemplatesItemDialogComponent } from '../stations-notif-templates-item-dialog/stations-notif-templates-item-dialog.component';
import { ErrorService } from 'src/app/core/error.service';
import { StationsNotifTemplatesItemAddDialogComponent } from '../stations-notif-templates-item-add-dialog/stations-notif-templates-item-add-dialog.component';

@Component({
	selector: 'app-station-settings-templates-item',
	templateUrl: './station-settings-templates-item.component.html',
	styleUrl: './station-settings-templates-item.component.scss',
})
export class StationSettingsTemplatesItemComponent {
	@Input() is_expanded = false;
	@Input() template?: TemplateItem;
	@Input() station?: Station;
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();

	@ViewChildren(StationSettingsTemplatesItemComponent)
	containers?: QueryList<StationSettingsTemplatesItemComponent>;

	sms_type: NotificationType = NotificationType.SMS;

	is_default_selected = true;

	constructor(
		private dialog_service: DialogService,
		private notification_service: NotificationService,
		private error_service: ErrorService,
	) {}

	ngOnInit(): void {
		this.is_default_selected =
			!this.template?.custom || this.template?.custom?.is_disabled;
	}

	onTemplateClick(is_default_selected: boolean): void {
		if (this.is_default_selected != is_default_selected) {
			this.is_default_selected = is_default_selected;

			if (is_default_selected) {
				this.disableTemplate(this.template?.custom);
			} else {
				this.enableTemplate(this.template?.custom);
			}
		}
	}

	onDefaultClick(): void {
		this.dialog_service.openDialog(StationsNotifTemplatesItemDialogComponent, {
			is_update_enabled: false,
			station: this.station,
			template: this.template?.default,
		});
	}

	onAddTemplateClick(): void {
		this.dialog_service
			.openDialog(StationsNotifTemplatesItemAddDialogComponent, {
				station: this.station,
				template_name: this.template?.name,
				template_type: this.template?.type,
			})
			?.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.refresh.emit();
				} else if (dialog_result && !dialog_result.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onCustomUpdateClick(): void {
		this.dialog_service
			.openDialog(StationsNotifTemplatesItemDialogComponent, {
				is_update_enabled: true,
				station: this.station,
				template: this.template?.custom,
			})
			?.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.refresh.emit();
				} else if (dialog_result && !dialog_result.success) {
					this.error_service.wrapError(dialog_result.error);
				}
			});
	}

	onCustomUploadClick(event: any): void {
		const file: File = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = _event => {
			if (this.template && this.template.custom) {
				this.template.custom.content = reader.result?.toString();
				this.updateTemplate(this.template.custom);
			}
		};
	}

	onCustomDeleteClick(): void {
		this.deleteTemplate(this.template.custom);
	}

	deleteTemplate(notification: Notification): void {
		const custom_container: StationSettingsTemplatesItemComponent | undefined =
			this.containers?.get(1);

		if (this.template?.custom && this.station?.id && custom_container) {
			this.notification_service
				.remove(this.station.id, notification)
				.subscribe({
					next: () => {
						this.refresh.emit();
					},
					error: error => {
						this.error_service.wrapError(error);
					},
				});
		}
	}

	updateTemplate(notification: Notification): void {
		const custom_container: StationSettingsTemplatesItemComponent | undefined =
			this.containers?.get(1);

		if (custom_container) {
			this.notification_service
				.update(this.station.id, notification)
				.subscribe({
					next: () => {
						this.refresh.emit();
					},
					error: error => {
						this.error_service.wrapError(error);
					},
				});
		}
	}

	enableTemplate(notification?: Notification): void {
		if (notification?.id && this.station?.id) {
			this.notification_service
				.enable(notification.id, this.station.id)
				.subscribe({
					next: () => {
						this.is_default_selected = false;
					},
					error: error => {
						this.error_service.wrapError(error);
						this.is_default_selected = true;
					},
				});
		}
	}

	disableTemplate(notification?: Notification): void {
		if (notification?.id && this.station?.id) {
			this.notification_service
				.disable(notification.id, this.station.id)
				.subscribe({
					next: () => {
						this.is_default_selected = true;
					},
					error: error => {
						this.error_service.wrapError(error);
						this.is_default_selected = false;
					},
				});
		}
	}
}
