import { Component, Input } from '@angular/core';
import { Station, StationService } from 'src/app/core/station.service';
import { DEFAULT_STATION_LOGO } from 'src/app/shared/constants';

@Component({
	selector: 'app-station-panel-logo',
	templateUrl: './station-panel-logo.component.html',
	styleUrl: './station-panel-logo.component.scss',
})
export class StationPanelLogoComponent {
	@Input() logo?: string;
	@Input() station?: Station;

	is_logo_loading = false;

	constructor(private station_service: StationService) {}

	onLogoClick(event: any): void {
		const file: File = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = _event => {
			const image: string | ArrayBuffer = reader.result;
			this.setLogo(image);
		};
	}

	setLogo(image: string | ArrayBuffer): void {
		this.logo = null;
		this.is_logo_loading = true;
		this.station_service.setLogo(this.station.id, image.toString()).subscribe({
			next: response => {
				this.station = response.data as Station;
				this.logo = this.station.logo_url;
				//this.logo_build_click.emit(this.station);
				this.is_logo_loading = false;
			},
			error: () => {
				this.logo = DEFAULT_STATION_LOGO;
				this.is_logo_loading = false;
			},
		});
	}
}
