import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-hidden-stations-header-filters',
	templateUrl: './hidden-stations-header-filters.component.html',
	styleUrl: './hidden-stations-header-filters.component.scss',
})
export class HiddenStationsHeaderFiltersComponent {
	@Input() current_search_value?: string;
	@Input() current_alias_search_value?: string;
	@Output() name_reset: EventEmitter<void> = new EventEmitter<void>();
	@Output() alias_reset: EventEmitter<void> = new EventEmitter<void>();

	onNameReset(): void {
		this.current_search_value = undefined;
		this.name_reset.emit();
	}

	onAliasReset(): void {
		this.current_alias_search_value = undefined;
		this.alias_reset.emit();
	}
}
