import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from 'src/app/core/dialog.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { Station } from 'src/app/core/station.service';
import {
	Vehicle,
	VehicleService,
	VehicleStatus,
} from 'src/app/core/vehicle.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import {
	VEHICLE_DELETE_MESSAGE,
	GENERAL_ERROR_MESSAGE,
} from 'src/app/shared/translations';
import { VehicleDeleteDialogComponent } from 'src/app/vehicle/vehicle-delete-dialog/vehicle-delete-dialog.component';

@Component({
	selector: 'app-vehicle-details-header',
	templateUrl: './vehicle-details-header.component.html',
	styleUrl: './vehicle-details-header.component.scss',
})
export class VehicleDetailsHeaderComponent {
	@Input() vehicle?: Vehicle;
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();

	is_vehicle_loading = false;

	constructor(
		private navigation_service: NavigationService,
		private dialog_service: DialogService,
		private local_manager: LocalManagerService,
		private vehicle_service: VehicleService,
	) {}

	onBackClick(): void {
		this.navigation_service.toVehicles();
	}

	onVehicleDeleteClick(): void {
		this.dialog_service
			.openDialog(VehicleDeleteDialogComponent, { vehicle: this.vehicle })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.confirm) {
					const station: Station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);

					this.deleteVehicle(station.id, this.vehicle.id);
				}
			});
	}

	deleteVehicle(station_id: string, vehicle_id: string): void {
		this.is_vehicle_loading = true;
		this.vehicle_service.delete(station_id, vehicle_id).subscribe({
			next: () => {
				this.navigation_service.toVehicles();
				this.is_vehicle_loading = false;

				this.dialog_service.openInfoDialog(VEHICLE_DELETE_MESSAGE);
			},
			error: () => {
				this.is_vehicle_loading = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	getVehicleStatusClass(vehicle: Vehicle): { [key: string]: boolean } {
		return {
			'lk-vehicle-green-status':
				vehicle && vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle && vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}
}
