<div class="lk-row">
	<app-shared-logo
		[logo]="logo"
		(logo_click)="onHomeClick()"></app-shared-logo>

	<span
		[matMenuTriggerFor]="stations_menu"
		#menuTrigger="matMenuTrigger"
		class="lk-text__12 lk-text__ellipsized lk-cursor__pointer lk-margin__x8-left">
		{{ station?.name || '' }}
	</span>

	<mat-icon
		[matMenuTriggerFor]="stations_menu"
		#menuTrigger="matMenuTrigger"
		class="lk-cursor__pointer lk-width__min-24">
		keyboard_arrow_down
	</mat-icon>

	<mat-menu #stations_menu="matMenu">
		<button
			(click)="station_build.emit()"
			mat-menu-item>
			<mat-icon> add </mat-icon>
			<span> {{ 'STATION.BUILDER_CTA' | translate }} </span>
		</button>

		<div *ngIf="!is_stations_list_running">
			<button
				mat-menu-item
				*ngFor="let station of stations"
				(click)="station_change.emit(station)">
				<div class="lk-row">
					<app-shared-logo
						[logo]="station?.logo_url || default_logo"></app-shared-logo>

					<p class="lk-margin__x8-left lk-margin__x12-right">
						{{ station?.name }}
					</p>
				</div>
			</button>
		</div>

		<div
			*ngIf="is_stations_list_running"
			class="lk-row__center">
			<mat-spinner
				class="lk-button__spinner-accent"
				diameter="20"></mat-spinner>
		</div>
	</mat-menu>
</div>
