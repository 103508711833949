<div class="lk-row__space lk-margin__x12-bottom">
	<h2>
		{{ 'STATION.NAVBAR_TEAM_ITEM_NAME' | translate }}
	</h2>

	<button
		(click)="onAddTeammateClick()"
		class="lk-icon__button-neutral-0"
		mat-icon-button>
		<mat-icon class="lk-accent-color"> add </mat-icon>
	</button>
</div>

<div
	*ngIf="station && !is_team_loading"
	class="lk-padding__x48 lk-shadow lk-border-radius lk-background__light-neutral-0 lk-margin__x24-bottom">
	<p
		*ngIf="!invitations.length && !team.length"
		class="lk-primary-medium-color lk-text__center">
		{{ 'STATION.TEAM_EMPTY_TEXT' | translate }}
	</p>

	<span
		*ngIf="invitations.length"
		class="lk-primary-medium-color lk-text__12">
		{{ 'STATION.TEAM_INVITATIONS_LABEL' | translate }}
	</span>

	<div
		*ngIf="invitations.length"
		class="lk-width__full lk-row lk-wrap">
		<div
			*ngFor="let invitation of invitations"
			class="lk-chips-layout lk-background__light-neutral-20 lk-row__center lk-margin__x12-bottom lk-margin__x12-right">
			<span class="lk-primary-medium-color lk-text__12">
				{{ invitation.admin_email }}
			</span>

			<mat-icon
				(click)="onDeleteInvitationClick(invitation)"
				class="lk-cursor__pointer lk-margin__x6-left lk-primary-medium-color">
				delete
			</mat-icon>
		</div>
	</div>

	<span
		*ngIf="team.length"
		class="lk-primary-medium-color lk-text__12">
		{{ 'STATION.TEAM_MEMBERS_LABEL' | translate }}
	</span>

	<div
		*ngIf="team.length"
		class="lk-width__full lk-row lk-wrap">
		<div
			*ngFor="let admin of team"
			class="lk-chips-layout lk-background__light-neutral-20 lk-row__center lk-margin__x12-bottom lk-margin__x12-right lk-state-800-background lk-state-900-color">
			<span class="lk-text__12">
				{{ admin.email }}
			</span>
			<mat-icon
				(click)="onDeleteMemberClick(admin)"
				class="lk-margin__x6-left lk-cursor__pointer">
				delete
			</mat-icon>
		</div>
	</div>
</div>

<div
	*ngIf="!station || is_team_loading"
	style="height: 400px; display: block"
	class="lk-shadow lk-border-radius">
	<app-loading class="lk-width__full lk-height__full"></app-loading>
</div>
