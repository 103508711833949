<div class="lk-row__space lk-wrap">
	<div
		class="lk-row lk-display__hidden-before-md lk-cursor__pointer"
		(click)="onBackClick()">
		<button
			class="lk-icon__button-neutral-40"
			mat-icon-button>
			<mat-icon class="lk-accent-color"> keyboard_arrow_left </mat-icon>
		</button>

		<p class="lk-accent-color lk-text__12 lk-margin__x6-left">
			{{ 'BOOKING_DETAILS.BACK_CTA' | translate }}
		</p>
	</div>

	<div class="lk-booking-details-header">
		<h1
			*ngIf="booking && booking.id"
			class="lk-margin__x6-bottom">
			{{ getUserIdentifier(booking) }}
		</h1>

		<div
			class="lk-border-radius lk-primary-medium-background"
			style="padding: 2px 12px; width: fit-content"
			[ngClass]="getBookingStatusClass()">
			<span
				class="lk-ellipsized-text"
				*ngIf="booking">
				{{ getBookingStatusLabel() | translate }}
			</span>
		</div>
	</div>

	<div
		style="width: 135px"
		class="lk-row__end">
		<button
			mat-icon-button
			(click)="refresh.emit()"
			class="lk-icon__button-neutral-40"
			matTooltip="{{ 'BOOKING_DETAILS.REFRESH_CTA' | translate }}">
			<mat-icon class="lk-accent-color"> refresh </mat-icon>
		</button>

		<button
			mat-icon-button
			[matMenuTriggerFor]="menu"
			class="lk-icon__button-neutral-40 lk-margin__x8-left">
			<mat-icon class="lk-color__neutral-100"> more_horiz </mat-icon>
		</button>

		<mat-menu #menu="matMenu">
			<button
				mat-menu-item
				(click)="onBookingDeleteClick()">
				<mat-icon style="color: red"> delete </mat-icon>
				{{ 'BOOKING.DELETE_CTA' | translate }}
			</button>
		</mat-menu>
	</div>
</div>
