import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-station-panel-menu',
	templateUrl: './station-panel-menu.component.html',
	styleUrl: './station-panel-menu.component.scss',
})
export class StationPanelMenuComponent {
	@Output() update_click: EventEmitter<void> = new EventEmitter<void>();
	@Output() delete_click: EventEmitter<void> = new EventEmitter<void>();
}
