import { Component, Input } from '@angular/core';
import { NavigationService, NavItem } from 'src/app/core/navigation.service';

@Component({
	selector: 'app-toolbar-station-navigation',
	templateUrl: './toolbar-station-navigation.component.html',
	styleUrl: './toolbar-station-navigation.component.scss',
})
export class ToolbarStationNavigationComponent {
	@Input() current_nav_item?: string;
	@Input() nav_items?: NavItem[];

	constructor(private navigation_service: NavigationService) {}

	onNavItemClick(item: NavItem): void {
		this.navigation_service.toGenericPath(item.path);
	}

	getNavItemClass(item: NavItem): { [key: string]: boolean } {
		return {
			'lk-color__secondary-50':
				this.current_nav_item && item.name == this.current_nav_item,
			'': !this.current_nav_item || item.name != this.current_nav_item,
		};
	}
}
