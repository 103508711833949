<div [ngClass]="getRootClass()">
	<div
		class="lk-ended-row lk-margin__x24-bottom"
		*ngIf="!is_header_hidden">
		<form
			[formGroup]="form_group"
			class="lk-width__full">
			<mat-form-field
				class="lk-form-field lk-width__full"
				color="primary"
				appearance="fill">
				<mat-label>
					{{ 'VEHICLE.SEARCH_BY_PLATE_LABEL' | translate }}
				</mat-label>
				<mat-icon
					matPrefix
					class="lk-primary-medium-color">
					search
				</mat-icon>
				<input
					matInput
					type="text"
					formControlName="plate"
					(change)="search_change.emit(form_group.get('plate').value)" />
				<mat-icon
					matSuffix
					class="lk-primary-medium-color lk-cursor-pointer"
					(click)="filters_click.emit()">
					tune
				</mat-icon>
			</mat-form-field>
		</form>
	</div>

	<div
		class="lk-full-width lk-border-radius lk-padding lk-margin-bottom lk-shadow lk-cursor-pointer lk-primary-600-hover"
		id="lk-vehicle-list-item-layout"
		*ngFor="let vehicle of vehicles"
		(click)="onVehicleClick(vehicle)">
		<div class="lk-full-width lk-spaced-row">
			<p class="lk-no-margin">{{ vehicle.brand }} {{ vehicle.model }}</p>

			<div
				class="lk-border-radius lk-primary-medium-background"
				[ngClass]="getVehicleStatusClass(vehicle)"
				style="margin-top: 4px; padding: 2px 12px; width: fit-content">
				<span *ngIf="vehicle.status"> {{ vehicle.status | lowercase }} </span>
				<span *ngIf="!vehicle.status"> unknown </span>
			</div>
		</div>

		<div>
			<p
				class="lk-no-margin lk-small-text-size"
				style="margin-top: 4px !important">
				{{ vehicle.plate }} - {{ vehicle.color }}
			</p>
		</div>

		<div class="lk-spaced-row lk-margin-top lk-full-width">
			<app-button
				class="lk-vehicle-list-button lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[icon]="'lock_open'"
				[cta]="''"
				[is_disabled]="isVehicleUnlockInteracting(vehicle)"
				[is_spinner_visible]="isVehicleUnlockInteracting(vehicle)"
				(button_click)="onStartClick(vehicle)"></app-button>

			<app-button
				class="lk-vehicle-list-button lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[icon]="'lock'"
				[cta]="''"
				[is_disabled]="isVehicleLockInteracting(vehicle)"
				[is_spinner_visible]="isVehicleLockInteracting(vehicle)"
				(button_click)="onStopClick(vehicle)"></app-button>
		</div>
	</div>
</div>
