<form
	[formGroup]="signin_form_group"
	class="lk-row__center lk-wrap lk-z-index">
	<mat-form-field class="lk-width__full lk-margin__x24-bottom lk-form-field">
		<mat-label>
			{{ 'GENERAL.EMAIL_LABEL' | translate }}
		</mat-label>
		<input
			type="email"
			matInput
			formControlName="username"
			placeholder="address@email.com"
			required />
	</mat-form-field>

	<mat-form-field class="lk-width__full lk-form-field">
		<mat-label>
			{{ 'GENERAL.PASSWORD_LABEL' | translate }}
		</mat-label>
		<input
			[type]="is_password_visible ? 'text' : 'password'"
			matInput
			formControlName="password"
			required />
		<mat-icon
			class="lk-cursor__pointer"
			matSuffix
			(click)="is_password_visible = !is_password_visible">
			{{ is_password_visible ? 'visibility_off' : 'visibility' }}
		</mat-icon>
	</mat-form-field>

	<span
		class="lk-width__full lk-color__neutral-30 lk-cursor__pointer lk-text__hover lk-text__right lk-text__14"
		style="margin-top: 6px"
		(click)="onForgotPssClick()">
		{{ 'AUTHENTICATION.FORGOT_PSS_CTA' | translate }}
	</span>

	<app-button
		class="lk-width__full lk-margin__x48-top"
		[button_class]="'lk-button__secondary lk-width__full'"
		[spinner_class]="'lk-button__spinner-white'"
		[cta]="'AUTHENTICATION.SIGNIN_BUTTON_CTA' | translate"
		[is_disabled]="!isFormValid() || is_signin_running"
		[is_spinner_visible]="is_signin_running"
		(button_click)="onSigninClick()"></app-button>
</form>
