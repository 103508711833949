<div class="lk-height__max-80vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x12-horiz lk-hide-scrollbar">
		<form
			class="lk-width__full lk-row__space lk-wrap lk-margin__x24-bottom"
			[formGroup]="form_group">
			<h3 class="lk-width__full lk-margin__x12-bottom">
				{{ 'VEHICLE.FILTER_TITLE' | translate }}
			</h3>

			<mat-form-field
				appearance="fill"
				class="lk-form-field lk-third-width lk-margin__x12-bottom">
				<mat-label>
					{{ 'VEHICLE.CURRENT_FILTER_LABEL' | translate }}
				</mat-label>
				<mat-select formControlName="current">
					<mat-option value="plate">
						{{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate }}
					</mat-option>
					<mat-option value="category">
						{{ 'VEHICLE.CATEGORY_COLUMN_LABEL' | translate }}
					</mat-option>
					<mat-option value="brand">
						{{ 'VEHICLE.BRAND_COLUMN_LABEL' | translate }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				*ngIf="isPlateVisible()"
				appearance="fill"
				class="lk-three-third-width lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="plate" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				*ngIf="isCategoryVisible()"
				class="lk-three-third-width lk-form-field lk-margin__x12-bottom">
				<mat-label>
					{{ 'VEHICLE.CATEGORY_COLUMN_LABEL' | translate }}
				</mat-label>
				<input
					matInput
					type="text"
					formControlName="category" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				*ngIf="isBrandVisible()"
				class="lk-three-third-width lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'VEHICLE.BRAND_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="brand" />
			</mat-form-field>

			<h3 class="lk-full-width lk-half-margin-bottom">
				{{ 'VEHICLE.FILTER_SUBTITLE' | translate }}
			</h3>

			<mat-radio-group
				formControlName="status"
				class="lk-column">
				<mat-radio-button
					*ngFor="let status of states"
					[value]="status"
					style="padding: 4px 0">
					{{ status }}
				</mat-radio-button>
			</mat-radio-group>

			<div class="lk-full-width lk-ended-row">
				<span
					class="lk-cursor-pointer"
					(click)="onFiltersResetClick()">
					<u> {{ 'GENERAL.DELETE_FILTERS_CTA' | translate }} </u>
				</span>
			</div>

			<app-button
				class="lk-width__full lk-margin__x48-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.CONFIRM_CTA' | translate"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>
