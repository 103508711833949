import { Component, OnInit, ViewChild } from '@angular/core';
import { TOOLBAR_STATION_NAV_ITEM_NAME } from '../shared/translations';
import { Station } from '../core/station.service';
import {
	LOCAL_STATION_KEY,
	STATION_NAVBAR_TEAM_ITEM_NAME,
	STATION_NAVBAR_NOTIF_ITEM_NAME,
	DEFAULT_STATION_LOGO,
} from '../shared/constants';
import { DialogService } from '../core/dialog.service';
import {
	Invitation,
	InvitationResponseType,
	InvitationService,
} from '../core/invitation.service';
import { NavigationService } from '../core/navigation.service';
import { StationBuilderDialogComponent } from './station-builder-dialog/station-builder-dialog.component';
import { StationMapComponent } from './station-map/station-map.component';
import { StationSettingsComponent } from './station-settings/station-settings.component';

declare var L: any;

@Component({
	selector: 'app-station',
	templateUrl: './station.component.html',
	styleUrls: ['./station.component.scss'],
})
export class StationComponent implements OnInit {
	@ViewChild(StationMapComponent) map_component?: StationMapComponent;
	@ViewChild(StationSettingsComponent)
	settings_component?: StationSettingsComponent;

	current_item = TOOLBAR_STATION_NAV_ITEM_NAME;
	navbar_item = STATION_NAVBAR_TEAM_ITEM_NAME;

	station?: Station;
	logo?: string;

	/*
	@ViewChild(StationTeamComponent) team_component: StationTeamComponent;
	@ViewChild(StationNotifComponent) notif_component: StationNotifComponent;
	@ViewChild(StationNotifSettingsComponent)
	notif_settings_component: StationNotifSettingsComponent;
	@ViewChild(StationPicsComponent) pics_component: StationPicsComponent;
	@ViewChild(StationUnlockComponent) unlock_component: StationUnlockComponent;
	@ViewChild(StationLockComponent) lock_component: StationLockComponent;
	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	current_item: string = TOOLBAR_STATION_NAV_ITEM_NAME;
	navbar_items: string[] = [
		STATION_NAVBAR_TEAM_ITEM_NAME,
		STATION_NAVBAR_NOTIF_ITEM_NAME,
		STATION_NAVBAR_PICS_ITEM_NAME,
		STATION_NAVBAR_UNLOCK_ITEM_NAME,
		STATION_NAVBAR_LOCK_ITEM_NAME,
		STATION_NAVBAR_DATA_SETTINGS_ITEM_NAME,
	];
	navbar_item: string = STATION_NAVBAR_TEAM_ITEM_NAME;
	logo: string;
	station: Station;
	is_production: boolean = false;
	map: any;*/

	constructor(
		private navigation_service: NavigationService,
		private invitation_service: InvitationService,
		private dialog_service: DialogService,
	) {}

	ngOnInit(): void {
		this.navbar_item = STATION_NAVBAR_NOTIF_ITEM_NAME;
		this.listInvitations();
	}

	onStationChange(station: Station): void {
		this.logo = null;
		this.setStation(station);
	}

	onNoStationFound(): void {
		this.navigation_service.toStationEmpty();
	}

	onStationBuild(update_mode?: boolean): void {
		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: this.station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result?.success && dialog_result.station) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onStationDelete(): void {
		localStorage.removeItem(LOCAL_STATION_KEY);
		this.station = null;
		this.logo = null;
	}

	onNavbarItemChange(item: string): void {
		this.navbar_item = item;
	}

	setStation(station: Station): void {
		this.station = station;

		this.logo = this.station.logo_url
			? this.station.logo_url
			: DEFAULT_STATION_LOGO;

		localStorage.setItem(LOCAL_STATION_KEY, JSON.stringify(this.station));

		this.map_component?.setStation(station);
		this.map_component?.loadMap();
		this.settings_component?.onRefresh(station);
	}

	listInvitations(): void {
		this.invitation_service.listByAdmin().subscribe({
			next: response => {
				const pending_invitations = response.data.filter((i: Invitation) => {
					return i.response == InvitationResponseType.PENDING;
				});

				if (pending_invitations?.length > 0) {
					this.navigation_service.toInvitation();
				}
			},
			error: () => {},
		});
	}

	/*
	onLogoBuildClick(station: Station): void {
		localStorage.setItem(LOCAL_STATION_KEY, JSON.stringify(station));
		this.toolbar_refresh.next(true);
	}

	onStationDeleteClick(): void {
		localStorage.removeItem(LOCAL_STATION_KEY);
		this.station = null;
		this.logo = null;
		this.toolbar_refresh.next(true);
	}

	onStationChangeClick(station: Station): void {
		this.logo = null;
		this.setStation(station);
		this.resetCurrentNavbarItem(station);
	}

	onNoStationFound(): void {
		localStorage.removeItem(LOCAL_STATION_KEY);
		this.no_station_found = true;
	}

	onAddStationClick(): void {
		this.router.navigate(['station/builder/basics']);
	}

	onNavbarItemChange(item: string): void {
		this.navbar_item = item;
	}

	onSelfDelete(): void {
		this.station = null;
		localStorage.removeItem(LOCAL_STATION_KEY);
		this.toolbar_refresh.next(true);
	}

	onNotifStartSpinner(): void {
		if (this.notif_component) {
			this.notif_component.startSpinner();
		}
	}

	onNotifRefreshClick(): void {
		if (this.notif_component) {
			this.notif_component.onReset();
		}
	}

	listInvitations(): void {
		this.invitation_service.listByAdmin().subscribe({
			next: response => {
				const station = JSON.parse(
					localStorage.getItem(LOCAL_STATION_KEY),
				) as Station;
				const pendingInvitations = (response.data as Invitation[]).filter(i => {
					return i.response == InvitationResponseType.PENDING;
				});
				if (pendingInvitations && pendingInvitations.length > 0) {
					this.router.navigate([INVITATION_PATH]);
				} else if (station) {
					this.setStation(station);
				}
			},
			error: error => {
				const station = JSON.parse(
					localStorage.getItem(LOCAL_STATION_KEY),
				) as Station;
				if (station) {
					this.setStation(station);
				}
			},
		});
	}

	setStation(station: Station): void {
		this.station = station;
		this.no_station_found = false;
		this.logo = this.station.logo_url
			? this.station.logo_url
			: DEFAULT_STATION_LOGO;
		localStorage.setItem(LOCAL_STATION_KEY, JSON.stringify(this.station));
		//this.loadMap();
	}

	loadMap(): void {
		if (this.map) {
			this.map.remove();
		}

		const is_attached: boolean = document.getElementById(
			'lk-station-map-layout',
		)
			? true
			: false;
		if (is_attached) {
			this.map = L.map('lk-station-map-layout', {
				attributionControl: false,
				minZoom: 10,
				zoomControl: false,
			});
			const mapBoxUrl =
				'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=' +
				MAPBOX_API_KEY;
			this.map.setView(MAP_DEFAULT_BASE_POINT, MAP_DEFAULT_ZOOM);
			L.tileLayer(mapBoxUrl, {
				attribution: '',
				maxZoom: MAP_MAX_ZOOM,
				id: 'mapbox/light-v10',
				accessToken: 'your.mapbox.access.token',
			}).addTo(this.map);

			this.loadMarker();
		} else {
			setTimeout(() => {
				this.loadMap();
			}, 1000);
		}
	}

	loadMarker(): void {
		if (
			this.map &&
			this.station &&
			this.station.address &&
			this.station.address.lat_lng
		) {
			const lkMarkerIcon = L.icon({
				iconUrl: '../../../assets/imgs/lk-marker-icon.png',
				iconSize: [40, 42], // size of the icon
				shadowSize: [40, 42], // size of the shadow
				iconAnchor: [20, 42], // point of the icon which will correspond to marker's location
				shadowAnchor: [20, 42], // the same for the shadow
			});
			L.marker(
				[this.station.address.lat_lng[0], this.station.address.lat_lng[1]],
				{
					icon: lkMarkerIcon,
				},
			).addTo(this.map);
			this.map.setView(
				[this.station.address.lat_lng[0], this.station.address.lat_lng[1]],
				MAP_MAX_ZOOM,
			);
		}
	}

	resetCurrentNavbarItem(station: Station): void {
		switch (this.navbar_item) {
			case STATION_NAVBAR_TEAM_ITEM_NAME:
				this.team_component.onReset();
				break;
			case STATION_NAVBAR_NOTIF_ITEM_NAME:
				this.notif_component.onReset();
				this.notif_settings_component.onReset(station);
				break;
			case STATION_NAVBAR_PICS_ITEM_NAME:
				this.pics_component.onReset(station);
				break;
			case STATION_NAVBAR_UNLOCK_ITEM_NAME:
				this.unlock_component.onReset(station);
				break;
			case STATION_NAVBAR_LOCK_ITEM_NAME:
				this.lock_component.onReset(station);
				break;
		}
	}

	getCurrentStationClass(station: Station): { [key: string]: boolean } {
		const localStation: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		return {
			'lk-primary-medium-background': station.id == localStation.id,
			'lk-disabled-buttonlk-primary-medium-background':
				station.id != localStation.id,
		};
	}*/
}
