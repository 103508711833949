import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { from, Observable } from 'rxjs';
import { get } from 'aws-amplify/api';

export const BOOKINGS_MEDIA_API_NAME: string = 'booking-media';
export const BOOKINGS_MEDIA_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/booking-media/';

@Injectable({
	providedIn: 'root',
})
export class BookingMediaService {
	list(station_id: string, booking_id: string): Observable<any> {
		const path: string = `stations/${station_id}/bookings/${booking_id}`;

		const init = { response: true };

		return from(
			get({
				apiName: BOOKINGS_MEDIA_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}
}

export class BookingMedia {
	id: string;
	name: VehicleReportName;
	type: BookingMediaType;
	path: string;
	content: string;
	content_url: string;
}

export enum VehicleReportName {
	FRONT = 'front',
	FRONT_RIGHT = 'front_right',
	BACK_RIGHT = 'back_right',
	BACK = 'back',
	BACK_LEFT = 'back_left',
	FRONT_LEFT = 'front_left',
}

export enum BookingMediaType {
	PRE_REPORT = 'pre_report',
	POST_REPORT = 'post_report',
}
