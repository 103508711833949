<div
	style="position: relative; overflow-y: scroll; max-height: calc(100% - 180px)"
	class="lk-vehicle-details-commands-list lk-hide-scrollbar">
	<div
		*ngFor="let command of commands"
		class="lk-width__full">
		<div
			class="lk-row"
			style="align-items: flex-start; position: relative">
			<div
				class="lk-vehicle-details-commands-list__bar lk-primary-medium-background"></div>

			<div
				class="lk-vehicle-details-commands-list__circle lk-primary-medium-background"></div>

			<div>
				<p
					class="lk-primary-medium-color lk-text__ellipsized lk-text__12 lk-margin__x6-left">
					{{ command.created_at | date: 'short':'':current_locale }}
				</p>

				<p
					*ngIf="!isCommandClickable(command)"
					[ngClass]="getCommandClass(command)"
					class="lk-text__ellipsized lk-margin__x6-left">
					{{ getCommandLabel(command) || '-' | translate }}
				</p>

				<a
					*ngIf="isCommandClickable(command)"
					[routerLink]="[getCommandBookingRoute(command)]"
					class="lk-text__ellipsized lk-margin__x6-left lk-dark-link">
					{{ getCommandLabel(command) || '-' | translate }}
				</a>
			</div>
		</div>

		<hr class="lk-vehicle-details-commands-list-separator lk-list-separator" />
	</div>
</div>
