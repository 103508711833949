<div class="lk-row__space">
	<h1>{{ 'VEHICLE.TITLE' | translate }}</h1>

	<div class="lk-row">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-0 lk-margin__x6-left"
			(click)="refresh.emit()"
			matTooltip="{{ 'VEHICLE.REFRESH_CTA' | translate }}">
			<mat-icon class="lk-accent-color"> refresh </mat-icon>
		</button>

		<button
			id="lk-vehicle-add-button"
			mat-icon-button
			class="lk-icon__button-neutral-0 lk-margin__x6-left"
			(click)="onRequestManualClick()"
			matTooltip="{{ 'VEHICLE.REQUEST_MANUAL_CTA' | translate }}">
			<mat-icon class="lk-accent-color"> directions_car </mat-icon>
		</button>

		<button
			*ngIf="is_vehicle_map_visible"
			mat-icon-button
			class="lk-icon__button-neutral-0 lk-margin__x6-left"
			(click)="onMapClick()"
			matTooltip="{{ 'VEHICLE.MAP_CTA' | translate }}">
			<mat-icon class="lk-accent-color"> map </mat-icon>
		</button>

		<button
			mat-icon-button
			class="lk-icon__button-neutral-0 lk-margin__x6-left"
			(click)="onVehiclesDownloadClick()"
			matTooltip="{{ 'VEHICLE.DOWNLOAD_CTA' | translate }}">
			<mat-icon
				*ngIf="!is_export_running"
				class="lk-accent-color">
				download
			</mat-icon>

			<mat-spinner
				*ngIf="is_export_running"
				class="lk-button__spinner-accent"
				style="margin: auto"
				[diameter]="20"></mat-spinner>
		</button>
	</div>
</div>
