import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StationBuilderRoutingModule } from './station-builder-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';

import { StationBuilderComponent } from './station-builder.component';
import { StationBuilderBasicsComponent } from './station-builder-basics/station-builder-basics.component';
import { StationBuilderLogoComponent } from './station-builder-logo/station-builder-logo.component';
import { StationBuilderTeamComponent } from './station-builder-team/station-builder-team.component';
import { StationBuilderNotifToggleComponent } from './station-builder-notif-toggle/station-builder-notif-toggle.component';
import { StationBuilderNotifLangComponent } from './station-builder-notif-lang/station-builder-notif-lang.component';
import { StationBuilderNotifListComponent } from './station-builder-notif-list/station-builder-notif-list.component';
import { StationBuilderNotifDialogComponent } from './station-builder-notif-dialog/station-builder-notif-dialog.component';
import { StationBuilderStepsComponent } from './station-builder-steps/station-builder-steps.component';
import { StationBuilderNotifLangSummaryComponent } from './station-builder-notif-lang-summary/station-builder-notif-lang-summary.component';
import { StationBuilderTuroDialogComponent } from './station-builder-turo-dialog/station-builder-turo-dialog.component';

@NgModule({
	declarations: [
		StationBuilderComponent,
		StationBuilderBasicsComponent,
		StationBuilderLogoComponent,
		StationBuilderTeamComponent,
		StationBuilderNotifToggleComponent,
		StationBuilderNotifLangComponent,
		StationBuilderNotifListComponent,
		StationBuilderNotifDialogComponent,
		StationBuilderStepsComponent,
		StationBuilderNotifLangSummaryComponent,
		StationBuilderTuroDialogComponent,
	],
	imports: [
		CommonModule,
		StationBuilderRoutingModule,
		SharedModule,
		MatInputModule,
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatChipsModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatExpansionModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		MatProgressSpinnerModule,
	],
})
export class StationBuilderModule {}
