import { Component, OnInit, ViewChild } from '@angular/core';
import {
	MAP_DEFAULT_BASE_POINT,
	MAP_DEFAULT_ZOOM,
	MAP_MAX_ZOOM,
	MAPBOX_API_KEY,
	LOCAL_STATION_KEY,
	VEHICLE_NOT_FOUND_ERROR_CODE,
	ADAPTER_CRED_NOT_FOUND_ERROR_CODE,
	MAP_DEFAULT_URL,
	MAP_CUSTOM_MARKER_ICON,
	ADAPTER_FAILED_ERROR_CODE,
	ERROR_DESCRIPTION_500,
	SATELLITE_LAYER_TYPE,
	STREET_LAYER_TYPE,
	MAP_SATELLITE_URL,
	ADAPTER_OFFLINE_ERROR_CODE,
} from '../../shared/constants';
import { Station } from '../../core/station.service';
import { LocalManagerService } from '../../core/local-manager.service';
import { Router } from '@angular/router';
import {
	Vehicle,
	VehicleCategory,
	VehicleService,
} from '../../core/vehicle.service';
import {
	ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
	ADAPTER_FAILED_ERROR_MESSAGE,
	ADAPTER_GENERIC_ERROR_MESSAGE,
	ADAPTER_OFFLINE_ERROR_MESSAGE,
	GENERAL_ERROR_MESSAGE,
	TOOLBAR_VEHICLES_NAV_ITEM_NAME,
} from '../../shared/translations';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../core/dialog.service';
import { SharedInteractionErrorDialogComponent } from 'src/app/shared/shared-interaction-error-dialog/shared-interaction-error-dialog.component';
import {
	STATION_PATH,
	VEHICLES_DETAILS_PATH,
	VEHICLES_PATH,
} from 'src/app/core/navigation.service';
import {
	LockCommand,
	LockCommandsService,
	LockCommandStatus,
} from 'src/app/core/lock-commands.service';
import {
	UnlockCommand,
	UnlockCommandsService,
	UnlockCommandStatus,
} from 'src/app/core/unlock-commands.service';
import { StationBuilderDialogComponent } from 'src/app/station/station-builder-dialog/station-builder-dialog.component';
import { VehicleListComponent } from '../vehicle-list/vehicle-list.component';
import { ErrorService } from 'src/app/core/error.service';

declare var L: any;

@Component({
	selector: 'app-vehicle-map',
	templateUrl: './vehicle-map.component.html',
	styleUrls: ['./vehicle-map.component.scss'],
})
export class VehicleMapComponent implements OnInit {
	@ViewChild(VehicleListComponent) list_component?: VehicleListComponent;

	layer_type: string = STREET_LAYER_TYPE;
	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);

	current_item: string = TOOLBAR_VEHICLES_NAV_ITEM_NAME;
	is_vehicles_loading: boolean = true;
	vehicles: Vehicle[];
	map: any;
	markers: any[];

	constructor(
		private local_manager: LocalManagerService,
		private unlock_commands_service: UnlockCommandsService,
		private lock_commands_service: LockCommandsService,
		private router: Router,
		private vehicle_service: VehicleService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private error_service: ErrorService,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service.current_read_index = 0;
		this.lock_commands_service.current_read_index = 0;
		if (station) {
			this.station_update_trigger.next(true);
			this.listVehicles(station.id);
		} else {
			this.router.navigate([STATION_PATH]);
		}
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuildClick(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
					this.toolbar_refresh.next(true);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onStationChangeClick(station: Station): void {
		this.onRefreshClick();
	}

	onRefreshClick(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		if (station) {
			this.station_update_trigger.next(true);
			this.listVehicles(station.id);
		} else {
			this.router.navigate([STATION_PATH]);
		}
	}

	onVehicleClick(vehicle: Vehicle): void {
		const latitude = vehicle.stats.position.value.latitude;
		const longitude = vehicle.stats.position.value.longitude;
		if (latitude && longitude) {
			this.map.setView([latitude, longitude], 19);

			const markers = this.markers.filter(m => {
				return m._latlng.lat == latitude && m._latlng.lng == longitude;
			});
			if (markers && markers.length) {
				const marker = markers[0];
				marker.openPopup();
			}
		}
	}

	onVehicleDeleteClick(vehicle: Vehicle): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_vehicles_loading = true;
		this.vehicle_service.delete(station.id, vehicle.id).subscribe({
			next: () => {
				this.onRefreshClick();
			},
			error: () => {
				this.is_vehicles_loading = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	onStartVehicleClick(vehicle: Vehicle): void {
		this.is_vehicles_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service.create(station.id, vehicle.id).subscribe({
			next: response => {
				response.body.json().then(response => {
					const command: UnlockCommand = response as UnlockCommand;
					this.readUnlockCommand(command.id, vehicle.id);
				});
			},
			error: error => {
				this.is_vehicles_loading = false;
				this.error_service.wrapError(error);
			},
		});
	}

	readUnlockCommand(command_id: string, vehicle_id: string): void {
		this.is_vehicles_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service
			.read(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						const command: UnlockCommand = response as UnlockCommand;
						if (
							command.status == UnlockCommandStatus.PENDING &&
							this.unlock_commands_service.current_read_index <=
								this.unlock_commands_service.max_read_retry
						) {
							this.unlock_commands_service.current_read_index++;
							setTimeout(() => {
								this.readUnlockCommand(command_id, vehicle_id);
							}, 5000);
						} else if (command.status == UnlockCommandStatus.SUCCEEDED) {
							this.unlock_commands_service.current_read_index = 0;
							this.is_vehicles_loading = false;
							this.onRefreshClick();
						} else if (command.status == UnlockCommandStatus.FAILED) {
							this.unlock_commands_service.current_read_index = 0;
							this.is_vehicles_loading = false;
							const command_error = command.error.code
								? command.error.code
								: command.error;
							switch (command_error) {
								case ADAPTER_FAILED_ERROR_CODE:
									this.dialog_service.openDialog(
										SharedInteractionErrorDialogComponent,
										{
											message: ADAPTER_FAILED_ERROR_MESSAGE,
										},
									);
									break;
								case ADAPTER_OFFLINE_ERROR_CODE:
									this.dialog_service.openDialog(
										SharedInteractionErrorDialogComponent,
										{
											message: ADAPTER_OFFLINE_ERROR_MESSAGE,
										},
									);
									break;
								default:
									this.dialog_service.openDialog(
										SharedInteractionErrorDialogComponent,
										{
											message: ADAPTER_GENERIC_ERROR_MESSAGE,
										},
									);
									break;
							}
						}
					});
				},
				error: () => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_vehicles_loading = false;
				},
			});
	}

	onStopVehicleClick(vehicle: Vehicle): void {
		this.is_vehicles_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.lock_commands_service.create(station.id, vehicle.id).subscribe({
			next: response => {
				response.body.json().then(response => {
					const command: LockCommand = response.data as LockCommand;
					this.readLockCommand(command.id, vehicle.id);
				});
			},
			error: error => {
				this.is_vehicles_loading = false;
				this.error_service.wrapError(error);
			},
		});
	}

	onLayersClick(): void {
		this.layer_type =
			this.layer_type == STREET_LAYER_TYPE
				? SATELLITE_LAYER_TYPE
				: STREET_LAYER_TYPE;

		if (this.layer_type == SATELLITE_LAYER_TYPE) {
			let mapBoxUrl = MAP_SATELLITE_URL + MAPBOX_API_KEY;
			L.tileLayer(mapBoxUrl, {
				attribution: '',
				maxZoom: MAP_MAX_ZOOM,
				id: 'mapbox/light-v10',
				accessToken: 'your.mapbox.access.token',
			}).addTo(this.map);
		} else {
			let mapBoxUrl = MAP_DEFAULT_URL + MAPBOX_API_KEY;
			L.tileLayer(mapBoxUrl, {
				attribution: '',
				maxZoom: MAP_MAX_ZOOM,
				id: 'mapbox/light-v10',
				accessToken: 'your.mapbox.access.token',
			}).addTo(this.map);
		}
	}

	readLockCommand(command_id: string, vehicle_id: string): void {
		this.is_vehicles_loading = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.lock_commands_service
			.read(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						const command: LockCommand = response as LockCommand;
						if (
							command.status == LockCommandStatus.PENDING &&
							this.lock_commands_service.current_read_index <=
								this.lock_commands_service.max_read_retry
						) {
							this.lock_commands_service.current_read_index++;
							setTimeout(() => {
								this.readLockCommand(command_id, vehicle_id);
							}, 5000);
						} else if (command.status == LockCommandStatus.SUCCEEDED) {
							this.lock_commands_service.current_read_index = 0;
							this.is_vehicles_loading = false;
							this.onRefreshClick();
						} else if (command.status == LockCommandStatus.FAILED) {
							this.lock_commands_service.current_read_index = 0;
							this.is_vehicles_loading = false;
							const command_error = command.error.code
								? command.error.code
								: command.error;
							switch (command_error) {
								case ADAPTER_FAILED_ERROR_CODE:
									this.dialog_service.openDialog(
										SharedInteractionErrorDialogComponent,
										{
											message: ADAPTER_FAILED_ERROR_MESSAGE,
										},
									);
									break;
								case ADAPTER_OFFLINE_ERROR_CODE:
									this.dialog_service.openDialog(
										SharedInteractionErrorDialogComponent,
										{
											message: ADAPTER_OFFLINE_ERROR_MESSAGE,
										},
									);
									break;
								default:
									this.dialog_service.openDialog(
										SharedInteractionErrorDialogComponent,
										{
											message: ADAPTER_GENERIC_ERROR_MESSAGE,
										},
									);
									break;
							}
						}
					});
				},
				error: () => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_vehicles_loading = false;
				},
			});
	}

	listVehicles(stationId: string): void {
		this.is_vehicles_loading = true;
		this.vehicle_service
			.list(
				stationId,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				true,
			)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						this.is_vehicles_loading = false;
						this.vehicles = response.items;

						this.list_component?.setStation(null, this.vehicles);
						this.loadMap();
						this.loadMarkers();
					});
				},
				error: error => {
					const converted_error = this.error_service.convertError(error);
					if (converted_error.code === VEHICLE_NOT_FOUND_ERROR_CODE) {
						this.is_vehicles_loading = false;
						this.vehicles = [];
						this.router.navigate([VEHICLES_PATH]);
					} else {
						this.error_service.processError(converted_error);
					}
				},
			});
	}

	loadMap() {
		this.map = L.map('lk-vehicle-map-layout', {
			attributionControl: false,
			zoomControl: false,
			minZoom: 3,
		});
		L.control
			.zoom({
				position: 'topright',
			})
			.addTo(this.map);

		let mapBoxUrl = MAP_DEFAULT_URL + MAPBOX_API_KEY;
		this.map.setView(MAP_DEFAULT_BASE_POINT, MAP_DEFAULT_ZOOM);
		return L.tileLayer(mapBoxUrl, {
			attribution: '',
			maxZoom: MAP_MAX_ZOOM,
			id: 'mapbox/light-v10',
			accessToken: 'your.mapbox.access.token',
		}).addTo(this.map);
	}

	loadMarkers(): void {
		const markers = [];
		this.vehicles.map(vehicle => {
			MAP_CUSTOM_MARKER_ICON.iconUrl = this.getCustomMarkerIcon(vehicle);
			const lkMarkerIcon = L.icon(MAP_CUSTOM_MARKER_ICON);
			const context = this;
			let latitude: number, longitude: number;
			if (vehicle.stats.position) {
				latitude = vehicle.stats.position.value.latitude;
				longitude = vehicle.stats.position.value.longitude;
			}

			if (latitude && longitude) {
				const marker = L.marker([latitude, longitude], { icon: lkMarkerIcon })
					.addTo(this.map)
					.bindPopup(vehicle.plate);
				marker.on('mouseover', function (ev) {
					ev.target.openPopup();
				});
				marker.on('click', function (ev) {
					context.router.navigate([VEHICLES_DETAILS_PATH + vehicle.id]);
				});
				markers.push(marker);
			}
		});

		if (markers && markers.length) {
			this.markers = markers;
			const group = new L.featureGroup(markers);
			this.map.fitBounds(group.getBounds(), { padding: [50, 50] });
		}
	}

	getCustomMarkerIcon(vehicle: Vehicle): string {
		const categories: string[] = [
			VehicleCategory.COMMERCIAL.toString(),
			VehicleCategory.LARGE.toString(),
			VehicleCategory.VAN.toString(),
			VehicleCategory.WAGON.toString(),
			VehicleCategory.MEDIUM.toString(),
			VehicleCategory.PREMIUM.toString(),
			VehicleCategory.SMALL.toString(),
			VehicleCategory.SUV.toString(),
		];

		if (!categories.includes(vehicle.category)) {
			return `../../../assets/imgs/lk-vehicle-icons/lk-vehicle-medium.png`;
		}
		return `../../../assets/imgs/lk-vehicle-icons/lk-vehicle-${vehicle.category.toLowerCase()}.png`;
	}

	getLayersIconClass(): { [key: string]: boolean } {
		return {
			'lk-accent-color':
				this.layer_type && this.layer_type == SATELLITE_LAYER_TYPE,
			'lk-primary-dark-color':
				!this.layer_type || this.layer_type != SATELLITE_LAYER_TYPE,
		};
	}
}
