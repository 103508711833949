import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
	AUTH_MFA_RESET_PATH,
	AUTH_MFA_SETUP_PATH,
	AUTH_MFA_VERIFY_PATH,
	AUTH_SIGNIN_PATH,
	AUTH_SIGNUP_PATH,
} from '../core/navigation.service';
import { NoAuthGuard } from '../guards/no-auth.guard';
import { AuthSigninComponent } from './auth-signin/auth-signin.component';
import { AuthSignupComponent } from './auth-signup/auth-signup.component';
import { AuthMfaSetupComponent } from './auth-mfa-setup/auth-mfa-setup.component';
import { AuthMfaVerifyComponent } from './auth-mfa-verify/auth-mfa-verify.component';
import { MfaSetupMechanismGuard } from '../guards/mfa-setup-mechanism.guard';
import { AuthGuard } from '../shared/auth.guard';
import { AuthMfaResetComponent } from './auth-mfa-reset/auth-mfa-reset.component';
import { TotpSetupGuard } from '../guards/totp-setup.guard';
import { CodeDeliveryDestinationGuard } from '../guards/code-delivery-destination.guard';

const routes: Routes = [
	{ path: '', redirectTo: AUTH_SIGNIN_PATH, pathMatch: 'full' },
	{
		path: AUTH_SIGNUP_PATH,
		component: AuthSignupComponent,
		canActivate: [NoAuthGuard],
	},
	{
		path: AUTH_SIGNIN_PATH,
		component: AuthSigninComponent,
		canActivate: [NoAuthGuard],
	},
	{
		path: AUTH_MFA_SETUP_PATH,
		component: AuthMfaSetupComponent,
		canActivate: [NoAuthGuard, MfaSetupMechanismGuard, TotpSetupGuard],
	},
	{
		path: AUTH_MFA_VERIFY_PATH,
		component: AuthMfaVerifyComponent,
		canActivate: [
			NoAuthGuard,
			MfaSetupMechanismGuard,
			CodeDeliveryDestinationGuard,
		],
	},
	{
		path: AUTH_MFA_RESET_PATH,
		component: AuthMfaResetComponent,
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
