import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../core/form.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User, UserService } from '../../core/user.service';
import { Station } from '../../core/station.service';
import {
	LOCAL_STATION_KEY,
	UNKNOWN_FLAG,
	USER_NOT_FOUND_ERROR_CODE,
	USER_STATION_CONFLICT_ERROR_CODE,
} from '../../shared/constants';
import { LocalManagerService } from '../../core/local-manager.service';
import {
	CountriesService,
	CountryPrefix,
} from 'src/app/core/countries.service';
import { Observable, map, startWith } from 'rxjs';
import { ErrorService } from 'src/app/core/error.service';

@Component({
	selector: 'app-booking-phone-builder-dialog',
	templateUrl: './booking-phone-builder-dialog.component.html',
	styleUrls: ['./booking-phone-builder-dialog.component.scss'],
})
export class BookingPhoneBuilderDialogComponent implements OnInit {
	form_group?: FormGroup;

	update_user?: User;
	is_request_loading = false;

	country_prefixes?: CountryPrefix[];
	filtered_options?: Observable<CountryPrefix[]>;
	current_country_prefix?: CountryPrefix;
	unknown_flag_src = UNKNOWN_FLAG;

	constructor(
		private dialog_ref: MatDialogRef<BookingPhoneBuilderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private form_service: FormService,
		private user_service: UserService,
		private local_manager: LocalManagerService,
		private countries: CountriesService,
		private error_service: ErrorService,
	) {
		this.form_group = this.form_service.bookingPhoneBuilderFormGroup;

		if (data && data.user) {
			this.update_user = data.user;
		}
	}

	ngOnInit(): void {
		this.form_group.reset();

		this.country_prefixes = this.countries.getCountryPrefixList();
		this.filtered_options = this.form_group
			.get('phone_prefix')
			.valueChanges.pipe(
				startWith(''),
				map(value => this.filterPrefix(value || '')),
			);

		if (this.update_user) {
			this.form_group
				.get('phone_prefix')
				.setValue(this.update_user.phone.prefix);
			this.form_group.get('phone').setValue(this.update_user.phone.value);
		}
	}

	onCountryPrefixSelect(): void {
		const code: string = this.form_group.get('phone_prefix').value;
		this.current_country_prefix = this.getCountryPrefixFromCode(code);
		this.form_group
			.get('phone_prefix')
			.setValue(this.current_country_prefix.prefix);
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		if (this.form_service.validateForm(this.form_group)) {
			const station: Station =
				this.local_manager.getLocalObject(LOCAL_STATION_KEY);

			let phone_prefix: string = this.form_group.get('phone_prefix').value;
			let phone_value: string = this.form_group.get('phone').value;
			phone_prefix = phone_prefix.replace(/ /g, '');
			phone_prefix = phone_prefix.replace('+', '');
			phone_value = phone_value.replace(/ /g, '');

			this.readUserByPhone(station, phone_prefix, phone_value);
		}
	}

	readUserByPhone(
		station: Station,
		phone_prefix: string,
		phone_value: string,
	): void {
		this.is_request_loading = true;
		this.user_service.readByPhone('+' + phone_prefix + phone_value).subscribe({
			next: response => {
				response.body.json().then(response => {
					const user: User = response as User;
					this.addUserToStation(station, user);
				});
			},
			error: error => {
				this.manageReadUserErrors(error, phone_value, phone_prefix);
				this.is_request_loading = false;
			},
		});
	}

	addUserToStation(station: Station, user: User): void {
		this.is_request_loading = true;
		this.user_service.addUserToStation(station.id, user.id).subscribe({
			next: response => {
				response.body.json().then(() => {
					this.dialog_ref.close({ user: user });
					this.is_request_loading = false;
				});
			},
			error: error => {
				this.manageAddUserToStationErrors(error, user);
				this.is_request_loading = false;
			},
		});
	}

	manageReadUserErrors(error: any, phone: string, phone_prefix: string): void {
		const converted_error = this.error_service.convertError(error);

		if (converted_error.code === USER_NOT_FOUND_ERROR_CODE) {
			this.dialog_ref.close({ phone, phone_prefix });
		}

		if (converted_error.code != USER_NOT_FOUND_ERROR_CODE) {
			this.error_service.processError(converted_error);
			this.dialog_ref.close();
		}
	}

	manageAddUserToStationErrors(error: any, user: User): void {
		const converted_error = this.error_service.convertError(error);

		if (converted_error.code === USER_STATION_CONFLICT_ERROR_CODE) {
			this.dialog_ref.close({ user: user });
		}

		if (converted_error.code != USER_STATION_CONFLICT_ERROR_CODE) {
			this.error_service.processError(converted_error);
			this.dialog_ref.close();
		}
	}

	filterPrefix(value: string) {
		const filter_value = value.toLowerCase();
		return this.country_prefixes.filter(option => {
			return option.prefix.toLowerCase().includes(filter_value);
		});
	}

	getCurrentPrefixFlag(): string {
		const phone_prefix: string = this.form_group.get('phone_prefix')?.value;
		let flag_path: string;
		if (phone_prefix) {
			const current_prefixes: CountryPrefix[] = this.filterPrefix(phone_prefix);
			if (current_prefixes && current_prefixes.length) {
				flag_path = current_prefixes[0]?.flag;
			}
		}
		return flag_path ? flag_path : UNKNOWN_FLAG;
	}

	getCountryPrefixFromCode(code: string): CountryPrefix {
		return this.country_prefixes.filter(cp => {
			return cp.code == code;
		})[0];
	}
}
