import { Injectable } from '@angular/core';
import { get, post } from 'aws-amplify/api';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export const LOCK_COMMANDS_API_NAME: string = 'lock_commands';
export const LOCK_COMMANDS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/lock-host-commands/';

@Injectable({
	providedIn: 'root',
})
export class LockCommandsService {
	max_read_retry: number = 10;
	current_read_index: number = 0;

	create(station_id: string, vehicle_id: string): Observable<any> {
		const path: string = `stations/${station_id}/vehicles/${vehicle_id}`;

		const init = { response: true };

		return from(
			post({
				apiName: LOCK_COMMANDS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	read(
		command_id: string,
		station_id: string,
		vehicle_id: string,
	): Observable<any> {
		const path: string = `${command_id}/stations/${station_id}/vehicles/${vehicle_id}`;

		const init = { response: true };

		return from(
			get({
				apiName: LOCK_COMMANDS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}
}

export class LockCommand {
	id: string;
	object: string;
	source: string;
	station_id: string;
	vehicle_id: string;
	booking_id?: string;
	status: LockCommandStatus;
	error: {
		code: string;
		description: string;
	};
}

export enum LockCommandStatus {
	PENDING = 'pending',
	SUCCEEDED = 'succeeded',
	FAILED = 'failed',
}
