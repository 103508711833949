import { Component, OnInit } from '@angular/core';
import {
	GENERAL_ERROR_MESSAGE,
	STATION_DELETE_MESSAGE,
	TOOLBAR_STATION_SETTINGS_NAV_ITEM_NAME,
} from '../shared/translations';
import {
	LockSettings,
	PicturePolicy,
	Station,
	StationService,
	UnlockSettings,
} from '../core/station.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LocalManagerService } from '../core/local-manager.service';
import { PROD_STAGE, LOCAL_STATION_KEY } from '../shared/constants';
import { DialogService } from '../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '../core/notification.service';
import { StationDeleteDialogComponent } from '../station/station-delete-dialog/station-delete-dialog.component';
import { environment } from 'src/environments/environment';
import { STATION_PATH } from '../core/navigation.service';

@Component({
	selector: 'app-station-settings',
	templateUrl: './station-settings.component.html',
	styleUrls: ['./station-settings.component.scss'],
})
export class StationSettingsComponent implements OnInit {
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	current_item: string = TOOLBAR_STATION_SETTINGS_NAV_ITEM_NAME;
	stations: Station[] = [];
	is_request_running: boolean = false;
	current_station: Station;
	is_production: boolean;

	constructor(
		private station_service: StationService,
		private router: Router,
		private local_manager: LocalManagerService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit() {
		this.is_production = environment.STAGE == PROD_STAGE;
		this.listStations();
	}

	onStationDeleteClick(station: Station) {
		this.dialog_service
			.openDialog(StationDeleteDialogComponent, { station: station })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.confirm) {
					this.is_request_running = true;
					this.current_station = null;
					this.station_service.delete(station.id).subscribe({
						next: response => {
							this.is_request_running = false;
							this.listStations();
							this.dialog_service.openInfoDialog(STATION_DELETE_MESSAGE);

							localStorage.removeItem(LOCAL_STATION_KEY);
						},
						error: error => {
							this.is_request_running = false;
							this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
						},
					});
				}
			});
	}

	onStationChangeClick(station: Station) {
		this.current_station = station;
		this.local_manager.setLocalObject(station, LOCAL_STATION_KEY);
		this.station_update_trigger.next(true);
	}

	onNotifUpdateClick(notifType: NotificationType) {
		let toBeUpdated: Station = this.current_station;
		toBeUpdated.settings.default_notification_type = notifType;
		this.is_request_running = true;
		this.current_station = null;
		this.updateStation(toBeUpdated);
	}

	onTriggersUpdateClick(triggersSettings: {
		unlock: UnlockSettings;
		lock: LockSettings;
	}) {
		let toBeUpdated: Station = this.current_station;
		toBeUpdated.settings.unlock_settings = triggersSettings.unlock;
		toBeUpdated.settings.lock_settings = triggersSettings.lock;
		this.is_request_running = true;
		this.current_station = null;
		this.updateStation(toBeUpdated);
	}

	onPicsPolicyUpdateClick(policy: PicturePolicy) {
		let toBeUpdated: Station = this.current_station;
		toBeUpdated.settings.pictures_policy = policy;
		this.is_request_running = true;
		this.current_station = null;
		this.updateStation(toBeUpdated);
	}

	onLangUpdateClick(lang: string) {
		let toBeUpdated: Station = this.current_station;
		toBeUpdated.settings.default_lang = lang;
		this.is_request_running = true;
		this.current_station = null;
		this.updateStation(toBeUpdated);
	}

	listStations() {
		this.is_request_running = true;
		this.station_service.list().subscribe(
			response => {
				this.is_request_running = false;
				this.stations = response.data.items as Station[];
				let localStation: Station =
					this.local_manager.getLocalObject(LOCAL_STATION_KEY);
				let localReplacementCond = this.stations.filter(s => {
					return localStation && s.id == localStation.id;
				});
				if (localReplacementCond && localReplacementCond.length > 0) {
					this.current_station = localReplacementCond[0];
				} else {
					this.current_station = this.stations[0];
				}
				this.onStationChangeClick(this.current_station);
			},
			error => {
				this.is_request_running = false;
				this.router.navigate([STATION_PATH]);
			},
		);
	}

	updateStation(station: Station) {
		this.station_service.update(station).subscribe({
			next: response => {
				this.is_request_running = false;
				this.listStations();
			},
			error: error => {
				this.is_request_running = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}
}
