<div class="lk-row__space lk-width__full lk-display__hidden-after-lg">
	<h2>Stations</h2>

	<button
		(click)="onRefresh()"
		mat-icon-button
		class="lk-icon__button-neutral-0">
		<mat-icon class="lk-accent-color"> refresh </mat-icon>
	</button>
</div>

<div class="lk-width__full lk-row__space lk-wrap lk-display__hidden-before-lg">
	<h2>Stations</h2>

	<div class="lk-row">
		<app-hidden-stations-header-form
			(search_change)="onSearchChange($event)"
			(alias_search_change)="onAliasSearchChange($event)">
		</app-hidden-stations-header-form>

		<button
			(click)="onResetMfaClick()"
			mat-icon-button
			matTooltip="Reset MFA"
			class="lk-icon__button-neutral-0 lk-margin__x12-left">
			<mat-icon class="lk-accent-color"> sync_alt </mat-icon>
		</button>

		<button
			(click)="onAddAdminClick()"
			mat-icon-button
			matTooltip="Manage super admins"
			class="lk-icon__button-neutral-0 lk-margin__x6-left">
			<mat-icon class="lk-accent-color"> admin_panel_settings </mat-icon>
		</button>

		<button
			(click)="onRefresh()"
			matTooltip="Refresh"
			mat-icon-button
			class="lk-icon__button-neutral-0 lk-margin__x6-left">
			<mat-icon class="lk-accent-color"> refresh </mat-icon>
		</button>
	</div>
</div>

<app-hidden-stations-header-filters
	[current_search_value]="current_search_value"
	[current_alias_search_value]="current_alias_search_value"
	(name_reset)="onNameReset()"
	(alias_reset)="onAliasReset()">
</app-hidden-stations-header-filters>
