import { Component, EventEmitter, Output } from '@angular/core';
import { Booking, BookingService } from 'src/app/core/booking.service';
import { DialogService } from 'src/app/core/dialog.service';
import { BookingPhoneBuilderDialogComponent } from '../booking-phone-builder-dialog/booking-phone-builder-dialog.component';
import { BookingTimeBuilderDialogComponent } from '../booking-time-builder-dialog/booking-time-builder-dialog.component';
import { BookingUserBuilderDialogComponent } from '../booking-user-builder-dialog/booking-user-builder-dialog.component';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { Station, Settings } from 'src/app/core/station.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import { BookingSettingsGeneralDialogComponent } from '../booking-settings-general-dialog/booking-settings-general-dialog.component';
import { BookingSettingsLockDialogComponent } from '../booking-settings-lock-dialog/booking-settings-lock-dialog.component';
import { BookingSettingsUnlockDialogComponent } from '../booking-settings-unlock-dialog/booking-settings-unlock-dialog.component';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-booking-header',
	templateUrl: './booking-header.component.html',
	styleUrl: './booking-header.component.scss',
})
export class BookingHeaderComponent {
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();
	@Output() create_booking: EventEmitter<Booking> = new EventEmitter<Booking>();

	is_export_running = false;

	constructor(
		private dialog_service: DialogService,
		private local_manager: LocalManagerService,
		private booking_service: BookingService,
	) {}

	onAddBookingClick() {
		this.dialog_service
			.openDialog(BookingPhoneBuilderDialogComponent)
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.user) {
					const booking = new Booking();
					booking.user_id = dialog_result.user.id;
					this.openTimeBuilderDialog(booking);
				} else if (dialog_result && dialog_result.phone) {
					this.openUserBuilderDialog(dialog_result);
				}
			});
	}

	onBookingsDownloadClick() {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.export(station.id);
	}

	openTimeBuilderDialog(booking: Booking): void {
		this.dialog_service
			.openDialog(BookingTimeBuilderDialogComponent, { booking })
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.booking) {
					this.openSettingsGeneralDialog(dialog_result.booking);
				}
			});
	}

	openUserBuilderDialog(dialog_result: any): void {
		this.dialog_service
			.openDialog(BookingUserBuilderDialogComponent, {
				phone_prefix: dialog_result.phone_prefix,
				phone: dialog_result.phone,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.user) {
					const booking: Booking = new Booking();
					booking.user_id = dialog_result.user.id;
					this.openTimeBuilderDialog(booking);
				}
			});
	}

	openSettingsGeneralDialog(booking: Booking) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		booking.settings = new Settings();
		booking.settings.default_lang = station.settings.default_lang;
		booking.settings.default_notification_type =
			station.settings.default_notification_type;
		booking.settings.pictures_policy = station.settings.pictures_policy;

		this.dialog_service
			.openDialog(BookingSettingsGeneralDialogComponent, { station })
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.settings) {
					booking.settings.default_notification_type =
						dialog_result.settings.notifType;
					booking.settings.default_lang = dialog_result.settings.lang;
					booking.settings.pictures_policy = dialog_result.settings.policy;
					this.openSettingsUnlockDialog(station, booking);
				}
			});
	}

	openSettingsUnlockDialog(station: Station, booking: Booking): void {
		this.dialog_service
			.openDialog(BookingSettingsUnlockDialogComponent, { station })
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.unlock_settings) {
					booking.settings.unlock_settings = dialog_result.unlock_settings;
					booking.settings.unlock_settings.is_link_invalid = false;
					this.openSettingsLockDialog(station, booking);
				}
			});
	}

	openSettingsLockDialog(station: Station, booking: Booking): void {
		this.dialog_service
			.openDialog(BookingSettingsLockDialogComponent, { station })
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.lock_settings) {
					booking.settings.lock_settings = dialog_result.lock_settings;
					booking.settings.lock_settings.is_link_invalid = false;
					this.create_booking.emit(booking);
				}
			});
	}

	export(station_id: string): void {
		this.is_export_running = true;
		this.booking_service.export(station_id).subscribe({
			next: response => {
				response.body.blob().then(response => {
					saveAs(response, 'bookings(' + new Date().toLocaleString() + ').csv');
					this.is_export_running = false;
				});
			},
			error: () => {
				this.is_export_running = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}
}
