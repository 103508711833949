import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
	BOOKING_DETAILS_NAVBAR_GENERAL_ITEM_NAME,
	BOOKING_DETAILS_NAVBAR_LINKS_ITEM_NAME,
	BOOKING_DETAILS_NAVBAR_EVENTS_ITEM_NAME,
	BOOKING_DETAILS_NAVBAR_GALLERY_ITEM_NAME,
	PROD_STAGE,
	BOOKING_DETAILS_NAVBAR_DATA_ITEM_NAME,
	BOOKING_DETAILS_NAVBAR_PATH_ITEM_NAME,
} from 'src/app/shared/constants';
import {
	BOOKING_DETAILS_NAVBAR_GENERAL_LABEL,
	BOOKING_DETAILS_NAVBAR_LINKS_LABEL,
	BOOKING_DETAILS_NAVBAR_EVENTS_LABEL,
	BOOKING_DETAILS_NAVBAR_GALLERY_LABEL,
	BOOKING_DETAILS_NAVBAR_DATA_LABEL,
	BOOKING_DETAILS_NAVBAR_PATH_LABEL,
} from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-booking-details-navbar',
	templateUrl: './booking-details-navbar.component.html',
	styleUrls: ['./booking-details-navbar.component.scss'],
})
export class BookingDetailsNavbarComponent implements OnInit {
	@Output() navbar_item_change: EventEmitter<string> =
		new EventEmitter<string>();
	items: string[];
	labels: string[];
	current_item: string = BOOKING_DETAILS_NAVBAR_GENERAL_ITEM_NAME;
	is_production: boolean = false;

	constructor() {}

	ngOnInit(): void {
		this.is_production = environment.STAGE == PROD_STAGE;
		if (this.is_production) {
			this.items = [
				BOOKING_DETAILS_NAVBAR_GENERAL_ITEM_NAME,
				BOOKING_DETAILS_NAVBAR_LINKS_ITEM_NAME,
				BOOKING_DETAILS_NAVBAR_EVENTS_ITEM_NAME,
			];
			this.labels = [
				BOOKING_DETAILS_NAVBAR_GENERAL_LABEL,
				BOOKING_DETAILS_NAVBAR_LINKS_LABEL,
				BOOKING_DETAILS_NAVBAR_EVENTS_LABEL,
			];
		} else {
			this.items = [
				BOOKING_DETAILS_NAVBAR_GENERAL_ITEM_NAME,
				BOOKING_DETAILS_NAVBAR_LINKS_ITEM_NAME,
				BOOKING_DETAILS_NAVBAR_EVENTS_ITEM_NAME,
				BOOKING_DETAILS_NAVBAR_DATA_ITEM_NAME,
				BOOKING_DETAILS_NAVBAR_PATH_ITEM_NAME,
			];
			this.labels = [
				BOOKING_DETAILS_NAVBAR_GENERAL_LABEL,
				BOOKING_DETAILS_NAVBAR_LINKS_LABEL,
				BOOKING_DETAILS_NAVBAR_EVENTS_LABEL,
				BOOKING_DETAILS_NAVBAR_DATA_LABEL,
				BOOKING_DETAILS_NAVBAR_PATH_LABEL,
			];
		}
	}

	onItemClick(item: string): void {
		this.current_item = item;
		this.navbar_item_change.emit(item);
	}

	getItemOpacity(item: string): {} {
		return {
			opacity: this.current_item && this.current_item == item ? '1' : '.5',
		};
	}

	getItemBackgroundClass(item: string): { [key: string]: boolean } {
		return {
			'lk-booking-details-navbar-selected-item lk-primary-background':
				this.current_item && item == this.current_item,
			'lk-booking-details-navbar-not-selected-item':
				!this.current_item || item != this.current_item,
		};
	}

	getItemLabel(item: string): string {
		switch (item) {
			case BOOKING_DETAILS_NAVBAR_GENERAL_ITEM_NAME:
				return BOOKING_DETAILS_NAVBAR_GENERAL_LABEL;
			case BOOKING_DETAILS_NAVBAR_LINKS_ITEM_NAME:
				return BOOKING_DETAILS_NAVBAR_LINKS_LABEL;
			case BOOKING_DETAILS_NAVBAR_EVENTS_ITEM_NAME:
				return BOOKING_DETAILS_NAVBAR_EVENTS_LABEL;
			case BOOKING_DETAILS_NAVBAR_GALLERY_ITEM_NAME:
				return BOOKING_DETAILS_NAVBAR_GALLERY_LABEL;
			case BOOKING_DETAILS_NAVBAR_DATA_ITEM_NAME:
				return BOOKING_DETAILS_NAVBAR_DATA_LABEL;
			case BOOKING_DETAILS_NAVBAR_PATH_ITEM_NAME:
				return BOOKING_DETAILS_NAVBAR_PATH_LABEL;
			default:
				return BOOKING_DETAILS_NAVBAR_GENERAL_LABEL;
		}
	}
}
