<app-toolbar
	[current_nav_item]="current_item"
	(no_station_found)="onNoStationFound()"
	(station_build)="onStationBuild(false)"
	(station_change)="onStationChange()"></app-toolbar>

<section class="lk-page__with-toolbar lk-padding__x24">
	<app-vehicle-header
		[is_vehicle_map_visible]="is_vehicle_map_visible"
		(refresh)="onRefresh()"></app-vehicle-header>

	<app-vehicle-summary></app-vehicle-summary>

	<app-vehicle-table
		*ngIf="!is_vehicles_loading"
		class="lk-display__hidden-before-md"
		[vehicles]="vehicles"
		(search_change)="onSearchChange($event)"
		(filters_change)="onFiltersClick()"
		(refresh)="onRefresh()"></app-vehicle-table>

	<app-vehicle-list
		*ngIf="!is_vehicles_loading"
		class="lk-display__hidden-after-md"
		[vehicles]="vehicles"
		(search_change)="onSearchChange($event)"
		(filters_click)="onFiltersClick()"
		(refresh)="onRefresh()"></app-vehicle-list>

	<div
		#loadMoreLayout
		class="lk-width__full lk-row__center lk-margin__x12-top"
		*ngIf="!is_vehicles_loading && !is_last_page">
		<app-button
			[button_class]="'lk-button__secondary lk-width__full'"
			[spinner_class]="'lk-button__spinner-white'"
			[cta]="'GENERAL.LOAD_MORE_CTA' | translate"
			[is_disabled]="is_page_loading"
			[is_spinner_visible]="is_page_loading"
			(button_click)="onLoadPageClick()"></app-button>
	</div>

	<app-loading
		class="lk-width__full lk-display__block"
		style="height: 1000px"
		*ngIf="is_vehicles_loading"></app-loading>
</section>
