<div class="lk-height__max-80vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x24">
		<h3>{{ 'VEHICLE.REQUEST_MANUAL_INFO_TITLE' | translate }}:</h3>

		<form
			[formGroup]="vehicle_request_manual_info_form_group"
			class="lk-margin__x24-vert lk-row__space lk-wrap">
			<mat-form-field
				class="lk-width__half-x4 lk-margin__x12-bottom lk-form-field">
				<mat-label>
					{{ 'VEHICLE.BRAND_COLUMN_LABEL' | translate }}
				</mat-label>
				<input
					matInput
					type="text"
					formControlName="brand"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-width__half-x4 lk-margin__x12-bottom lk-form-field">
				<mat-label> {{ 'VEHICLE.MODEL_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="model"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-width__half-x4 lk-margin__x12-bottom lk-form-field">
				<mat-label> {{ 'VEHICLE.YEAR_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="number"
					formControlName="year"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-width__half-x4 lk-margin__x12-bottom lk-form-field">
				<mat-label>
					{{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate }}
				</mat-label>
				<input
					matInput
					type="text"
					formControlName="plate" />
			</mat-form-field>

			<app-button
				class="lk-width__full lk-margin__x24-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'VEHICLE.REQUEST_MANUAL_ADD_VEHICLE_CTA' | translate"
				[is_disabled]="!isValidForm() || is_send_request_running"
				[is_spinner_visible]="is_send_request_running"
				(button_click)="onAddVehicleClick()"></app-button>
		</form>

		<p
			*ngIf="vehicles && vehicles.length"
			class="lk-margin__x12-bottom">
			{{ 'VEHICLE.REQUEST_MANUAL_ADD_VEHICLE_DIPSATCH_LABEL' | translate }}.
		</p>

		<div
			*ngIf="vehicles && vehicles.length"
			class="lk-primary-medium-background lk-padding__x24 lk-row lk-wrap lk-border-radius">
			<div
				*ngFor="let vehicle of vehicles"
				style="padding: 4px 12px; border-radius: 45px"
				class="lk-margin__x8 lk-primary-background lk-row">
				{{ vehicleToString(vehicle, true) }}

				<mat-icon
					class="lk-margin__x12-left lk-cursor__pointer"
					(click)="onRemoveVehicleClick(vehicle)">
					clear
				</mat-icon>
			</div>
		</div>

		<p
			*ngIf="vehicles && vehicles.length"
			class="lk-margin__x24-top lk-margin__x12-bottom">
			{{ 'VEHICLE.REQUEST_RESPONSE_DISCLAIMER' | translate }}
		</p>

		<app-button
			*ngIf="vehicles && vehicles.length"
			class="lk-width__full lk-margin__x24-bottom lk-display__block"
			[button_class]="'lk-button__secondary lk-width__full'"
			[spinner_class]="'lk-button__spinner-white'"
			[cta]="'VEHICLE.REQUEST_MANUAL_SEND_CTA' | translate"
			[is_disabled]="is_send_request_running"
			[is_spinner_visible]="is_send_request_running"
			(button_click)="onSendClick()"></app-button>

		<!--<button
			*ngIf="vehicles && vehicles.length"
			mat-button
			(click)="onSendClick()"
			[disabled]="is_send_request_running"
			[ngClass]="getSendDisabledClass()"
			class="lk-main-button lk-full-width lk-margin-top lk-margin-bottom">
			<mat-icon
				*ngIf="!is_send_request_running"
				class="lk-primary-color"
				matPrefix>
				directions_car
			</mat-icon>
			<span
				*ngIf="!is_send_request_running"
				class="lk-primary-color">
				{{ 'VEHICLE.REQUEST_MANUAL_SEND_CTA' | translate }}
			</span>
			<div
				class="lk-spinner-layout"
				*ngIf="is_send_request_running">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>
		</button>-->
	</div>
</div>
