import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingRoutingModule } from './booking-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StationSettingsModule } from '../station-settings/station-settings.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { LoadingModule } from '../loading/loading.module';
import { ButtonModule } from '../button/button.module';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { BookingComponent } from './booking.component';
import { BookingSummaryComponent } from './booking-summary/booking-summary.component';
import { BookingTableComponent } from './booking-table/booking-table.component';
import { BookingUserBuilderDialogComponent } from './booking-user-builder-dialog/booking-user-builder-dialog.component';
import { BookingFiltersDialogComponent } from './booking-filters-dialog/booking-filters-dialog.component';
import { BookingEmptyComponent } from './booking-empty/booking-empty.component';
import { BookingListComponent } from './booking-list/booking-list.component';
import { BookingTimeBuilderDialogComponent } from './booking-time-builder-dialog/booking-time-builder-dialog.component';
import { BookingPhoneBuilderDialogComponent } from './booking-phone-builder-dialog/booking-phone-builder-dialog.component';
import { BookingAddVehicleDialogComponent } from './booking-add-vehicle-dialog/booking-add-vehicle-dialog.component';
import { BookingSettingsLockDialogComponent } from './booking-settings-lock-dialog/booking-settings-lock-dialog.component';
import { BookingSettingsUnlockDialogComponent } from './booking-settings-unlock-dialog/booking-settings-unlock-dialog.component';
import { BookingDeleteDialogComponent } from './booking-delete-dialog/booking-delete-dialog.component';
import { BookingSettingsGeneralDialogComponent } from './booking-settings-general-dialog/booking-settings-general-dialog.component';
import { BookingHeaderComponent } from './booking-header/booking-header.component';
import { BookingTableControlsComponent } from './booking-table-controls/booking-table-controls.component';

@NgModule({
	declarations: [
		BookingComponent,
		BookingSummaryComponent,
		BookingTableComponent,
		BookingUserBuilderDialogComponent,
		BookingFiltersDialogComponent,
		BookingEmptyComponent,
		BookingListComponent,
		BookingTimeBuilderDialogComponent,
		BookingPhoneBuilderDialogComponent,
		BookingAddVehicleDialogComponent,
		BookingSettingsLockDialogComponent,
		BookingSettingsUnlockDialogComponent,
		BookingDeleteDialogComponent,
		BookingSettingsGeneralDialogComponent,
		BookingHeaderComponent,
		BookingTableControlsComponent,
	],
	imports: [
		CommonModule,
		BookingRoutingModule,
		SharedModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		StationSettingsModule,
		ToolbarModule,
		LoadingModule,
		ButtonModule,
		MatButtonModule,
		MatButtonModule,
		MatIconModule,
		MatTableModule,
		MatFormFieldModule,
		MatInputModule,
		MatTooltipModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatAutocompleteModule,
		MatSlideToggleModule,
	],
	exports: [
		BookingTimeBuilderDialogComponent,
		BookingAddVehicleDialogComponent,
		BookingDeleteDialogComponent,
	],
})
export class BookingModule {}
