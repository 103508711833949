import { Component, OnInit } from '@angular/core';
import { Station } from 'src/app/core/station.service';
import {
	VehiclesSummary,
	VehiclesSummaryService,
} from 'src/app/core/vehicles-summary.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';

@Component({
	selector: 'app-vehicle-summary',
	templateUrl: './vehicle-summary.component.html',
	styleUrls: ['./vehicle-summary.component.scss'],
})
export class VehicleSummaryComponent implements OnInit {
	is_summary_loading = false;
	vehicles_summary?: VehiclesSummary;

	constructor(private vehicles_summary_service: VehiclesSummaryService) {}

	ngOnInit(): void {
		if (
			localStorage.getItem(LOCAL_STATION_KEY) &&
			localStorage.getItem(LOCAL_STATION_KEY) != 'undefined'
		) {
			const station = JSON.parse(
				localStorage.getItem(LOCAL_STATION_KEY),
			) as Station;

			this.readVehiclesSummary(station.id);
		}
	}

	onRefresh(): void {
		if (
			localStorage.getItem(LOCAL_STATION_KEY) &&
			localStorage.getItem(LOCAL_STATION_KEY) != 'undefined'
		) {
			const station = JSON.parse(
				localStorage.getItem(LOCAL_STATION_KEY),
			) as Station;

			this.readVehiclesSummary(station.id);
		}
	}

	readVehiclesSummary(station_d: string): void {
		this.is_summary_loading = true;
		this.vehicles_summary_service.read(station_d).subscribe({
			next: response => {
				response.body.json().then(response => {
					this.is_summary_loading = false;
					this.vehicles_summary = response;
				});
			},
			error: () => {
				this.is_summary_loading = false;
				this.vehicles_summary = {
					total: 0,
					vehicles_in_locked: 0,
					vehicles_in_unlocked: 0,
					vehicles_in_moving: 0,
					vehicles_online: 0,
					vehicles_offline: 0,
				};
			},
		});
	}
}
