import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'src/app/core/dialog.service';
import { Vehicle } from 'src/app/core/vehicle.service';
import { Booking } from '../../core/booking.service';
import { BookingAddVehicleDialogComponent } from '../booking-add-vehicle-dialog/booking-add-vehicle-dialog.component';
import { getTimezoneOffset } from 'date-fns-tz';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';
import { BookingDeleteDialogComponent } from '../booking-delete-dialog/booking-delete-dialog.component';
import { BOOKING_PENDING_LABEL } from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';
import {
	BOOKING_START_DATE_SORT_ASC_OPTION,
	BOOKING_START_DATE_SORT_DESC_OPTION,
	BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
	LOCAL_STATION_KEY,
	PROD_STAGE,
} from 'src/app/shared/constants';
import { Station } from 'src/app/core/station.service';
import { UtilsService } from 'src/app/core/utils.service';
import { NavigationService } from 'src/app/core/navigation.service';

@Component({
	selector: 'app-booking-list',
	templateUrl: './booking-list.component.html',
	styleUrls: ['./booking-list.component.scss'],
})
export class BookingListComponent implements OnInit {
	@Input() bookings?: Booking[];
	@Output() search_change: EventEmitter<string> = new EventEmitter<string>();
	@Output() delete_click: EventEmitter<Booking> = new EventEmitter<Booking>();
	@Output() filters_change: EventEmitter<void> = new EventEmitter<void>();
	@Output() sort_change: EventEmitter<string> = new EventEmitter<string>();
	@Output() vehicle_add: EventEmitter<{
		booking: Booking;
		vehicle: Vehicle;
	}> = new EventEmitter<{ booking: Booking; vehicle: Vehicle }>();

	form_group?: FormGroup;
	update_time?: number;

	desc_option = BOOKING_START_DATE_SORT_DESC_OPTION;
	asc_option = BOOKING_START_DATE_SORT_ASC_OPTION;
	pending_label = BOOKING_PENDING_LABEL;
	current_start_date_sort_option?: string;
	is_production?: boolean;

	constructor(
		private dialogService: DialogService,
		private formService: FormService,
		private utils_service: UtilsService,
		private navigation_service: NavigationService,
	) {
		this.form_group = this.formService.bookingSearchFormGroup;
		this.current_start_date_sort_option = localStorage.getItem(
			BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
		);
	}

	ngOnInit() {
		this.is_production = environment.STAGE == PROD_STAGE;

		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		this.setStation(station, this.bookings);
	}

	onAddVehicleClick(booking: Booking) {
		this.dialogService
			.openDialog(BookingAddVehicleDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.vehicle) {
					this.vehicle_add.emit({
						booking: booking,
						vehicle: dialogResult.vehicle,
					});
				}
			});
	}

	onBookingClick(booking: Booking) {
		this.navigation_service.toBookingsDetails(booking.id);
	}

	onBookingDeleteClick(booking: Booking) {
		this.dialogService
			.openDialog(BookingDeleteDialogComponent, { booking: booking })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.confirm) {
					this.delete_click.emit(booking);
				}
			});
	}

	onSortStartDateClick() {
		switch (this.current_start_date_sort_option) {
			case BOOKING_START_DATE_SORT_ASC_OPTION:
				this.current_start_date_sort_option =
					BOOKING_START_DATE_SORT_DESC_OPTION;
				break;
			case BOOKING_START_DATE_SORT_DESC_OPTION:
				this.current_start_date_sort_option = undefined;
				break;
			default:
				this.current_start_date_sort_option =
					BOOKING_START_DATE_SORT_ASC_OPTION;
				break;
		}
		if (this.current_start_date_sort_option) {
			localStorage.setItem(
				BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
				this.current_start_date_sort_option,
			);
		} else {
			localStorage.removeItem(BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY);
		}
		this.sort_change.emit(this.current_start_date_sort_option);
	}

	setStation(station: Station, bookings: Booking[]): void {
		this.update_time = new Date().getTime();
		this.bookings = bookings;

		this.current_start_date_sort_option = localStorage.getItem(
			BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
		);
	}

	getBookingStatusLabel(booking: Booking): string {
		return this.utils_service.getBookingStatusLabel(booking);
	}

	getBookingStatusClass(booking: Booking): { [key: string]: boolean } {
		return this.utils_service.getBookingStatusClass(booking);
	}

	getTimezoneOffset(booking: Booking): string {
		return (
			'GMT' +
			(getTimezoneOffset(booking.timezone) / 3600000 >= 0 ? '+' : '') +
			getTimezoneOffset(booking.timezone) / 3600000
		);
	}

	getTimezoneStartTime(booking: Booking): number {
		return (
			booking.start_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}

	getTimezoneEndTime(booking: Booking): number {
		return (
			booking.end_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}

	getUserIdentifier(booking: Booking): string {
		if (booking.user) {
			if (booking.user.first_name && booking.user.last_name) {
				return booking.user.first_name + ' ' + booking.user.last_name;
			} else if (
				booking.user.phone &&
				booking.user.phone.prefix &&
				booking.user.phone.value
			) {
				return '+' + booking.user.phone.prefix + booking.user.phone.value;
			} else if (booking.user.email) {
				return booking.user.email;
			}
			return (
				booking.id.substring(0, 3) +
				booking.id.substring(booking.id.length - 8, booking.id.length)
			);
		}
	}

	isAddVehicleVisible(booking: Booking): boolean {
		return this.getBookingStatusLabel(booking) == this.pending_label;
	}
}
