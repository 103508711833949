<app-toolbar
	[current_nav_item]="current_item"
	(no_station_found)="onNoStationFound()"
	(station_build)="onStationBuild(false)"
	(station_change)="onStationChange($event)"></app-toolbar>

<section class="lk-page__with-toolbar">
	<app-station-map [station]="station"></app-station-map>

	<app-station-panel
		[station]="station"
		[logo]="logo"
		(station_build)="onStationBuild(true)"
		(station_delete)="onStationDelete()"></app-station-panel>

	<app-station-navbar
		[station]="station"
		(navbar_item_change)="onNavbarItemChange($event)"></app-station-navbar>

	<app-station-settings
		[station]="station"
		[navbar_item]="navbar_item"></app-station-settings>
</section>
