import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/dialog.service';
import {
	Notification,
	NotificationName,
	NotificationService,
	NotificationType,
	TemplateItem,
} from 'src/app/core/notification.service';
import { Station } from 'src/app/core/station.service';
import { DEFAULT_TEMPLATES, LOCAL_STATION_KEY } from 'src/app/shared/constants';

@Component({
	selector: 'app-station-settings-templates',
	templateUrl: './station-settings-templates.component.html',
	styleUrl: './station-settings-templates.component.scss',
})
export class StationSettingsTemplatesComponent {
	@Input() station: Station;

	template_items?: TemplateItem[];
	default_templates: Notification[] = DEFAULT_TEMPLATES;

	is_notifications_list_running: boolean = true;
	notifications: Notification[] = [];
	current_notif_loading: Notification;

	sms_type: NotificationType = NotificationType.SMS;

	constructor(
		private notification_service: NotificationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		if (
			this.station.settings
				.is_booking_vehicle_changed_custom_template_enabled &&
			!this.default_templates
				.map(dt => dt.name)
				.includes(NotificationName.BOOKING_VEHICLE_CHANGED)
		) {
			this.default_templates.splice(2, 0, {
				name: NotificationName.BOOKING_VEHICLE_CHANGED,
				type: NotificationType.SMS,
			});
			this.default_templates.push({
				name: NotificationName.BOOKING_VEHICLE_CHANGED,
				type: NotificationType.EMAIL,
			});
		}

		if (
			this.station.settings.is_booking_complete_notif_enabled &&
			this.station.settings.is_booking_complete_custom_template_enabled &&
			!this.default_templates
				.map(dt => dt.name)
				.includes(NotificationName.BOOKING_COMPLETE)
		) {
			this.default_templates.splice(3, 0, {
				name: NotificationName.BOOKING_COMPLETE,
				type: NotificationType.SMS,
			});
			this.default_templates.push({
				name: NotificationName.BOOKING_COMPLETE,
				type: NotificationType.EMAIL,
			});
		}

		this.listNotifications();
	}

	onRefresh(): void {
		this.listNotifications();
	}

	listNotifications(): void {
		this.is_notifications_list_running = true;
		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;
		this.notification_service.list(station.id).subscribe({
			next: response => {
				response.body.json().then(response => {
					this.notifications = response as Notification[];
					this.setTemplateItems();
					this.is_notifications_list_running = false;
				});
			},
			error: () => {
				this.notifications = [];
				this.setTemplateItems();
				this.is_notifications_list_running = false;
			},
		});
	}

	setTemplateItems(): void {
		this.template_items = [];
		this.default_templates.forEach(default_template => {
			const custom_template: Notification | undefined =
				this.getCustomTemplateByName(
					default_template.name,
					default_template.type,
				);

			this.template_items?.push({
				name: default_template.name ? default_template.name : '',
				type: default_template.type
					? default_template.type
					: NotificationType.BOTH,
				default: default_template,
				custom: custom_template,
			});
		});
	}

	getCustomTemplateByName(
		name?: string,
		type?: NotificationType,
	): Notification | undefined {
		return this.notifications?.filter(ct => {
			return ct.name == name && ct.type == type;
		})[0];
	}
}
