<h2 class="lk-margin__0 lk-margin__x12-bottom">
	{{ 'STATION.NAVBAR_LOCK_ITEM_NAME' | translate }}
</h2>

<div
	*ngIf="station"
	class="lk-padding__x48 lk-shadow lk-border-radius lk-background__light-neutral-0 lk-margin__x24-bottom">
	<mat-slide-toggle
		(change)="onLockableAfterVehicleUnlockChange()"
		[(ngModel)]="is_lockable_after_vehicle_unlock">
		<div class="lk-margin__x12-left">
			<h3>{{ 'STATION.SETTINGS_TRIG_LOCK_AFTER_UNLOCK' | translate }}</h3>
			<p
				class="lk-color__neutral-30 lk-text__12 lk-margin__0"
				style="max-width: 250px; line-height: 12px">
				{{ 'STATION.SETTINGS_TRIG_LOCK_AFTER_UNLOCK_SUB' | translate }}
			</p>
		</div>
	</mat-slide-toggle>

	<div class="lk-row lk-margin__x24-top">
		<form
			[formGroup]="lock_link_form_group"
			class="lk-row">
			<mat-form-field class="lk-form-field__custom-number-input lk-form-field">
				<input
					type="text"
					maxlength="5"
					matInput
					formControlName="time"
					(input)="onInputChange($event, lock_link_form_group)" />
			</mat-form-field>
		</form>

		<div class="lk-margin__x12-left">
			<p class="lk-text__12">
				{{
					'STATION.SETTINGS_TRIG_LOCK_LINK_TIME'
						| translate: lock_link_translate_param
				}}
			</p>
			<p
				class="lk-text__12 lk-primary-medium-color"
				*ngIf="lock_link_translate_param.time.substring(0, 1) != '-'">
				{{
					'STATION.SETTINGS_TRIG_LOCK_LINK_TIME_EX'
						| translate: lock_link_translate_param
				}}
			</p>
		</div>
	</div>

	<mat-expansion-panel
		style="box-shadow: none"
		class="lk-half-margin-top">
		<mat-expansion-panel-header
			class="lk-width__fit-content lk-padding__x12-horiz">
			<mat-panel-title>
				<span class="lk-primary-medium-color lk-text__12">
					{{ 'GENERAL.ADVANCED_OPTIONS_LABEL' | translate }}
				</span>
			</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="lk-row">
			<form
				[formGroup]="lock_before_form_group"
				class="lk-row">
				<mat-form-field
					class="lk-form-field__custom-number-input lk-form-field">
					<input
						type="text"
						maxlength="5"
						matInput
						formControlName="time"
						(input)="onInputChange($event, lock_before_form_group)" />
				</mat-form-field>
			</form>

			<div class="lk-margin__x12-left">
				<p class="lk-text__12">
					{{
						'STATION.SETTINGS_TRIG_LOCK_BEFORE_TIME'
							| translate: lock_before_translate_param
					}}
				</p>
				<p
					class="lk-text__12 lk-primary-medium-color"
					*ngIf="lock_before_translate_param.time.substring(0, 1) != '-'">
					{{
						'STATION.SETTINGS_TRIG_LOCK_BEFORE_TIME_EX'
							| translate: lock_before_translate_param
					}}
				</p>
			</div>
		</div>
	</mat-expansion-panel>

	<p class="lk-accent-color lk-margin__x12-top">
		{{ 'STATION.SETTINGS_TRIG_LOCK_AVAIL_SUBTITLE' | translate }}
	</p>

	<div class="lk-row lk-margin__x12-top">
		<form
			[formGroup]="lock_after_form_group"
			class="lk-row">
			<mat-form-field class="lk-form-field__custom-number-input lk-form-field">
				<input
					type="text"
					maxlength="5"
					matInput
					formControlName="time"
					(input)="onInputChange($event, lock_after_form_group)" />
			</mat-form-field>
		</form>

		<div class="lk-margin__x12-left">
			<p class="lk-text__12">
				{{
					'STATION.SETTINGS_TRIG_LOCK_AFTER_TIME'
						| translate: lock_after_translate_param
				}}
			</p>
			<p
				class="lk-text__12 lk-primary-medium-color"
				*ngIf="lock_after_translate_param.time.substring(0, 1) != '-'">
				{{
					'STATION.SETTINGS_TRIG_LOCK_AFTER_TIME_EX'
						| translate: lock_after_translate_param
				}}
			</p>
		</div>
	</div>

	<app-button
		class="lk-width__full lk-margin__x48-top lk-display__block"
		[button_class]="'lk-button__secondary lk-width__full'"
		[spinner_class]="'lk-button__spinner-white'"
		[cta]="'GENERAL.SAVE_BUTTON_CTA' | translate"
		[is_disabled]="is_request_running"
		[is_spinner_visible]="is_request_running"
		(button_click)="onSaveClick()"></app-button>
</div>
