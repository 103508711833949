import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map, startWith } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication.service';
import {
	CountriesService,
	CountryPrefix,
} from 'src/app/core/countries.service';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import {
	AUTH_MFA_SETUP_PATH,
	STATION_PATH,
	VEHICLES_PATH,
} from 'src/app/core/navigation.service';
import { Station } from 'src/app/core/station.service';
import {
	LOCAL_STATION_KEY,
	SMS_MFA,
	SOFTWARE_TOKEN_MFA,
	UNKNOWN_FLAG,
} from 'src/app/shared/constants';
import { MFA_RESET_SUCCESS_MESSAGE } from 'src/app/shared/translations';
import { StationBuilderDialogComponent } from 'src/app/station/station-builder-dialog/station-builder-dialog.component';
import { AuthMfaResetConfirmDialogComponent } from '../auth-mfa-reset-confirm-dialog/auth-mfa-reset-confirm-dialog.component';

@Component({
	selector: 'app-auth-mfa-reset',
	templateUrl: './auth-mfa-reset.component.html',
	styleUrls: ['./auth-mfa-reset.component.scss'],
})
export class AuthMfaResetComponent implements OnInit {
	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	sms_value = SMS_MFA;
	totp_value = SOFTWARE_TOKEN_MFA;
	form_group: FormGroup = this.form_service.mfa_host_reset_form_group;
	phone_form_group: FormGroup =
		this.form_service.mfa_host_phone_reset_form_group;
	is_reset_running: boolean = false;

	user_attributes?: any;

	country_prefixes: CountryPrefix[];
	filtered_options: Observable<CountryPrefix[]>;
	current_country_prefix: CountryPrefix;
	unknown_flag_src: string = UNKNOWN_FLAG;

	constructor(
		private local_manager: LocalManagerService,
		private router: Router,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private form_service: FormService,
		private countries: CountriesService,
		private auth_service: AuthenticationService,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.form_group.reset();
		this.phone_form_group.reset();

		this.country_prefixes = this.countries.getCountryPrefixList();
		this.filtered_options = this.phone_form_group
			.get('phone_prefix')
			.valueChanges.pipe(
				startWith(''),
				map(value => this.filterPrefix(value || '')),
			);

		this.auth_service.getUserAttributes().subscribe({
			next: response => {
				this.user_attributes = response;
			},
			error: () => {
				this.auth_service.signOut();
			},
		});
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuildClick(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
					this.toolbar_refresh.next(true);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onStationChangeClick(station: Station): void {}

	onCountryPrefixSelect(): void {
		const code: string = this.phone_form_group.get('phone_prefix').value;
		this.current_country_prefix = this.getCountryPrefixFromCode(code);
		this.phone_form_group
			.get('phone_prefix')
			.setValue(this.current_country_prefix.prefix);
	}

	onConfirmClick(): void {
		if (this.isSmsEnabled() && this.isFormValid() && this.isPhoneFormValid()) {
			let phone_prefix = this.phone_form_group.get('phone_prefix')?.value;
			const phone_value = this.phone_form_group.get('phone')?.value;

			if (!phone_prefix.includes('+')) {
				phone_prefix = '+' + phone_prefix;
			}

			this.dialog_service
				.openDialog(AuthMfaResetConfirmDialogComponent, {
					phone: `${phone_prefix} ${phone_value}`,
				})
				.afterClosed()
				.subscribe(dialog_result => {
					if (dialog_result.confirm) {
						this.setupPhoneNumber(`${phone_prefix}${phone_value}`);
					}
				});
		}

		if (this.isTotpEnabled() && this.isFormValid()) {
			this.setupMfaPreference();
		}
	}

	setupPhoneNumber(phone_number: string): void {
		this.is_reset_running = true;
		this.user_attributes['phone_number'] = phone_number;
		this.auth_service.updateUserAttributes(this.user_attributes).subscribe({
			next: () => {
				this.setupMfaPreference();
			},
			error: error => {
				this.is_reset_running = false;
				this.dialog_service.openInfoDialog(error.message);
			},
		});
	}

	setupMfaPreference(): void {
		this.is_reset_running = true;
		const preference = this.isSmsEnabled() ? SMS_MFA : SOFTWARE_TOKEN_MFA;
		this.auth_service.setupMFAPreference(preference).subscribe({
			next: () => {
				if (this.isSmsEnabled()) {
					this.is_reset_running = false;
					this.dialog_service.openInfoDialog(MFA_RESET_SUCCESS_MESSAGE);
					this.router.navigate([VEHICLES_PATH]);
				} else {
					this.setupTOTP();
				}
			},
			error: error => {
				this.is_reset_running = false;
				this.dialog_service.openInfoDialog(error.message);
			},
		});
	}

	setupTOTP(): void {
		this.is_reset_running = true;
		this.auth_service.setupTOTP().subscribe({
			next: response => {
				this.is_reset_running = false;
				this.auth_service.mfa_setup_mechanism = 'totp';
				this.auth_service.totp_setup_secret = response;
				this.router.navigate([AUTH_MFA_SETUP_PATH]);
			},
			error: error => {
				this.is_reset_running = false;
				this.dialog_service.openInfoDialog(error.message);
			},
		});
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.form_group);
	}

	isPhoneFormValid(): boolean {
		return this.form_service.validateForm(this.phone_form_group);
	}

	isSmsEnabled(): boolean {
		return this.form_group.get('mfa_type')?.value == this.sms_value;
	}

	isTotpEnabled(): boolean {
		return this.form_group.get('mfa_type')?.value == this.totp_value;
	}

	isButtonDisabled(): boolean {
		return (
			this.is_reset_running ||
			!this.isFormValid() ||
			(this.isSmsEnabled() && !this.isPhoneFormValid())
		);
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.isButtonDisabled(),
			'lk-main-button': !this.isButtonDisabled(),
		};
	}

	filterPrefix(value: string) {
		const filter_value = value.toLowerCase();
		return this.country_prefixes.filter(option => {
			return option.prefix.toLowerCase().includes(filter_value);
		});
	}

	getCountryPrefixFromCode(code: string): CountryPrefix {
		return this.country_prefixes.filter(cp => {
			return cp.code == code;
		})[0];
	}
}
