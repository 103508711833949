<form
	[formGroup]="signup_form_group"
	class="lk-row__center lk-wrap lk-z-index">
	<mat-form-field class="lk-width__full lk-margin__x24-bottom lk-form-field">
		<mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
		<input
			type="email"
			matInput
			formControlName="username"
			placeholder="address@email.com"
			required />
	</mat-form-field>

	<mat-form-field
		class="lk-width__full lk-margin__x24-bottom lk-form-field"
		[ngClass]="getPssFormFieldErrorClass('password')">
		<mat-label> {{ 'GENERAL.PASSWORD_LABEL' | translate }} </mat-label>

		<input
			[type]="is_password_visible ? 'text' : 'password'"
			matInput
			[errorStateMatcher]="matcher"
			formControlName="password"
			required />

		<mat-icon
			class="lk-cursor__pointer"
			matSuffix
			(click)="is_password_visible = !is_password_visible">
			{{ is_password_visible ? 'visibility_off' : 'visibility' }}
		</mat-icon>

		<mat-error *ngIf="isPssLengthWrong('password')">
			<span>{{ 'AUTHENTICATION.PSS_MIN_LENGTH_ERROR' | translate }}</span>
		</mat-error>

		<mat-error *ngIf="isPssPatternWrong('password')">
			<span>{{ 'AUTHENTICATION.PSS_PATTERN_ERROR' | translate }}</span>
		</mat-error>
	</mat-form-field>

	<mat-form-field
		class="lk-width__full lk-form-field"
		[ngClass]="getPssFormFieldErrorClass('password_confirm')">
		<mat-label>
			{{ 'GENERAL.PASSWORD_CONFIRM_LABEL' | translate }}
		</mat-label>

		<input
			[type]="is_password_visible ? 'text' : 'password'"
			matInput
			[errorStateMatcher]="matcher"
			formControlName="password_confirm"
			required />

		<mat-icon
			class="lk-cursor__pointer"
			matSuffix
			(click)="is_password_visible = !is_password_visible">
			{{ is_password_visible ? 'visibility_off' : 'visibility' }}
		</mat-icon>

		<mat-error *ngIf="isPssLengthWrong('password_confirm')">
			<span>{{ 'AUTHENTICATION.PSS_MIN_LENGTH_ERROR' | translate }}</span>
		</mat-error>

		<mat-error *ngIf="isPssPatternWrong('password_confirm')">
			<span>{{ 'AUTHENTICATION.PSS_PATTERN_ERROR' | translate }}</span>
		</mat-error>
	</mat-form-field>

	<app-button
		class="lk-width__full lk-margin__x48-top"
		[button_class]="'lk-button__secondary lk-width__full'"
		[spinner_class]="'lk-button__spinner-white'"
		[cta]="'AUTHENTICATION.SIGNUP_BUTTON_CTA' | translate"
		[is_disabled]="!isFormValid() || is_signup_running"
		[is_spinner_visible]="is_signup_running"
		(button_click)="onSignupClick()"></app-button>
</form>
