import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/auth.guard';
import { VehicleDetailsComponent } from './vehicle-details.component';

const routes: Routes = [
	{
		path: '',
		component: VehicleDetailsComponent,
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class VehicleDetailsRoutingModule {}
