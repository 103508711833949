import { Component, Input, ViewChild } from '@angular/core';
import { Station } from 'src/app/core/station.service';
import { StationSettingsTemplatesComponent } from '../station-settings-templates/station-settings-templates.component';
import { StationSettingsNotifComponent } from '../station-settings-notif/station-settings-notif.component';
import {
	STATION_NAVBAR_NOTIF_ITEM_NAME,
	STATION_NAVBAR_UNLOCK_ITEM_NAME,
	STATION_NAVBAR_LOCK_ITEM_NAME,
	STATION_NAVBAR_DATA_SETTINGS_ITEM_NAME,
	STATION_NAVBAR_TEAM_ITEM_NAME,
} from 'src/app/shared/constants';

@Component({
	selector: 'app-station-settings',
	templateUrl: './station-settings.component.html',
	styleUrl: './station-settings.component.scss',
})
export class StationSettingsComponent {
	@Input() station?: Station;
	@Input() navbar_item?: string;

	@ViewChild(StationSettingsTemplatesComponent)
	templates_component?: StationSettingsTemplatesComponent;
	@ViewChild(StationSettingsNotifComponent)
	settings_component?: StationSettingsNotifComponent;

	notif_item_name = STATION_NAVBAR_NOTIF_ITEM_NAME;
	unlock_item_name = STATION_NAVBAR_UNLOCK_ITEM_NAME;
	lock_item_name = STATION_NAVBAR_LOCK_ITEM_NAME;
	data_item_name = STATION_NAVBAR_DATA_SETTINGS_ITEM_NAME;
	team_item_name = STATION_NAVBAR_TEAM_ITEM_NAME;

	onRefresh(station: Station): void {
		this.templates_component?.onRefresh();
		this.settings_component?.onRefresh(station);
	}
}
