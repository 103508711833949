import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleRoutingModule } from './vehicle-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { ButtonModule } from '../button/button.module';
import { LoadingModule } from '../loading/loading.module';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { VehicleComponent } from './vehicle.component';
import { VehicleSummaryComponent } from './vehicle-summary/vehicle-summary.component';
import { VehicleTableComponent } from './vehicle-table/vehicle-table.component';
import { VehicleBuilderDialogComponent } from './vehicle-builder-dialog/vehicle-builder-dialog.component';
import { VehicleFiltersDialogComponent } from './vehicle-filters-dialog/vehicle-filters-dialog.component';
import { VehicleEmptyComponent } from './vehicle-empty/vehicle-empty.component';
import { VehicleListComponent } from './vehicle-list/vehicle-list.component';
import { VehicleMapComponent } from './vehicle-map/vehicle-map.component';
import { VehicleDeleteDialogComponent } from './vehicle-delete-dialog/vehicle-delete-dialog.component';
import { VehicleTuroDialogComponent } from './vehicle-turo-dialog/vehicle-turo-dialog.component';
import { VehicleManualContactsDialogComponent } from './vehicle-manual-contacts-dialog/vehicle-manual-contacts-dialog.component';
import { VehicleManualInfoDialogComponent } from './vehicle-manual-info-dialog/vehicle-manual-info-dialog.component';
import { VehicleDirectLinkDialogComponent } from './vehicle-direct-link-dialog/vehicle-direct-link-dialog.component';
import { VehicleDirectLinkConfirmDialogComponent } from './vehicle-direct-link-confirm-dialog/vehicle-direct-link-confirm-dialog.component';
import { VehicleHeaderComponent } from './vehicle-header/vehicle-header.component';
import { VehicleTableControlsComponent } from './vehicle-table-controls/vehicle-table-controls.component';

@NgModule({
	declarations: [
		VehicleComponent,
		VehicleSummaryComponent,
		VehicleTableComponent,
		VehicleBuilderDialogComponent,
		VehicleFiltersDialogComponent,
		VehicleEmptyComponent,
		VehicleListComponent,
		VehicleMapComponent,
		VehicleDeleteDialogComponent,
		VehicleTuroDialogComponent,
		VehicleManualContactsDialogComponent,
		VehicleManualInfoDialogComponent,
		VehicleDirectLinkDialogComponent,
		VehicleDirectLinkConfirmDialogComponent,
		VehicleHeaderComponent,
  VehicleTableControlsComponent,
	],
	imports: [
		CommonModule,
		VehicleRoutingModule,
		SharedModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule,
		ToolbarModule,
		ButtonModule,
		LoadingModule,
		MatButtonModule,
		MatIconModule,
		MatTableModule,
		MatFormFieldModule,
		MatInputModule,
		MatTooltipModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatExpansionModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatAutocompleteModule,
	],
	exports: [VehicleDeleteDialogComponent],
})
export class VehicleModule {}