import { Component, Input } from '@angular/core';
import { DialogService } from 'src/app/core/dialog.service';
import { TemplateItem } from 'src/app/core/notification.service';
import { Station } from 'src/app/core/station.service';
import { UtilsService } from 'src/app/core/utils.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import { TEST_NOTIF_SUCCESS_MESSAGE } from 'src/app/shared/translations';
import { StationNotifTestDialogComponent } from '../station-notif-test-dialog/station-notif-test-dialog.component';

@Component({
	selector: 'app-station-settings-templates-item-header',
	templateUrl: './station-settings-templates-item-header.component.html',
	styleUrl: './station-settings-templates-item-header.component.scss',
})
export class StationSettingsTemplatesItemHeaderComponent {
	@Input() template?: TemplateItem;
	@Input() is_default_selected = true;

	station?: Station;

	constructor(
		private dialog_service: DialogService,
		private utils_service: UtilsService,
	) {}

	ngOnInit(): void {
		this.station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;
	}

	onTestClick(): void {
		this.dialog_service
			.openDialog(StationNotifTestDialogComponent, {
				type: this.template.type,
				name: this.template.name,
				template_id: this.is_default_selected
					? undefined
					: this.template.custom.id,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result?.success) {
					this.dialog_service.openInfoDialog(TEST_NOTIF_SUCCESS_MESSAGE);
				}

				if (!dialog_result?.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	getStationFlag(): string {
		return this.utils_service.getStationFlag(
			this.station?.settings?.default_lang,
		);
	}

	getTemplateLabel(): string {
		return this.utils_service.getNotificationLabelByTemplate(this.template);
	}
}
