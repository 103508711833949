import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { HiddenStationsResetMfaDialogComponent } from '../hidden-stations-reset-mfa-dialog/hidden-stations-reset-mfa-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HiddenStationsAdminAddDialogComponent } from '../hidden-stations-admin-add-dialog/hidden-stations-admin-add-dialog.component';

@Component({
	selector: 'app-hidden-stations-header',
	templateUrl: './hidden-stations-header.component.html',
	styleUrls: ['./hidden-stations-header.component.scss'],
})
export class HiddenStationsHeaderComponent {
	@Output() search_change: EventEmitter<string> = new EventEmitter<string>();
	@Output() alias_search_change: EventEmitter<string> =
		new EventEmitter<string>();
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();

	form_group: FormGroup;
	current_search_value?: string;
	current_alias_search_value?: string;

	constructor(
		private form_service: FormService,
		private dialog_service: DialogService,
		private snackbar: MatSnackBar,
	) {
		this.form_group = this.form_service.hidden_stations_search_form_group;
	}

	onRefresh(): void {
		this.refresh.emit();

		this.current_search_value = undefined;
		this.current_alias_search_value = undefined;

		this.form_group.reset();
	}

	onSearchChange(value: string): void {
		this.search_change.emit(value);

		this.current_search_value = this.form_group.get('name').value;
		this.current_alias_search_value = undefined;

		this.form_group.reset();
	}

	onAliasSearchChange(value: string): void {
		this.alias_search_change.emit(value);

		this.current_search_value = undefined;
		this.current_alias_search_value = this.form_group.get('alias').value;

		this.form_group.reset();
	}

	onNameReset(): void {
		this.search_change.emit('');
	}

	onAliasReset(): void {
		this.alias_search_change.emit('');
	}

	onResetMfaClick(): void {
		this.dialog_service
			.openDialog(HiddenStationsResetMfaDialogComponent)
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.snackbar.open('MFA reset successfully!', '', { duration: 2000 });
				} else if (dialog_result && !dialog_result.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onAddAdminClick(): void {
		this.dialog_service
			.openDialog(HiddenStationsAdminAddDialogComponent)
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.snackbar.open('Admin added successfully!', '', {
						duration: 2000,
					});
				} else if (dialog_result && !dialog_result.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}
}
