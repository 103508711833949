<div class="lk-auth-mfa-setup__grid lk-height__full lk-width__full">
	<div
		*ngIf="mfa_setup_mechanism === 'sms'"
		class="lk-auth-mfa-setup__form lk-margin__auto lk-border__radius lk-row lk-wrap lk-background__light-neutral-0 lk-z-index lk-shadow">
		<app-auth-logo-header
			[logo_src]="logo_src"
			class="lk-width__full"></app-auth-logo-header>
		<app-auth-mfa-setup-sms></app-auth-mfa-setup-sms>
	</div>

	<div
		*ngIf="mfa_setup_mechanism === 'totp'"
		class="lk-auth-mfa-setup__form lk-margin__auto lk-border__radius lk-row lk-wrap lk-background__light-neutral-0 lk-z-index lk-shadow">
		<app-auth-logo-header
			[logo_src]="logo_src"
			class="lk-width__full"></app-auth-logo-header>
		<app-auth-mfa-setup-totp></app-auth-mfa-setup-totp>
	</div>

	<div
		class="lk-auth-mfa-setup__img lk-height__full lk-width__full lk-padding__x48 lk-display__hidden-before-lg"
		[ngStyle]="{ 'background-image': 'url(' + background_src + ')' }"></div>
</div>
