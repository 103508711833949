import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import {
	Station,
	StationService,
	UnlockSettings,
} from 'src/app/core/station.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-station-settings-unlock',
	templateUrl: './station-settings-unlock.component.html',
	styleUrl: './station-settings-unlock.component.scss',
})
export class StationSettingsUnlockComponent {
	@Input() station?: Station;

	is_unlockable_on_vehicle_assignment: boolean;
	is_unlockable_until_booking_end: boolean;
	is_request_running: boolean = false;
	unlock_link_form_group: FormGroup;
	unlock_before_form_group: FormGroup;
	unlock_after_form_group: FormGroup;
	unlock_link_time_translate_param: {
		hours: string;
		minutes: string;
		time: string;
	};
	unlock_before_translate_param: {
		hours: string;
		minutes: string;
		time: string;
	};
	unlock_after_translate_param: {
		hours: string;
		minutes: string;
		time: string;
	};

	constructor(
		private form_service: FormService,
		private station_service: StationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private snackbar: MatSnackBar,
		private translate: TranslateService,
	) {
		this.dialog_service.dialog = this.dialog;
		this.unlock_link_form_group =
			this.form_service.stationSettingsUnlockLinkFormGroup;
		this.unlock_before_form_group =
			this.form_service.stationSettingsUnlockBeforeFormGroup;
		this.unlock_after_form_group =
			this.form_service.stationSettingsUnlockAfterFormGroup;
	}

	ngOnInit(): void {
		this.initFormFields();
		this.setTranslationParams();
	}

	onReset(station: Station): void {
		this.station = station;
		this.initFormFields();
		this.setTranslationParams();
	}

	onUnlockableOnVehicleAssignmentChange(): void {
		if (this.is_unlockable_on_vehicle_assignment) {
			this.unlock_link_form_group.get('time').disable();
			this.unlock_link_form_group.get('time').setValue('00:00');
			this.unlock_before_form_group.get('time').disable();
			this.unlock_before_form_group.get('time').setValue('00:00');
			this.setTranslationParams();
		} else {
			this.unlock_link_form_group.get('time').enable();
			this.unlock_before_form_group.get('time').enable();
		}
	}

	onUnlockableUntilBookingEndChange(): void {
		if (this.is_unlockable_until_booking_end) {
			this.unlock_after_form_group.get('time').disable();
			this.unlock_after_form_group.get('time').setValue('00:00');
			this.setTranslationParams();
		} else {
			this.unlock_after_form_group.get('time').enable();
		}
	}

	onInputChange(event: any, formGroup: FormGroup): void {
		if (!formGroup.get('time').value.includes(':')) {
			const column_index: number = Math.ceil(
				formGroup.get('time').value.length / 2,
			);
			event.target.value =
				event.target.value.substr(0, column_index) +
				':' +
				event.target.value.substr(column_index + 1);
		}

		const target: string[] = event.target.value.split(':');
		if (target && target[0].length > 2) {
			event.target.value = target[0].slice(0, -1) + ':' + target[1];
		}
		if (target && target[1].length > 2) {
			event.target.value = target[0] + ':' + target[1].slice(0, -1);
		}

		this.setTranslationParams();
	}

	onSaveClick(): void {
		let hours_and_minutes: number[];
		const unlock_settings: UnlockSettings = new UnlockSettings();
		unlock_settings.is_unlockable_on_vehicle_assignment =
			this.is_unlockable_on_vehicle_assignment;
		unlock_settings.is_unlockable_until_booking_end_date =
			this.is_unlockable_until_booking_end;
		hours_and_minutes = this.parseTimeFormGroup(
			this.form_service.stationSettingsUnlockLinkFormGroup,
		);
		unlock_settings.time_to_unlock_link_before_booking_start =
			this.getMillisFromHoursAndMin(hours_and_minutes[0], hours_and_minutes[1]);
		hours_and_minutes = this.parseTimeFormGroup(
			this.form_service.stationSettingsUnlockBeforeFormGroup,
		);
		unlock_settings.time_to_unlock_availability_before_booking_start =
			this.getMillisFromHoursAndMin(hours_and_minutes[0], hours_and_minutes[1]);
		hours_and_minutes = this.parseTimeFormGroup(
			this.form_service.stationSettingsUnlockAfterFormGroup,
		);
		unlock_settings.time_to_unlock_availability_after_booking_start =
			this.getMillisFromHoursAndMin(hours_and_minutes[0], hours_and_minutes[1]);
		this.station.settings.unlock_settings = unlock_settings;
		this.updateStation(this.station);
	}

	updateStation(station: Station): void {
		this.is_request_running = true;
		const station_logo_path = station.logo_path;
		const station_logo_url = station.logo_url;
		this.station_service.update(station).subscribe({
			next: response => {
				response.body.json().then(response => {
					const station: Station = response;
					station.logo_path = station_logo_path;
					station.logo_url = station_logo_url;
					this.translate
						.get('STATION.UPDATE_COMPLETED_MESSAGE')
						.subscribe(message => {
							this.snackbar.open(message, '', { duration: 3000 });
						});
					localStorage.setItem(LOCAL_STATION_KEY, JSON.stringify(station));
					this.is_request_running = false;
				});
			},
			error: () => {
				this.is_request_running = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	initFormFields(): void {
		// Flags
		this.is_unlockable_on_vehicle_assignment = this.station.settings
			.unlock_settings
			? this.station.settings.unlock_settings
					.is_unlockable_on_vehicle_assignment
			: false;
		this.is_unlockable_until_booking_end = this.station.settings.unlock_settings
			? this.station.settings.unlock_settings
					.is_unlockable_until_booking_end_date
			: false;

		// Unlock link
		let hoursAndMinutes = this.convertUnlockLinkTime();
		this.unlock_link_form_group
			.get('time')
			.setValue(
				hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString(),
			);

		// Unlock before
		hoursAndMinutes = this.convertUnlockBefore();
		this.unlock_before_form_group
			.get('time')
			.setValue(
				hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString(),
			);

		// Unlock after
		hoursAndMinutes = this.convertUnlockAfter();
		this.unlock_after_form_group
			.get('time')
			.setValue(
				hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString(),
			);

		// Block fields
		this.onUnlockableOnVehicleAssignmentChange();
		this.onUnlockableUntilBookingEndChange();
	}

	setTranslationParams(): void {
		this.unlock_link_time_translate_param = {
			hours: this.parseTimeFormGroup(this.unlock_link_form_group)[0].toString(),
			minutes: this.parseTimeFormGroup(
				this.unlock_link_form_group,
			)[1].toString(),
			time: this.getTimeLabel(this.unlock_link_form_group),
		};

		this.unlock_before_translate_param = {
			hours: this.parseTimeFormGroup(
				this.unlock_before_form_group,
			)[0].toString(),
			minutes: this.parseTimeFormGroup(
				this.unlock_before_form_group,
			)[1].toString(),
			time: this.getTimeLabel(this.unlock_before_form_group),
		};

		this.unlock_after_translate_param = {
			hours: this.parseTimeFormGroup(
				this.unlock_after_form_group,
			)[0].toString(),
			minutes: this.parseTimeFormGroup(
				this.unlock_after_form_group,
			)[1].toString(),
			time: this.getTimeLabel(this.unlock_after_form_group, true),
		};
	}

	parseTimeFormGroup(formGroup: FormGroup): number[] {
		const time: string = formGroup.get('time').value;
		const columnIndex: number = time.indexOf(':');
		return [
			+time.substring(0, columnIndex),
			+time.substring(columnIndex + 1, time.length),
		];
	}

	getTimeLabel(formGroup: FormGroup, sum?: boolean): string {
		const MILLIS_IN_MIN: number = 60 * 1000;
		const MILLIS_IN_HOUR: number = 60 * 60 * 1000;
		const NOON_MILLIS: number = 12 * MILLIS_IN_HOUR;
		const hoursAndMins: number[] = this.parseTimeFormGroup(formGroup);
		const hoursAndMinsMillis: number =
			hoursAndMins[0] * MILLIS_IN_HOUR + hoursAndMins[1] * MILLIS_IN_MIN;
		const time: string[] = sum
			? this.getHoursAndMinutesFromMillis(NOON_MILLIS + hoursAndMinsMillis)
			: this.getHoursAndMinutesFromMillis(NOON_MILLIS - hoursAndMinsMillis);
		return time[0] + ':' + time[1];
	}

	getHoursAndMinutesFromMillis(millis: number): string[] {
		const minutes = this.correctDigits(Math.floor((millis / (1000 * 60)) % 60)),
			hours = this.correctDigits(Math.floor(millis / (1000 * 60 * 60)));
		return [hours, minutes];
	}

	getMillisFromHoursAndMin(hours: number, minutes: number): number {
		const HOUR_IN_MILLIS: number = 3600000;
		const MINUTE_IN_MILLIS: number = 60000;
		return hours * HOUR_IN_MILLIS + minutes * MINUTE_IN_MILLIS;
	}

	correctDigits(num: number): string {
		if (num.toString().length <= 1) {
			return '0' + num;
		}
		return num.toString();
	}

	convertUnlockLinkTime(): string[] {
		return this.getHoursAndMinutesFromMillis(
			this.station.settings.unlock_settings
				? this.station.settings.unlock_settings
						.time_to_unlock_link_before_booking_start
				: 3600000,
		);
	}

	convertUnlockBefore(): string[] {
		return this.getHoursAndMinutesFromMillis(
			this.station.settings.unlock_settings
				? this.station.settings.unlock_settings
						.time_to_unlock_availability_before_booking_start
				: 3600000,
		);
	}

	convertUnlockAfter(): string[] {
		return this.getHoursAndMinutesFromMillis(
			this.station.settings.unlock_settings
				? this.station.settings.unlock_settings
						.time_to_unlock_availability_after_booking_start
				: 3600000,
		);
	}

	getDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': this.is_request_running,
			'lk-main-button': !this.is_request_running,
		};
	}
}
