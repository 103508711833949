<div
	style="position: relative"
	class="lk-padding__x24 lk-height__full lk-no-z-index">
	<div
		id="lk-station-map-layout"
		class="lk-border-radius lk-shadow"></div>

	<div
		[ngClass]="station ? '' : 'lk-station-map__loading'"
		class="lk-station-map__overlay lk-border-radius"></div>
</div>
