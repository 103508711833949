<app-toolbar
	[current_nav_item]="current_item"
	(no_station_found)="onNoStationFound()"
	(station_build)="onStationBuild(false)"
	(station_change)="onStationChange($event)"></app-toolbar>

<section
	class="lk-page__with-toolbar lk-padding__x24"
	[ngClass]="
		is_vehicle_loading
			? 'lk-vehicle-details__loading-grid'
			: 'lk-vehicle-details__grid'
	">
	<app-loading
		*ngIf="is_vehicle_loading"
		class="lk-width__full lk-height__full"></app-loading>

	<app-loading
		*ngIf="is_vehicle_loading"
		class="lk-width__full lk-height__full"></app-loading>

	<app-vehicle-details-header
		*ngIf="!is_vehicle_loading && vehicle"
		[vehicle]="vehicle"
		(refresh)="onRefresh()"></app-vehicle-details-header>

	<app-vehicle-details-controls
		*ngIf="!is_vehicle_loading && vehicle"
		[vehicle]="vehicle"
		(refresh)="onRefresh()"></app-vehicle-details-controls>

	<app-vehicle-details-navbar
		*ngIf="!is_vehicle_loading && vehicle"
		(navbar_item_change)="onNavbarItemChange($event)">
	</app-vehicle-details-navbar>

	<div
		*ngIf="!is_vehicle_loading && vehicle"
		class="lk-width__full">
		<div
			*ngIf="current_navbar_item == navbar_items[0]"
			class="lk-width__full lk-height__full lk-row__center lk-row__align-start lk-vehicle-details__general-grid">
			<app-vehicle-details-general
				class="lk-vehicle-details__content-layout lk-height__full"
				[vehicle]="vehicle"
				(vehicle_update_click)="onUpdateClick($event)">
			</app-vehicle-details-general>

			<div
				class="lk-vehicle-details__content-layout lk-height__full lk-vehicle-details__map">
				<app-vehicle-details-map
					class="lk-height__full"
					[vehicleSubject]="vehicle_subject"></app-vehicle-details-map>
			</div>
		</div>

		<div
			*ngIf="current_navbar_item == navbar_items[1]"
			class="lk-width__full lk-height__full lk-row__center lk-row__align-start">
			<app-vehicle-details-commands
				class="lk-vehicle-details__content-layout lk-height__full lk-vehicle-details__standalone"
				[vehicle]="vehicle"></app-vehicle-details-commands>
		</div>

		<div
			*ngIf="current_navbar_item == navbar_items[2]"
			class="lk-width__full lk-height__full lk-row__center lk-row__align-start">
			<app-vehicle-details-measures
				class="lk-vehicle-details__content-layout lk-height__full lk-vehicle-details__standalone"
				[vehicle]="vehicle"></app-vehicle-details-measures>
		</div>

		<div
			*ngIf="vehicle && current_navbar_item == navbar_items[3]"
			class="lk-width__full lk-height__full lk-row__center lk-row__align-start">
			<app-vehicle-details-links
				class="lk-vehicle-details__content-layout lk-height__full lk-vehicle-details__standalone"
				[vehicle]="vehicle"></app-vehicle-details-links>
		</div>

		<div
			*ngIf="vehicle && current_navbar_item == navbar_items[4]"
			class="lk-width__full lk-height__full lk-row__center lk-row__align-start">
			<app-vehicle-details-gallery
				class="lk-vehicle-details__content-layout lk-height__full lk-vehicle-details__standalone"
				[vehicleSubject]="vehicle_subject"
				(vehicleUpdateClick)="onVehicleUpdateClick($event)">
			</app-vehicle-details-gallery>
		</div>
	</div>
</section>
