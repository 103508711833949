<div style="min-width: 300px">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x24">
		<form [formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				class="lk-width__full lk-form-field"
				color="accent">
				<mat-label> Alias </mat-label>
				<input
					type="text"
					matInput
					formControlName="alias" />
			</mat-form-field>

			<app-button
				class="lk-width__full lk-margin__x24-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.SAVE_BUTTON_CTA' | translate"
				[is_disabled]="is_save_running"
				[is_spinner_visible]="is_save_running"
				(button_click)="onAliasUpdateClick()"></app-button>
		</form>
	</div>
</div>
