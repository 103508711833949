import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../core/authentication.service';
import { NavigationService } from '../core/navigation.service';

@Injectable({
	providedIn: 'root',
})
export class TotpSetupGuard implements CanActivate {
	constructor(
		private auth_service: AuthenticationService,
		private navigation_service: NavigationService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const totp_setup_secret = this.auth_service.totp_setup_secret;
		const totp_setup_uri = this.auth_service.totp_setup_uri;
		if (totp_setup_secret && totp_setup_uri) {
			return true;
		} else {
			this.navigation_service.toAuthSignin();
			return false;
		}
	}
}
