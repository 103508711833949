import { Component, OnInit } from '@angular/core';
import { SharedSingleActionDialogComponent } from '../../shared/shared-single-action-dialog/shared-single-action-dialog.component';
import {
	GENERAL_ERROR_MESSAGE,
	NO_NOTIF_TYPE_MESSAGE,
	STATION_BUILDER_NOTIF_STEP,
	TOOLBAR_STATION_NAV_ITEM_NAME,
} from '../../shared/translations';
import { NotificationType } from '../../core/notification.service';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { Station, StationService } from '../../core/station.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
	STATION_BUILDER_LANG_PATH,
	STATION_BUILDER_NOTIF_PATH,
	STATION_PATH,
} from 'src/app/core/navigation.service';
import { ERROR_DESCRIPTION_500 } from 'src/app/shared/constants';
import { ErrorService } from 'src/app/core/error.service';

@Component({
	selector: 'app-station-builder-notif-toggle',
	templateUrl: './station-builder-notif-toggle.component.html',
	styleUrls: ['./station-builder-notif-toggle.component.scss'],
})
export class StationBuilderNotifToggleComponent implements OnInit {
	station_id: string;
	station: Station;
	is_sms_checked: boolean = true;
	is_email_checked: boolean = true;
	update_mode: boolean = false;
	is_request_running: boolean = false;
	is_station_loading: boolean = false;
	current_step: string = STATION_BUILDER_NOTIF_STEP;
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	current_item: string = TOOLBAR_STATION_NAV_ITEM_NAME;

	constructor(
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private station_service: StationService,
		private router: Router,
		private route: ActivatedRoute,
		private error_service: ErrorService,
	) {
		this.dialog_service.dialog = this.dialog;
		this.station_id = this.route.snapshot.paramMap.get('station_id');
		this.route.queryParams.subscribe(params => {
			if (params && params.update) {
				this.update_mode = params.update;
			}
		});
		if (!this.station_id) {
			this.router.navigate([STATION_PATH]);
		}
	}

	ngOnInit() {
		this.station_update_trigger.next(false);
		if (this.station_id) {
			this.readStation();
		}
	}

	onNextClick() {
		if (this.is_sms_checked || this.is_email_checked) {
			let type: NotificationType;
			if (this.is_sms_checked && this.is_email_checked) {
				type = NotificationType.BOTH;
			} else {
				type = this.is_sms_checked
					? NotificationType.SMS
					: NotificationType.EMAIL;
			}
			this.station.settings.default_notification_type = type;
			this.updateStation();
		} else {
			this.dialog_service.openDialog(SharedSingleActionDialogComponent, {
				message: NO_NOTIF_TYPE_MESSAGE,
			});
		}
	}

	readStation() {
		this.is_request_running = true;
		this.station_service.read(this.station_id).subscribe({
			next: response => {
				this.station = response.data;
				if (
					this.station.settings &&
					this.station.settings.default_notification_type ==
						NotificationType.BOTH
				) {
					this.is_sms_checked = true;
					this.is_email_checked = true;
				} else {
					this.is_sms_checked =
						this.station.settings &&
						this.station.settings.default_notification_type ==
							NotificationType.SMS;
					this.is_email_checked =
						this.station.settings &&
						this.station.settings.default_notification_type ==
							NotificationType.EMAIL;
				}
				this.is_request_running = false;
			},
			error: error => {
				this.router.navigate([STATION_PATH]);
				this.is_request_running = false;
			},
		});
	}

	updateStation() {
		this.is_station_loading = true;
		this.station_service.update(this.station).subscribe({
			next: response => {
				this.is_station_loading = false;
				if (this.update_mode) {
					this.router.navigate([STATION_PATH]);
				} else {
					//this.router.navigate([STATION_PATH + '/' + this.station_id + STATION_BUILDER_LANG_PATH]);
					this.router.navigate([
						STATION_PATH + '/' + this.station_id + STATION_BUILDER_NOTIF_PATH,
					]);
				}
			},
			error: error => {
				this.is_station_loading = false;
				this.error_service.wrapError(error);
			},
		});
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_station_loading,
			'lk-main-button': !this.is_station_loading,
		};
	}
}
