import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vehicle, VehicleStatus } from '../../core/vehicle.service';
import { FormService } from '../../core/form.service';
import { FormGroup } from '@angular/forms';
import { Station } from 'src/app/core/station.service';
import {
	UnlockCommand,
	UnlockCommandsService,
	UnlockCommandStatus,
} from 'src/app/core/unlock-commands.service';
import {
	LOCAL_STATION_KEY,
	ADAPTER_CRED_NOT_FOUND_ERROR_CODE,
	ADAPTER_FAILED_ERROR_CODE,
	ADAPTER_OFFLINE_ERROR_CODE,
} from 'src/app/shared/constants';
import {
	GENERAL_ERROR_MESSAGE,
	ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
	ADAPTER_FAILED_ERROR_MESSAGE,
	ADAPTER_GENERIC_ERROR_MESSAGE,
	ADAPTER_OFFLINE_ERROR_MESSAGE,
} from 'src/app/shared/translations';
import { DialogService } from 'src/app/core/dialog.service';
import { SharedInteractionErrorDialogComponent } from 'src/app/shared/shared-interaction-error-dialog/shared-interaction-error-dialog.component';
import {
	LockCommand,
	LockCommandsService,
	LockCommandStatus,
} from 'src/app/core/lock-commands.service';
import { ErrorService } from 'src/app/core/error.service';
import { NavigationService } from 'src/app/core/navigation.service';

@Component({
	selector: 'app-vehicle-list',
	templateUrl: './vehicle-list.component.html',
	styleUrls: ['./vehicle-list.component.scss'],
})
export class VehicleListComponent {
	@Input() is_header_hidden: boolean;
	@Input() horiz_mode: boolean;
	@Input() vehicles: Vehicle[];

	@Output() filters_click: EventEmitter<void> = new EventEmitter<void>();
	@Output() search_change: EventEmitter<string> = new EventEmitter<string>();
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();

	form_group: FormGroup;
	is_vehicle_unlock_interacting: boolean[] = [];
	is_vehicle_lock_interacting: boolean[] = [];

	constructor(
		private form_service: FormService,
		private unlock_commands_service: UnlockCommandsService,
		private lock_commands_service: LockCommandsService,
		private dialog_service: DialogService,
		private error_service: ErrorService,
		private navigation_service: NavigationService,
	) {
		this.form_group = this.form_service.vehicleSearchFormGroup;
	}

	onStartClick(vehicle: Vehicle): void {
		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		this.createUnlockCommand(station, vehicle);
	}

	onStopClick(vehicle: Vehicle): void {
		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		this.createLockCommand(station, vehicle);
	}

	onVehicleClick(vehicle: Vehicle) {
		this.navigation_service.toVehiclesDetails(vehicle.id);
	}

	createUnlockCommand(station: Station, vehicle: Vehicle): void {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle.id);
		this.is_vehicle_unlock_interacting[index] = true;

		this.unlock_commands_service.create(station.id, vehicle.id).subscribe({
			next: response => {
				response.body.json().then(response => {
					const command: UnlockCommand = response as UnlockCommand;
					this.readUnlockCommand(station, command.id, vehicle.id);
				});
			},
			error: error => {
				this.is_vehicle_unlock_interacting[index] = false;

				const converted_error = this.error_service.convertError(error);

				if (converted_error.code === ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.dialog_service.openInfoDialog(
						ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
					);
				}

				if (converted_error.code != ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.error_service.processError(converted_error);
				}
			},
		});
	}

	createLockCommand(station: Station, vehicle: Vehicle): void {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle.id);
		this.is_vehicle_lock_interacting[index] = true;

		this.lock_commands_service.create(station.id, vehicle.id).subscribe({
			next: response => {
				response.body.json().then(response => {
					const command: LockCommand = response as LockCommand;
					this.readLockCommand(station, command.id, vehicle.id);
				});
			},
			error: error => {
				this.is_vehicle_lock_interacting[index] = false;

				const converted_error = this.error_service.convertError(error);

				if (converted_error.code === ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.dialog_service.openInfoDialog(
						ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
					);
				}

				if (converted_error.code != ADAPTER_CRED_NOT_FOUND_ERROR_CODE) {
					this.error_service.processError(converted_error);
				}
			},
		});
	}

	readUnlockCommand(station: Station, command_id: string, vehicle_id: string) {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle_id);
		this.is_vehicle_unlock_interacting[index] = true;

		this.unlock_commands_service
			.read(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						const command: UnlockCommand = response as UnlockCommand;

						if (
							command.status === UnlockCommandStatus.PENDING &&
							this.unlock_commands_service.current_read_index <=
								this.unlock_commands_service.max_read_retry
						) {
							this.unlock_commands_service.current_read_index++;
							setTimeout(() => {
								this.readUnlockCommand(station, command_id, vehicle_id);
							}, 5000);
						}

						if (command.status == UnlockCommandStatus.SUCCEEDED) {
							this.unlock_commands_service.current_read_index = 0;
							this.is_vehicle_unlock_interacting[index] = false;
							this.refresh.emit();
						}

						if (command.status == UnlockCommandStatus.FAILED) {
							this.unlock_commands_service.current_read_index = 0;

							const command_error = command.error.code
								? command.error.code
								: command.error;
							this.manageCommandError(command_error as string);

							this.is_vehicle_unlock_interacting[index] = false;
						}
					});
				},
				error: () => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_vehicle_unlock_interacting[index] = false;
				},
			});
	}

	readLockCommand(station: Station, command_id: string, vehicle_id: string) {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle_id);
		this.is_vehicle_lock_interacting[index] = true;

		this.lock_commands_service
			.read(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						const command: LockCommand = response as LockCommand;

						if (
							command.status === LockCommandStatus.PENDING &&
							this.lock_commands_service.current_read_index <=
								this.lock_commands_service.max_read_retry
						) {
							this.lock_commands_service.current_read_index++;

							setTimeout(() => {
								this.readLockCommand(station, command_id, vehicle_id);
							}, 5000);
						}

						if (command.status == LockCommandStatus.SUCCEEDED) {
							this.lock_commands_service.current_read_index = 0;
							this.refresh.emit();
							this.is_vehicle_lock_interacting[index] = false;
						}

						if (command.status == LockCommandStatus.FAILED) {
							this.lock_commands_service.current_read_index = 0;

							const command_error = command.error.code
								? command.error.code
								: command.error;
							this.manageCommandError(command_error as string);

							this.is_vehicle_lock_interacting[index] = false;
						}
					});
				},
				error: () => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_vehicle_lock_interacting[index] = false;
				},
			});
	}

	setStation(station: Station, vehicles: Vehicle[]): void {
		this.vehicles = vehicles;
		this.is_vehicle_unlock_interacting = Array(vehicles.length).fill(false);
		this.is_vehicle_lock_interacting = Array(vehicles.length).fill(false);
	}

	manageCommandError(command_error: string): void {
		switch (command_error) {
			case ADAPTER_FAILED_ERROR_CODE:
				this.dialog_service.openDialog(SharedInteractionErrorDialogComponent, {
					message: ADAPTER_FAILED_ERROR_MESSAGE,
				});
				break;
			case ADAPTER_OFFLINE_ERROR_CODE:
				this.dialog_service.openDialog(SharedInteractionErrorDialogComponent, {
					message: ADAPTER_OFFLINE_ERROR_MESSAGE,
				});
				break;
			default:
				this.dialog_service.openDialog(SharedInteractionErrorDialogComponent, {
					message: ADAPTER_GENERIC_ERROR_MESSAGE,
				});
				break;
		}
	}

	isVehicleUnlockInteracting(vehicle: Vehicle): boolean {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle.id);
		if (index != -1) {
			return this.is_vehicle_unlock_interacting[index];
		}
		return false;
	}

	isVehicleLockInteracting(vehicle: Vehicle): boolean {
		const index: number = this.vehicles.map(v => v.id).indexOf(vehicle.id);
		if (index != -1) {
			return this.is_vehicle_lock_interacting[index];
		}
		return false;
	}

	getVehicleStatusClass(vehicle: Vehicle) {
		return {
			'lk-vehicle-green-status':
				vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}

	getRootClass() {
		return {
			'lk-vehicle-list-horiz-root-layout': this.horiz_mode,
			'': !this.horiz_mode,
		};
	}
}
