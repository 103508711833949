import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';

@Component({
	selector: 'app-hidden-stations-header-form',
	templateUrl: './hidden-stations-header-form.component.html',
	styleUrl: './hidden-stations-header-form.component.scss',
})
export class HiddenStationsHeaderFormComponent {
	@Output() search_change: EventEmitter<string> = new EventEmitter<string>();
	@Output() alias_search_change: EventEmitter<string> =
		new EventEmitter<string>();

	form_group: FormGroup;

	constructor(private form_service: FormService) {
		this.form_group = this.form_service.hidden_stations_search_form_group;
	}
}
