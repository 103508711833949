import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { Station } from 'src/app/core/station.service';
import {
	NotificationName,
	Notification,
	NotificationType,
	NotificationService,
} from 'src/app/core/notification.service';
import {
	ERROR_DESCRIPTION_500,
	SUPPORTED_LANGUAGES,
} from 'src/app/shared/constants';
import {
	BOOKING_COMPLETE_EMAIL_LABEL,
	BOOKING_COMPLETE_SMS_LABEL,
	BOOKING_VEHICLE_EDITED_EMAIL_LABEL,
	BOOKING_VEHICLE_EDITED_SMS_LABEL,
	END_RENTAL_EMAIL_LABEL,
	END_RENTAL_SMS_LABEL,
	GENERAL_ERROR_MESSAGE,
	NOTIF_ITEM_INFO_MESSAGE,
	START_RENTAL_EMAIL_LABEL,
	START_RENTAL_SMS_LABEL,
} from 'src/app/shared/translations';
import { DialogService } from 'src/app/core/dialog.service';
import { ErrorService } from 'src/app/core/error.service';

@Component({
	selector: 'app-stations-notif-templates-item-add-dialog',
	templateUrl: './stations-notif-templates-item-add-dialog.component.html',
	styleUrls: ['./stations-notif-templates-item-add-dialog.component.scss'],
})
export class StationsNotifTemplatesItemAddDialogComponent implements OnInit {
	station: Station;
	template_name: string;
	template_type: NotificationType;
	sms_type: NotificationType = NotificationType.SMS;
	email_type: NotificationType = NotificationType.EMAIL;
	is_create_running: boolean = false;
	template_add_item_form_group: FormGroup =
		this.form_service.template_add_item_form_group;
	params_list: string[] = [
		'unlock link',
		'lock link',
		'direct_link',
		'vehicle plate',
		'vehicle brand',
		'vehicle model',
		'user first name',
		'user phone',
		'booking start date',
		'booking end date',
		'station email',
		'station phone',
	];

	template?: Notification;

	constructor(
		private templates_service: NotificationService,
		private form_service: FormService,
		private dialog_ref: MatDialogRef<StationsNotifTemplatesItemAddDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data: any,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private error_service: ErrorService,
	) {
		this.station = data.station;
		this.template_name = data.template_name;
		this.template_type = data.template_type;
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.template_add_item_form_group.reset();
		this.template = new Notification();

		if (this.template_name == NotificationName.BOOKING_START) {
			const index_of_lock_link = this.params_list.indexOf('lock link');
			if (index_of_lock_link != -1)
				this.params_list.splice(index_of_lock_link, 1);
		}

		if (this.template_name == NotificationName.BOOKING_END) {
			const index_of_unlock_link = this.params_list.indexOf('unlock link');
			if (index_of_unlock_link != -1)
				this.params_list.splice(index_of_unlock_link, 1);
		}
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		if (this.isFormValid() && this.template && !this.template?.content) {
			const content = this.template_add_item_form_group.get('text')?.value;
			//const encoded_content = `data:text/plain;base64,${window.btoa(content)}`;
			/*const encoded_content = `data:text/plain;base64,${window.btoa(
				encodeURIComponent(content),
			)}`;*/
			const encoded_content = `data:text/plain;base64,${window.btoa(
				unescape(encodeURIComponent(content)),
			)}`;

			this.template.name = this.template_name;
			this.template.type = this.template_type;
			this.template.lang = this.station.settings?.default_lang;
			this.template.content = encoded_content;

			this.createTemplate();
		}
	}

	onUploadClick(event: any): void {
		const file: File = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = _event => {
			if (this.template) {
				this.template.name = this.template_name;
				this.template.type = this.template_type;
				this.template.lang = this.station.settings?.default_lang;
				this.template.content = reader.result?.toString();
				this.createTemplate();
			}
		};
	}

	onParameterSelection(event: any): void {
		const current_value: string =
			this.template_add_item_form_group.get('text').value;
		const new_value: string = `${
			current_value ? current_value + ' ' : ''
		}{{{${event.value.toString().replaceAll(' ', '_')}}}}`;
		this.template_add_item_form_group.get('text').setValue(new_value);
		this.template_add_item_form_group.get('current_parameter').reset();
	}

	onInfoClick(): void {
		this.dialog_service.openInfoDialog(NOTIF_ITEM_INFO_MESSAGE);
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.template_add_item_form_group);
	}

	createTemplate(): void {
		if (this.station.id && this.template) {
			this.is_create_running = true;
			this.templates_service.create(this.station.id, this.template).subscribe({
				next: () => {
					this.dialog_ref.close({ success: true });
					this.is_create_running = false;
				},
				error: error => {
					const converted_error = this.error_service.convertError(error);
					this.dialog_ref.close({
						error_message: converted_error.message,
					});
				},
			});
		}
	}

	getTemplateLabel(): string {
		return this.getNotificationLabel(
			this.template_name as NotificationName,
			this.template_type as NotificationType,
		);
	}

	getNotificationLabel(name: NotificationName, type: NotificationType): string {
		switch (name) {
			case NotificationName.BOOKING_START:
				return type == NotificationType.SMS
					? START_RENTAL_SMS_LABEL
					: START_RENTAL_EMAIL_LABEL;
			case NotificationName.BOOKING_END:
				return type == NotificationType.SMS
					? END_RENTAL_SMS_LABEL
					: END_RENTAL_EMAIL_LABEL;
			case NotificationName.BOOKING_VEHICLE_CHANGED:
				return type == NotificationType.SMS
					? BOOKING_VEHICLE_EDITED_SMS_LABEL
					: BOOKING_VEHICLE_EDITED_EMAIL_LABEL;
			case NotificationName.BOOKING_COMPLETE:
				return type == NotificationType.SMS
					? BOOKING_COMPLETE_SMS_LABEL
					: BOOKING_COMPLETE_EMAIL_LABEL;
			case NotificationName.QUICK_LINK:
				return 'Quick link SMS';
			default:
				return '';
		}
	}

	getStationFlag(): string {
		switch (this.station?.settings?.default_lang) {
			case SUPPORTED_LANGUAGES[0]:
				return '../../../assets/imgs/lk-flags/IT.svg';
			case SUPPORTED_LANGUAGES[1]:
				return '../../../assets/imgs/lk-flags/GB.svg';
			case SUPPORTED_LANGUAGES[2]:
				return '../../../assets/imgs/lk-flags/ES.svg';
			case SUPPORTED_LANGUAGES[3]:
				return '../../../assets/imgs/lk-flags/FR.svg';
			default:
				return '';
		}
	}

	getDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': this.is_create_running || !this.isFormValid(),
			'lk-main-button': !this.is_create_running && this.isFormValid(),
		};
	}
}
