<mat-expansion-panel
	style="box-shadow: none"
	[expanded]="is_expanded">
	<mat-expansion-panel-header>
		<app-station-settings-templates-item-header
			class="lk-width__full"
			[template]="template"
			[is_default_selected]="
				is_default_selected
			"></app-station-settings-templates-item-header>
	</mat-expansion-panel-header>

	<div class="lk-full-width lk-spaced-row lk-wrapped-flex lk-bottom-margin">
		<app-station-settings-templates-item-container
			class="lk-half-margin-top lk-half-width"
			[template_type]="template?.type || sms_type"
			[is_selected]="is_default_selected"
			[pill_text]="'default template'"
			(container_click)="onTemplateClick(true)"
			(default_click)="onDefaultClick()">
		</app-station-settings-templates-item-container>

		<app-station-settings-templates-item-container
			*ngIf="template?.custom"
			class="lk-half-margin-top lk-half-width"
			[template_type]="template?.type || sms_type"
			[is_default]="false"
			[is_selected]="!is_default_selected"
			[color]="'lk-accent-color'"
			[pill_background]="'lk-accent-background'"
			[pill_color]="'lk-primary-color'"
			[pill_text]="'custom template'"
			(container_click)="onTemplateClick(false)"
			(custom_update_click)="onCustomUpdateClick()"
			(custom_upload_click)="onCustomUploadClick($event)"
			(custom_delete_click)="onCustomDeleteClick()">
		</app-station-settings-templates-item-container>

		<button
			*ngIf="!template?.custom"
			(click)="onAddTemplateClick()"
			id="lk-stations-notif-tenplates-item-add-button"
			class="lk-half-margin-top lk-half-width lk-primary-medium-button lk-border-radius"
			mat-button>
			<mat-icon
				matPrefix
				class="lk-accent-color">
				add
			</mat-icon>
			<span class="lk-accent-color">
				{{ 'STATION_BUILDER.ADD_TEMPLATE_CTA' | translate }}
			</span>
		</button>
	</div>
</mat-expansion-panel>
