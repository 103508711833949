<div class="lk-row">
	<app-toolbar-station-selector
		[station]="station"
		[logo]="logo"
		(station_change)="station_change.emit($event)"
		(station_build)="station_build.emit()"></app-toolbar-station-selector>

	<app-toolbar-station-navigation
		[current_nav_item]="current_nav_item"
		[nav_items]="nav_items"></app-toolbar-station-navigation>
</div>
