<form
	class="lk-width__full lk-row__space lk-wrap lk-margin__x12-bottom"
	[formGroup]="form_group">
	<mat-form-field
		appearance="fill"
		class="lk-vehicle-details-commands-form__field lk-form-field lk-margin__x12-bottom">
		<mat-label> {{ 'BOOKING.START_COLUMN_LABEL' | translate }} </mat-label>
		<input
			matInput
			type="text"
			formControlName="created_from"
			[matDatepicker]="startPicker" />
		<mat-datepicker-toggle
			matSuffix
			[for]="startPicker"></mat-datepicker-toggle>
		<mat-datepicker #startPicker></mat-datepicker>
	</mat-form-field>

	<mat-form-field
		appearance="fill"
		color="accent"
		class="lk-vehicle-details-commands-form__field lk-form-field lk-margin__x12-bottom">
		<mat-label> {{ 'BOOKING.END_COLUMN_LABEL' | translate }} </mat-label>
		<input
			matInput
			type="text"
			formControlName="created_to"
			[matDatepicker]="endPicker" />
		<mat-datepicker-toggle
			matSuffix
			[for]="endPicker"></mat-datepicker-toggle>
		<mat-datepicker #endPicker></mat-datepicker>
	</mat-form-field>

	<div class="lk-width__full lk-row__space lk-wrap">
		<app-button
			class="lk-vehicle-details-commands-form__field lk-margin__x12-bottom"
			[button_class]="'lk-button__secondary-border lk-width__full'"
			[spinner_class]="'lk-button__spinner-accent'"
			[cta]="'VEHICLE_DETAILS.COMMANDS_FILTERS_RESET_CTA' | translate"
			(button_click)="onReset()"></app-button>

		<app-button
			class="lk-vehicle-details-commands-form__field lk-margin__x12-bottom"
			[button_class]="'lk-button__secondary lk-width__full'"
			[spinner_class]="'lk-button__spinner-white'"
			[cta]="'VEHICLE_DETAILS.COMMANDS_FILTERS_CTA' | translate"
			(button_click)="onApply()"></app-button>
	</div>
</form>
