import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from 'src/app/core/dialog.service';
import { ErrorService, LinkeyError } from 'src/app/core/error.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { Station, StationService } from 'src/app/core/station.service';
import {
	DEFAULT_STATION_LOGO,
	STATION_NOT_FOUND_ERROR_CODE,
} from 'src/app/shared/constants';

@Component({
	selector: 'app-toolbar-station-selector',
	templateUrl: './toolbar-station-selector.component.html',
	styleUrl: './toolbar-station-selector.component.scss',
})
export class ToolbarStationSelectorComponent {
	@Input() station?: Station;
	@Input() logo?: string;

	@Output() station_change: EventEmitter<Station> = new EventEmitter<Station>();
	@Output() station_build: EventEmitter<void> = new EventEmitter<void>();

	stations?: Station[];
	is_stations_list_running = false;
	default_logo = DEFAULT_STATION_LOGO;

	constructor(
		private navigation_service: NavigationService,
		private station_service: StationService,
		private dialog_service: DialogService,
		private error_service: ErrorService,
	) {}

	ngOnInit(): void {
		this.listStations();
	}

	onHomeClick(): void {
		this.navigation_service.toStation();
	}

	listStations(): void {
		const page: number = 1;
		const per_page: number = 10;
		this.is_stations_list_running = true;
		this.station_service.list(page, per_page).subscribe({
			next: response => {
				response.body.json().then(response => {
					this.stations = response.items;
					this.is_stations_list_running = false;
				});
			},
			error: error => {
				const lk_error: LinkeyError = this.error_service.convertError(error);
				if (lk_error.code === STATION_NOT_FOUND_ERROR_CODE) {
					this.stations = [];
				} else {
					this.dialog_service.openInfoDialog(lk_error.message);
				}
				this.is_stations_list_running = false;
			},
		});
	}
}
