<div>
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-vehicle-direct-link__content-layout lk-horiz-padding">
		<h3 class="lk-accent-color">
			{{ 'VEHICLE.DIRECT_LINK_DIALOG_TITLE' | translate }}
		</h3>

		<p class="lk-margin__x12-top">
			{{ 'VEHICLE.DIRECT_LINK_INTERVAL_CTA' | translate }}
		</p>

		<form
			class="lk-width__full lk-row__space lk-wrap lk-margin__x24-vert"
			[formGroup]="direct_link_time_form_group">
			<mat-form-field
				appearance="fill"
				class="lk-width__half-x4 lk-width__full-md lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'BOOKING.START_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="start_date"
					required
					[max]="direct_link_time_form_group.get('end_date')?.value"
					[matDatepicker]="startPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="startPicker"></mat-datepicker-toggle>
				<mat-datepicker #startPicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-quarter-width lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'BOOKING.START_HOUR_LABEL' | translate }} </mat-label>
				<mat-select
					formControlName="start_hour"
					required>
					<mat-option
						*ngFor="let hour of hours"
						[value]="hour">
						{{ hour }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-quarter-width lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'BOOKING.START_MINUTE_LABEL' | translate }} </mat-label>
				<mat-select
					formControlName="start_minute"
					required>
					<mat-option
						*ngFor="let minute of minutes"
						[value]="minute">
						{{ minute }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-width__half-x4 lk-width__full-md lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'BOOKING.END_COLUMN_LABEL' | translate }} </mat-label>
				<input
					type="text"
					matInput
					formControlName="end_date"
					required
					[min]="direct_link_time_form_group.get('start_date')?.value"
					[matDatepicker]="endPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="endPicker"></mat-datepicker-toggle>
				<mat-datepicker #endPicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-quarter-width lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'BOOKING.END_HOUR_LABEL' | translate }} </mat-label>
				<mat-select
					formControlName="end_hour"
					required>
					<mat-option
						*ngFor="let hour of hours"
						[value]="hour">
						{{ hour }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-quarter-width lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'BOOKING.END_MINUTE_LABEL' | translate }} </mat-label>
				<mat-select
					formControlName="end_minute"
					required>
					<mat-option
						*ngFor="let minute of minutes"
						[value]="minute">
						{{ minute }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				color="accent"
				class="lk-width__full lk-form-field lk-margin__x12-bottom">
				<mat-label> Timezone </mat-label>
				<mat-select
					formControlName="timezone"
					required>
					<mat-option
						*ngFor="let timezone of timezones"
						[value]="timezone">
						({{ getTimezoneOffset(timezone) }})
						{{ timezone }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				floatLabel="always"
				class="lk-third-width lk-form-field lk-margin__x12-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_PREFIX_LABEL' | translate | titlecase }}
				</mat-label>

				<img
					*ngIf="current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-margin__x12-left"
					[src]="current_country_prefix.flag" />

				<img
					*ngIf="!current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-margin__x12-left"
					[src]="unknown_flag_src" />

				<input
					type="text"
					matInput
					formControlName="phone_prefix"
					[matAutocomplete]="autocountry" />

				<mat-autocomplete
					autoActiveFirstOption
					#autocountry="matAutocomplete"
					(optionSelected)="onCountryPrefixSelect()">
					<mat-option
						*ngFor="let country_prefix of filtered_options | async"
						[value]="country_prefix.code">
						<div class="lk-row">
							<img
								style="min-width: 32px; height: 24px"
								class="lk-margin__x12-right"
								[src]="country_prefix.flag" />
							<div class="lk-column">
								<p
									style="max-width: calc(100% - 6px); line-height: 18px"
									class="lk-text__10 lk-primary-medium-color lk-text__ellipsized">
									{{ country_prefix.name }}
								</p>
								<p
									style="line-height: 18px"
									class="lk-text__ellipsized">
									{{ country_prefix.prefix }}
								</p>
							</div>
						</div>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-three-third-width lk-form-field lk-margin__x12-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					matInput
					type="tel"
					formControlName="phone" />
			</mat-form-field>

			<div class="lk-margin__x12-top">
				<p>{{ 'VEHICLE.DIRECT_LINK_TYPE_CTA' | translate }}</p>
				<mat-radio-group
					aria-label="Select an option"
					class="lk-column"
					formControlName="type">
					<mat-radio-button value="1">
						{{ 'VEHICLE.DIRECT_LINK_TYPE_OPTION_1_CTA' | translate }}
					</mat-radio-button>

					<mat-radio-button
						class="lk-margin__x12-bottom"
						value="2">
						{{ 'VEHICLE.DIRECT_LINK_TYPE_OPTION_2_CTA' | translate }}
					</mat-radio-button>
				</mat-radio-group>
			</div>

			<app-button
				class="lk-width__full lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.CONFIRM_CTA' | translate"
				[is_disabled]="!isFormValid() || is_send_running"
				[is_spinner_visible]="is_send_running"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>