import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getTimezoneOffset } from 'date-fns-tz';
import { Observable, map, startWith } from 'rxjs';
import { Address, Phone } from 'src/app/core/core.module';
import {
	CountriesService,
	CountryPrefix,
} from 'src/app/core/countries.service';
import { ErrorService } from 'src/app/core/error.service';
import { FormService } from 'src/app/core/form.service';
import { Station, StationService } from 'src/app/core/station.service';
import {
	DATE_FORMATS,
	DATE_FORMAT_LABELS,
	ERROR_DESCRIPTION_500,
	TIMEZONES,
	UNKNOWN_FLAG,
} from 'src/app/shared/constants';
import {
	DEFAULT_CHECKLIST,
	GENERAL_ERROR_MESSAGE,
} from 'src/app/shared/translations';

@Component({
	selector: 'app-station-builder-dialog',
	templateUrl: './station-builder-dialog.component.html',
	styleUrls: ['./station-builder-dialog.component.scss'],
})
export class StationBuilderDialogComponent implements OnInit {
	station: Station;
	form_group: FormGroup;
	address: any;
	is_station_loading: boolean = false;
	update_mode: boolean = false;
	timezones: string[] = TIMEZONES;
	country_prefixes: CountryPrefix[];
	current_country_prefix: CountryPrefix;
	unknown_flag_src: string = UNKNOWN_FLAG;
	filtered_options: Observable<CountryPrefix[]>;
	date_formats: { format: string; label: string }[] = [
		{
			format: DATE_FORMATS[0],
			label: DATE_FORMAT_LABELS[0],
		},
		{
			format: DATE_FORMATS[1],
			label: DATE_FORMAT_LABELS[1],
		},
		{
			format: DATE_FORMATS[2],
			label: DATE_FORMAT_LABELS[2],
		},
	];

	constructor(
		private dialog_ref: MatDialogRef<StationBuilderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private station_service: StationService,
		private form_service: FormService,
		private countries: CountriesService,
		private error_service: ErrorService,
	) {
		this.form_group = this.form_service.stationBuilderBasicsFormGroup;
		this.update_mode = data.update_mode;
		this.station = data.station;
	}

	ngOnInit(): void {
		this.form_group.reset();

		this.country_prefixes = this.countries.getCountryPrefixList();
		this.filtered_options = this.form_group
			.get('phone_prefix')
			.valueChanges.pipe(
				startWith(''),
				map(value => this.filterPrefix(value || '')),
			);

		const timezone: string =
			Intl.DateTimeFormat().resolvedOptions().timeZone + ' (local time)';
		this.timezones = TIMEZONES;
		this.timezones = [timezone, ...this.timezones];
		this.form_group.get('ext_timezone').setValue(this.timezones[0]);

		if (this.update_mode && this.station) {
			this.setStation(this.station);
		}
	}

	onCountryPrefixSelect(): void {
		const code: string = this.form_group.get('phone_prefix').value;
		this.current_country_prefix = this.getCountryPrefixFromCode(code);
		this.form_group
			.get('phone_prefix')
			.setValue(this.current_country_prefix.prefix);
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onAddressChange(address: any) {
		this.address = address;
	}

	onNextClick(): void {
		if (this.form_service.validateForm(this.form_group)) {
			this.buildStation();
			this.createStation();
		}
	}

	onUpdateClick(): void {
		if (this.form_service.validateForm(this.form_group)) {
			this.buildStation();
			this.updateStation();
		}
	}

	buildStation(): void {
		if (!this.station) {
			this.station = new Station();
		}

		this.station.name = this.form_group.get('name').value;
		this.station.email = this.form_group.get('email').value;
		this.station.phone = new Phone();
		this.station.phone.prefix = this.form_group
			.get('phone_prefix')
			.value.replace('+', '');
		this.station.phone.number = this.form_group.get('phone').value;

		if (this.address) {
			this.buildStationAddress();
		}

		this.station.ext_email = this.form_group.get('ext_email').value;
		this.station.ext_time_format = this.form_group.get('ext_time_format').value;
		this.station.ext_timezone = this.buildStationTimezone();
	}

	buildStationAddress(): void {
		this.station.address = new Address();
		this.station.address.formatted_address = this.address.formatted_address;
		this.station.address.lat_lng = [
			this.address.geometry.location.lat(),
			this.address.geometry.location.lng(),
		];

		const localityComponent = this.address.address_components.filter(ac => {
			return ac.types.includes('locality');
		})[0];
		if (localityComponent && localityComponent.length > 0) {
			this.station.address.city = localityComponent.long_name;
		}

		const regionComponent = this.address.address_components.filter(ac => {
			return ac.types.includes('administrative_area_level_1');
		})[0];
		if (regionComponent) {
			this.station.address.region = regionComponent.long_name;
		}

		const countryComponent = this.address.address_components.filter(ac => {
			return ac.types.includes('country');
		})[0];
		if (countryComponent) {
			this.station.address.country = countryComponent.long_name;
		}
	}

	buildStationTimezone(): string {
		let timezone: string = undefined;
		if (
			this.form_group.get('ext_timezone') &&
			this.form_group.get('ext_timezone').value
		) {
			const timezone_input = this.form_group.get('ext_timezone').value;
			timezone =
				timezone_input == 'GMT'
					? undefined
					: timezone_input.toString().replace(' (local time)', '');
		}
		return timezone;
	}

	setStation(station: Station): void {
		this.form_group.get('name').setValue(station.name);
		this.form_group.get('email').setValue(station.email);
		this.form_group.get('phone_prefix').setValue(`+${station.phone?.prefix}`);
		this.form_group.get('phone').setValue(station.phone?.number);
		this.form_group.get('address').setValue(station.address?.formatted_address);
		this.form_group.get('ext_email').setValue(station.ext_email);
		this.form_group.get('ext_timezone').setValue(station.ext_timezone);
		this.form_group.get('ext_time_format').setValue(station.ext_time_format);
	}

	createStation(): void {
		this.is_station_loading = true;
		this.station_service.create(this.station).subscribe({
			next: response => {
				//this.is_station_loading = false;
				//const station: Station = response.data as Station;
				//this.dialog_ref.close({ success: true, station: station });
				this.station = response.data as Station;
				this.station.settings.checklist = { messages: DEFAULT_CHECKLIST };
				this.updateStation();
			},
			error: error => {
				this.is_station_loading = false;
				this.error_service.wrapError(error);
			},
		});
	}

	updateStation(): void {
		this.is_station_loading = true;
		this.station_service.update(this.station).subscribe({
			next: response => {
				this.is_station_loading = false;
				const station: Station = response.data as Station;
				this.dialog_ref.close({ success: true, station: station });
			},
			error: error => {
				this.is_station_loading = false;
				const converted_error = this.error_service.convertError(error);
				this.dialog_ref.close({
					error_message: converted_error.message,
				});
			},
		});
	}

	getDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': this.is_station_loading,
			'lk-main-button': !this.is_station_loading,
		};
	}

	getTimezoneOffset(timezone: string) {
		return (
			'GMT' +
			(getTimezoneOffset(timezone.replace(' (local time)', '')) / 3600000 >= 0
				? '+'
				: '') +
			getTimezoneOffset(timezone.replace(' (local time)', '')) / 3600000
		);
	}

	filterPrefix(value: string) {
		const filter_value = value.toLowerCase();
		return this.country_prefixes.filter(option => {
			return option.prefix.toLowerCase().includes(filter_value);
		});
	}

	getCountryPrefixFromCode(code: string): CountryPrefix {
		return this.country_prefixes.filter(cp => {
			return cp.code == code;
		})[0];
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.form_group);
	}
}
