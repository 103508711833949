<mat-expansion-panel class="lk-padding__x12-vert-md">
	<mat-expansion-panel-header>
		<mat-panel-title class="lk-row__space">
			<h3 *ngIf="!checklist?.messages?.length">
				No checklist message added yet
			</h3>

			<h3 *ngIf="checklist?.messages?.length">
				<span class="lk-accent-color lk-text__24">
					<b> {{ checklist.messages.length }} </b>
				</span>

				message{{ checklist.messages.length > 1 ? 's' : '' }} added
			</h3>

			<span
				(click)="clear.emit(); $event.stopPropagation()"
				class="lk-cursor__pointer lk-text__right lk-warn-color">
				<u>clear checklist</u>
			</span>
		</mat-panel-title>
	</mat-expansion-panel-header>

	<div class="lk-padding__x12 lk-text__center">
		<button
			mat-button
			(click)="onLangItemClick('en')"
			[ngClass]="getCurrentLangClass('en')">
			EN
		</button>

		<button
			mat-button
			(click)="onLangItemClick('es')"
			[ngClass]="getCurrentLangClass('es')">
			ES
		</button>

		<button
			mat-button
			(click)="onLangItemClick('it')"
			[ngClass]="getCurrentLangClass('it')">
			IT
		</button>
	</div>

	<div class="lk-padding__x24-after-md">
		<div
			*ngFor="let message of getCurrentLangMessages()"
			[innerHTML]="escapeMessage(message)"></div>
	</div>
</mat-expansion-panel>
