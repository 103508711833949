import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import {
	Station,
	StationService,
	LockSettings,
} from 'src/app/core/station.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-station-settings-lock',
	templateUrl: './station-settings-lock.component.html',
	styleUrl: './station-settings-lock.component.scss',
})
export class StationSettingsLockComponent {
	@Input() station?: Station;
	is_lockable_after_vehicle_unlock: boolean;
	is_request_running: boolean = false;
	lock_reminder_form_group: FormGroup;
	lock_link_form_group: FormGroup;
	lock_before_form_group: FormGroup;
	lock_after_form_group: FormGroup;
	lock_link_translate_param: { hours: string; minutes: string; time: string };
	lock_before_translate_param: { hours: string; minutes: string; time: string };
	lock_after_translate_param: { hours: string; minutes: string; time: string };

	constructor(
		private form_service: FormService,
		private station_service: StationService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private snackbar: MatSnackBar,
		private translate: TranslateService,
	) {
		this.dialog_service.dialog = this.dialog;
		this.lock_reminder_form_group =
			this.form_service.stationSettingsLockReminderFormGroup;
		this.lock_link_form_group =
			this.form_service.stationSettingsLockLinkFormGroup;
		this.lock_before_form_group =
			this.form_service.stationSettingsLockBeforeFormGroup;
		this.lock_after_form_group =
			this.form_service.stationSettingsLockAfterFormGroup;
	}

	ngOnInit(): void {
		this.initFormFields();
		this.setTranslationParams();
	}

	onReset(station: Station): void {
		this.station = station;
		this.initFormFields();
		this.setTranslationParams();
	}

	onLockableAfterVehicleUnlockChange(): void {
		if (!this.is_lockable_after_vehicle_unlock) {
			this.lock_reminder_form_group.get('time').disable();
			this.lock_link_form_group.get('time').enable();
			this.lock_before_form_group.get('time').enable();
		} else {
			this.lock_reminder_form_group.get('time').enable();
			this.lock_link_form_group.get('time').disable();
			this.lock_before_form_group.get('time').disable();
			this.lock_link_form_group.get('time').setValue('00:00');
			this.lock_before_form_group.get('time').setValue('00:00');
		}
	}

	onInputChange(event: any, formGroup: FormGroup): void {
		if (!formGroup.get('time').value.includes(':')) {
			event.target.value = event.target.value + ':';
		}
		const target: string[] = event.target.value.split(':');
		if (target && target[0].length > 2) {
			event.target.value = target[0].slice(0, -1) + ':' + target[1];
		}
		if (target && target[1].length > 2) {
			event.target.value = target[0] + ':' + target[1].slice(0, -1);
		}
		this.setTranslationParams();
	}

	onSaveClick(): void {
		let hours_and_minutes: number[];
		const lock_settings: LockSettings = new LockSettings();
		lock_settings.is_lockable_after_vehicle_unlock =
			this.is_lockable_after_vehicle_unlock;
		hours_and_minutes = this.parseTimeFormGroup(
			this.form_service.stationSettingsLockReminderFormGroup,
		);
		hours_and_minutes = this.parseTimeFormGroup(
			this.form_service.stationSettingsLockLinkFormGroup,
		);
		lock_settings.time_to_lock_link_before_booking_end =
			this.getMillisFromHoursAndMin(hours_and_minutes[0], hours_and_minutes[1]);
		hours_and_minutes = this.parseTimeFormGroup(
			this.form_service.stationSettingsLockBeforeFormGroup,
		);
		lock_settings.time_to_lock_availability_before_booking_end =
			this.getMillisFromHoursAndMin(hours_and_minutes[0], hours_and_minutes[1]);
		hours_and_minutes = this.parseTimeFormGroup(
			this.form_service.stationSettingsLockAfterFormGroup,
		);
		lock_settings.time_to_lock_availability_after_booking_end =
			this.getMillisFromHoursAndMin(hours_and_minutes[0], hours_and_minutes[1]);
		this.station.settings.lock_settings = lock_settings;
		this.updateStation(this.station);
	}

	updateStation(station: Station): void {
		this.is_request_running = true;
		const station_logo_path = station.logo_path;
		const station_logo_url = station.logo_url;
		this.station_service.update(station).subscribe({
			next: response => {
				const station: Station = response.data;
				station.logo_path = station_logo_path;
				station.logo_url = station_logo_url;
				this.translate
					.get('STATION.UPDATE_COMPLETED_MESSAGE')
					.subscribe(message => {
						this.snackbar.open(message, '', { duration: 3000 });
					});
				localStorage.setItem(LOCAL_STATION_KEY, JSON.stringify(station));
				this.is_request_running = false;
			},
			error: error => {
				this.is_request_running = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	initFormFields(): void {
		// Flags
		this.is_lockable_after_vehicle_unlock = this.station.settings.lock_settings
			? this.station.settings.lock_settings.is_lockable_after_vehicle_unlock
			: false;

		// Lock link
		let hoursAndMinutes = this.getHoursAndMinutesFromMillis(
			this.station.settings.lock_settings
				? this.station.settings.lock_settings
						.time_to_lock_link_before_booking_end
				: 3600000,
		);
		this.lock_link_form_group
			.get('time')
			.setValue(
				hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString(),
			);

		// Before lock
		hoursAndMinutes = this.getHoursAndMinutesFromMillis(
			this.station.settings.lock_settings
				? this.station.settings.lock_settings
						.time_to_lock_availability_before_booking_end
				: 3600000,
		);
		this.lock_before_form_group
			.get('time')
			.setValue(
				hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString(),
			);

		// After lock
		hoursAndMinutes = this.getHoursAndMinutesFromMillis(
			this.station.settings.lock_settings
				? this.station.settings.lock_settings
						.time_to_lock_availability_after_booking_end
				: 3600000,
		);
		this.lock_after_form_group
			.get('time')
			.setValue(
				hoursAndMinutes[0].toString() + ':' + hoursAndMinutes[1].toString(),
			);

		// Block fields
		this.onLockableAfterVehicleUnlockChange();
	}

	setTranslationParams(): void {
		this.lock_link_translate_param = {
			hours: this.parseTimeFormGroup(this.lock_link_form_group)[0].toString(),
			minutes: this.parseTimeFormGroup(this.lock_link_form_group)[1].toString(),
			time: this.getTimeLabel(this.lock_link_form_group),
		};

		this.lock_before_translate_param = {
			hours: this.parseTimeFormGroup(this.lock_before_form_group)[0].toString(),
			minutes: this.parseTimeFormGroup(
				this.lock_before_form_group,
			)[1].toString(),
			time: this.getTimeLabel(this.lock_before_form_group),
		};

		this.lock_after_translate_param = {
			hours: this.parseTimeFormGroup(this.lock_after_form_group)[0].toString(),
			minutes: this.parseTimeFormGroup(
				this.lock_after_form_group,
			)[1].toString(),
			time: this.getTimeLabel(this.lock_after_form_group, true),
		};
	}

	parseTimeFormGroup(formGroup: FormGroup): number[] {
		const time: string = formGroup.get('time').value;
		const columnIndex: number = time.indexOf(':');
		return [
			+time.substring(0, columnIndex),
			+time.substring(columnIndex + 1, time.length),
		];
	}

	getTimeLabel(formGroup: FormGroup, sum?: boolean): string {
		const MILLIS_IN_MIN: number = 60 * 1000;
		const MILLIS_IN_HOUR: number = 60 * 60 * 1000;
		const AFTERNOON_MILLIS: number = 18 * MILLIS_IN_HOUR;
		const hoursAndMins: number[] = this.parseTimeFormGroup(formGroup);
		let hoursAndMinsMillis: number =
			hoursAndMins[0] * MILLIS_IN_HOUR + hoursAndMins[1] * MILLIS_IN_MIN;
		const time: string[] = sum
			? this.getHoursAndMinutesFromMillis(AFTERNOON_MILLIS + hoursAndMinsMillis)
			: this.getHoursAndMinutesFromMillis(
					AFTERNOON_MILLIS - hoursAndMinsMillis,
			  );
		return time[0] + ':' + time[1];
	}

	getHoursAndMinutesFromMillis(millis: number): string[] {
		const minutes = this.correctDigits(Math.floor((millis / (1000 * 60)) % 60)),
			hours = this.correctDigits(Math.floor(millis / (1000 * 60 * 60)));
		return [hours, minutes];
	}

	getMillisFromHoursAndMin(hours: number, minutes: number): number {
		const HOUR_IN_MILLIS: number = 3600000;
		const MINUTE_IN_MILLIS: number = 60000;
		return hours * HOUR_IN_MILLIS + minutes * MINUTE_IN_MILLIS;
	}

	correctDigits(num: number): string {
		if (num.toString().length <= 1) {
			return '0' + num;
		}
		return num.toString();
	}

	getDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': this.is_request_running,
			'lk-main-button': !this.is_request_running,
		};
	}
}
