import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';

@Component({
	selector: 'app-booking-table-controls',
	templateUrl: './booking-table-controls.component.html',
	styleUrl: './booking-table-controls.component.scss',
})
export class BookingTableControlsComponent {
	@Input() update_time: number = new Date().getTime();
	@Input() current_locale?: string;

	@Output() search_change: EventEmitter<void> = new EventEmitter<void>();
	@Output() filter_change: EventEmitter<void> = new EventEmitter<void>();

	form_group: FormGroup;

	constructor(private form_service: FormService) {
		this.form_group = this.form_service.bookingSearchFormGroup;
	}
}
