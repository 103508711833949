<form [formGroup]="form_group">
	<mat-form-field
		appearance="fill"
		class="lk-form-field lk-form-field__short-height">
		<mat-label> Search by name... </mat-label>
		<mat-icon
			matPrefix
			class="lk-primary-medium-color">
			search
		</mat-icon>
		<input
			matInput
			type="text"
			formControlName="name"
			(change)="search_change.emit($event.target.value)" />
	</mat-form-field>

	<mat-form-field
		class="lk-form-field lk-form-field__short-height lk-margin__x12-left"
		appearance="fill">
		<mat-label> Search by alias... </mat-label>
		<mat-icon
			matPrefix
			class="lk-primary-medium-color">
			search
		</mat-icon>
		<input
			matInput
			type="text"
			formControlName="alias"
			(change)="alias_search_change.emit($event.target.value)" />
	</mat-form-field>
</form>
