<div
	id="lk-booking-details-general-root-layout"
	class="lk-border-radius lk-shadow lk-padding lk-primary-background lk-hide-scrollbar lk-full-height"
	style="overflow-y: scroll">
	<div class="lk-full-width lk-spaced-row">
		<h2 class="lk-no-margin lk-subtitle-size">
			{{ 'BOOKING_DETAILS.GENERAL_SECTION_LABEL' | translate }}
		</h2>

		<button
			*ngIf="isUpdateGeneralVisible(booking)"
			(click)="onBookingUpdateClick()"
			mat-icon-button
			class="lk-icon__button-neutral-40"
			matTooltip="{{ 'BOOKING_DETAILS.UPDATE_INFO_CTA' | translate }}">
			<mat-icon class="lk-accent-color"> create </mat-icon>
		</button>
	</div>

	<div class="lk-half-margin-top">
		<div class="lk-double-margin-top">
			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"
					style="font-size: 12px">
					ID
				</p>
				<p
					id="lk-booking-details-general-id"
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text">
					{{
						booking.id.substring(0, 3) +
							booking.id.substring(booking.id.length - 8, booking.id.length)
					}}
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"
					style="font-size: 12px">
					{{ 'BOOKING.EXT_ID_LABEL' | translate }}
				</p>
				<p
					*ngIf="booking && booking.ext_id"
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text">
					{{ booking.ext_id }}
				</p>
				<p
					*ngIf="booking && !booking.ext_id"
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"
					style="font-size: 12px">
					{{ 'BOOKING.USER_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					id="lk-booking-details-general-name"
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text"
					*ngIf="booking.user">
					{{ booking.user.first_name }} {{ booking.user.last_name }}
				</p>
				<p
					class="lk-no-margin"
					*ngIf="!booking.user">
					-
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"
					style="font-size: 12px">
					{{ 'GENERAL.EMAIL_LABEL' | translate | titlecase }}
				</p>
				<div class="lk-row">
					<p
						id="lk-booking-details-general-email"
						class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text"
						*ngIf="booking.user">
						{{ booking.user.email }}
					</p>
					<p
						class="lk-no-margin"
						*ngIf="!booking.user">
						-
					</p>
					<button
						(click)="onUpdateEmailClick()"
						mat-icon-button
						style="margin-left: 4px; margin-right: -12px">
						<mat-icon class="lk-accent-color"> create </mat-icon>
					</button>
				</div>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"
					style="font-size: 12px">
					{{ 'GENERAL.PHONE_LABEL' | translate | titlecase }}
				</p>
				<div class="lk-row">
					<p
						id="lk-booking-details-general-phone"
						class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text"
						*ngIf="booking.user && booking.user.phone">
						+{{ booking.user.phone.prefix }} {{ booking.user.phone.value }}
					</p>
					<p
						class="lk-no-margin"
						*ngIf="!booking.user && !booking.user.phone">
						-
					</p>
					<button
						(click)="onUpdatePhoneClick()"
						mat-icon-button
						style="margin-left: 4px; margin-right: -12px">
						<mat-icon class="lk-accent-color"> create </mat-icon>
					</button>
				</div>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"
					style="font-size: 12px">
					{{ 'BOOKING.START_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					id="lk-booking-details-general-start-date-text"
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text">
					{{ getTimezoneStartTime(booking) | date: 'short':'':current_locale }}
					{{ getTimezoneOffset(booking) }}
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"
					style="font-size: 12px">
					{{ 'BOOKING.END_COLUMN_LABEL' | translate | titlecase }}
				</p>
				<p
					id="lk-booking-details-general-end-date-text"
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text">
					{{ getTimezoneEndTime(booking) | date: 'short':'':current_locale }}
					{{ getTimezoneOffset(booking) }}
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row">
				<p
					class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"
					style="font-size: 12px">
					{{ 'STATION_BUILDER.STEP_NOTIF_TITLE' | translate | titlecase }}
				</p>
				<p
					id="lk-booking-details-general-notification"
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text">
					{{ getNotifType(booking) }}
				</p>
			</div>
			<hr class="lk-list-separator" />

			<div class="lk-spaced-row lk-margin-bottom">
				<p
					class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"
					style="font-size: 12px">
					{{ 'STATION.SETTINGS_NOTIF_LANG_CTA' | translate | titlecase }}
				</p>
				<p
					id="lk-booking-details-general-language"
					class="lk-no-margin lk-half-margin-left lk-right-text lk-ellipsized-text">
					{{ getNotifLang(booking) | translate }}
				</p>
			</div>
		</div>
	</div>
</div>
