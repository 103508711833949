import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, map, startWith } from 'rxjs';
import { Phone } from 'src/app/core/core.module';
import {
	CountriesService,
	CountryPrefix,
} from 'src/app/core/countries.service';
import { ErrorService } from 'src/app/core/error.service';
import { FormService } from 'src/app/core/form.service';
import {
	NotificationName,
	NotificationService,
	NotificationType,
} from 'src/app/core/notification.service';
import { Station } from 'src/app/core/station.service';
import {
	ERROR_DESCRIPTION_500,
	LOCAL_STATION_KEY,
	UNKNOWN_FLAG,
} from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-station-notif-test-dialog',
	templateUrl: './station-notif-test-dialog.component.html',
	styleUrls: ['./station-notif-test-dialog.component.scss'],
})
export class StationNotifTestDialogComponent implements OnInit {
	type: NotificationType;
	name: NotificationName;
	template_id?: string;

	is_test_running: boolean = false;
	test_sms_form_group: FormGroup =
		this.form_service.template_test_sms_form_group;
	test_email_form_group: FormGroup =
		this.form_service.template_test_email_form_group;

	current_country_prefix: CountryPrefix;
	unknown_flag_src: string = UNKNOWN_FLAG;
	country_prefixes: CountryPrefix[];
	filtered_options: Observable<CountryPrefix[]>;
	sms_type: NotificationType = NotificationType.SMS;
	email_type: NotificationType = NotificationType.EMAIL;

	constructor(
		private dialog_ref: MatDialogRef<StationNotifTestDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private form_service: FormService,
		private notification_service: NotificationService,
		private countries: CountriesService,
		private error_service: ErrorService,
	) {
		this.type = data.type;
		this.name = data.name;
		this.template_id = data.template_id;
	}

	ngOnInit(): void {
		this.country_prefixes = this.countries.getCountryPrefixList();
		this.filtered_options = this.test_sms_form_group
			.get('phone_prefix')
			.valueChanges.pipe(
				startWith(''),
				map(value => this.filterPrefix(value || '')),
			);
	}

	onCountryPrefixSelect(): void {
		const code: string = this.test_sms_form_group.get('phone_prefix').value;
		this.current_country_prefix = this.getCountryPrefixFromCode(code);
		this.test_sms_form_group
			.get('phone_prefix')
			.setValue(this.current_country_prefix.prefix);
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		if (this.isValidForm()) {
			if (this.type == NotificationType.SMS) {
				const phone_prefix: string =
					this.test_sms_form_group.get('phone_prefix').value;
				const phone_value: string = this.test_sms_form_group.get('phone').value;
				const phone: Phone = new Phone();
				phone.prefix = phone_prefix.toString().replace('+', '');
				phone.number = phone_value;
				this.testTemplate(phone);
			}

			if (this.type == NotificationType.EMAIL) {
				const email: string = this.test_email_form_group.get('email').value;
				this.testTemplate(undefined, email);
			}
		}
	}

	testTemplate(phone?: Phone, email?: string): void {
		const station: Station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;
		if (station?.id && this.type && this.name) {
			this.is_test_running = true;
			this.notification_service
				.test(station.id, this.name, this.type, this.template_id, phone, email)
				.subscribe({
					next: () => {
						this.dialog_ref.close({ success: true });
						this.is_test_running = false;
					},
					error: error => {
						const converted_error = this.error_service.convertError(error);
						this.dialog_ref.close({
							error_message: converted_error.message,
						});
					},
				});
		}
	}

	isValidForm(): boolean {
		if (this.type == NotificationType.SMS) {
			return this.form_service.validateForm(this.test_sms_form_group);
		}

		if (this.type == NotificationType.EMAIL) {
			return this.form_service.validateForm(this.test_email_form_group);
		}

		return false;
	}

	getCountryPrefixFromCode(code: string): CountryPrefix {
		return this.country_prefixes.filter(cp => {
			return cp.code == code;
		})[0];
	}

	filterPrefix(value: string) {
		const filter_value = value.toLowerCase();
		return this.country_prefixes.filter(option => {
			return option.prefix.toLowerCase().includes(filter_value);
		});
	}

	getDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': this.is_test_running || !this.isValidForm(),
			'lk-main-button': !this.is_test_running && this.isValidForm(),
		};
	}
}
