import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getTimezoneOffset } from 'date-fns-tz';
import { BehaviorSubject } from 'rxjs';
import { Booking, BookingService } from 'src/app/core/booking.service';
import { DialogService } from 'src/app/core/dialog.service';
import { KeysService } from 'src/app/core/keys.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { Station } from 'src/app/core/station.service';
import {
	EN_LOCALE,
	IT_LOCALE,
	PROD_STAGE,
	LOCAL_STATION_KEY,
	DATE_FORMATS,
} from 'src/app/shared/constants';
import {
	SEND_LOCK_LINK_FAILURE_MESSAGE,
	SEND_LOCK_LINK_SUCCESS_MESSAGE,
	SEND_UNLOCK_LINK_FAILURE_MESSAGE,
	SEND_UNLOCK_LINK_SUCCESS_MESSAGE,
} from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-booking-details-unlock-link',
	templateUrl: './booking-details-unlock-link.component.html',
	styleUrls: ['./booking-details-unlock-link.component.scss'],
})
export class BookingDetailsUnlockLinkComponent implements OnInit {
	@Input() booking_trigger: BehaviorSubject<Booking>;
	@Input() booking_update_trigger: BehaviorSubject<boolean>;
	booking: Booking;
	is_production: boolean;
	is_sending: boolean;
	current_locale: string;
	is_unlockable_on_vehicle_ass: boolean;
	is_unlockable_until_end: boolean;
	is_lockable_after_unlock: boolean;
	unlock_link_short: string;
	lock_link_short: string;

	is_unlock_link_valid?: boolean;

	constructor(
		private dialogService: DialogService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private bookingService: BookingService,
		private keysService: KeysService,
		private localManager: LocalManagerService,
	) {
		this.dialogService.dialog = this.dialog;
		const current_station: Station =
			this.localManager.getLocalObject(LOCAL_STATION_KEY);
		switch (current_station.settings.date_format) {
			case DATE_FORMATS[0]:
				this.current_locale = EN_LOCALE;
				break;
			case DATE_FORMATS[1]:
				this.current_locale = IT_LOCALE;
				break;
			default:
				this.current_locale = EN_LOCALE;
				break;
		}
	}

	ngOnInit(): void {
		this.booking_trigger.subscribe({
			next: (booking: Booking) => {
				this.booking = booking;
				this.is_production = environment.STAGE == PROD_STAGE;
				this.is_unlockable_on_vehicle_ass =
					this.booking.settings.unlock_settings.is_unlockable_on_vehicle_assignment;
				this.is_unlockable_until_end =
					this.booking.settings.unlock_settings.is_unlockable_until_booking_end_date;
				this.is_lockable_after_unlock =
					this.booking.settings.lock_settings.is_lockable_after_vehicle_unlock;

				this.is_unlock_link_valid =
					!booking.settings.unlock_settings.is_link_invalid;

				this.manageLinksRead();
			},
		});
	}

	onBookingUnlockLinkValidityChange(booking: Booking) {
		const station: Station =
			this.localManager.getLocalObject(LOCAL_STATION_KEY);
		booking.settings.unlock_settings.is_link_invalid =
			!booking.settings.unlock_settings.is_link_invalid;

		this.is_unlock_link_valid =
			!booking.settings.unlock_settings.is_link_invalid;

		this.bookingService.update(station.id, booking).subscribe({
			next: response => {},
			error: error => {},
		});
	}

	onBookingLockLinkValidityChange(booking: Booking) {
		const station: Station =
			this.localManager.getLocalObject(LOCAL_STATION_KEY);
		booking.settings.lock_settings.is_link_invalid =
			!booking.settings.lock_settings.is_link_invalid;

		this.bookingService.update(station.id, booking).subscribe({
			next: response => {},
			error: error => {},
		});
	}

	onSendUnlockLinkClick(booking: Booking) {
		const station: Station =
			this.localManager.getLocalObject(LOCAL_STATION_KEY);
		this.is_sending = true;

		if (booking && booking.unlock_key_id) {
			this.keysService
				.sendUnlockKeyV2(station.id, booking.id, booking.unlock_key_id)
				.subscribe({
					next: response => {
						this.dialogService.openInfoDialog(SEND_UNLOCK_LINK_SUCCESS_MESSAGE);
						this.is_sending = false;
					},
					error: error => {
						this.dialogService.openInfoDialog(SEND_UNLOCK_LINK_FAILURE_MESSAGE);
						this.is_sending = false;
					},
				});
		} else {
			this.bookingService
				.sendUnlockLink(
					station.id,
					booking.id,
					booking.vehicle_id,
					booking.user_id,
				)
				.subscribe({
					next: response => {
						this.dialogService.openInfoDialog(SEND_UNLOCK_LINK_SUCCESS_MESSAGE);
						this.is_sending = false;
					},
					error: error => {
						this.dialogService.openInfoDialog(SEND_UNLOCK_LINK_FAILURE_MESSAGE);
						this.is_sending = false;
					},
				});
		}
	}

	onSendLockLinkClick(booking: Booking) {
		const station: Station =
			this.localManager.getLocalObject(LOCAL_STATION_KEY);
		this.is_sending = true;

		if (booking && booking.lock_key_id) {
			this.keysService
				.sendLockKeyV2(station.id, booking.id, booking.lock_key_id)
				.subscribe({
					next: response => {
						this.dialogService.openInfoDialog(SEND_LOCK_LINK_SUCCESS_MESSAGE);
						this.is_sending = false;
					},
					error: error => {
						this.dialogService.openInfoDialog(SEND_LOCK_LINK_FAILURE_MESSAGE);
						this.is_sending = false;
					},
				});
		} else {
			this.bookingService
				.sendLockLink(
					station.id,
					booking.id,
					booking.vehicle_id,
					booking.user_id,
				)
				.subscribe({
					next: response => {
						this.dialogService.openInfoDialog(SEND_LOCK_LINK_SUCCESS_MESSAGE);
						this.is_sending = false;
					},
					error: error => {
						this.dialogService.openInfoDialog(SEND_LOCK_LINK_FAILURE_MESSAGE);
						this.is_sending = false;
					},
				});
		}
	}

	onUnlockCopyClick() {
		navigator.clipboard.writeText(this.unlock_link_short);
		this.snackBar.open('Copied!', '', {
			duration: 2000,
		});
	}

	onLockCopyClick() {
		navigator.clipboard.writeText(this.lock_link_short);
		this.snackBar.open('Copied!', '', {
			duration: 2000,
		});
	}

	manageLinksRead(): void {
		if (
			this.booking &&
			this.booking.vehicle_id &&
			!this.booking.unlock_key_id &&
			!this.booking.lock_key_id
		) {
			this.readUnlockLink();
			this.readLockLink();
		} else if (
			this.booking &&
			this.booking.vehicle_id &&
			this.booking.unlock_key_id &&
			this.booking.lock_key_id
		) {
			this.readUnlockLinkV2(this.booking.unlock_key_id);
			this.readLockLinkV2(this.booking.lock_key_id);
		}
	}

	readUnlockLink(): void {
		const station: Station =
			this.localManager.getLocalObject(LOCAL_STATION_KEY);
		this.keysService.readUnlockKey(station.id, this.booking.id).subscribe({
			next: response => {
				response.body.json().then(response => {
					this.unlock_link_short = response.short_url;
				});
			},
			error: () => {},
		});
	}

	readLockLink(): void {
		const station: Station =
			this.localManager.getLocalObject(LOCAL_STATION_KEY);
		this.keysService.readLockKey(station.id, this.booking.id).subscribe({
			next: response => {
				response.body.json().then(response => {
					this.lock_link_short = response.short_url;
				});
			},
			error: () => {},
		});
	}

	readUnlockLinkV2(unlock_key_id: string): void {
		const station: Station =
			this.localManager.getLocalObject(LOCAL_STATION_KEY);
		this.keysService
			.readUnlockKeyV2(station.id, this.booking.id, unlock_key_id)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						this.unlock_link_short = response.short_url;
					});
				},
				error: () => {},
			});
	}

	readLockLinkV2(lock_key_id: string): void {
		const station: Station =
			this.localManager.getLocalObject(LOCAL_STATION_KEY);
		this.keysService
			.readLockKeyV2(station.id, this.booking.id, lock_key_id)
			.subscribe({
				next: response => {
					response.body.json().then(response => {
						this.lock_link_short = response.short_url;
					});
				},
				error: () => {},
			});
	}

	getUnlockDeliveryBefore(): number {
		return (
			this.booking.start_date -
			this.booking.settings.unlock_settings
				.time_to_unlock_link_before_booking_start
		);
	}

	getUnlockAvailabilityBefore(): number {
		return (
			this.booking.start_date -
			this.booking.settings.unlock_settings
				.time_to_unlock_availability_before_booking_start
		);
	}

	getUnlockAvailabilityAfter(): number {
		return (
			this.booking.start_date +
			this.booking.settings.unlock_settings
				.time_to_unlock_availability_after_booking_start
		);
	}

	getLockDeliveryBefore(): number {
		return (
			this.booking.end_date -
			this.booking.settings.lock_settings.time_to_lock_link_before_booking_end
		);
	}

	getLockAvailabilityBefore(): number {
		return (
			this.booking.end_date -
			this.booking.settings.lock_settings
				.time_to_lock_availability_before_booking_end
		);
	}

	getLockAvailabilityAfter(): number {
		return (
			this.booking.end_date +
			this.booking.settings.lock_settings
				.time_to_lock_availability_after_booking_end
		);
	}

	getTimezonedTime(time: number) {
		return (
			time -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(this.booking.timezone)
		);
	}

	getTimezoneOffset() {
		return (
			'GMT' +
			(getTimezoneOffset(this.booking.timezone) / 3600000 >= 0 ? '+' : '') +
			getTimezoneOffset(this.booking.timezone) / 3600000
		);
	}

	getUnlockOnAssignClass(booking: Booking) {
		return {
			'lk-state-300-background':
				booking.settings.unlock_settings.is_unlockable_on_vehicle_assignment,
			'lk-state-50-background':
				!booking.settings.unlock_settings.is_unlockable_on_vehicle_assignment,
		};
	}

	getUnlockTillEndClass(booking: Booking) {
		return {
			'lk-state-300-background':
				booking.settings.unlock_settings.is_unlockable_until_booking_end_date,
			'lk-state-50-background':
				!booking.settings.unlock_settings.is_unlockable_until_booking_end_date,
		};
	}

	getLockOnUnlockClass(booking: Booking) {
		return {
			'lk-state-300-background':
				booking.settings.lock_settings.is_lockable_after_vehicle_unlock,
			'lk-state-50-background':
				!booking.settings.lock_settings.is_lockable_after_vehicle_unlock,
		};
	}

	getToggleClass(isValid: boolean) {
		return {
			'lk-warned-toggle': !isValid,
			'': isValid,
		};
	}

	getMinutesAndSecondsFromMillis(millis: number): number[] {
		const MILLIS_IN_HOUR = 3600000;
		const MILLIS_IN_MINUTE = 60000;
		const MILLIS_IN_SECOND = 1000;
		const hours: number = Math.floor(millis / MILLIS_IN_HOUR);
		const minuteMillis: number = millis % MILLIS_IN_HOUR;
		const minutes: number = Math.floor(minuteMillis / MILLIS_IN_MINUTE);
		const secondMillis: number = minuteMillis % MILLIS_IN_MINUTE;
		const seconds: number = Math.floor(secondMillis / MILLIS_IN_SECOND);
		return [hours, minutes, seconds];
	}
}
