import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from '../core/dialog.service';
import { FormService } from '../core/form.service';
import { LocalManagerService } from '../core/local-manager.service';
import { STATION_PATH } from '../core/navigation.service';
import { Service, Station, StationService } from '../core/station.service';
import { ERROR_DESCRIPTION_500, LOCAL_STATION_KEY } from '../shared/constants';
import {
	GENERAL_ERROR_MESSAGE,
	TOOLBAR_STATION_SETTINGS_NAV_ITEM_NAME,
} from '../shared/translations';
import { StationBuilderDialogComponent } from '../station/station-builder-dialog/station-builder-dialog.component';
import { ErrorService } from '../core/error.service';

@Component({
	selector: 'app-hidden-settings',
	templateUrl: './hidden-settings.component.html',
	styleUrls: ['./hidden-settings.component.scss'],
})
export class HiddenSettingsComponent implements OnInit {
	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	current_item: string = TOOLBAR_STATION_SETTINGS_NAV_ITEM_NAME;
	is_password_visible: boolean = false;
	is_request_running: boolean = false;
	is_save_running: boolean = false;
	stations: Station[];
	current_station: Station;
	form_group: FormGroup;

	constructor(
		private form_service: FormService,
		private local_manager: LocalManagerService,
		private router: Router,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private station_service: StationService,
		private error_service: ErrorService,
	) {
		this.form_group =
			this.form_service.hiddenSettingsAdapterCredentialsFormGroup;
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.listStations();
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuildClick(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
					this.toolbar_refresh.next(true);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onStationChangeClick(station: Station): void {
		this.listStations();
	}

	onCredentialsUpdateClick() {
		if (this.form_service.validateForm(this.form_group)) {
			this.is_save_running = true;
			const servicesToBeRemoved: Service[] =
				this.current_station.services.filter(s => {
					return (
						s.type == 'ADAPTER_SYNC_CLIENT_ID' ||
						s.type == 'ADAPTER_SYNC_CLIENT_SECRET'
					);
				});
			this.station_service
				.removeServices(this.current_station.id, servicesToBeRemoved)
				.subscribe({
					next: () => {
						this.addServices();
					},
					error: () => {
						this.addServices();
					},
				});
		}
	}

	setStation(station: Station) {
		this.current_station = station;
		const clientId: string = this.current_station.services
			? this.current_station.services.filter(s => {
					return s.type == 'ADAPTER_SYNC_CLIENT_ID';
			  })[0]?.value
			: '';
		const clientSecret: string = this.current_station.services
			? this.current_station.services.filter(s => {
					return s.type == 'ADAPTER_SYNC_CLIENT_SECRET';
			  })[0]?.value
			: '';
		this.form_group.get('client_id').setValue(clientId);
		this.form_group.get('client_secret').setValue(clientSecret);
		this.local_manager.setLocalObject(station, LOCAL_STATION_KEY);
		this.station_update_trigger.next(true);
	}

	listStations() {
		this.is_request_running = true;
		this.station_service.list().subscribe({
			next: response => {
				this.is_request_running = false;
				this.stations = response.data.items as Station[];
				const localStation: Station =
					this.local_manager.getLocalObject(LOCAL_STATION_KEY);
				const localReplacementCond = this.stations.filter(s => {
					return localStation && s.id == localStation.id;
				});
				if (localReplacementCond && localReplacementCond.length > 0) {
					this.current_station = localReplacementCond[0];
				} else {
					this.current_station = this.stations[0];
				}
				//this.onStationChangeClick(this.current_station);
				this.setStation(this.current_station);
			},
			error: error => {
				this.is_request_running = false;
				this.router.navigate([STATION_PATH]);
			},
		});
	}

	addServices() {
		const services: Service[] = [
			{
				type: 'ADAPTER_SYNC_CLIENT_ID',
				value: this.form_group.get('client_id').value,
			},
			{
				type: 'ADAPTER_SYNC_CLIENT_SECRET',
				value: this.form_group.get('client_secret').value,
			},
		];

		this.is_save_running = true;
		this.station_service
			.addServices(this.current_station.id, services)
			.subscribe({
				next: () => {
					this.listStations();
					this.is_save_running = false;
				},
				error: error => {
					this.is_save_running = false;
					this.error_service.wrapError(error);
				},
			});
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_save_running,
			'lk-main-button': !this.is_save_running,
		};
	}
}
