<h2 class="lk-margin__0 lk-margin__x12-bottom">
	{{ 'STATION.NAVBAR_DATA_SETTINGS_ITEM_NAME' | translate }}
</h2>

<div
	*ngIf="station"
	class="lk-padding__x48 lk-shadow lk-border-radius lk-background__light-neutral-0 lk-margin__x24-bottom">
	<form [formGroup]="form_group">
		<p class="lk-no-margin lk-half-margin-bottom">
			{{ 'STATION.VEHICLES_SETTINGS_UM_LABEL' | translate }}
		</p>

		<mat-form-field class="lk-margin__x24-bottom lk-form-field">
			<mat-label>
				{{ 'GENERAL.MU_LABEL' | translate }}
			</mat-label>
			<mat-select formControlName="odometer_um">
				<mat-option [value]="mi_um">
					{{ 'GENERAL.MILES_MU_LABEL' | translate }} [mi]
				</mat-option>
				<mat-option [value]="km_um">
					{{ 'GENERAL.KM_MU_LABEL' | translate }} [km]
				</mat-option>
			</mat-select>
		</mat-form-field>

		<p class="lk-no-margin lk-half-margin-bottom">
			{{ 'STATION.SETTINGS_DATE_FORMAT' | translate }}
		</p>

		<mat-form-field class="lk-margin__x24-bottom lk-form-field">
			<mat-label> {{ 'STATION.SETTINGS_DATE_FORMAT' | translate }} </mat-label>
			<mat-select formControlName="date_format">
				<mat-option
					*ngFor="let date of date_formats"
					[value]="date.format">
					{{ date.label }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</form>

	<app-button
		class="lk-width__full lk-margin__x24-top lk-display__block"
		[button_class]="'lk-button__secondary lk-width__full'"
		[spinner_class]="'lk-button__spinner-white'"
		[cta]="'GENERAL.SAVE_BUTTON_CTA' | translate"
		[is_disabled]="is_request_running"
		[is_spinner_visible]="is_request_running"
		(button_click)="onSaveClick()"></app-button>
</div>
