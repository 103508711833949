import { Component, OnInit } from '@angular/core';
import {
	CUSTOM_AUTH_LOGO_PATH,
	CUSTOM_AUTH_LOGO_BACKGROUND_PATH,
} from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-auth-signup',
	templateUrl: './auth-signup.component.html',
	styleUrls: ['./auth-signup.component.scss'],
})
export class AuthSignupComponent implements OnInit {
	logo_src: string = '../../../assets/imgs/lk-logo.svg';
	background_src: string =
		'../../../assets/imgs/lk-backgrounds/lk-default-auth-background.svg';

	ngOnInit() {
		const base_path = location.origin
			.replace('http://', '')
			.replace('https://', '');

		const dot_index = base_path.indexOf('.');
		const double_dot_index = base_path.indexOf(':');
		let subdomain: string;

		if (dot_index != -1) {
			subdomain = base_path.substring(0, dot_index);
		} else if (double_dot_index != -1) {
			subdomain = base_path.substring(0, double_dot_index);
		}

		if (subdomain && subdomain != 'manage') {
			this.logo_src = CUSTOM_AUTH_LOGO_PATH(environment.STAGE, subdomain);
			this.background_src = CUSTOM_AUTH_LOGO_BACKGROUND_PATH(
				environment.STAGE,
				subdomain,
			);
		}
	}
}
