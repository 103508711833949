import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SharedToolbarComponent } from './shared-toolbar/shared-toolbar.component';
import { SharedSingleActionDialogComponent } from './shared-single-action-dialog/shared-single-action-dialog.component';
import { SharedSpinnerOverlayComponent } from './shared-spinner-overlay/shared-spinner-overlay.component';
import { SharedMenuComponent } from './shared-menu/shared-menu.component';
import { SharedInteractionErrorDialogComponent } from './shared-interaction-error-dialog/shared-interaction-error-dialog.component';
import { SharedAppToolbarComponent } from './shared-app-toolbar/shared-app-toolbar.component';
import { SharedPillComponent } from './shared-pill/shared-pill.component';
import { SharedLogoComponent } from './shared-logo/shared-logo.component';

@NgModule({
	declarations: [
		SharedToolbarComponent,
		SharedSingleActionDialogComponent,
		SharedSpinnerOverlayComponent,
		SharedMenuComponent,
		SharedInteractionErrorDialogComponent,
		SharedAppToolbarComponent,
		SharedPillComponent,
		SharedLogoComponent,
	],
	imports: [
		CommonModule,
		MatInputModule,
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatMenuModule,
		MatToolbarModule,
		TranslateModule,
		HttpClientModule,
		MatProgressSpinnerModule,
	],
	exports: [
		SharedToolbarComponent,
		SharedAppToolbarComponent,
		SharedSingleActionDialogComponent,
		SharedSpinnerOverlayComponent,
		SharedMenuComponent,
		SharedInteractionErrorDialogComponent,
		SharedPillComponent,
		SharedLogoComponent,
	],
})
export class SharedModule {}
