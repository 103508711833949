<app-shared-app-toolbar
	[current_nav_item]="current_item"
	[refresh]="toolbar_refresh"
	(no_station_found)="onNoStationFound()"
	(station_build_click)="onStationBuildClick(false)"
	(station_change)="onStationChangeClick($event)"></app-shared-app-toolbar>

<section class="lk-section-with-toolbar">
	<div
		id="lk-vehicle-map-layout"
		class="lk-full-width"></div>
	<div
		id="lk-vehicle-map-list-layout"
		class="lk-vert-padding lk-full-height lk-hide-scrollbar">
		<app-vehicle-list
			(vehicleDeleteClick)="onVehicleDeleteClick($event)"
			(vehicleStartClick)="onStartVehicleClick($event)"
			(vehicleStopClick)="onStopVehicleClick($event)"
			[is_header_hidden]="true"
			[horiz_mode]="true"
			(vehicleClick)="onVehicleClick($event)"></app-vehicle-list>
	</div>
	<div
		*ngIf="!is_vehicles_loading"
		id="lk-vehicle-map-actions">
		<button
			mat-icon-button
			(click)="onLayersClick()"
			class="lk-primary-background">
			<mat-icon [ngClass]="getLayersIconClass()"> layers </mat-icon>
		</button>
	</div>

	<div
		*ngIf="is_vehicles_loading"
		class="lk-full-width lk-full-height lk-centered-row"
		style="position: absolute; top: 0; left: 0">
		<div class="lk-overlay-spinner-layout lk-padding lk-border-radius">
			<mat-spinner
				color="accent"
				diameter="30"></mat-spinner>
		</div>
	</div>
</section>
