import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { get, post, put, del } from 'aws-amplify/api';
import { environment } from '../../environments/environment';
import { Phone } from './core.module';

export const NOTIFICATIONS_API_NAME: string = 'notifications';
export const NOTIFICATIONS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/notification-templates/';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	create(station_id: string, notification: Notification): Observable<any> {
		const path: string = `stations/${station_id}`;

		const init = { response: true, body: notification };

		return from(
			post({
				apiName: NOTIFICATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	update(station_id: string, notification: Notification): Observable<any> {
		const path: string = `${notification.id}/stations/${station_id}/content`;

		const init = { response: true, body: notification.content };

		return from(
			put({
				apiName: NOTIFICATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	remove(station_id: string, notification: Notification): Observable<any> {
		const path: string = `${notification.id}/stations/${station_id}`;

		const init = { response: true };

		return from(
			del({
				apiName: NOTIFICATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	list(station_id: string): Observable<any> {
		const path: string = `stations/${station_id}`;

		const init = { response: true };

		return from(
			get({
				apiName: NOTIFICATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	enable(notification_id: string, station_id: string): Observable<any> {
		const path: string = `${notification_id}/stations/${station_id}/enable`;

		const init = { response: true };

		return from(
			put({
				apiName: NOTIFICATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	disable(notification_id: string, station_id: string): Observable<any> {
		const path: string = `${notification_id}/stations/${station_id}/disable`;

		const init = { response: true };

		return from(
			put({
				apiName: NOTIFICATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	test(
		station_id: string,
		template_name: NotificationName,
		template_type: NotificationType,
		template_id?: string,
		phone?: Phone,
		email?: string,
	): Observable<any> {
		const path: string = `stations/${station_id}/simulate`;

		let phone_body: {
			prefix: string;
			value: string;
		};
		if (phone) {
			phone_body = {
				prefix: phone.prefix,
				value: phone.number,
			};
		}
		const init = {
			response: true,
			body: {
				phone: phone_body,
				email: email,
				template_id: template_id,
				template_name: template_name,
				template_type: template_type,
			},
		};

		return from(
			post({
				apiName: NOTIFICATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}
}

export interface TemplateItem {
	name: string;
	type: NotificationType;
	default: Notification;
	custom?: Notification;
}

export class Notification {
	id?: string;
	name?: string;
	type?: NotificationType;
	lang?: string;
	content?: string;
	content_url?: string;
	is_disabled?: boolean;
}

export enum NotificationType {
	EMAIL = 'email',
	SMS = 'sms',
	BOTH = 'all',
}

export enum NotificationName {
	BOOKING_START = 'booking_start',
	BOOKING_END = 'booking_end',
	BOOKING_VEHICLE_CHANGED = 'booking_vehicle_changed',
	BOOKING_DATES_CHANGED = 'booking_dates_changed',
	BOOKING_DELETED = 'booking_deleted',
	BOOKING_COMPLETE = 'booking_completed',
	QUICK_LINK = 'quick_link',
}

export const NOTIFICATIONS: Notification[] = [
	{
		id: null,
		name: NotificationName.BOOKING_START,
		type: NotificationType.SMS,
		lang: null,
	},
	{
		id: null,
		name: NotificationName.BOOKING_END,
		type: NotificationType.SMS,
		lang: null,
	},
	{
		id: null,
		name: NotificationName.BOOKING_START,
		type: NotificationType.EMAIL,
		lang: null,
	},
	{
		id: null,
		name: NotificationName.BOOKING_END,
		type: NotificationType.EMAIL,
		lang: null,
	},
];
