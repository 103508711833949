import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import {
	NO_RESULT_FOUND_MESSAGE,
	TOOLBAR_VEHICLES_NAV_ITEM_NAME,
} from '../shared/translations';
import { Station, StationService } from '../core/station.service';
import { LocalManagerService } from '../core/local-manager.service';
import {
	LOCAL_STATION_KEY,
	VEHICLE_NOT_FOUND_ERROR_CODE,
} from '../shared/constants';
import {
	Vehicle,
	VehicleFiltersConfig,
	VehicleService,
} from '../core/vehicle.service';
import { DialogService } from '../core/dialog.service';
import { VehicleFiltersDialogComponent } from './vehicle-filters-dialog/vehicle-filters-dialog.component';
import { NavigationService } from '../core/navigation.service';
import { UnlockCommandsService } from '../core/unlock-commands.service';
import { LockCommandsService } from '../core/lock-commands.service';
import { StationBuilderDialogComponent } from '../station/station-builder-dialog/station-builder-dialog.component';
import { ErrorService } from '../core/error.service';
import { VehicleTableComponent } from './vehicle-table/vehicle-table.component';
import { VehicleSummaryComponent } from './vehicle-summary/vehicle-summary.component';
import { VehicleListComponent } from './vehicle-list/vehicle-list.component';

@Component({
	selector: 'app-vehicle',
	templateUrl: './vehicle.component.html',
	styleUrls: ['./vehicle.component.scss'],
})
export class VehicleComponent {
	@ViewChild(VehicleSummaryComponent)
	summary_component?: VehicleSummaryComponent;
	@ViewChild(VehicleTableComponent) table_component?: VehicleTableComponent;
	@ViewChild(VehicleListComponent) list_component?: VehicleListComponent;
	@ViewChild('loadMoreLayout') load_more_layout?: ElementRef;

	current_item = TOOLBAR_VEHICLES_NAV_ITEM_NAME;

	current_page = 1;
	filters_config: VehicleFiltersConfig;

	vehicles: Vehicle[];
	stored_vehicles?: Vehicle[];
	station?: Station;

	is_vehicle_map_visible = false;
	is_vehicles_loading = true;
	is_page_loading = false;
	is_last_page = false;

	constructor(
		private local_manager: LocalManagerService,
		private unlock_commands_service: UnlockCommandsService,
		private lock_commands_service: LockCommandsService,
		private vehicle_service: VehicleService,
		private dialog_service: DialogService,
		private station_service: StationService,
		private navigation_service: NavigationService,
		private error_service: ErrorService,
	) {}

	onStationChange(): void {
		this.onRefresh();
	}

	onRefresh(): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.prepareVehiclesLoading();

		this.listVehicles(station.id);
		this.readStation(station.id);

		this.summary_component?.onRefresh();
	}

	@HostListener('document:scroll', ['$event'])
	onViewportScroll(): void {
		const window_height = window.innerHeight;
		const bounding_rect =
			this.load_more_layout?.nativeElement?.getBoundingClientRect();
		if (
			bounding_rect &&
			bounding_rect.top >= 0 &&
			bounding_rect.bottom <= window_height &&
			!this.is_page_loading
		) {
			this.onLoadPageClick();
		}
	}

	onNoStationFound(): void {
		this.navigation_service.toStation();
	}

	onStationBuild(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onFiltersClick(): void {
		this.dialog_service
			.openDialog(VehicleFiltersDialogComponent)
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.filters) {
					const station: Station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);

					this.stored_vehicles = this.vehicles;

					this.prepareVehiclesLoading();

					this.filters_config = dialog_result.filters;

					this.listVehicles(station.id);
				}
			});
	}

	onSearchChange(plate: string): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.stored_vehicles = this.vehicles;

		this.prepareVehiclesLoading();

		this.filters_config = { plate };

		this.listVehicles(station.id);
	}

	onLoadPageClick(): void {
		this.current_page++;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.is_page_loading = true;
		this.listVehicles(station.id);
	}

	prepareVehiclesLoading(): void {
		this.is_vehicles_loading = true;
		this.is_last_page = false;

		this.unlock_commands_service.current_read_index = 0;
		this.lock_commands_service.current_read_index = 0;

		this.vehicles = [];
		this.current_page = 1;

		this.filters_config = new VehicleFiltersConfig();
	}

	listVehicles(station_id: string): void {
		this.filters_config['page'] = this.current_page;

		this.vehicle_service._list(station_id, this.filters_config).subscribe({
			next: response => {
				response.body.json().then(response => {
					const station: Station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);

					const vehicles = response.items;

					this.vehicles = this.vehicles
						? this.vehicles.concat(vehicles)
						: vehicles;

					this.is_last_page = response.total_items <= this.vehicles.length;

					this.table_component?.setStation(station, this.vehicles);
					this.list_component?.setStation(station, this.vehicles);

					this.is_vehicles_loading = false;
					this.is_page_loading = false;

					setTimeout(() => {
						const window_height = window.innerHeight;
						const bounding_rect =
							this.load_more_layout?.nativeElement?.getBoundingClientRect();
						if (
							bounding_rect &&
							bounding_rect.top >= 0 &&
							bounding_rect.bottom <= window_height &&
							!this.is_page_loading
						) {
							this.onLoadPageClick();
						}
					}, 200);
				});
			},
			error: error => {
				const converted_error = this.error_service.convertError(error);

				if (
					converted_error.code === VEHICLE_NOT_FOUND_ERROR_CODE &&
					!this.areFiltersSet()
				) {
					this.is_last_page = true;
					this.vehicles = this.vehicles ? this.vehicles.concat([]) : [];
				}

				if (
					converted_error.code === VEHICLE_NOT_FOUND_ERROR_CODE &&
					this.areFiltersSet()
				) {
					this.is_last_page = true;
					this.dialog_service.openInfoDialog(NO_RESULT_FOUND_MESSAGE);
					this.vehicles = this.stored_vehicles;
				}

				if (converted_error.code != VEHICLE_NOT_FOUND_ERROR_CODE) {
					this.error_service.processError(converted_error);
				}

				this.is_vehicles_loading = false;
				this.is_page_loading = false;
			},
		});
	}

	readStation(station_id: string): void {
		this.station_service.read(station_id).subscribe({
			next: response => {
				const station = response;
				this.is_vehicle_map_visible =
					(station.vehicle_collection_length || 0) <= 100;
			},
			error: () => {
				this.is_vehicle_map_visible = true;
			},
		});
	}

	areFiltersSet(): boolean | string {
		return (
			this.filters_config?.plate ||
			this.filters_config?.category ||
			this.filters_config?.brand ||
			this.filters_config?.status
		);
	}
}
