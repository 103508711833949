import { Component, OnInit } from '@angular/core';
import { FormService } from '../../core/form.service';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../core/authentication.service';

@Component({
	selector: 'app-auth-forgot-password-dialog',
	templateUrl: './auth-forgot-password-dialog.component.html',
	styleUrls: ['./auth-forgot-password-dialog.component.scss'],
})
export class AuthForgotPasswordDialogComponent implements OnInit {
	form_group: FormGroup;
	is_forgot_pss_running: boolean = false;

	constructor(
		private dielog_ref: MatDialogRef<AuthForgotPasswordDialogComponent>,
		private form_service: FormService,
		private auth_service: AuthenticationService,
	) {
		this.form_group = this.form_service.forgotPasswordEmailFormGroup;
	}

	ngOnInit() {
		this.form_group.reset();
	}

	onCloseClick() {
		this.dielog_ref.close();
	}

	onConfirmClick() {
		if (this.isFormValid() && !this.is_forgot_pss_running) {
			const email: string = this.form_group.get('username').value;
			this.forgotPassword(email);
		}
	}

	forgotPassword(email: string): void {
		this.is_forgot_pss_running = true;
		this.auth_service.forgotPassword(email).subscribe({
			next: () => {
				this.is_forgot_pss_running = false;
				this.dielog_ref.close({ success: true, email: email });
			},
			error: error => {
				this.is_forgot_pss_running = false;
				this.dielog_ref.close({ success: false, error: error.message });
			},
		});
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.form_group);
	}
}
