import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';

@Component({
	selector: 'app-vehicle-table-controls',
	templateUrl: './vehicle-table-controls.component.html',
	styleUrl: './vehicle-table-controls.component.scss',
})
export class VehicleTableControlsComponent {
	@Input() update_time: number = new Date().getTime();
	@Input() current_locale?: string;

	@Output() search_change: EventEmitter<void> = new EventEmitter<void>();
	@Output() filter_change: EventEmitter<void> = new EventEmitter<void>();

	form_group: FormGroup;

	constructor(private form_service: FormService) {
		this.form_group = this.form_service.vehicleSearchFormGroup;
	}
}
