import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
	TOOLBAR_STATION_NAV_ITEM_NAME,
	TOOLBAR_VEHICLES_NAV_ITEM_NAME,
	TOOLBAR_BOOKINGS_NAV_ITEM_NAME,
	TOOLBAR_METABASE_NAV_ITEM_NAME,
} from '../shared/translations';

// Auth
export const AUTH_PATH: string = 'authenticate';
export const AUTH_SIGNIN_PATH: string = 'signin';
export const AUTH_SIGNUP_PATH: string = 'signup';
export const AUTH_MFA_SETUP_PATH: string = 'mfa/setup';
export const AUTH_MFA_VERIFY_PATH: string = 'mfa/verify';
export const AUTH_MFA_RESET_PATH: string = 'mfa/reset';

// Bookings
export const BOOKINGS_PATH: string = 'bookings';
export const BOOKING_DETAILS_PATH: string = 'booking/';

// Hidden
export const HIDDEN_SETTINGS_PATH: string = 'hidden/settings';
export const HIDDEN_STATIONS_PATH: string = 'hidden/stations';

// Invitation
export const INVITATION_PATH: string = 'invitation';

// Station
export const STATION_PATH: string = 'station';
export const STATION_EMPTY_PATH: string = 'empty';
export const STATION_BUILDER_PATH: string = '/builder';
export const STATION_BUILDER_BASICS_PATH: string = '/builder/basics';
export const STATION_BUILDER_LOGO_PATH: string = '/builder/logo';
export const STATION_BUILDER_TEAM_PATH: string = '/builder/team';
export const STATION_BUILDER_SETTINGS_PATH: string = '/builder/notif/settings';
export const STATION_BUILDER_LANG_PATH: string = '/builder/notif/lang';
export const STATION_BUILDER_NOTIF_PATH: string = '/builder/notif/templates';
export const STATION_SETTINGS_PATH: string = 'station/settings';

// Vehicles
export const VEHICLES_PATH: string = 'vehicles';
export const VEHICLES_EMPTY_PATH: string = 'empty';
export const VEHICLES_MAP_PATH: string = 'vehicles/map';
export const VEHICLES_DETAILS_PATH: string = 'vehicle/';

// Profile
export const PROFILE_PATH: string = 'profile';

// Metabase
export const METABASE_PATH: string = 'metabase';

// Subscription
export const SUBSCRIPTION_START_PATH: string = 'subscription/start';
export const SUBSCRIPTION_PAYMENT_PATH: string = 'subscription/payment';
export const SUBSCRIPTION_INFO_PATH: string = 'subscription/path';
export const SUBSCRIPTION_SIGNIN_PATH: string = 'subscription/signin';
export const SUBSCRIPTION_SIGNUP_PATH: string = 'subscription/signup';

@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	constructor(private router: Router) {}

	toAuthSignin(): void {
		this.router.navigate([`${AUTH_PATH}/${AUTH_SIGNIN_PATH}`]);
	}

	toAuthMfaSetup(): void {
		this.router.navigate([`${AUTH_PATH}/${AUTH_MFA_SETUP_PATH}`]);
	}

	toAuthMfaVerify(): void {
		this.router.navigate([`${AUTH_PATH}/${AUTH_MFA_VERIFY_PATH}`]);
	}

	toVehicles(): void {
		this.router.navigate([VEHICLES_PATH]);
	}

	toVehiclesEmpty(): void {
		this.router.navigate([`${VEHICLES_PATH}/${VEHICLES_EMPTY_PATH}`]);
	}

	toVehiclesMap(): void {
		this.router.navigate([`${VEHICLES_PATH}/${VEHICLES_MAP_PATH}`]);
	}

	toVehiclesDetails(vehicle_id: string): void {
		this.router.navigate([`${VEHICLES_DETAILS_PATH}${vehicle_id}`]);
	}

	toBookings(): void {
		this.router.navigate([BOOKINGS_PATH]);
	}

	toBookingsDetails(booking_id: string): void {
		this.router.navigate([`${BOOKING_DETAILS_PATH}${booking_id}`]);
	}

	toStation(): void {
		this.router.navigate([STATION_PATH]);
	}

	toStationEmpty(): void {
		this.router.navigate([`${STATION_PATH}/${STATION_EMPTY_PATH}`]);
	}

	toProfile(): void {
		this.router.navigate([PROFILE_PATH]);
	}

	toInvitation(): void {
		this.router.navigate([INVITATION_PATH]);
	}

	toGenericPath(path: string): void {
		this.router.navigate([path]);
	}
}

export class NavItem {
	name: string;
	path: string;
	icon: string;
}

export const NAV_ITEMS: NavItem[] = [
	{
		name: TOOLBAR_STATION_NAV_ITEM_NAME,
		path: STATION_PATH,
		icon: 'lk-stations',
	},
	{
		name: TOOLBAR_VEHICLES_NAV_ITEM_NAME,
		path: VEHICLES_PATH,
		icon: 'lk-vehicles',
	},
	{
		name: TOOLBAR_BOOKINGS_NAV_ITEM_NAME,
		path: BOOKINGS_PATH,
		icon: 'lk-bookings',
	},
];

export const METABASE_NAV_ITEM: NavItem = {
	name: TOOLBAR_METABASE_NAV_ITEM_NAME,
	path: METABASE_PATH,
	icon: 'lk-dashboard',
};
