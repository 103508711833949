<form
	[formGroup]="form_group"
	class="lk-row__space lk-wrap lk-z-index">
	<span *ngIf="!is_app_setup">
		{{ 'AUTHENTICATION.MFA_SETUP_TOTP_CTA' | translate }}
	</span>

	<div
		*ngIf="!is_app_setup"
		class="lk-background__light-neutral-20 lk-margin__x24-vert lk-row lk-padding__x6 lk-border__radius-x4">
		<button
			(click)="onCopyClick(setup_secret)"
			mat-icon-button>
			<mat-icon> content_copy </mat-icon>
		</button>
		<p
			class="lk-color__secondary-50 lk-margin__0 lk-text__word-breal-break-all">
			<b> {{ setup_secret }} </b>
		</p>
	</div>

	<div
		*ngIf="!is_app_setup"
		class="lk-width__full lk-row__center">
		<qrcode
			#parent
			[qrdata]="this.setup_uri?.href"
			[allowEmptyString]="true"
			[cssClass]="'center'"
			[colorDark]="'#000000ff'"
			[colorLight]="'#ffffffff'"
			[elementType]="'img'"
			[errorCorrectionLevel]="'M'"
			[margin]="4"
			[scale]="1"
			[width]="200"></qrcode>
	</div>

	<app-button
		*ngIf="!is_app_setup"
		class="lk-width__full lk-margin__x48-top"
		[button_class]="'lk-button__secondary lk-width__full'"
		[spinner_class]="'lk-button__spinner-white'"
		[cta]="'GENERAL.NEXT_STEP_BUTTON_CTA' | translate"
		(button_click)="onAppSetupClick()"></app-button>

	<span *ngIf="is_app_setup">
		{{ 'AUTHENTICATION.MFA_SETUP_TOTP_CONFIRM_CTA' | translate }}
	</span>

	<mat-form-field
		*ngIf="is_app_setup"
		class="lk-width__full lk-margin__x12-bottom lk-margin__x24-top lk-form-field lk-form-field__large">
		<mat-label>
			{{ 'GENERAL.CODE_LABEL' | translate | titlecase }}
		</mat-label>
		<input
			matInput
			type="text"
			formControlName="token"
			required />
	</mat-form-field>

	<app-button
		*ngIf="is_app_setup"
		class="lk-width__full lk-margin__x48-top"
		[button_class]="'lk-button__secondary lk-width__full'"
		[spinner_class]="'lk-button__spinner-white'"
		[cta]="'GENERAL.CONFIRM_CTA' | translate"
		[is_disabled]="!isFormValid() || is_mfa_setup_running"
		[is_spinner_visible]="is_mfa_setup_running"
		(button_click)="onMfaSetupClick()"></app-button>

	<div
		*ngIf="is_app_setup"
		(click)="onBackClick()"
		class="lk-row">
		<button mat-icon-button>
			<mat-icon class="lk-color__secondary-50"> arrow_back </mat-icon>
		</button>
		<span class="lk-color__secondary-50 lk-text__12">
			{{ 'AUTHENTICATION.MFA_SETUP_TOTP_BACK_CTA' | translate }}
		</span>
	</div>
</form>
