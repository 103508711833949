import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Vehicle } from 'src/app/core/vehicle.service';

@Component({
	selector: 'app-vehicle-delete-dialog',
	templateUrl: './vehicle-delete-dialog.component.html',
	styleUrls: ['./vehicle-delete-dialog.component.scss'],
})
export class VehicleDeleteDialogComponent {
	vehicle: Vehicle;

	constructor(
		private dialog_ref: MatDialogRef<VehicleDeleteDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.vehicle = data.vehicle;
	}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onConfirmClick() {
		this.dialog_ref.close({ confirm: true });
	}
}
