import { Injectable } from '@angular/core';
import { Address } from './core.module';
import { from, Observable } from 'rxjs';
import { get, post, put } from 'aws-amplify/api';
import { environment } from '../../environments/environment';

export const USERS_API_NAME: string = 'users';
export const USERS_API_ENDPOINT: string = environment.API_BASE_PATH + '/users/';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	create(station_id: string, user: User): Observable<any> {
		const path: string = `stations/${station_id}`;

		const init = { response: true, body: user };

		return from(
			post({
				apiName: USERS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	readByEmail(email: string): Observable<any> {
		const path: string = `email/${email}`;

		const init = { response: true };

		return from(
			get({
				apiName: USERS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	readByPhone(phone: string): Observable<any> {
		const path: string = `phone/${phone}`;

		const init = { response: true };

		return from(
			get({
				apiName: USERS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	list(station_id: string, email?: string): Observable<any> {
		const path: string = `stations/${station_id}`;

		let query_params = {};
		if (email) {
			query_params['email'] = email;
		}
		const init = { response: true, queryStringParameters: query_params };

		return from(
			get({
				apiName: USERS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	addUserToStation(station_id: string, user_id: string): Observable<any> {
		const path: string = `${user_id}/stations/${station_id}`;

		const init = { response: true };

		return from(
			post({
				apiName: USERS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	setEmail(user_id: string, new_email: string): Observable<any> {
		const path: string = `${user_id}/email/${new_email}`;

		const init = { response: true };

		return from(
			put({
				apiName: USERS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}
}

export class User {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	phone: { prefix: string; value: string };
	tax_code: string;
	address: Address;
	birth_date: number;
}

export class Phone {}
