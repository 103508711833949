<app-loading
	*ngIf="is_commands_request_running"
	class="lk-vehicle-details-commands__loading lk-display__block lk-height__full lk-border-radius lk-shadow"></app-loading>

<div
	*ngIf="!is_commands_request_running"
	class="lk-height__full lk-border-radius lk-shadow lk-padding__x24 lk-background__light-neutral-0 lk-hide-scrollbar">
	<h3 class="lk-margin__0 lk-text__24 lk-margin__x12-bottom">
		{{ 'VEHICLE_DETAILS.COMMANDS_SECTION_LABEL' | translate }}
	</h3>

	<app-vehicle-details-commands-form
		(reset)="onFiltersReset()"
		(apply)="onFiltersApply($event)"></app-vehicle-details-commands-form>

	<app-vehicle-details-commands-list
		[commands]="commands"></app-vehicle-details-commands-list>

	<div
		*ngIf="!commands?.length"
		class="lk-row__center">
		<p class="lk-primary-medium-color lk-text__center">
			{{ 'VEHICLE_DETAILS.NO_COMMANDS_LABEL' | translate }}
		</p>
	</div>
</div>
