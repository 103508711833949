import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
	BookingMedia,
	BookingMediaService,
	BookingMediaType,
} from 'src/app/core/booking-media.service';
import { Booking } from 'src/app/core/booking.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { STATION_PATH } from 'src/app/core/navigation.service';
import { PicturePolicy, Station } from 'src/app/core/station.service';
import {
	BOOKING_MEDIA_NOT_FOUND_ERROR_CODE,
	LOCAL_STATION_KEY,
} from 'src/app/shared/constants';
import {
	GENERAL_ERROR_MESSAGE,
	PICTURES_DISABLED_POLICY_TITLE,
	PICTURES_DROP_OFF_POLICY_TITLE,
	PICTURES_MANDATORY_POLICY_TITLE,
	PICTURES_OPTIONAL_POLICY_TITLE,
	PICTURES_PICK_UP_POLICY_TITLE,
} from 'src/app/shared/translations';
import { BookingDetailsGalleryDialogComponent } from '../booking-details-gallery-dialog/booking-details-gallery-dialog.component';
import { ErrorService } from 'src/app/core/error.service';

@Component({
	selector: 'app-booking-details-docs',
	templateUrl: './booking-details-docs.component.html',
	styleUrls: ['./booking-details-docs.component.scss'],
})
export class BookingDetailsDocsComponent implements OnInit {
	@Input() booking: Booking;
	@Input() refresh: BehaviorSubject<boolean>;
	booking_media: BookingMedia[];
	pre_reports: BookingMedia[];
	post_reports: BookingMedia[];
	is_request_running: boolean = false;

	constructor(
		private booking_media_service: BookingMediaService,
		private local_manager: LocalManagerService,
		private router: Router,
		private dialog_service: DialogService,
		private error_service: ErrorService,
	) {}

	ngOnInit(): void {
		if (this.refresh) {
			this.refresh.subscribe(refresh => {
				if (refresh) {
					const station: Station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);
					if (station) {
						this.listBookingMedia(station);
					} else {
						this.router.navigate([STATION_PATH]);
					}
				}
			});
		}
	}

	onMediaClick(media: BookingMedia): void {
		this.dialog_service.openDialog(BookingDetailsGalleryDialogComponent, {
			current:
				media.type == BookingMediaType.PRE_REPORT
					? this.pre_reports.map(m => m.name).indexOf(media.name)
					: this.post_reports.map(m => m.name).indexOf(media.name),
			gallery:
				media.type == BookingMediaType.PRE_REPORT
					? this.pre_reports
					: this.post_reports,
			type: media.type,
		});
	}

	listBookingMedia(station: Station): void {
		this.is_request_running = true;
		this.booking_media_service.list(station.id, this.booking.id).subscribe(
			response => {
				this.booking_media = response.data;
				const preReports: BookingMedia[] = this.booking_media.filter(bm => {
					return bm.type == BookingMediaType.PRE_REPORT;
				});
				const postReports: BookingMedia[] = this.booking_media.filter(bm => {
					return bm.type == BookingMediaType.POST_REPORT;
				});

				const uniquePreReports: BookingMedia[] = preReports.filter(
					(value, index, self) =>
						self.map(x => x.name).indexOf(value.name) == index,
				);
				this.pre_reports = uniquePreReports;

				const uniquePostReports: BookingMedia[] = postReports.filter(
					(value, index, self) =>
						self.map(x => x.name).indexOf(value.name) == index,
				);
				this.post_reports = uniquePostReports;

				this.is_request_running = false;
			},
			error => {
				const converted_error = this.error_service.convertError(error);
				if (converted_error.code === BOOKING_MEDIA_NOT_FOUND_ERROR_CODE) {
					this.booking_media = [];
				} else {
					this.error_service.processError(converted_error);
				}
				this.is_request_running = false;
			},
		);
	}

	getPolicyLabel(policy: string): string {
		switch (policy) {
			case PicturePolicy.MANDATORY:
				return PICTURES_MANDATORY_POLICY_TITLE;
			case PicturePolicy.MANDATORY_PRE:
				return PICTURES_PICK_UP_POLICY_TITLE;
			case PicturePolicy.MANDATORY_POST:
				return PICTURES_DROP_OFF_POLICY_TITLE;
			case PicturePolicy.OPTIONAL:
				return PICTURES_OPTIONAL_POLICY_TITLE;
			case PicturePolicy.DISABLED:
				return PICTURES_DISABLED_POLICY_TITLE;
		}
	}
}
