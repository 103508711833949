import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { uploadData } from 'aws-amplify/storage';
import { LOCAL_STATION_KEY } from '../../shared/constants';
import { Station } from '../../core/station.service';
import { LocalManagerService } from '../../core/local-manager.service';
import { Vehicle } from '../../core/vehicle.service';

@Component({
	selector: 'app-vehicle-details-image-dialog',
	templateUrl: './vehicle-details-image-dialog.component.html',
	styleUrls: ['./vehicle-details-image-dialog.component.scss'],
})
export class VehicleDetailsImageDialogComponent implements OnInit {
	vehicle: Vehicle;
	image: string | ArrayBuffer;
	image_key: string;
	is_picture_loading: boolean = false;

	constructor(
		private dialog_ref: MatDialogRef<VehicleDetailsImageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data: any,
		private local_manager: LocalManagerService,
	) {
		this.vehicle = data.vehicle;
	}

	ngOnInit() {}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onFileInput(event) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		if (station) {
			const file: File = event.target.files[0];
			const reader = new FileReader();
			const stationName = station.name;
			const vehicleName = this.vehicle.plate;
			reader.readAsDataURL(file);
			reader.onload = _event => {
				this.image = reader.result;
				this.is_picture_loading = true;

				uploadData({
					data: file,
					path: `${stationName}/vehicles/${vehicleName}/${file.name}`,
				})
					.result.then(result => {
						this.image_key = result.path;
						this.is_picture_loading = false;
					})
					.catch(err => {
						this.is_picture_loading = false;
					});
			};
		}
	}

	onConfirmClick() {
		this.dialog_ref.close({ imageKey: this.image_key });
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_picture_loading,
			'lk-main-button': !this.is_picture_loading,
		};
	}
}
