import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ToolbarComponent } from './toolbar/toolbar.component';
import { ToolbarStationComponent } from './toolbar-station/toolbar-station.component';
import { ToolbarStationNavigationComponent } from './toolbar-station-navigation/toolbar-station-navigation.component';
import { ToolbarStationSelectorComponent } from './toolbar-station-selector/toolbar-station-selector.component';
import { ToolbarProfileComponent } from './toolbar-profile/toolbar-profile.component';

@NgModule({
	declarations: [ToolbarComponent, ToolbarStationComponent, ToolbarStationNavigationComponent, ToolbarStationSelectorComponent, ToolbarProfileComponent],
	imports: [
		CommonModule,
		SharedModule,
		TranslateModule,
		MatButtonModule,
		MatIconModule,
		MatToolbarModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
	],
	exports: [ToolbarComponent],
})
export class ToolbarModule {}
