<app-booking-table-controls
	class="lk-display__hidden-before-md"
	[update_time]="update_time"
	[current_locale]="current_locale"
	(search_change)="search_change.emit(form_group.get('email').value)"
	(filter_change)="filters_change.emit()"></app-booking-table-controls>

<div class="lk-hide-scrollbar lk-overflow__x-scroll">
	<table
		mat-table
		[dataSource]="bookings"
		class="lk-full-width">
		<ng-container matColumnDef="id">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header lk-booking-table-header-top-left-border-radius">
				Linkey ID
			</th>
			<td
				mat-cell
				*matCellDef="let booking">
				<div class="lk-row">
					{{
						booking.id.substr(0, 3) +
							booking.id.substr(booking.id.length - 8, booking.id.length)
					}}
					<img
						style="width: 50px"
						*ngIf="booking.ext_id"
						src="../../../assets/imgs/lk-turo-logo.png" />
				</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="ext_id">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header lk-booking-table-header-top-left-border-radius">
				Turo ID
			</th>
			<td
				mat-cell
				*matCellDef="let booking">
				<span *ngIf="booking.ext_id"> {{ booking.ext_id }} </span>
				<span *ngIf="!booking.ext_id"> - </span>
			</td>
		</ng-container>

		<ng-container matColumnDef="user_phone">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header lk-booking-table-header-top-left-border-radius">
				{{ 'BOOKING.USER_PHONE_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let booking">
				+{{ booking.user.phone.prefix }} {{ booking.user.phone.value }}
			</td>
		</ng-container>

		<ng-container matColumnDef="user_name">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'BOOKING.USER_NAME_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let booking">
				<span
					*ngIf="
						booking.user && booking.user.first_name && booking.user.last_name
					">
					{{ booking.user.first_name }} {{ booking.user.last_name }}
				</span>

				<span
					*ngIf="
						!booking.user || !booking.user.first_name || !booking.user.last_name
					">
					-
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="start">
			<th
				(click)="onSortStartDateClick()"
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header lk-cursor-pointer">
				<div style="display: flex; align-items: center">
					{{ 'BOOKING.START_COLUMN_LABEL' | translate }}
					<mat-icon
						class="lk-accent-color"
						*ngIf="
							current_start_date_sort_option &&
							current_start_date_sort_option == asc_option
						">
						expand_less
					</mat-icon>
					<mat-icon
						class="lk-accent-color"
						*ngIf="
							current_start_date_sort_option &&
							current_start_date_sort_option == desc_option
						">
						expand_more
					</mat-icon>
					<mat-icon *ngIf="!current_start_date_sort_option">
						swap_vert
					</mat-icon>
				</div>
			</th>
			<td
				mat-cell
				*matCellDef="let booking">
				<span class="lk-ellipsized-text">
					{{ getTimezoneStartTime(booking) | date: 'short':'':current_locale }}
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="end">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'BOOKING.END_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let booking">
				<span class="lk-ellipsized-text">
					{{ getTimezoneEndTime(booking) | date: 'short':'':current_locale }}
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="timezone">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				Timezone
			</th>
			<td
				mat-cell
				*matCellDef="let booking">
				<span class="lk-ellipsized-text">
					{{ getTimezoneOffset(booking) }}
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="vehicle">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'BOOKING.VEHICLE_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let booking">
				<span *ngIf="booking.vehicle">
					{{ booking.vehicle.brand }} {{ booking.vehicle.model }}
				</span>
				<span *ngIf="!booking.vehicle"> - </span>
			</td>
		</ng-container>

		<ng-container matColumnDef="plate">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'BOOKING.VEHICLE_PLATE_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let booking">
				<span *ngIf="booking.vehicle"> {{ booking.vehicle.plate }} </span>
				<span *ngIf="!booking.vehicle"> - </span>
			</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'BOOKING.STATUS_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let booking">
				<div class="lk-vehicle-table-online-circle">
					<div
						class="lk-border-radius"
						[ngClass]="getBookingStatusClass(booking)"
						style="padding: 2px 12px; width: fit-content">
						<span class="lk-ellipsized-text">
							{{ getBookingStatusLabel(booking) | translate }}
						</span>
					</div>
				</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="vehicle_status">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header">
				{{ 'BOOKING.VEHICLE_STATUS_COLUMN_LABEL' | translate }}
			</th>
			<td
				mat-cell
				*matCellDef="let booking"
				style="min-width: 80px">
				<div class="lk-vehicle-table-online-circle">
					<div
						class="lk-border-radius"
						[ngClass]="getVehicleStatusClass(booking.vehicle)"
						*ngIf="booking.vehicle && booking.vehicle.status"
						style="padding: 2px 12px; width: fit-content">
						<span> {{ booking.vehicle.status | lowercase }} </span>
					</div>
					<span *ngIf="!booking.vehicle || !booking.vehicle.status"> - </span>
				</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="actions">
			<th
				mat-header-cell
				*matHeaderCellDef
				class="lk-table-header lk-booking-table-header-top-right-border-radius">
				{{ 'BOOKING.ACTIONS_COLUMN_LABEL' | translate }}
			</th>

			<td
				mat-cell
				*matCellDef="let booking">
				<div class="lk-row">
					<button
						mat-icon-button
						*ngIf="isAddVehicleVisible(booking)"
						(click)="onAddVehicleClick(booking); $event.stopPropagation()"
						class="lk-icon__button-neutral-10 lk-margin__x6-left"
						matTooltip="{{ 'VEHICLE.ADD_CTA' | translate }}">
						<mat-icon class="lk-accent-color"> directions_car </mat-icon>
					</button>

					<button
						mat-icon-button
						(click)="onDeleteBookingClick(booking); $event.stopPropagation()"
						class="lk-icon__button-neutral-10 lk-margin__x6-left"
						matTooltip="{{ 'BOOKING.DELETE_CTA' | translate }}">
						<mat-icon style="color: red"> delete </mat-icon>
					</button>
				</div>
			</td>
		</ng-container>

		<tr
			mat-header-row
			*matHeaderRowDef="displayed_columns"
			class="lk-booking-table-header-bottom-border-radius"></tr>
		<tr
			mat-row
			*matRowDef="let row; columns: displayed_columns"
			(click)="onBookingClick(row)"
			class="lk-booking-table-header-bottom-border-radius lk-cursor__pointer"></tr>
	</table>
</div>
