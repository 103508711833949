import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import {
	BOOKINGS_PATH,
	METABASE_PATH,
	PROFILE_PATH,
	STATION_PATH,
	VEHICLES_PATH,
} from 'src/app/core/navigation.service';
import { Station, StationService } from 'src/app/core/station.service';
import {
	DEFAULT_STATION_LOGO,
	ERROR_DESCRIPTION_500,
	LOCAL_STATION_KEY,
	PROD_STAGE,
	STATION_NOT_FOUND_ERROR_CODE,
} from '../constants';
import { SharedMenuComponent } from '../shared-menu/shared-menu.component';
import {
	GENERAL_ERROR_MESSAGE,
	TOOLBAR_BOOKINGS_NAV_ITEM_NAME,
	TOOLBAR_METABASE_NAV_ITEM_NAME,
	TOOLBAR_STATION_NAV_ITEM_NAME,
	TOOLBAR_VEHICLES_NAV_ITEM_NAME,
} from '../translations';
import { environment } from 'src/environments/environment';
import { ErrorService } from 'src/app/core/error.service';

@Component({
	selector: 'app-shared-app-toolbar',
	templateUrl: './shared-app-toolbar.component.html',
	styleUrls: ['./shared-app-toolbar.component.scss'],
})
export class SharedAppToolbarComponent implements OnInit {
	@Input() current_nav_item: string;
	@Input() refresh: BehaviorSubject<boolean>;
	@Output() station_change: EventEmitter<Station> = new EventEmitter<Station>();
	@Output() no_station_found: EventEmitter<void> = new EventEmitter<void>();
	@Output() station_build_click: EventEmitter<void> = new EventEmitter<void>();
	nav_items: NavItem[] = NAV_ITEMS;
	stations: Station[];
	station: Station;
	logo: string;
	is_station_loading: boolean = false;
	username: string = '';
	scroll_position: number;
	is_production: boolean;

	constructor(
		private station_service: StationService,
		private auth_service: AuthenticationService,
		private local_manager: LocalManagerService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private error_service: ErrorService,
		private router: Router,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		this.is_production = environment.STAGE == PROD_STAGE;

		this.initToolbar();
		this.setNavItems();
		this.refresh.subscribe({
			next: do_refresh => {
				if (do_refresh) {
					this.station = null;
					this.logo = null;
					this.initToolbar(true);
				}
			},
		});
	}

	@HostListener('window:scroll', ['$event'])
	onScroll(event: any): void {
		this.scroll_position = window.pageYOffset;
	}

	onHomeClick(): void {
		this.router.navigate([STATION_PATH]);
	}

	onNavItemClick(item: NavItem): void {
		this.router.navigate([item.path]);
	}

	onStationBuildClick(): void {
		this.station_build_click.emit();
	}

	onStationClick(station: Station): void {
		this.station = station;
		this.local_manager.setLocalObject(this.station, LOCAL_STATION_KEY);
		this.logo = this.station.logo_url
			? this.station.logo_url
			: DEFAULT_STATION_LOGO;
		this.setNavItems();
		this.readStation();
		this.station_change.emit(station);
	}

	onMenuClick(): void {
		this.dialog_service.openDialog(SharedMenuComponent, {
			station: this.station,
		});
	}

	onSignoutClick(): void {
		this.auth_service.signOut();
	}

	onProfileclick(): void {
		this.router.navigate([PROFILE_PATH]);
	}

	initToolbar(do_refresh?: boolean): void {
		if (
			localStorage.getItem(LOCAL_STATION_KEY) &&
			localStorage.getItem(LOCAL_STATION_KEY) != 'undefined' &&
			!do_refresh
		) {
			this.station = JSON.parse(
				localStorage.getItem(LOCAL_STATION_KEY),
			) as Station;
			this.local_manager.setLocalObject(this.station, LOCAL_STATION_KEY);
			this.logo = this.station.logo_url
				? this.station.logo_url
				: DEFAULT_STATION_LOGO;
			this.readStation();
			this.listStations(false);
		} else {
			this.listStations(true);
		}

		this.auth_service.getAuthenticatedUser().subscribe({
			next: response => {
				this.username = response.signInDetails?.loginId;
			},
		});
	}

	readStation(): void {
		if (this.station) {
			this.station_service.read(this.station.id).subscribe({
				next: response => {
					this.station = response.data;
					this.local_manager.setLocalObject(this.station, LOCAL_STATION_KEY);
					this.setNavItems();
				},
				error: () => {
					this.nav_items = NAV_ITEMS;
				},
			});
		}
	}

	listStations(set_station: boolean): void {
		const page: number = 1;
		const per_page: number = 10;
		this.station_service.list(page, per_page).subscribe({
			next: response => {
				response.body.json().then(response => {
					this.stations = response.items;

					if (set_station) {
						this.setStation();
					}
				});

				/*if (!this.isStationAvailable()) {
					localStorage.removeItem(LOCAL_STATION_KEY);
					this.setStation();
				}*/
			},
			error: error => {
				const converted_error = this.error_service.convertError(error);
				if (converted_error.code === STATION_NOT_FOUND_ERROR_CODE) {
					this.stations = [];
					this.station = null;
					this.no_station_found.emit();
				} else {
					this.error_service.processError(converted_error);
				}
				this.is_station_loading = false;
				this.logo = DEFAULT_STATION_LOGO;
			},
		});
	}

	setStation(): void {
		let local_station: Station, selected_station: Station;
		if (
			localStorage.getItem(LOCAL_STATION_KEY) &&
			localStorage.getItem(LOCAL_STATION_KEY) != 'undefined'
		) {
			/*local_station = JSON.parse(
				localStorage.getItem(LOCAL_STATION_KEY),
			) as Station;
			const local_replacement_cond = this.stations.filter(s => {
				return local_station && s.id == local_station.id;
			});
			selected_station =
				local_replacement_cond && local_replacement_cond.length
					? local_replacement_cond[0]
					: this.stations[0];*/
			selected_station = JSON.parse(
				localStorage.getItem(LOCAL_STATION_KEY),
			) as Station;
		} else {
			selected_station = this.stations[0];
		}
		this.station = selected_station;
		this.local_manager.setLocalObject(this.station, LOCAL_STATION_KEY);
		this.logo = this.station.logo_url
			? this.station.logo_url
			: DEFAULT_STATION_LOGO;

		this.setNavItems();

		this.station_change.emit(this.station);
	}

	isStationAvailable(): boolean {
		const filtered_stations = this.stations.filter(s => {
			return (
				s.id ==
				(JSON.parse(localStorage.getItem(LOCAL_STATION_KEY)) as Station)?.id
			);
		});
		const is_station_available: boolean =
			filtered_stations && filtered_stations.length ? true : false;
		return is_station_available;
	}

	setNavItems(): void {
		if (this.station?.meta_dashboard) {
			this.nav_items = [...NAV_ITEMS, METABASE_NAV_ITEM];
		} else {
			this.nav_items = NAV_ITEMS;
		}
	}

	getStationLogo(station: Station): string {
		return station.logo_url ? station.logo_url : DEFAULT_STATION_LOGO;
	}

	getToolbarShadowClass(): { [key: string]: boolean } {
		return {
			'lk-shadow': this.scroll_position > 50,
			'': this.scroll_position <= 50,
		};
	}

	getNavItemClass(item: NavItem): { [key: string]: boolean } {
		return {
			'lk-accent-background':
				this.current_nav_item && item.name == this.current_nav_item,
			'': !this.current_nav_item || item.name != this.current_nav_item,
		};
	}
}

export class NavItem {
	name: string;
	path: string;
	icon: string;
}

export const NAV_ITEMS: NavItem[] = [
	{
		name: TOOLBAR_STATION_NAV_ITEM_NAME,
		path: STATION_PATH,
		icon: 'lk-stations',
	},
	{
		name: TOOLBAR_VEHICLES_NAV_ITEM_NAME,
		path: VEHICLES_PATH,
		icon: 'lk-vehicles',
	},
	{
		name: TOOLBAR_BOOKINGS_NAV_ITEM_NAME,
		path: BOOKINGS_PATH,
		icon: 'lk-bookings',
	},
];

export const METABASE_NAV_ITEM: NavItem = {
	name: TOOLBAR_METABASE_NAV_ITEM_NAME,
	path: METABASE_PATH,
	icon: 'lk-dashboard',
};
