import { Component, Input } from '@angular/core';
import { Station } from 'src/app/core/station.service';
import {
	MAPBOX_API_KEY,
	MAPBOX_VERSION,
	MAP_DEFAULT_BASE_POINT,
	MAP_DEFAULT_URL,
	MAP_DEFAULT_ZOOM,
	MAP_MAX_ZOOM,
	MAP_STATION_MARKER_ICON,
} from 'src/app/shared/constants';

declare var L: any;

@Component({
	selector: 'app-station-map',
	templateUrl: './station-map.component.html',
	styleUrl: './station-map.component.scss',
})
export class StationMapComponent {
	@Input() station?: Station;

	map: any;

	ngOnInit(): void {
		this.loadMap();
	}

	setStation(station: Station): void {
		this.station = station;
	}

	loadMap(): void {
		if (this.map) {
			this.map.remove();
		}

		const is_attached = document.getElementById('lk-station-map-layout')
			? true
			: false;

		if (is_attached) {
			this.map = L.map('lk-station-map-layout', {
				attributionControl: false,
				minZoom: 10,
				zoomControl: false,
			});

			const mapbox_url = `${MAP_DEFAULT_URL}${MAPBOX_API_KEY}`;

			this.map.setView(MAP_DEFAULT_BASE_POINT, MAP_DEFAULT_ZOOM);

			L.tileLayer(mapbox_url, {
				attribution: '',
				maxZoom: MAP_MAX_ZOOM,
				id: MAPBOX_VERSION,
				accessToken: 'blank.mapbox.access.token',
			}).addTo(this.map);

			this.loadMarker();
		} else {
			setTimeout(() => {
				this.loadMap();
			}, 1000);
		}
	}

	loadMarker(): void {
		if (this.map && this.station?.address?.lat_lng) {
			const lk_marker_icon = L.icon(MAP_STATION_MARKER_ICON);

			L.marker(
				[this.station.address.lat_lng[0], this.station.address.lat_lng[1]],
				{ icon: lk_marker_icon },
			).addTo(this.map);

			this.map.setView(
				[this.station.address.lat_lng[0], this.station.address.lat_lng[1]],
				MAP_MAX_ZOOM,
			);
		}
	}
}
