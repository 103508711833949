import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Booking } from '../../core/booking.service';
import { DialogService } from '../../core/dialog.service';
import { BookingAddVehicleDialogComponent } from '../booking-add-vehicle-dialog/booking-add-vehicle-dialog.component';
import { Vehicle } from '../../core/vehicle.service';
import { getTimezoneOffset } from 'date-fns-tz';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/core/form.service';
import { Station } from 'src/app/core/station.service';
import {
	BOOKING_START_DATE_SORT_ASC_OPTION,
	BOOKING_START_DATE_SORT_DESC_OPTION,
	BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
	BOOKING_TABLE_COLUMNS,
	BOOKING_TABLE_TURO_COLUMNS,
	PROD_STAGE,
	LOCAL_STATION_KEY,
	DATE_FORMATS,
	EN_LOCALE,
	IT_LOCALE,
} from 'src/app/shared/constants';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { BookingDeleteDialogComponent } from '../booking-delete-dialog/booking-delete-dialog.component';
import { BOOKING_PENDING_LABEL } from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/core/utils.service';
import { NavigationService } from 'src/app/core/navigation.service';

@Component({
	selector: 'app-booking-table',
	templateUrl: './booking-table.component.html',
	styleUrls: ['./booking-table.component.scss'],
})
export class BookingTableComponent implements OnInit {
	@Input() bookings: Booking[];
	@Output() filters_change: EventEmitter<void> = new EventEmitter<void>();
	@Output() search_change: EventEmitter<string> = new EventEmitter<string>();
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();
	@Output() delete_click: EventEmitter<Booking> = new EventEmitter<Booking>();
	@Output() sort_change: EventEmitter<string> = new EventEmitter<string>();
	@Output() vehicle_add: EventEmitter<{
		booking: Booking;
		vehicle: Vehicle;
	}> = new EventEmitter<{ booking: Booking; vehicle: Vehicle }>();

	form_group: FormGroup;
	update_time: number;

	is_link_loading = false;

	displayed_columns: string[];
	desc_option = BOOKING_START_DATE_SORT_DESC_OPTION;
	asc_option = BOOKING_START_DATE_SORT_ASC_OPTION;
	pending_label = BOOKING_PENDING_LABEL;
	current_start_date_sort_option?: string;
	is_production?: boolean;
	current_locale?: string;

	constructor(
		private dialog_service: DialogService,
		private local_manager: LocalManagerService,
		private form_service: FormService,
		private utils_service: UtilsService,
		private navigation_service: NavigationService,
	) {
		this.form_group = this.form_service.bookingSearchFormGroup;

		this.current_start_date_sort_option = localStorage.getItem(
			BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
		);

		const current_station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		switch (current_station.settings.date_format) {
			case DATE_FORMATS[0]:
				this.current_locale = EN_LOCALE;
				break;
			case DATE_FORMATS[1]:
				this.current_locale = IT_LOCALE;
				break;
			default:
				this.current_locale = EN_LOCALE;
				break;
		}
	}

	ngOnInit() {
		this.is_production = environment.STAGE == PROD_STAGE;

		const station = JSON.parse(
			localStorage.getItem(LOCAL_STATION_KEY),
		) as Station;

		if (station.ext_email) {
			this.displayed_columns = BOOKING_TABLE_TURO_COLUMNS;
		} else {
			this.displayed_columns = BOOKING_TABLE_COLUMNS;
		}

		this.setStation(station, this.bookings);
	}

	onAddVehicleClick(booking: Booking) {
		this.dialog_service
			.openDialog(BookingAddVehicleDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.vehicle) {
					this.vehicle_add.emit({
						booking,
						vehicle: dialogResult.vehicle,
					});
				}
			});
	}

	onDeleteBookingClick(booking: Booking) {
		this.dialog_service
			.openDialog(BookingDeleteDialogComponent, { booking: booking })
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.confirm) {
					this.delete_click.emit(booking);
				}
			});
	}

	onBookingClick(booking: Booking) {
		this.navigation_service.toBookingsDetails(booking.id);
	}

	onSortStartDateClick() {
		switch (this.current_start_date_sort_option) {
			case BOOKING_START_DATE_SORT_ASC_OPTION:
				this.current_start_date_sort_option =
					BOOKING_START_DATE_SORT_DESC_OPTION;
				break;
			case BOOKING_START_DATE_SORT_DESC_OPTION:
				this.current_start_date_sort_option = undefined;
				break;
			default:
				this.current_start_date_sort_option =
					BOOKING_START_DATE_SORT_ASC_OPTION;
				break;
		}

		if (this.current_start_date_sort_option) {
			localStorage.setItem(
				BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
				this.current_start_date_sort_option,
			);
		} else {
			localStorage.removeItem(BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY);
		}

		this.sort_change.emit(this.current_start_date_sort_option);
	}

	setStation(station: Station, bookings: Booking[]): void {
		this.update_time = new Date().getTime();
		this.bookings = bookings;

		this.current_start_date_sort_option = localStorage.getItem(
			BOOKING_START_DATE_SORT_OPTION_LOCAL_KEY,
		);
	}

	getBookingStatusLabel(booking: Booking): string {
		return this.utils_service.getBookingStatusLabel(booking);
	}

	getBookingStatusClass(booking: Booking): { [key: string]: boolean } {
		return this.utils_service.getBookingStatusClass(booking);
	}

	getVehicleStatusClass(vehicle: Vehicle): { [key: string]: boolean } {
		return this.utils_service.getVehicleStatusClass(vehicle);
	}

	getTimezoneOffset(booking: Booking) {
		return (
			'GMT' +
			(getTimezoneOffset(booking.timezone) / 3600000 >= 0 ? '+' : '') +
			getTimezoneOffset(booking.timezone) / 3600000
		);
	}

	getTimezoneStartTime(booking: Booking) {
		return (
			booking.start_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}

	getTimezoneEndTime(booking: Booking) {
		return (
			booking.end_date -
			getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone) +
			getTimezoneOffset(booking.timezone)
		);
	}

	isAddVehicleVisible(booking: Booking) {
		return this.getBookingStatusLabel(booking) == this.pending_label;
	}
}
