import { Component, OnInit } from '@angular/core';
import { SUPPORTED_LANGUAGES } from '../../shared/constants';
import {
	DEFAULT_LANGUAGE_LABEL,
	STATION_BUILDER_NOTIF_STEP,
	SUPPORTED_LANGUAGES_LABELS,
	TOOLBAR_STATION_NAV_ITEM_NAME,
} from '../../shared/translations';
import { FormGroup } from '@angular/forms';
import { FormService } from '../../core/form.service';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Station, StationService } from 'src/app/core/station.service';
import { NotificationService } from 'src/app/core/notification.service';
import {
	STATION_BUILDER_NOTIF_PATH,
	STATION_PATH,
} from 'src/app/core/navigation.service';

@Component({
	selector: 'app-station-builder-notif-lang',
	templateUrl: './station-builder-notif-lang.component.html',
	styleUrls: ['./station-builder-notif-lang.component.scss'],
})
export class StationBuilderNotifLangComponent implements OnInit {
	station_id: string;
	station: Station;
	notifications: Notification[];
	form_group: FormGroup;
	languages: string[] = SUPPORTED_LANGUAGES;
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	current_item: string = TOOLBAR_STATION_NAV_ITEM_NAME;
	update_mode: boolean = false;
	is_request_running: boolean = false;
	current_step: string = STATION_BUILDER_NOTIF_STEP;

	constructor(
		private form_service: FormService,
		private station_service: StationService,
		private notif_service: NotificationService,
		private route: ActivatedRoute,
		private router: Router,
	) {
		this.form_group = form_service.stationBuilderNotifLangFormGroup;
		this.station_id = this.route.snapshot.paramMap.get('station_id');
		this.route.queryParams.subscribe(params => {
			if (params && params.update) {
				this.update_mode = params.update;
			}
		});
		if (!this.station_id) {
			this.router.navigate([STATION_PATH]);
		}
	}

	ngOnInit() {
		this.station_update_trigger.next(false);
		this.form_group.reset();
		if (this.station_id) {
			this.readStation();
		}
	}

	onNextClick() {
		if (this.form_service.validateForm(this.form_group)) {
			if (this.update_mode) {
				this.router.navigate(
					[
						STATION_PATH +
							'/' +
							this.station_id +
							STATION_BUILDER_NOTIF_PATH +
							'/' +
							this.form_group.get('lang').value,
					],
					{ queryParams: { update: true } },
				);
			} else {
				this.router.navigate([
					STATION_PATH +
						'/' +
						this.station_id +
						STATION_BUILDER_NOTIF_PATH +
						'/' +
						this.form_group.get('lang').value,
				]);
			}
		}
	}

	readStation() {
		this.is_request_running = true;
		this.station_service.read(this.station_id).subscribe({
			next: response => {
				this.station = response.data;
				this.listNotifications();
			},
			error: error => {
				this.router.navigate([STATION_PATH]);
				this.is_request_running = false;
			},
		});
	}

	listNotifications() {
		this.is_request_running = true;
		this.notif_service.list(this.station_id).subscribe({
			next: response => {
				this.notifications = response.data;
				this.is_request_running = false;
			},
			error: error => {
				this.notifications = [];
				this.is_request_running = false;
			},
		});
	}

	getLanguageLabel(lang: string) {
		switch (lang) {
			case this.languages[0]:
				return SUPPORTED_LANGUAGES_LABELS[0];
			case this.languages[1]:
				return SUPPORTED_LANGUAGES_LABELS[1];
			default:
				return DEFAULT_LANGUAGE_LABEL;
		}
	}
}
