import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { from, Observable } from 'rxjs';
import { get, post, put, del } from 'aws-amplify/api';

export const INVITATIONS_API_NAME: string = 'invitations';
export const INVITATIONS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/admin-invitations/';

@Injectable({
	providedIn: 'root',
})
export class InvitationService {
	batchCreate(station_id: string, emails: string[]): Observable<any> {
		const path: string = `stations/${station_id}/batch`;

		const init = { response: true, body: emails };

		return from(
			post({
				apiName: INVITATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	listByAdmin(): Observable<any> {
		const path: string = ``;

		const init = { response: true };

		return from(
			get({
				apiName: INVITATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	listByStation(station_id: string): Observable<any> {
		const path: string = `stations/${station_id}`;

		const init = { response: true };

		return from(
			get({
				apiName: INVITATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	setResponse(
		invitation_id: string,
		station_id: string,
		response: boolean,
	): Observable<any> {
		const path: string = `${invitation_id}/stations/${station_id}/response`;

		const init = {
			response: true,
			body: {
				response: response
					? InvitationResponseType.ACCEPTED
					: InvitationResponseType.DECLINED,
			},
		};

		return from(
			put({
				apiName: INVITATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	delete(station_id: string, invitation_id: string): Observable<any> {
		const path: string = `${invitation_id}/stations/${station_id}`;

		const init = { response: true };

		return from(
			del({
				apiName: INVITATIONS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}
}

export class Invitation {
	id: string;
	admin_email: string;
	expire_on: number;
	is_signed_admin: boolean;
	station_id: string;
	station_name: string;
	invitation_sender_id: string;
	invitation_sender_email: string;
	response: InvitationResponseType;
}

export enum InvitationResponseType {
	PENDING = 'pending',
	ACCEPTED = 'accepted',
	DECLINED = 'declined',
	EXPIRED = 'expired',
}
