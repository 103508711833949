<h2 class="lk-margin__0 lk-margin__x12-bottom">
	{{ 'STATION.TEMPLATES_LABEL' | translate }}
</h2>

<div
	*ngIf="!is_notifications_list_running"
	class="lk-padding__x24 lk-shadow lk-border-radius lk-background__light-neutral-0 lk-margin__x24-bottom">
	<app-station-settings-templates-item
		*ngFor="let template of template_items; let i = index"
		[is_expanded]="i == 0"
		[station]="station"
		[template]="template"
		(refresh)="onRefresh()"></app-station-settings-templates-item>
</div>

<div
	*ngIf="is_notifications_list_running"
	style="height: 400px; display: block"
	class="lk-shadow lk-border-radius">
	<app-loading class="lk-width__full lk-height__full"></app-loading>
</div>
