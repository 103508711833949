<app-loading
	*ngIf="is_events_request_running"
	class="lk-booking-details-events__loading lk-display__block lk-height__full lk-border-radius lk-shadow"></app-loading>

<div
	*ngIf="!is_events_request_running"
	id="lk-booking-details-events-root-layout"
	class="lk-border-radius lk-shadow lk-padding lk-primary-background lk-hide-scrollbar lk-full-height">
	<div
		class="lk-centered-row lk-full-height"
		*ngIf="is_events_request_running">
		<mat-spinner
			diameter="20"
			color="accent"></mat-spinner>
	</div>

	<h3
		*ngIf="!is_events_request_running"
		class="lk-no-margin lk-subtitle-size">
		{{ 'BOOKING_DETAILS.NAVBAR_EVENTS_LABEL' | translate }}
	</h3>

	<div
		*ngIf="!is_events_request_running"
		class="lk-spaced-row lk-wrapped-flex">
		<div
			class="lk-half-width lk-border-radius lk-primary-medium-background lk-padding lk-half-margin-top"
			style="min-width: fit-content">
			<p class="lk-no-margin lk-primary-medium-color lk-centered-text">
				{{ 'BOOKING.START_COLUMN_LABEL' | translate }}
			</p>
			<p class="lk-no-margin lk-centered-text">
				{{ booking.start_date | date: 'short':'':current_locale }}
			</p>
		</div>

		<div
			class="lk-half-width lk-border-radius lk-primary-medium-background lk-padding lk-half-margin-top"
			style="min-width: fit-content">
			<p class="lk-no-margin lk-primary-medium-color lk-centered-text">
				{{ 'BOOKING.END_COLUMN_LABEL' | translate }}
			</p>

			<p class="lk-no-margin lk-centered-text">
				{{ booking.end_date | date: 'short':'':current_locale }}
			</p>
		</div>
	</div>

	<div
		style="
			position: relative;
			overflow-y: scroll;
			max-height: calc(100% - 160px);
		"
		class="lk-margin-top lk-hide-scrollbar"
		*ngIf="!is_events_request_running">
		<div
			id="lk-booking-details-events-list"
			*ngFor="let event of events"
			class="lk-full-width lk-booking-details-events-list">
			<div class="lk-spaced-row">
				<div
					class="lk-row"
					style="align-items: flex-start; position: relative">
					<div
						class="lk-primary-medium-background"
						style="
							width: 4px;
							position: absolute;
							top: -12px;
							bottom: -12px;
							left: 4px;
						"></div>

					<div
						class="lk-primary-medium-background"
						style="
							width: 12px;
							min-width: 12px;
							height: 12px;
							border-radius: 50%;
							margin-top: 2px;
						"></div>

					<div>
						<p
							class="lk-no-margin lk-primary-medium-color lk-ellipsized-text"
							style="font-size: 12px; margin-left: 6px !important">
							{{ event.created_at | date: 'short':'':current_locale }}
						</p>

						<p
							class="lk-no-margin lk-ellipsized-text"
							style="margin-left: 6px !important">
							<a
								class="lk-dark-link"
								[routerLink]="[
									'/' + vehicle_details_path + '/' + event.data.vehicle_id
								]"
								>{{ getEventPrefix(event) | translate }}</a
							>
							{{ getEventLabel(event) | translate }}
						</p>
					</div>
				</div>
			</div>
			<hr class="lk-list-separator" />
		</div>
	</div>

	<div
		*ngIf="!is_events_request_running && events && !events.length"
		class="lk-centered-row">
		<p class="lk-primary-medium-color lk-centered-text">
			{{ 'BOOKING_DETAILS.NO_EVENTS_LABEL' | translate }}
		</p>
	</div>
</div>
