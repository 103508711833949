import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-vehicle-details-measures-item',
	templateUrl: './vehicle-details-measures-item.component.html',
	styleUrl: './vehicle-details-measures-item.component.scss',
})
export class VehicleDetailsMeasuresItemComponent {
	@Input() current_locale?: string;
	@Input() label?: string;
	@Input() value?: string;
	@Input() um?: string;
	@Input() updated_at?: number;
}
