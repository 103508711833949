import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from 'src/app/core/dialog.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { Station } from 'src/app/core/station.service';
import { Vehicle, VehicleService } from 'src/app/core/vehicle.service';
import { LOCAL_STATION_KEY } from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';
import { VehicleBuilderDialogComponent } from 'src/app/vehicle/vehicle-builder-dialog/vehicle-builder-dialog.component';
import { HiddenStationsCredentialsDialogComponent } from '../hidden-stations-credentials-dialog/hidden-stations-credentials-dialog.component';
import { HiddenStationsAliasDialogComponent } from '../hidden-stations-alias-dialog/hidden-stations-alias-dialog.component';
import { HiddenStationsSettingsDialogComponent } from '../hidden-stations-settings-dialog/hidden-stations-settings-dialog.component';
import { HiddenStationsChecklistDialogComponent } from '../hidden-stations-checklist-dialog/hidden-stations-checklist-dialog.component';

@Component({
	selector: 'app-hidden-stations-list-item',
	templateUrl: './hidden-stations-list-item.component.html',
	styleUrls: ['./hidden-stations-list-item.component.scss'],
})
export class HiddenStationsListItemComponent {
	@Input() station?: Station;
	@Input() station_logo?: string;
	@Input() station_name?: string;
	@Input() station_id?: string;
	@Input() is_current_station?: string;
	@Output() station_click: EventEmitter<void> = new EventEmitter<void>();
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();

	is_vehicles_loading: boolean = false;

	constructor(
		private snackbar: MatSnackBar,
		private local_manager: LocalManagerService,
		private vehicle_service: VehicleService,
		private dialog_service: DialogService,
	) {}

	onCopyClick(content?: string): void {
		if (content) {
			try {
				navigator.share({ text: content });
			} catch (error) {
				navigator.clipboard.writeText(content);
				this.snackbar.open('Copied!', '', { duration: 2000 });
			}
		}
	}

	onAddVehicleClick(station_id?: string): void {
		this.dialog_service
			.openDialog(VehicleBuilderDialogComponent)
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.vehicle) {
					this.createVehicle(dialogResult.vehicle, station_id);
				}
			});
	}

	onSettingsClick(): void {
		this.dialog_service
			.openDialog(HiddenStationsSettingsDialogComponent, {
				station: this.station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.snackbar.open('Settings updated!', '', { duration: 2000 });
					this.refresh.emit();
				} else if (dialog_result && !dialog_result.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onChecklistClick(): void {
		this.dialog_service
			.openDialog(HiddenStationsChecklistDialogComponent, {
				station: this.station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.snackbar.open('Checklist updated!', '', { duration: 2000 });
					this.refresh.emit();
				} else if (dialog_result && !dialog_result.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onAddCredentialsClick(): void {
		this.dialog_service
			.openDialog(HiddenStationsCredentialsDialogComponent, {
				station: this.station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.snackbar.open('Credentials updated!', '', { duration: 2000 });
					this.refresh.emit();
				} else if (dialog_result && !dialog_result.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onAddAliasClick(): void {
		this.dialog_service
			.openDialog(HiddenStationsAliasDialogComponent, {
				station: this.station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.snackbar.open('Alias updated!', '', { duration: 2000 });
					this.refresh.emit();
				} else if (dialog_result && !dialog_result.success) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	createVehicle(vehicle: Vehicle, station_id?: string): void {
		const local_station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		station_id = station_id ? station_id : local_station?.id;

		this.is_vehicles_loading = true;
		this.vehicle_service.create(station_id, vehicle).subscribe({
			next: () => {
				this.snackbar.open('Vehicle created!', '', { duration: 2000 });
				this.is_vehicles_loading = false;
			},
			error: () => {
				this.is_vehicles_loading = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}
}
