<div
	*ngIf="station"
	(click)="station_click.emit()"
	style="position: relative"
	class="lk-width__full lk-border-radius lk-padding__x24 lk-shadow lk-cursor__pointer lk-primary-background lk-row">
	<div
		class="lk-hidden-stations-list-item__logo lk-primary-medium-background lk-row__center">
		<img
			*ngIf="station_logo"
			class="lk-width__full"
			[src]="station_logo" />
	</div>

	<div
		id="lk-hidden-stations-list-item__info"
		class="lk-margin__x12-left">
		<h3 class="lk-text__ellipsized">{{ station_name }}</h3>

		<p class="lk-text__12 lk-primary-medium-color">
			<span *ngIf="station.alias"> # </span>
			{{ station?.alias || '' }}
		</p>

		<div class="lk-row">
			<mat-icon
				style="min-width: 24px"
				(click)="$event.stopPropagation(); onCopyClick(station_id)"
				class="lk-primary-medium-color">
				content_copy
			</mat-icon>

			<p class="lk-primary-medium-color lk-text__ellipsized">
				{{
					station_id.substr(0, 3) +
						station_id.substr(station_id.length - 8, station_id.length)
				}}
			</p>
		</div>
	</div>

	<div
		class="lk-row"
		style="position: absolute; top: 6px; right: 6px">
		<div
			class="lk-row"
			*ngIf="is_current_station">
			<span class="lk-text__12 lk-margin__x6-right lk-accent-color">
				current
			</span>
			<mat-icon class="lk-accent-color"> check_circle </mat-icon>
		</div>

		<button
			mat-icon-button
			[matMenuTriggerFor]="hstation_menu"
			(click)="$event.stopPropagation()">
			<mat-icon> more_vert </mat-icon>
		</button>

		<div
			*ngIf="is_vehicles_loading"
			class="lk-padding__x12-horiz">
			<mat-spinner
				diameter="20"
				class="lk-button__spinner-accent"></mat-spinner>
		</div>

		<mat-menu #hstation_menu="matMenu">
			<button
				mat-menu-item
				(click)="onAddVehicleClick(station_id)">
				<mat-icon> add </mat-icon>
				<p>Add vehicle</p>
			</button>

			<button
				mat-menu-item
				(click)="onSettingsClick()">
				<mat-icon> settings </mat-icon>
				<p>Settings</p>
			</button>

			<button
				mat-menu-item
				(click)="onChecklistClick()">
				<mat-icon> checklist </mat-icon>
				<p>Checklist</p>
			</button>

			<button
				mat-menu-item
				(click)="onAddAliasClick()">
				<mat-icon> create </mat-icon>
				<p>Update alias</p>
			</button>

			<button
				mat-menu-item
				(click)="onAddCredentialsClick()">
				<mat-icon> key </mat-icon>
				<p>Add credentials</p>
			</button>
		</mat-menu>
	</div>
</div>
