import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from 'src/app/core/dialog.service';
import { ErrorService, LinkeyError } from 'src/app/core/error.service';
import {
	METABASE_NAV_ITEM,
	NAV_ITEMS,
	NavItem,
} from 'src/app/core/navigation.service';
import { Station, StationService } from 'src/app/core/station.service';
import {
	DEFAULT_STATION_LOGO,
	LOCAL_STATION_KEY,
	STATION_NOT_FOUND_ERROR_CODE,
} from 'src/app/shared/constants';

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent {
	@Input() current_nav_item?: string;

	@Output() station_change: EventEmitter<Station> = new EventEmitter<Station>();
	@Output() no_station_found: EventEmitter<void> = new EventEmitter<void>();
	@Output() station_build: EventEmitter<void> = new EventEmitter<void>();

	station?: Station;
	nav_items?: NavItem[];
	logo?: string;
	is_stations_list_running = false;

	constructor(
		private station_service: StationService,
		private dialog_service: DialogService,
		private error_service: ErrorService,
	) {}

	ngOnInit(): void {
		const station =
			localStorage.getItem(LOCAL_STATION_KEY) &&
			localStorage.getItem(LOCAL_STATION_KEY) != 'undefined'
				? JSON.parse(localStorage.getItem(LOCAL_STATION_KEY))
				: undefined;

		if (station) {
			this.setupCurrentStation(station);
			this.is_stations_list_running = false;
		} else {
			this.listStations();
		}
	}

	onStationChange(station: Station): void {
		this.setupCurrentStation(station);
	}

	listStations(): void {
		const page: number = 1;
		const per_page: number = 10;
		this.is_stations_list_running = true;
		this.station_service.list(page, per_page).subscribe({
			next: response => {
				response.body.json().then(response => {
					const stations = response.items;
					this.setupCurrentStation(stations[0]);
					this.is_stations_list_running = false;
				});
			},
			error: error => {
				const lk_error: LinkeyError = this.error_service.convertError(error);
				if (lk_error.code === STATION_NOT_FOUND_ERROR_CODE) {
					this.no_station_found.emit();
				} else {
					this.dialog_service.openInfoDialog(lk_error.message);
				}
				this.logo = DEFAULT_STATION_LOGO;
				this.is_stations_list_running = false;
			},
		});
	}

	setupCurrentStation(station: Station): void {
		this.station = station;

		this.logo = this.station.logo_url
			? this.station.logo_url
			: DEFAULT_STATION_LOGO;

		if (this.station?.meta_dashboard) {
			this.nav_items = [...NAV_ITEMS, METABASE_NAV_ITEM];
		} else {
			this.nav_items = NAV_ITEMS;
		}

		localStorage.setItem(LOCAL_STATION_KEY, JSON.stringify(this.station));

		this.station_change.emit(this.station);
	}
}
