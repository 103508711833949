<div style="max-height: 80vh; min-width: 700px; max-width: 700px">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x24">
		<app-hidden-stations-checklist-dialog-header
			[checklist]="checklist"
			(clear)="onClearChecklistClick()">
		</app-hidden-stations-checklist-dialog-header>

		<form
			[formGroup]="form_group"
			class="lk-margin__x24-top"
			style="position: relative">
			<div
				class="lk-margin__x24-bottom lk-row__end"
				style="position: absolute; bottom: 56px; right: 0; z-index: 1">
				<mat-form-field
					appearance="fill"
					style="width: 124px"
					class="lk-form-field-no-padding-top lk-form-field">
					<img
						matPrefix
						style="min-width: 16px; height: 12px"
						class="lk-margin__x12-horiz"
						[src]="getCurrentLangPrefixImg()" />

					<mat-select formControlName="current_lang">
						<mat-option value="en">
							<img
								matPrefix
								style="min-width: 16px; height: 12px"
								class="lk-margin__x12"
								[src]="getCurrentLangPrefixImg('en')" />
							EN
						</mat-option>
						<mat-option value="es">
							<img
								matPrefix
								style="min-width: 16px; height: 12px"
								class="lk-margin__x12-right"
								[src]="getCurrentLangPrefixImg('es')" />
							ES
						</mat-option>
						<mat-option value="it"
							><img
								matPrefix
								style="min-width: 16px; height: 12px"
								class="lk-margin__x12-right"
								[src]="getCurrentLangPrefixImg('it')" />
							IT
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div style="min-height: 250px; max-height: 400px; overflow-y: scroll">
				<quill-editor formControlName="current_message"></quill-editor>
			</div>

			<div class="lk-width__full lk-row__space lk-wrap lk-margin__x24-top">
				<app-button
					class="lk-width__half-x8 lk-margin__x12-bottom lk-display__block"
					[button_class]="'lk-button__secondary-border lk-width__full'"
					[spinner_class]="'lk-button__spinner-white'"
					[cta]="'Add message'"
					[is_disabled]="is_save_running"
					(button_click)="onAddMessageClick()"></app-button>

				<app-button
					class="lk-width__half-x8 lk-margin__x12-bottom lk-display__block"
					[button_class]="'lk-button__secondary lk-width__full'"
					[spinner_class]="'lk-button__spinner-white'"
					[cta]="'GENERAL.SAVE_BUTTON_CTA' | translate"
					[is_disabled]="is_save_running"
					[is_spinner_visible]="is_save_running"
					(button_click)="onConfirmClick()"></app-button>
			</div>
		</form>
	</div>
</div>
