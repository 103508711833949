<div
	class="lk-row__space lk-margin__x12-bottom"
	style="align-items: flex-end">
	<span>
		{{ 'GENERAL.UPDATED_AT_LABEL' | translate }}:
		{{ update_time | date: 'short':'':current_locale }}
	</span>

	<div class="lk-row">
		<form [formGroup]="form_group">
			<mat-form-field class="lk-form-field">
				<mat-label>
					{{ 'BOOKING.SEARCH_BY_EMAIL_LABEL' | translate }}
				</mat-label>
				<mat-icon
					matPrefix
					class="lk-primary-medium-color">
					search
				</mat-icon>
				<input
					matInput
					type="text"
					formControlName="email"
					(change)="search_change.emit()" />
				<mat-icon
					matSuffix
					class="lk-primary-medium-color lk-cursor__pointer"
					(click)="filter_change.emit()">
					tune
				</mat-icon>
			</mat-form-field>
		</form>
	</div>
</div>
