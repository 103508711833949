<div
	*ngIf="current_search_value || current_alias_search_value"
	class="lk-width__full lk-column"
	style="align-items: flex-end">
	<hr class="lk-list-separator lk-width__full" />

	<div
		*ngIf="current_search_value"
		class="lk-row">
		<span>
			<span class="lk-primary-medium-color"> name: </span>
			{{ current_search_value }}
		</span>

		<mat-icon
			(click)="onNameReset()"
			class="lk-cursor__pointer lk-margin__x12-left">
			backspace
		</mat-icon>
	</div>

	<div
		*ngIf="current_alias_search_value"
		class="lk-row">
		<span>
			<span class="lk-primary-medium-color"> alias: </span>
			{{ current_alias_search_value }}
		</span>

		<mat-icon
			(click)="onAliasReset()"
			class="lk-cursor__pointer lk-margin__x12-left">
			backspace
		</mat-icon>
	</div>

	<hr class="lk-list-separator lk-full-width" />
</div>
