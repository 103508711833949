<div
	class="lk-padding__x24 lk-border-radius lk-primary-medium-background"
	style="min-width: fit-content">
	<p class="lk-text__center lk-text__12 lk-primary-medium-color">
		{{ label | translate }}
	</p>

	<h3 class="lk-text__center">
		<span *ngIf="value">
			{{ value | number: '0.2-2' }}
			<span class="lk-text__12">
				{{ um }}
			</span>
		</span>

		<span *ngIf="!value"> NA </span>
	</h3>

	<p
		*ngIf="updated_at"
		class="lk-text__center lk-text__10">
		&#64; {{ updated_at | date: 'short':'':current_locale }}
	</p>
</div>
