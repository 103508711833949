<div class="lk-auth-mfa-verify__grid lk-height__full lk-width__full">
	<div
		class="lk-auth-mfa-verify__form lk-margin__auto lk-border__radius lk-row lk-wrap lk-background__light-neutral-0 lk-z-index lk-shadow">
		<app-auth-logo-header
			[logo_src]="logo_src"
			class="lk-width__full"></app-auth-logo-header>

		<app-auth-mfa-verify-form
			[mfa_setup_mechanism]="mfa_setup_mechanism"
			[code_destination]="code_destination"></app-auth-mfa-verify-form>
	</div>

	<div
		class="lk-auth-mfa-verify__img lk-height__full lk-width__full lk-padding__x48 lk-display__hidden-before-lg"
		[ngStyle]="{ 'background-image': 'url(' + background_src + ')' }"></div>
</div>
