import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
	START_RENTAL_SMS_LABEL,
	START_RENTAL_EMAIL_LABEL,
	END_RENTAL_SMS_LABEL,
	END_RENTAL_EMAIL_LABEL,
	BOOKING_VEHICLE_EDITED_SMS_LABEL,
	BOOKING_VEHICLE_EDITED_EMAIL_LABEL,
	BOOKING_COMPLETE_SMS_LABEL,
	BOOKING_COMPLETE_EMAIL_LABEL,
	BOOKING_COMPLETED_LABEL,
	BOOKING_ERROR_WO_START_LABEL,
	BOOKING_ERROR_WO_STOP_LABEL,
	BOOKING_ERROR_WO_VEHICLE_LABEL,
	BOOKING_IN_PROGRESS_LABEL,
	BOOKING_PENDING_LABEL,
} from '../shared/translations';
import {
	NotificationName,
	NotificationType,
	TemplateItem,
} from './notification.service';
import { SUPPORTED_LANGUAGES } from '../shared/constants';
import { Booking, BookingStatus } from './booking.service';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Vehicle, VehicleStatus } from './vehicle.service';

@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	constructor(private snackbar: MatSnackBar) {}

	contentCopy(content?: string): void {
		if (!content) return;

		if (navigator.clipboard) {
			navigator.clipboard.writeText(content).then(() => {
				this.showSnackbar('Copied!', 'check_circle_outlined');
			});
		} else {
			const text_area = document.createElement('textarea');
			text_area.value = content;
			document.body.appendChild(text_area);
			text_area.focus();
			text_area.select();

			try {
				const successful = document.execCommand('copy');
				if (successful) {
					this.showSnackbar('Copied!', 'check_circle_outlined');
				}
			} catch (err) {}
			document.body.removeChild(text_area);
		}
	}

	showSnackbar(message: string, icon: string): void {
		this.snackbar.open(message, '', {
			duration: 2 * 1000,
			data: { message, icon },
		});
	}

	getNotificationLabelByTemplate(template: TemplateItem): string {
		return this.getNotificationLabel(
			template?.name as NotificationName,
			template?.type as NotificationType,
		);
	}

	getNotificationLabel(name: NotificationName, type: NotificationType): string {
		switch (name) {
			case NotificationName.BOOKING_START:
				return type == NotificationType.SMS
					? START_RENTAL_SMS_LABEL
					: START_RENTAL_EMAIL_LABEL;
			case NotificationName.BOOKING_END:
				return type == NotificationType.SMS
					? END_RENTAL_SMS_LABEL
					: END_RENTAL_EMAIL_LABEL;
			case NotificationName.BOOKING_VEHICLE_CHANGED:
				return type == NotificationType.SMS
					? BOOKING_VEHICLE_EDITED_SMS_LABEL
					: BOOKING_VEHICLE_EDITED_EMAIL_LABEL;
			case NotificationName.BOOKING_COMPLETE:
				return type == NotificationType.SMS
					? BOOKING_COMPLETE_SMS_LABEL
					: BOOKING_COMPLETE_EMAIL_LABEL;
			case NotificationName.QUICK_LINK:
				return 'Quick link SMS';
			default:
				return '';
		}
	}

	getStationFlag(lang?: string): string {
		switch (lang) {
			case SUPPORTED_LANGUAGES[0]:
				return '../../../assets/imgs/lk-flags/IT.svg';
			case SUPPORTED_LANGUAGES[1]:
				return '../../../assets/imgs/lk-flags/GB.svg';
			case SUPPORTED_LANGUAGES[2]:
				return '../../../assets/imgs/lk-flags/ES.svg';
			case SUPPORTED_LANGUAGES[3]:
				return '../../../assets/imgs/lk-flags/FR.svg';
			default:
				return '';
		}
	}

	getBookingStatusLabel(booking: Booking): string {
		if (!booking?.errors?.length) {
			if (this.isPendingBooking(booking)) {
				return BOOKING_PENDING_LABEL;
			} else if (this.isCompletedBooking(booking)) {
				return BOOKING_COMPLETED_LABEL;
			} else {
				return BOOKING_IN_PROGRESS_LABEL;
			}
		}

		if (booking?.errors?.length) {
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_VEHICLE)) {
				return BOOKING_ERROR_WO_VEHICLE_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_START)) {
				return BOOKING_ERROR_WO_START_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_STOP)) {
				return BOOKING_ERROR_WO_STOP_LABEL;
			}
		}
	}

	isPendingBooking(booking: Booking): boolean {
		const is_unlocked: boolean = booking.is_user_vehicle_unlocked;
		return !is_unlocked;
	}

	isCompletedBooking(booking: Booking): boolean {
		const lock_after_time: number =
			booking.settings.lock_settings
				.time_to_lock_availability_after_booking_end;

		const current_timezone: string =
			Intl.DateTimeFormat().resolvedOptions().timeZone;

		const is_in_time: boolean =
			new Date().getTime() >
			zonedTimeToUtc(
				booking.end_date + lock_after_time,
				current_timezone,
			).getTime();

		const is_locked: boolean = booking.is_user_vehicle_locked;

		return is_in_time || is_locked;
	}

	getBookingStatusClass(booking: Booking): { [key: string]: boolean } {
		let status_label: string;
		switch (this.getBookingStatusLabel(booking)) {
			case BOOKING_PENDING_LABEL:
				status_label = BookingStatus.PENDING;
				break;
			case BOOKING_IN_PROGRESS_LABEL:
				status_label = BookingStatus.IN_PROGRESS;
				break;
			case BOOKING_COMPLETED_LABEL:
				status_label = BookingStatus.COMPLETED;
				break;
			case BOOKING_ERROR_WO_VEHICLE_LABEL:
				status_label = BookingStatus.EXPIRED_WO_VEHICLE;
				break;
			case BOOKING_ERROR_WO_START_LABEL:
				status_label = BookingStatus.EXPIRED_WO_START;
				break;
			case BOOKING_ERROR_WO_STOP_LABEL:
				status_label = BookingStatus.EXPIRED_WO_STOP;
				break;
			default:
				status_label = BookingStatus.PENDING;
				break;
		}
		return {
			'lk-orange-status': status_label == BookingStatus.PENDING,
			'lk-green-status': status_label == BookingStatus.COMPLETED,
			'lk-blue-status': status_label == BookingStatus.IN_PROGRESS,
			'lk-red-status':
				status_label == BookingStatus.EXPIRED_WO_VEHICLE ||
				status_label == BookingStatus.EXPIRED_WO_START ||
				status_label == BookingStatus.EXPIRED_WO_STOP,
		};
	}

	getVehicleStatusClass(vehicle: Vehicle): { [key: string]: boolean } {
		return {
			'lk-vehicle-green-status':
				vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}
}
