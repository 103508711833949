import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { Station, StationService } from 'src/app/core/station.service';
import { VehicleOdometerUms } from 'src/app/core/vehicle.service';
import {
	DATE_FORMATS,
	DATE_FORMAT_LABELS,
	LOCAL_STATION_KEY,
} from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-station-settings-data',
	templateUrl: './station-settings-data.component.html',
	styleUrl: './station-settings-data.component.scss',
})
export class StationSettingsDataComponent {
	@Input() station?: Station;
	form_group: FormGroup = this.form_service.vehicles_settings_form_group;
	mi_um: VehicleOdometerUms = VehicleOdometerUms.mi;
	km_um: VehicleOdometerUms = VehicleOdometerUms.km;
	is_request_running: boolean = false;
	date_formats: { format: string; label: string }[] = [
		{
			format: DATE_FORMATS[0],
			label: DATE_FORMAT_LABELS[0],
		},
		{
			format: DATE_FORMATS[1],
			label: DATE_FORMAT_LABELS[1],
		},
	];

	constructor(
		private form_service: FormService,
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private station_service: StationService,
		private snackbar: MatSnackBar,
		private translate: TranslateService,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit(): void {
		if (this.station?.settings?.odometer_um) {
			this.form_group
				.get('odometer_um')
				?.setValue(this.station?.settings?.odometer_um);
		}

		if (this.station?.settings?.date_format) {
			this.form_group
				.get('date_format')
				?.setValue(this.station?.settings?.date_format);
		}
	}

	onSaveClick(): void {
		const odometer_um: string = this.form_group.get('odometer_um')?.value;
		const date_format: string = this.form_group.get('date_format')?.value;
		if (this.station?.settings) {
			this.station.settings.odometer_um = odometer_um;
			this.station.settings.date_format = date_format;
		}
		this.updateStation(this.station);
	}

	updateStation(station: Station): void {
		this.is_request_running = true;
		const station_logo_path = station.logo_path;
		const station_logo_url = station.logo_url;
		this.station_service.update(station).subscribe({
			next: response => {
				const station: Station = response.data;
				station.logo_path = station_logo_path;
				station.logo_url = station_logo_url;
				this.translate
					.get('STATION.UPDATE_COMPLETED_MESSAGE')
					.subscribe(message => {
						this.snackbar.open(message, '', { duration: 3000 });
					});
				localStorage.setItem(LOCAL_STATION_KEY, JSON.stringify(station));
				this.is_request_running = false;
			},
			error: error => {
				this.is_request_running = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	getDisabledClass() {
		return {
			'lk-disabled-button': this.is_request_running,
			'lk-main-button': !this.is_request_running,
		};
	}
}
