<div class="lk-height__max-80vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x12-horiz">
		<h3 class="lk-margin__x12-top">
			{{ 'BOOKING.ADD_PHONE_DIALOG_TITLE' | translate }}.
		</h3>

		<form
			class="lk-width__full lk-row__space lk-wrap lk-margin__x24-vert"
			[formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				floatLabel="always"
				class="lk-third-width lk-form-field lk-margin__x12-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_PREFIX_LABEL' | translate | titlecase }}
				</mat-label>
				<img
					*ngIf="current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-margin__x12-left lk-margin__x12-top"
					[src]="current_country_prefix.flag" />

				<img
					*ngIf="!current_country_prefix"
					matPrefix
					style="min-width: 16px; height: 12px"
					class="lk-margin__x12-left lk-margin__x12-top"
					[src]="unknown_flag_src" />

				<input
					type="text"
					matInput
					formControlName="phone_prefix"
					required
					[matAutocomplete]="autocountry" />

				<mat-autocomplete
					autoActiveFirstOption
					#autocountry="matAutocomplete"
					(optionSelected)="onCountryPrefixSelect()">
					<mat-option
						*ngFor="let country_prefix of filtered_options | async"
						[value]="country_prefix.code">
						<div class="lk-row">
							<img
								style="min-width: 32px; height: 24px"
								class="lk-margin__x12-right"
								[src]="country_prefix.flag" />
							<div class="lk-column">
								<p
									style="
										max-width: calc(100% - 6px);
										font-size: 10px;
										line-height: 18px;
									"
									class="lk-primary-medium-color lk-text__ellipsized">
									{{ country_prefix.name }}
								</p>
								<p
									style="line-height: 18px"
									class="lk-text__ellipsized">
									{{ country_prefix.prefix }}
								</p>
							</div>
						</div>
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-three-third-width lk-form-field lk-margin__x12-bottom">
				<mat-label>
					{{ 'GENERAL.PHONE_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					id="lk-booking-phone-builder-phone-input"
					matInput
					type="tel"
					formControlName="phone"
					required />
			</mat-form-field>

			<app-button
				class="lk-width__full lk-margin__x24-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.CONFIRM_CTA' | translate"
				[is_disabled]="is_request_loading"
				[is_spinner_visible]="is_request_loading"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>
