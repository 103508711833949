import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_STATION_LOGO } from '../constants';

@Component({
	selector: 'app-shared-logo',
	templateUrl: './shared-logo.component.html',
	styleUrl: './shared-logo.component.scss',
})
export class SharedLogoComponent {
	@Input() logo?: string;
	@Output() logo_click: EventEmitter<void> = new EventEmitter<void>();

	getLogoClass(): { [key: string]: boolean } {
		return {
			'': this.logo === DEFAULT_STATION_LOGO,
			'lk-padding__x4': this.logo != DEFAULT_STATION_LOGO,
		};
	}
}
