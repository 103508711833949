<div class="lk-height__max-70vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x24-horiz lk-hide-scrollbar">
		<app-station-settings-trigger-unlock
			(unlockableOnVehicleAssignmentChange)="
				onUnlockableOnVehicleAssignmentChange($event)
			"
			(unlockableUntilBookingEndChange)="
				onUnlockableUntilBookingEndChange($event)
			"
			[stationUpdateTrigger]="station_update_trigger">
		</app-station-settings-trigger-unlock>
	</div>

	<div
		style="position: sticky; bottom: -12px; left: 0"
		class="lk-width__full lk-padding__x24 lk-background__light-neutral-0">
		<app-button
			class="lk-width__full lk-display__block"
			[button_class]="'lk-button__secondary lk-width__full'"
			[spinner_class]="'lk-button__spinner-white'"
			[cta]="'GENERAL.NEXT_STEP_BUTTON_CTA' | translate"
			(button_click)="onConfirmClick()"></app-button>
	</div>
</div>
