<div class="lk-padding__x24 lk-row__center lk-width__full">
	<div
		style="overflow-x: scroll"
		class="lk-row lk-hide-scrollbar lk-cursor__pointer">
		<div
			*ngFor="let item of items"
			(click)="onItemClick(item)"
			[ngClass]="getItemBackgroundClass(item)"
			style="margin: 0 12px">
			<span class="lk-text__ellipsized">
				{{ getItemLabel(item) | translate | titlecase }}
			</span>
		</div>
	</div>
</div>
