<div>
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-margin__x24-top lk-padding__x24-horiz">
		<p>{{ 'VEHICLE.DELETE_CONFIRM_MESSAGE' | translate }}</p>

		<app-button
			class="lk-width__full lk-margin__x24-vert lk-display__block"
			[button_class]="'lk-button__warn lk-width__full'"
			[spinner_class]="'lk-button__spinner-white'"
			[cta]="'GENERAL.CONFIRM_CTA' | translate"
			(button_click)="onConfirmClick()"></app-button>
	</div>
</div>
