import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorService } from 'src/app/core/error.service';
import { FormService } from 'src/app/core/form.service';
import { InvitationService } from 'src/app/core/invitation.service';
import { ERROR_DESCRIPTION_500 } from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-station-add-team-dialog',
	templateUrl: './station-add-team-dialog.component.html',
	styleUrls: ['./station-add-team-dialog.component.scss'],
})
export class StationAddTeamDialogComponent implements OnInit {
	form_group: FormGroup;
	invites: string[];
	is_team_loading: boolean = false;
	station_id: string;

	constructor(
		private dialog_ref: MatDialogRef<StationAddTeamDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private form_service: FormService,
		private invitation_service: InvitationService,
		private error_service: ErrorService,
	) {
		this.form_group = this.form_service.stationBuilderTeamFormGroup;
		this.station_id = data.station_id;
	}

	ngOnInit(): void {
		this.invites = [];
		this.form_group.reset();
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		this.inviteTeammates(this.invites);
	}

	onAddTeammateClick(): void {
		if (this.form_service.validateForm(this.form_group)) {
			const email: string = this.form_group.get('email').value;
			this.invites.push(email);
			this.form_group.reset();
		}
	}

	inviteTeammates(invites: string[]) {
		this.is_team_loading = true;
		this.invitation_service.batchCreate(this.station_id, invites).subscribe({
			next: () => {
				this.is_team_loading = false;
				this.dialog_ref.close({ success: true });
			},
			error: error => {
				this.is_team_loading = false;
				const converted_error = this.error_service.convertError(error);
				this.dialog_ref.close({
					error_message: converted_error.message,
				});
			},
		});
	}

	getDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button':
				!this.invites || !this.invites.length || this.is_team_loading,
			'lk-main-button':
				this.invites && this.invites.length > 0 && !this.is_team_loading,
		};
	}
}
