<div class="lk-row__space lk-wrap">
	<div
		class="lk-row lk-display__hidden-before-md lk-cursor__pointer"
		(click)="onBackClick()">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40">
			<mat-icon class="lk-accent-color"> keyboard_arrow_left </mat-icon>
		</button>

		<p class="lk-accent-color lk-text__12 lk-margin__x6-left">
			{{ 'VEHICLE_DETAILS.BACK_CTA' | translate }}
		</p>
	</div>

	<div class="lk-vehicle-details-header">
		<h1
			*ngIf="vehicle && vehicle.plate"
			class="lk-margin__x6-bottom">
			{{ vehicle.plate }}
		</h1>

		<div
			class="lk-border-radius lk-primary-medium-background"
			style="padding: 2px 12px; width: fit-content"
			[ngClass]="getVehicleStatusClass(vehicle)">
			<span *ngIf="vehicle && vehicle.status">
				{{ vehicle.status | lowercase }}
			</span>
			<span *ngIf="vehicle && !vehicle.status"> unknown </span>
		</div>
	</div>

	<div
		class="lk-row__end"
		style="width: 135px">
		<button
			mat-icon-button
			(click)="refresh.emit()"
			class="lk-icon__button-neutral-40"
			matTooltip="{{ 'VEHICLE_DETAILS.REFRESH_CTA' | translate }}">
			<mat-icon class="lk-accent-color"> refresh </mat-icon>
		</button>

		<button
			mat-icon-button
			[matMenuTriggerFor]="menu"
			class="lk-icon__button-neutral-40 lk-margin__x8-left">
			<mat-icon class="lk-color__neutral-100"> more_horiz </mat-icon>
		</button>

		<mat-menu #menu="matMenu">
			<button
				mat-menu-item
				(click)="onVehicleDeleteClick()">
				<mat-icon class="lk-icon__warn"> delete </mat-icon>
				{{ 'VEHICLE_DETAILS.DELETE_VEHICLE' | translate }}
			</button>
		</mat-menu>
	</div>
</div>
