import { Injectable } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	FormGroupDirective,
	NgForm,
	Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export const AUTH_PASS_MIN_LENGTH: number = 8;
export const AUTH_PASS_PATTERN: string =
	'^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$';

@Injectable({
	providedIn: 'root',
})
export class FormService {
	signin_form_group: FormGroup = new FormBuilder().group({
		username: ['', [Validators.required, Validators.email]],
		password: ['', [Validators.required]],
	});

	signup_form_group: FormGroup = new FormBuilder().group({
		username: ['', [Validators.required, Validators.email]],
		password: [
			'',
			[
				Validators.required,
				Validators.minLength(AUTH_PASS_MIN_LENGTH),
				Validators.pattern(AUTH_PASS_PATTERN),
			],
		],
		password_confirm: [
			'',
			[
				Validators.required,
				Validators.minLength(AUTH_PASS_MIN_LENGTH),
				Validators.pattern(AUTH_PASS_PATTERN),
			],
		],
	});

	forgotPasswordEmailFormGroup: FormGroup = new FormBuilder().group({
		username: ['', [Validators.required, Validators.email]],
	});

	forgotPasswordConfirmationFormGroup: FormGroup = new FormBuilder().group({
		code: ['', [Validators.required]],
		password: [
			'',
			[
				Validators.required,
				Validators.minLength(AUTH_PASS_MIN_LENGTH),
				Validators.pattern(AUTH_PASS_PATTERN),
			],
		],
	});

	stationBuilderBasicsFormGroup: FormGroup = new FormBuilder().group({
		name: ['', [Validators.required]],
		email: ['', [Validators.required, Validators.email]],
		ext_email: ['', [Validators.email]],
		ext_timezone: ['', []],
		ext_time_format: ['', []],
		phone_prefix: ['', [Validators.required]],
		phone: ['', [Validators.required]],
		address: ['', [Validators.required]],
	});

	stationBuilderTeamFormGroup: FormGroup = new FormBuilder().group({
		email: ['', [Validators.email]],
	});

	stationBuilderNotifLangFormGroup: FormGroup = new FormBuilder().group({
		lang: ['', [Validators.required]],
	});

	stationSettingsUnlockLinkFormGroup: FormGroup = new FormBuilder().group({
		time: ['', []],
	});

	stationSettingsUnlockBeforeFormGroup: FormGroup = new FormBuilder().group({
		time: ['', []],
	});

	stationSettingsUnlockAfterFormGroup: FormGroup = new FormBuilder().group({
		time: ['', []],
	});

	stationSettingsLockReminderFormGroup: FormGroup = new FormBuilder().group({
		time: ['', []],
	});

	stationSettingsLockLinkFormGroup: FormGroup = new FormBuilder().group({
		time: ['', []],
	});

	stationSettingsLockBeforeFormGroup: FormGroup = new FormBuilder().group({
		time: ['', []],
	});

	stationSettingsLockAfterFormGroup: FormGroup = new FormBuilder().group({
		time: ['', []],
	});

	stationSettingLangFormGroup: FormGroup = new FormBuilder().group({
		lang: ['', [Validators.required]],
	});

	bookingSettingLangFormGroup: FormGroup = new FormBuilder().group({
		lang: ['', [Validators.required]],
	});

	vehicleBuilderFormGroup: FormGroup = new FormBuilder().group({
		plate: ['', [Validators.required]],
		ref_code: ['', []],
		profile: ['', []],
		adapter_id: ['', []],
		category: ['', []],
		brand: ['', [Validators.required]],
		model: ['', [Validators.required]],
		color: ['', []],
		year: ['', [Validators.required]],
		initial_status: [''],
		ext_id: [''],
	});

	vehicleFiltersFormGroup: FormGroup = new FormBuilder().group({
		current: [''],
		plate: [''],
		category: [''],
		brand: [''],
		status: [''],
	});

	vehicleSearchFormGroup: FormGroup = new FormBuilder().group({
		plate: [''],
	});

	bookingPhoneBuilderFormGroup: FormGroup = new FormBuilder().group({
		phone_prefix: ['', [Validators.required]],
		phone: ['', [Validators.required]],
	});

	bookingUserBuilderFormGroup: FormGroup = new FormBuilder().group({
		first_name: ['', [Validators.required]],
		last_name: ['', [Validators.required]],
		email: ['', [Validators.email]],
		tax_code: ['', []],
		address: [''],
		birth_date: [''],
	});

	bookingTimeBuilderFormGroup: FormGroup = new FormBuilder().group({
		start_date: ['', [Validators.required]],
		start_hour: ['', [Validators.required]],
		start_minute: ['', [Validators.required]],
		end_date: ['', [Validators.required]],
		end_hour: ['', [Validators.required]],
		end_minute: ['', [Validators.required]],
		timezone: ['', [Validators.required]],
	});

	bookingAddVehicleFormGroup: FormGroup = new FormBuilder().group({
		plate: ['', [Validators.required]],
	});

	bookingFiltersFormGroup: FormGroup = new FormBuilder().group({
		current: [''],
		plate: [''],
		email: [''],
		start_date: [''],
		end_date: [''],
	});

	bookingSearchFormGroup: FormGroup = new FormBuilder().group({
		email: [''],
	});

	hiddenSettingsAdapterCredentialsFormGroup: FormGroup =
		new FormBuilder().group({
			client_id: [''],
			client_secret: [''],
		});

	updateEmailDialogFormGroup: FormGroup = new FormBuilder().group({
		email: ['', [Validators.required]],
	});

	addNotifDialogFormGroup: FormGroup = new FormBuilder().group({
		notif_name: ['', [Validators.required]],
	});

	vehicleCommandsFilterFormGroup: FormGroup = new FormBuilder().group({
		created_from: [''],
		created_to: [''],
	});

	bookingEventsFilterFormGroup: FormGroup = new FormBuilder().group({
		created_from: [''],
		created_to: [''],
	});

	notifUpdateFormGroup: FormGroup = new FormBuilder().group({
		notif: ['', [Validators.required]],
	});

	notifAddContentFormGroup: FormGroup = new FormBuilder().group({
		notif: ['', [Validators.required]],
	});

	template_add_item_form_group: FormGroup = new FormBuilder().group({
		text: ['', [Validators.required]],
		current_parameter: [''],
	});

	template_item_form_group: FormGroup = new FormBuilder().group({
		text: ['', [Validators.required]],
		current_parameter: [''],
	});

	template_test_sms_form_group: FormGroup = new FormBuilder().group({
		phone_prefix: ['', [Validators.required]],
		phone: ['', [Validators.required]],
	});

	template_test_email_form_group: FormGroup = new FormBuilder().group({
		email: ['', [Validators.required, Validators.email]],
	});

	hidden_stations_search_form_group: FormGroup = new FormBuilder().group({
		name: [''],
		alias: [''],
	});

	hidden_stations_alias_form_group: FormGroup = new FormBuilder().group({
		alias: [''],
	});

	hidden_stations_settings_form_group: FormGroup = new FormBuilder().group({
		area_control: [''],
		interact_after_close: [''],
		parking_input: [''],
		unlock_confirmation: [''],
		custom_logo: [''],
		auto_close: [''],
		vehicle_change_custom_notif: [''],
		booking_closure_notif: [''],
		booking_closure_custom_notif: [''],
		sms_alias: ['', [Validators.maxLength(11)]],
		email_alias: [''],
		team_mgmt: [''],
		low_voltage_threshold: [''],
	});

	vehicle_add_contact_form_group: FormGroup = new FormBuilder().group({
		message: ['', [Validators.required]],
		phone: ['', [Validators.required]],
		email: ['', [Validators.required, Validators.email]],
	});

	vehicle_request_manual_contacts_form_group: FormGroup =
		new FormBuilder().group({
			station_name: ['', [Validators.required]],
			station_email: ['', [Validators.required, Validators.email]],
		});

	vehicle_request_manual_info_form_group: FormGroup = new FormBuilder().group({
		brand: ['', [Validators.required]],
		model: ['', [Validators.required]],
		plate: [''],
		year: ['', [Validators.required]],
	});

	booking_path_form_group: FormGroup = new FormBuilder().group({
		current_date: [''],
	});

	vehicles_settings_form_group: FormGroup = new FormBuilder().group({
		odometer_um: [''],
		date_format: [''],
	});

	checklist_message_form_group: FormGroup = new FormBuilder().group({
		current_lang: [''],
		current_message: [''],
	});

	mfa_setup_form_group: FormGroup = new FormBuilder().group({
		phone_prefix: ['', [Validators.required]],
		phone: ['', [Validators.required]],
	});

	mfa_setup_totp_form_group: FormGroup = new FormBuilder().group({
		token: ['', [Validators.required]],
	});

	mfa_verify_form_group: FormGroup = new FormBuilder().group({
		code: ['', [Validators.required]],
	});

	mfa_reset_form_group: FormGroup = new FormBuilder().group({
		email: ['', [Validators.required]],
		phone_prefix: ['', [Validators.required]],
		phone: ['', [Validators.required]],
	});

	mfa_host_reset_form_group: FormGroup = new FormBuilder().group({
		mfa_type: ['', [Validators.required]],
	});

	mfa_host_phone_reset_form_group: FormGroup = new FormBuilder().group({
		phone_prefix: ['', [Validators.required]],
		phone: ['', [Validators.required]],
	});

	set_superhost_form_group: FormGroup = new FormBuilder().group({
		email: ['', [Validators.required, Validators.email]],
	});

	direct_link_time_form_group: FormGroup = new FormBuilder().group({
		phone_prefix: [''],
		phone: [''],
		start_date: ['', [Validators.required]],
		start_hour: ['', [Validators.required]],
		start_minute: ['', [Validators.required]],
		end_date: ['', [Validators.required]],
		end_hour: ['', [Validators.required]],
		end_minute: ['', [Validators.required]],
		timezone: ['', [Validators.required]],
		type: ['', [Validators.required]],
	});

	direct_link_type_form_group: FormGroup = new FormBuilder().group({
		type: ['', [Validators.required]],
	});

	matcher: FormErrorStateMatcher = new FormErrorStateMatcher();

	validateForm(form_group: FormGroup): boolean {
		let valid: boolean = true;
		for (let control_name of Object.keys(form_group.controls)) {
			if (form_group.get(control_name)?.invalid) {
				valid = false;
			}
		}
		return valid;
	}
}

export class FormErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: FormControl | null,
		form: FormGroupDirective | NgForm | null,
	): boolean {
		const is_submitted = form && form.submitted;
		return !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched || is_submitted)
		);
	}
}