<div>
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		id="lk-vehicle-direct-link-confirm-content-layout"
		class="lk-padding__x24-horiz">
		<div
			*ngIf="is_link_sent"
			class="lk-row">
			<mat-icon
				class="lk-accent-color lk-margin__x12-right lk-vehicle-direct-link-confirm-success-icon">
				check_circle
			</mat-icon>

			<h2 class="lk-accent-color">
				{{ 'VEHICLE.DIRECT_LINK_SENT_MESSAGE' | translate }}
			</h2>
		</div>

		<p class="lk-text__center lk-margin__x12-vert">
			{{ 'VEHICLE.DIRECT_LINK_COPY_MESSAGE' | translate }}
		</p>

		<hr class="lk-list-separator lk-margin__0" />
		<div class="lk-row__center">
			<button
				(click)="onCopyClick(link)"
				mat-icon-button>
				<mat-icon> content_copy </mat-icon>
			</button>
			<a [href]="link"> {{ link }} </a>
		</div>
		<hr class="lk-list-separator lk-margin__0" />

		<div class="lk-margin__x24-bottom"></div>
	</div>
</div>
