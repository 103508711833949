import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { FormService } from 'src/app/core/form.service';
import { LockSettings, Station } from 'src/app/core/station.service';

@Component({
	selector: 'app-booking-settings-lock-dialog',
	templateUrl: './booking-settings-lock-dialog.component.html',
	styleUrls: ['./booking-settings-lock-dialog.component.scss'],
})
export class BookingSettingsLockDialogComponent implements OnInit {
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	station: Station;
	lock_settings: LockSettings;
	is_lockable_after_vehicle_unlock: boolean;

	constructor(
		private dialog_ref: MatDialogRef<BookingSettingsLockDialogComponent>,
		private form_service: FormService,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.station = data.station;
	}

	ngOnInit(): void {
		if (this.station) {
			this.lock_settings = this.station.settings.lock_settings;
			this.initFlags();
			this.station_update_trigger.next(true);
		}
	}

	onCloseClick() {
		this.dialog_ref.close();
	}

	onConfirmClick() {
		this.setLockSettings();
		this.dialog_ref.close({ lock_settings: this.lock_settings });
	}

	onLockableAfterVehicleUnlockChange(flag: boolean) {
		this.is_lockable_after_vehicle_unlock = flag;
	}

	initFlags() {
		this.is_lockable_after_vehicle_unlock = this.station.settings
			.unlock_settings
			? this.station.settings.lock_settings.is_lockable_after_vehicle_unlock
			: false;
	}

	setLockSettings() {
		let hoursAndMinutes: number[];
		let lockSettings: LockSettings = new LockSettings();
		lockSettings.is_lockable_after_vehicle_unlock =
			this.is_lockable_after_vehicle_unlock;
		hoursAndMinutes = this.parseTimeFormGroup(
			this.form_service.stationSettingsLockLinkFormGroup,
		);
		lockSettings.time_to_lock_link_before_booking_end =
			this.getMillisFromHoursAndMin(hoursAndMinutes[0], hoursAndMinutes[1]);
		hoursAndMinutes = this.parseTimeFormGroup(
			this.form_service.stationSettingsLockBeforeFormGroup,
		);
		lockSettings.time_to_lock_availability_before_booking_end =
			this.getMillisFromHoursAndMin(hoursAndMinutes[0], hoursAndMinutes[1]);
		hoursAndMinutes = this.parseTimeFormGroup(
			this.form_service.stationSettingsLockAfterFormGroup,
		);
		lockSettings.time_to_lock_availability_after_booking_end =
			this.getMillisFromHoursAndMin(hoursAndMinutes[0], hoursAndMinutes[1]);
		this.lock_settings = lockSettings;
	}

	parseTimeFormGroup(formGroup: FormGroup): number[] {
		const time: string = formGroup.get('time').value;
		const columnIndex: number = time.indexOf(':');
		return [
			+time.substring(0, columnIndex),
			+time.substring(columnIndex + 1, time.length),
		];
	}

	getMillisFromHoursAndMin(hours: number, minutes: number) {
		const HOUR_IN_MILLIS: number = 3600000;
		const MINUTE_IN_MILLIS: number = 60000;
		return hours * HOUR_IN_MILLIS + minutes * MINUTE_IN_MILLIS;
	}
}
