import { Component, Input } from '@angular/core';
import { Command } from 'src/app/core/events.service';
import { LockCommandStatus } from 'src/app/core/lock-commands.service';
import { BOOKING_DETAILS_PATH } from 'src/app/core/navigation.service';
import {
	COMMAND_HOST_UNLOCK_SUCCEEDED,
	COMMAND_HOST_LOCK_SUCCEEDED,
	COMMAND_GUEST_UNLOCK_SUCCEEDED,
	COMMAND_GUEST_LOCK_SUCCEEDED,
	COMMAND_HOST_UNLOCK_FAILED,
	COMMAND_HOST_LOCK_FAILED,
	COMMAND_GUEST_UNLOCK_FAILED,
	COMMAND_GUEST_LOCK_FAILED,
} from 'src/app/shared/translations';

@Component({
	selector: 'app-vehicle-details-commands-list',
	templateUrl: './vehicle-details-commands-list.component.html',
	styleUrl: './vehicle-details-commands-list.component.scss',
})
export class VehicleDetailsCommandsListComponent {
	@Input() commands?: Command[];

	getCommandLabel(command: Command): string {
		if (command.status == LockCommandStatus.SUCCEEDED) {
			switch (command.object) {
				case 'unlock_host_command':
					return COMMAND_HOST_UNLOCK_SUCCEEDED;
				case 'lock_host_command':
					return COMMAND_HOST_LOCK_SUCCEEDED;
				case 'unlock_command':
					return COMMAND_GUEST_UNLOCK_SUCCEEDED;
				case 'lock_command':
					return COMMAND_GUEST_LOCK_SUCCEEDED;
			}
		} else if (command.status == LockCommandStatus.FAILED) {
			switch (command.object) {
				case 'unlock_host_command':
					return COMMAND_HOST_UNLOCK_FAILED;
				case 'lock_host_command':
					return COMMAND_HOST_LOCK_FAILED;
				case 'unlock_command':
					return COMMAND_GUEST_UNLOCK_FAILED;
				case 'lock_command':
					return COMMAND_GUEST_LOCK_FAILED;
			}
		}
		return '';
	}

	isCommandClickable(command: Command): boolean {
		return (command.object == 'unlock_command' ||
			command.object == 'lock_command') &&
			command.status == LockCommandStatus.SUCCEEDED &&
			command.booking_id
			? true
			: false;
	}

	getCommandBookingRoute(command: Command): string {
		return '/' + BOOKING_DETAILS_PATH + '/' + command.booking_id;
	}

	getCommandClass(command: Command): { [key: string]: boolean } {
		return {
			'': command.status == LockCommandStatus.SUCCEEDED,
			'lk-warn-color': command.status == LockCommandStatus.FAILED,
		};
	}
}
