import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { get, post, del } from 'aws-amplify/api';
import { environment } from '../../environments/environment';
import { Address } from './core.module';

export const ADMINS_API_NAME: string = 'admins';
export const ADMINS_API_ENDPOINT: string =
	environment.API_BASE_PATH + '/admins/';

@Injectable({
	providedIn: 'root',
})
export class AdminService {
	listByStationId(station_id: string): Observable<any> {
		const path: string = `stations/${station_id}`;

		const init = { response: true };

		return from(
			get({
				apiName: ADMINS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	removeFromStation(station_id: string, emails: string[]): Observable<any> {
		const path: string = `stations/${station_id}/remove`;

		const init = { response: true, body: emails };

		return from(
			post({
				apiName: ADMINS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	resetMfa(
		email: string,
		phone: { prefix: string; value: string },
	): Observable<any> {
		const path: string = `reset-mfa`;

		const init = { response: true, body: { email, phone } };

		return from(
			post({
				apiName: ADMINS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	setAsSuperAdmin(email: string): Observable<any> {
		const path: string = `set-as-super-admin/${email}`;

		const init = { response: true };

		return from(
			post({
				apiName: ADMINS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	removeAsSuperAdmin(admin_id: string): Observable<any> {
		const path: string = `remove-as-super-admin/${admin_id}`;

		const init = { response: true };

		return from(
			del({
				apiName: ADMINS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}

	listSuperAdmins(): Observable<any> {
		const path: string = `super-admin`;

		const init = { response: true };

		return from(
			get({
				apiName: ADMINS_API_NAME,
				path,
				options: init as any,
			}).response,
		);
	}
}

export class Admin {
	id: string;
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
	lang: string;
	business_data: BusinessData;
	is_confirmed: boolean;
	created_at: number;
	updated_at: number;
}

export class BusinessData {
	business_name: string;
	vat: string;
	sdi: string;
	address: Address;
}
