import { Component, OnInit } from '@angular/core';
import {
	GENERAL_ERROR_MESSAGE,
	TOOLBAR_VEHICLES_NAV_ITEM_NAME,
} from '../shared/translations';
import { BehaviorSubject } from 'rxjs';
import { Station } from '../core/station.service';
import {
	LOCAL_STATION_KEY,
	VEHICLE_DETAILS_NAVBAR_GENERAL_ITEM_NAME,
	VEHICLE_DETAILS_NAVBAR_COMMANDS_ITEM_NAME,
	VEHICLE_DETAILS_NAVBAR_GALLERY_ITEM_NAME,
	VEHICLE_DETAILS_NAVBAR_MEASURES_ITEM_NAME,
	VEHICLE_DETAILS_NAVBAR_LINKS_ITEM_NAME,
} from '../shared/constants';
import { LocalManagerService } from '../core/local-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
	Vehicle,
	VehicleService,
	VehicleStatus,
} from '../core/vehicle.service';
import { DialogService } from '../core/dialog.service';
import {
	NavigationService,
	STATION_PATH,
	VEHICLES_PATH,
} from '../core/navigation.service';
import { UnlockCommandsService } from '../core/unlock-commands.service';
import { LockCommandsService } from '../core/lock-commands.service';
import { StationBuilderDialogComponent } from '../station/station-builder-dialog/station-builder-dialog.component';

@Component({
	selector: 'app-vehicle-details',
	templateUrl: './vehicle-details.component.html',
	styleUrls: ['./vehicle-details.component.scss'],
})
export class VehicleDetailsComponent implements OnInit {
	vehicle_id?: string;
	station?: Station;

	is_vehicle_loading: boolean = false;
	vehicle: Vehicle;

	vehicle_subject: BehaviorSubject<Vehicle> = new BehaviorSubject<Vehicle>(
		null,
	);

	current_item: string = TOOLBAR_VEHICLES_NAV_ITEM_NAME;
	current_navbar_item: string = VEHICLE_DETAILS_NAVBAR_GENERAL_ITEM_NAME;
	navbar_items: string[] = [
		VEHICLE_DETAILS_NAVBAR_GENERAL_ITEM_NAME,
		VEHICLE_DETAILS_NAVBAR_COMMANDS_ITEM_NAME,
		VEHICLE_DETAILS_NAVBAR_MEASURES_ITEM_NAME,
		VEHICLE_DETAILS_NAVBAR_LINKS_ITEM_NAME,
		VEHICLE_DETAILS_NAVBAR_GALLERY_ITEM_NAME,
	];

	constructor(
		private local_manager: LocalManagerService,
		private unlock_commands_service: UnlockCommandsService,
		private lock_commands_service: LockCommandsService,
		private router: Router,
		private vehicle_service: VehicleService,
		private route: ActivatedRoute,
		private dialog_service: DialogService,
		private navigation_service: NavigationService,
	) {
		this.vehicle_id = this.route.snapshot.paramMap.get('vehicle_id');
	}

	ngOnInit() {
		this.unlock_commands_service.current_read_index = 0;
		this.lock_commands_service.current_read_index = 0;

		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.readVehicle(station.id, this.vehicle_id);
	}

	onRefresh() {
		this.current_navbar_item = VEHICLE_DETAILS_NAVBAR_GENERAL_ITEM_NAME;
		this.unlock_commands_service.current_read_index = 0;
		this.lock_commands_service.current_read_index = 0;

		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.readVehicle(station.id, this.vehicle_id);
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuild(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onStationChange(station: Station): void {
		if (this.station) {
			this.router.navigate([VEHICLES_PATH]);
		} else {
			this.station = station;
		}
	}

	onUpdateClick(vehicle: Vehicle) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.updateVehicle(station.id, vehicle);
	}

	onBackClick() {
		this.navigation_service.toVehicles();
	}

	onNavbarItemChange(item_name: string): void {
		this.current_navbar_item = item_name;
	}

	readVehicle(station_id: string, vehicle_id: string) {
		this.is_vehicle_loading = true;
		this.vehicle_service.read(station_id, vehicle_id).subscribe({
			next: response => {
				response.body.json().then(response => {
					this.is_vehicle_loading = false;
					this.vehicle = response;
					this.vehicle_subject.next(this.vehicle);
				});
			},
			error: () => {
				this.is_vehicle_loading = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	updateVehicle(station_id: string, vehicle: Vehicle): void {
		this.is_vehicle_loading = true;
		this.vehicle_service.update(station_id, vehicle).subscribe({
			next: () => {
				this.onRefresh();
			},
			error: () => {
				this.is_vehicle_loading = false;
				this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
			},
		});
	}

	getVehicleStatusClass(vehicle: Vehicle) {
		return {
			'lk-vehicle-green-status':
				vehicle && vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle && vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}
}
