<div class="lk-height__max-80vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x12-horiz lk-hide-scrollbar">
		<p class="lk-margin__x24-top">
			{{ 'VEHICLE.ADD_DIALOG_TITLE' | translate }}:
		</p>

		<form
			class="lk-width__full lk-row__space lk-wrap lk-margin__x24-vert"
			[formGroup]="form_group">
			<mat-form-field
				appearance="fill"
				class="lk-width__full lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="plate"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-width__half-x8 lk-form-field lk-margin__x12-bottom">
				<mat-label>
					{{ 'VEHICLE.CATEGORY_COLUMN_LABEL' | translate }}
				</mat-label>
				<mat-select formControlName="category">
					<mat-option
						*ngFor="let category of categories"
						[value]="category">
						{{ category | titlecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-width__half-x8 lk-form-field lk-margin__x12-bottom">
				<mat-label>
					{{ 'VEHICLE.ADPATER_ID_COLUMN_LABEL' | translate }}
				</mat-label>
				<input
					matInput
					type="text"
					formControlName="adapter_id" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-width__half-x8 lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'VEHICLE.BRAND_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="brand"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-width__half-x8 lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'VEHICLE.MODEL_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="model"
					required />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-width__half-x8 lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'VEHICLE.COLOR_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="color" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-width__half-x8 lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'VEHICLE.YEAR_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="year"
					required />
			</mat-form-field>

			<mat-form-field
				*ngIf="!is_production"
				appearance="fill"
				class="lk-width__half-x8 lk-form-field lk-margin__x12-bottom">
				<mat-label>
					{{ 'VEHICLE.INITIAL_STATUS_COLUMN_LABEL' | translate }}
				</mat-label>
				<mat-select formControlName="initial_status">
					<mat-option value="{{ locked_status }}">
						{{ locked_status | titlecase }}
					</mat-option>
					<mat-option value="{{ unlocked_status }}">
						{{ unlocked_status | titlecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-expansion-panel
				class="lk-width__full"
				style="box-shadow: none">
				<mat-expansion-panel-header>
					<mat-panel-title class="lk-row__end">
						<p class="lk-primary-medium-color lk-text__12">
							{{ 'VEHICLE.TURO_VEHICLE_LINK_CTA' | translate }}
						</p>
						<img
							style="width: 50px"
							src="../../../assets/imgs/lk-turo-logo.png" />
						<p
							class="lk-text__12 lk-text__right"
							style="margin-left: -4px !important">
							?
						</p>
					</mat-panel-title>
				</mat-expansion-panel-header>

				<div class="lk-width__full">
					<form
						[formGroup]="form_group"
						class="lk-width__full">
						<mat-form-field
							appearance="fill"
							class="lk-width__full lk-form-field lk-margin__x12-bottom">
							<mat-label> Ext. vehicle ID </mat-label>
							<input
								matInput
								type="text"
								formControlName="ext_id" />
						</mat-form-field>
					</form>
				</div>
			</mat-expansion-panel>

			<app-button
				class="lk-width__full lk-margin__x48-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.CONFIRM_CTA' | translate"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>
