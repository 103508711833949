import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Station } from 'src/app/core/station.service';

@Component({
	selector: 'app-station-panel-info',
	templateUrl: './station-panel-info.component.html',
	styleUrl: './station-panel-info.component.scss',
})
export class StationPanelInfoComponent {
	@Input() station?: Station;

	@Output() update_click: EventEmitter<void> = new EventEmitter<void>();
	@Output() delete_click: EventEmitter<void> = new EventEmitter<void>();
}
