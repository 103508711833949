<div
	class="lk-height__max-70vh lk-width__max-450 lk-hide-scrollbar"
	style="position: relative">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-padding__x24-horiz lk-hide-scrollbar">
		<h3>
			{{ 'STATION_BUILDER.STEP_NOTIF_TITLE' | translate }}
		</h3>

		<p class="lk-primary-medium-color lk-margin__x24-bottom">
			{{ 'STATION_BUILDER.STEP_NOTIF_SUBTITLE' | translate }}
		</p>

		<div class="lk-column">
			<mat-slide-toggle [(ngModel)]="is_sms_checked">
				<p class="lk-margin__x12-left">
					{{ 'STATION_BUILDER.SMS_NOTIF_LABEL' | translate }}
				</p>
				<p
					class="lk-primary-medium-color lk-slide-toggle-label lk-margin__x12-left"
					style="max-width: 250px">
					{{ 'STATION_BUILDER.SMS_NOTIF_DESCRIPTION' | translate }}
				</p>
			</mat-slide-toggle>

			<mat-slide-toggle
				[(ngModel)]="is_email_checked"
				class="lk-margin__x24-top">
				<p class="lk-margin__x12-left">
					{{ 'STATION_BUILDER.EMAIL_NOTIF_LABEL' | translate }}
				</p>
				<p
					class="lk-primary-medium-color lk-slide-toggle-label lk-margin__x12-left"
					style="max-width: 250px">
					{{ 'STATION_BUILDER.EMAIL_NOTIF_DESCRIPTION' | translate }}
				</p>
			</mat-slide-toggle>
		</div>

		<h3 class="lk-margin__x48-top">
			{{ 'STATION.SETTINGS_NOTIF_LANG_CTA' | translate }}
		</h3>
		<p class="lk-primary-medium-color lk-margin__x24-bottom">
			{{ 'STATION.SETTINGS_LANG_SUBTITLE' | translate }}
		</p>

		<form
			[formGroup]="form_group"
			class="lk-margin__x24-bottom">
			<mat-form-field
				appearance="fill"
				class="lk-form-field">
				<mat-label>
					{{ 'STATION.SETTINGS_NOTIF_LANG_CTA' | translate }}
				</mat-label>
				<mat-select formControlName="lang">
					<mat-option
						*ngFor="let lang of languages"
						[value]="lang"
						class="lk-options-list-item">
						<img
							[src]="'../../../assets/imgs/lk-flag-icons/' + lang + '.png'" />
						{{ getLanguageLabel(lang) | translate }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>

		<div *ngIf="!is_production">
			<h3>
				{{ 'STATION.SETTINGS_PIC_POLICY_TITLE' | translate }}
			</h3>

			<p class="lk-primary-medium-color lk-margin__x24-bottom">
				{{ 'STATION.SETTINGS_PIC_POLICY_SUBTITLE' | translate }}
			</p>

			<div class="lk-column">
				<mat-slide-toggle
					[(ngModel)]="is_policy_mandatory"
					(change)="onToggleChange($event, mandatory_policy)">
					<p class="lk-margin__x12-left">
						{{ 'GENERAL.PICTURES_MANDATORY_POLICY_TITLE' | translate }}
					</p>
					<p
						class="lk-primary-medium-color lk-slide-toggle-label lk-margin__x12-left">
						{{ 'GENERAL.PICTURES_MANDATORY_POLICY_SUBTITLE' | translate }}
					</p>
				</mat-slide-toggle>

				<mat-slide-toggle
					class="lk-margin__x24-top"
					[(ngModel)]="is_policy_mandatory_pre"
					(change)="onToggleChange($event, mandatory_pre_policy)">
					<p class="lk-margin__x12-left">
						{{ 'GENERAL.PICTURES_PICK_UP_POLICY_TITLE' | translate }}
					</p>
					<p
						class="lk-primary-medium-color lk-slide-toggle-label lk-margin__x12-left">
						{{ 'GENERAL.PICTURES_PICK_UP_POLICY_SUBTITLE' | translate }}
					</p>
				</mat-slide-toggle>

				<mat-slide-toggle
					class="lk-margin__x24-top"
					[(ngModel)]="is_policy_mandatory_post"
					(change)="onToggleChange($event, mandatory_post_policy)">
					<p class="lk-margin__x12-left">
						{{ 'GENERAL.PICTURES_DROP_OFF_POLICY_TITLE' | translate }}
					</p>
					<p
						class="lk-primary-medium-color lk-slide-toggle-label lk-margin__x12-left">
						{{ 'GENERAL.PICTURES_DROP_OFF_POLICY_SUBTITLE' | translate }}
					</p>
				</mat-slide-toggle>

				<mat-slide-toggle
					class="lk-margin__x24-top"
					[(ngModel)]="is_policy_optional"
					(change)="onToggleChange($event, optional_policy)">
					<p class="lk-margin__x12-left">
						{{ 'GENERAL.PICTURES_OPTIONAL_POLICY_TITLE' | translate }}
					</p>
					<p
						class="lk-primary-medium-color lk-slide-toggle-label lk-margin__x12-left">
						{{ 'GENERAL.PICTURES_OPTIONAL_POLICY_SUBTITLE' | translate }}
					</p>
				</mat-slide-toggle>

				<mat-slide-toggle
					class="lk-margin__x24-top"
					[(ngModel)]="is_policy_disabled"
					(change)="onToggleChange($event, disabled_policy)">
					<p class="lk-margin__x12-left">
						{{ 'GENERAL.PICTURES_DISABLED_POLICY_TITLE' | translate }}
					</p>
					<p
						class="lk-primary-medium-color lk-slide-toggle-label lk-margin__x12-left">
						{{ 'GENERAL.PICTURES_DISABLED_POLICY_SUBTITLE' | translate }}
					</p>
				</mat-slide-toggle>
			</div>
		</div>
	</div>

	<div
		style="position: sticky; bottom: -12px; left: 0"
		class="lk-width__full lk-padding__x24 lk-background__light-neutral-0">
		<app-button
			class="lk-width__full lk-display__block"
			[button_class]="'lk-button__secondary lk-width__full'"
			[spinner_class]="'lk-button__spinner-white'"
			[cta]="'GENERAL.NEXT_STEP_BUTTON_CTA' | translate"
			(button_click)="onConfirmClick()"></app-button>
	</div>
</div>
