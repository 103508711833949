import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/auth.guard';
import { BookingDetailsComponent } from './booking-details.component';

const routes: Routes = [
	{
		path: '',
		component: BookingDetailsComponent,
		canActivate: [AuthGuard],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class BookingDetailsRoutingModule {}
