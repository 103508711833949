import { Component, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { STATION_PATH } from '../core/navigation.service';
import { Router } from '@angular/router';
import {
	Station,
	StationFiltersConfig,
	StationService,
} from '../core/station.service';
import {
	LOCAL_STATION_KEY,
	STATION_NOT_FOUND_ERROR_CODE,
} from '../shared/constants';
import { StationBuilderDialogComponent } from '../station/station-builder-dialog/station-builder-dialog.component';
import { LocalManagerService } from '../core/local-manager.service';
import { DialogService } from '../core/dialog.service';
import { ToolbarComponent } from '../toolbar/toolbar/toolbar.component';
import { ErrorService } from '../core/error.service';

@Component({
	selector: 'app-hidden-stations',
	templateUrl: './hidden-stations.component.html',
	styleUrls: ['./hidden-stations.component.scss'],
})
export class HiddenStationsComponent {
	@ViewChild(ToolbarComponent) toolbar_component?: ToolbarComponent;

	current_station?: Station;
	total_items?: number;
	current_page = 1;

	toolbar_refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
	is_request_running: boolean = true;
	is_load_more_running: boolean = false;
	stations?: Station[];

	loadings = Array.from(Array(60).keys());

	constructor(
		private router: Router,
		private local_manager: LocalManagerService,
		private dialog_service: DialogService,
		private station_service: StationService,
		private error_service: ErrorService,
	) {}

	onRefresh(): void {
		this.current_page = 1;
		this.stations = [];
		this.listStations();
	}

	onStationChange(station: Station): void {
		this.current_page = 1;
		this.stations = [];
		this.listStations();
	}

	onStationChangeClick(station: Station): void {
		this.toolbar_component?.onStationChange(station);
	}

	onNoStationFound(): void {
		this.router.navigate([STATION_PATH]);
	}

	onStationBuild(update_mode?: boolean): void {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);

		this.dialog_service
			.openDialog(StationBuilderDialogComponent, {
				update_mode,
				station: station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					localStorage.setItem(
						LOCAL_STATION_KEY,
						JSON.stringify(dialog_result.station),
					);
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}

	onLoadMoreClick(): void {
		this.current_page++;
		this.listStations({ page: this.current_page });
	}

	onSearchChange(value: string, is_alias_search?: boolean): void {
		this.current_page = 1;
		this.stations = [];

		if (value === '' || value === ' ') {
			this.listStations();
			return;
		}

		if (!is_alias_search) {
			this.listStations({ page: this.current_page, name: value });
		}

		if (is_alias_search) {
			this.listStations({ page: this.current_page, alias: value });
		}
	}

	listStations(filters?: StationFiltersConfig): void {
		this.is_request_running = !filters || filters.page === 1;
		this.is_load_more_running = true;
		this.station_service._list(filters).subscribe({
			next: response => {
				response.body.json().then(response => {
					if (this.stations?.length) {
						this.stations = this.stations.concat(response.items);
					} else {
						this.stations = response.items;
					}

					this.total_items = response.total_items;

					this.current_station =
						this.local_manager.getLocalObject(LOCAL_STATION_KEY);

					this.is_request_running = false;
					this.is_load_more_running = false;
				});
			},
			error: error => {
				const converted_error = this.error_service.convertError(error);

				if (converted_error.code === STATION_NOT_FOUND_ERROR_CODE) {
					this.stations = [];
				} else {
					this.error_service.processError(converted_error);
				}

				this.is_request_running = false;
				this.is_load_more_running = false;
			},
		});
	}

	isCurrentStation(station: Station): boolean {
		return this.current_station && this.current_station.id == station.id;
	}
}
