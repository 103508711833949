<div class="lk-padding__x24">
	<h1>
		{{ station?.name || '-' }}
	</h1>

	<p>
		{{
			station?.id?.substr(0, 3) +
				station?.id?.substr(station?.id?.length - 8, station?.id?.length)
		}}
	</p>

	<p class="lk-margin__x12-top lk-color__neutral-30">
		{{ station?.address?.formatted_address }} | +{{
			station?.phone?.prefix + station?.phone?.number
		}}
		|
		{{ station?.email }}
	</p>
</div>

<app-station-panel-menu
	(update_click)="update_click.emit()"
	(delete_click)="delete_click.emit()"></app-station-panel-menu>
