import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
	AUTH_PATH,
	BOOKING_DETAILS_PATH,
	BOOKINGS_PATH,
	STATION_PATH,
	VEHICLES_DETAILS_PATH,
	VEHICLES_PATH,
} from './core/navigation.service';

const routes: Routes = [
	{
		path: '',
		redirectTo: AUTH_PATH,
		pathMatch: 'full',
	},
	{
		path: AUTH_PATH,
		loadChildren: () =>
			import('./authentication/authentication.module').then(
				m => m.AuthenticationModule,
			),
	},
	{
		path: VEHICLES_PATH,
		loadChildren: () =>
			import('./vehicle/vehicle.module').then(m => m.VehicleModule),
	},
	{
		path: `${VEHICLES_DETAILS_PATH}:vehicle_id`,
		loadChildren: () =>
			import('./vehicle-details/vehicle-details.module').then(
				m => m.VehicleDetailsModule,
			),
	},
	{
		path: BOOKINGS_PATH,
		loadChildren: () =>
			import('./booking/booking.module').then(m => m.BookingModule),
	},
	{
		path: `${BOOKING_DETAILS_PATH}:booking_id`,
		loadChildren: () =>
			import('./booking-details/booking-details.module').then(
				m => m.BookingDetailsModule,
			),
	},
	{
		path: STATION_PATH,
		loadChildren: () =>
			import('./station/station.module').then(m => m.StationModule),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
