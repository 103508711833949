import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-auth-logo-header',
	templateUrl: './auth-logo-header.component.html',
	styleUrl: './auth-logo-header.component.scss',
})
export class AuthLogoHeaderComponent {
	@Input() logo_src?: string;
}
