import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { FormService } from 'src/app/core/form.service';
import { Station, UnlockSettings } from 'src/app/core/station.service';

@Component({
	selector: 'app-booking-settings-unlock-dialog',
	templateUrl: './booking-settings-unlock-dialog.component.html',
	styleUrls: ['./booking-settings-unlock-dialog.component.scss'],
})
export class BookingSettingsUnlockDialogComponent implements OnInit {
	station_update_trigger: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(null);
	station: Station;
	unlock_settings: UnlockSettings;
	is_unlockable_on_vehicle_assignment: boolean;
	is_unlockable_until_booking_end: boolean;

	constructor(
		private dialogRef: MatDialogRef<BookingSettingsUnlockDialogComponent>,
		private formService: FormService,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.station = data.station;
	}

	ngOnInit(): void {
		if (this.station) {
			this.unlock_settings = this.station.settings.unlock_settings;
			this.initFlags();
			this.station_update_trigger.next(true);
		}
	}

	onUnlockableOnVehicleAssignmentChange(flag: boolean) {
		this.is_unlockable_on_vehicle_assignment = flag;
	}

	onUnlockableUntilBookingEndChange(flag: boolean) {
		this.is_unlockable_until_booking_end = flag;
	}

	onCloseClick() {
		this.dialogRef.close();
	}

	onConfirmClick() {
		this.setUnlockSettings();
		this.dialogRef.close({ unlock_settings: this.unlock_settings });
	}

	initFlags() {
		this.is_unlockable_on_vehicle_assignment = this.station.settings
			.unlock_settings
			? this.station.settings.unlock_settings
					.is_unlockable_on_vehicle_assignment
			: false;
		this.is_unlockable_until_booking_end = this.station.settings.unlock_settings
			? this.station.settings.unlock_settings
					.is_unlockable_until_booking_end_date
			: false;
	}

	setUnlockSettings() {
		let hoursAndMinutes: number[];
		let unlockSettings: UnlockSettings = new UnlockSettings();
		unlockSettings.is_unlockable_on_vehicle_assignment =
			this.is_unlockable_on_vehicle_assignment;
		unlockSettings.is_unlockable_until_booking_end_date =
			this.is_unlockable_until_booking_end;
		hoursAndMinutes = this.parseTimeFormGroup(
			this.formService.stationSettingsUnlockLinkFormGroup,
		);
		unlockSettings.time_to_unlock_link_before_booking_start =
			this.getMillisFromHoursAndMin(hoursAndMinutes[0], hoursAndMinutes[1]);
		hoursAndMinutes = this.parseTimeFormGroup(
			this.formService.stationSettingsUnlockBeforeFormGroup,
		);
		unlockSettings.time_to_unlock_availability_before_booking_start =
			this.getMillisFromHoursAndMin(hoursAndMinutes[0], hoursAndMinutes[1]);
		hoursAndMinutes = this.parseTimeFormGroup(
			this.formService.stationSettingsUnlockAfterFormGroup,
		);
		unlockSettings.time_to_unlock_availability_after_booking_start =
			this.getMillisFromHoursAndMin(hoursAndMinutes[0], hoursAndMinutes[1]);
		this.unlock_settings = unlockSettings;
	}

	parseTimeFormGroup(formGroup: FormGroup): number[] {
		const time: string = formGroup.get('time').value;
		const columnIndex: number = time.indexOf(':');
		return [
			+time.substring(0, columnIndex),
			+time.substring(columnIndex + 1, time.length),
		];
	}

	getMillisFromHoursAndMin(hours: number, minutes: number) {
		const HOUR_IN_MILLIS: number = 3600000;
		const MINUTE_IN_MILLIS: number = 60000;
		return hours * HOUR_IN_MILLIS + minutes * MINUTE_IN_MILLIS;
	}
}
