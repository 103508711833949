<div class="lk-height__max-80vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-margin__x24-top lk-padding__x24">
		<form
			[formGroup]="form_group"
			class="lk-width__full lk-row__space lk-wrap">
			<div class="lk-width__full lk-row__space">
				<mat-form-field
					style="width: calc(100% - 70px)"
					class="lk-form-field">
					<mat-label> {{ 'GENERAL.EMAIL_LABEL' | translate }} </mat-label>
					<input
						matInput
						type="email"
						formControlName="email" />
				</mat-form-field>

				<button
					(click)="onAddTeammateClick()"
					class="lk-button__fab"
					mat-fab>
					<mat-icon class="lk-primary-color"> add </mat-icon>
				</button>
			</div>

			<div
				style="background: rgba(0, 0, 0, 0.04)"
				class="lk-row lk-wrap lk-width__full lk-padding__x48 lk-margin__x24-top lk-margin__x24-bottom lk-border-radius">
				<p
					*ngIf="!invites || !invites.length"
					class="lk-primary-medium-color lk-text__center lk-width__full">
					{{ 'STATION.TEAM_EMPTY_TEXT' | translate }}
				</p>

				<div
					*ngFor="let invite of invites"
					class="lk-chips-layout lk-background__light-neutral-20 lk-row__center lk-margin__x12-bottom lk-margin__x12-right">
					<span class="lk-text__12 lk-primary-medium-color">
						{{ invite }}
					</span>
				</div>
			</div>

			<app-button
				class="lk-width__full lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.CONFIRM_CTA' | translate"
				[is_disabled]="!invites || !invites.length || is_team_loading"
				[is_spinner_visible]="is_team_loading"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>
