<div
	id="lk-booking-details-path-root-layout"
	style="position: relative"
	class="lk-border-radius lk-shadow lk-primary-background lk-full-height">
	<form
		[formGroup]="path_form_group"
		class="lk-z-index"
		style="position: absolute; top: 12px; right: 64px">
		<mat-form-field
			appearance="fill"
			color="accent"
			style="border-radius: 4px"
			class="lk-white-form-field lk-form-field lk-shadow">
			<mat-label>
				{{ 'BOOKING_DETAILS.PATH_CURRENT_DATE_LABEL' | translate }}
			</mat-label>
			<mat-select formControlName="current_date">
				<mat-option
					*ngFor="let date of booking_interval"
					[value]="date">
					{{ date | date: 'shortDate' }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</form>

	<div
		id="lk-booking-details-path-map"
		class="lk-full-width lk-full-height lk-border-radius lk-no-z-index"></div>
</div>
