<div>
	<div class="lk-spaced-row lk-margin__x24-bottom">
		<form
			[formGroup]="form_group"
			class="lk-width__full">
			<mat-form-field
				color="primary"
				appearance="fill"
				class="lk-form-field lk-width__full">
				<mat-label>
					{{ 'BOOKING.SEARCH_BY_EMAIL_LABEL' | translate }}
				</mat-label>

				<mat-icon
					matPrefix
					class="lk-primary-medium-color">
					search
				</mat-icon>

				<input
					matInput
					type="text"
					formControlName="email"
					(change)="search_change.emit(form_group.get('email').value)" />

				<div
					class="lk-row lk-padding__x12-horiz"
					matSuffix>
					<mat-icon
						matSuffix
						class="lk-primary-medium-color lk-cursor-pointer"
						(click)="filters_change.emit()">
						tune
					</mat-icon>

					<mat-icon
						(click)="onSortStartDateClick()"
						*ngIf="
							current_start_date_sort_option &&
							current_start_date_sort_option == asc_option
						"
						matSuffix
						class="lk-accent-color lk-margin__x6-left">
						expand_less
					</mat-icon>

					<mat-icon
						(click)="onSortStartDateClick()"
						*ngIf="
							current_start_date_sort_option &&
							current_start_date_sort_option == desc_option
						"
						matSuffix
						class="lk-accent-color lk-margin__x6-left">
						expand_more
					</mat-icon>

					<mat-icon
						(click)="onSortStartDateClick()"
						*ngIf="!current_start_date_sort_option"
						matSuffix
						class="lk-accent-color lk-margin__x6-left">
						swap_vert
					</mat-icon>
				</div>
			</mat-form-field>
		</form>
	</div>

	<div
		class="lk-full-width lk-border-radius lk-padding lk-margin-bottom lk-shadow lk-cursor-pointer lk-primary-600-contrast-hover"
		id="lk-booking-list-item-layout"
		*ngFor="let booking of bookings"
		(click)="onBookingClick(booking)">
		<div
			class="lk-full-width lk-spaced-row"
			style="margin-top: -12px">
			<h3 class="lk-no-margin">
				{{ getUserIdentifier(booking) }}
			</h3>
			<button
				mat-icon-button
				style="margin-right: -12px"
				matTooltip="{{ 'VEHICLE_DETAILS.DELETE_VEHICLE' | translate }}"
				(click)="onBookingDeleteClick(booking); $event.stopPropagation()">
				<mat-icon style="color: red"> delete </mat-icon>
			</button>
		</div>

		<div>
			<div
				class="lk-border-radius"
				style="padding: 2px 12px; width: fit-content"
				[ngClass]="getBookingStatusClass(booking)">
				<span> {{ getBookingStatusLabel(booking) | translate }} </span>
			</div>
			<p
				class="lk-no-margin lk-half-margin-top lk-primary-medium-color"
				style="font-size: 12px">
				{{ getTimezoneStartTime(booking) | date: 'short' }} -
				{{ getTimezoneEndTime(booking) | date: 'short' }}
				{{ getTimezoneOffset(booking) }}
			</p>
		</div>

		<div
			*ngIf="isAddVehicleVisible(booking)"
			class="lk-centered-row lk-full-width lk-margin-top">
			<app-button
				class="lk-width__full lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="
					booking.vehicle_id
						? ('VEHICLE.CHANGE_CTA' | translate)
						: ('VEHICLE.ADD_CTA' | translate)
				"
				(button_click)="onAddVehicleClick(booking)"></app-button>
		</div>
	</div>
</div>
