<div>
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div
		id="lk-vehicle-details-image-content-layout"
		class="lk-horiz-padding lk-margin-top">
		<button
			mat-button
			(click)="fileInput.click()"
			class="lk-vehicle-details-image-dialog-input-button lk-full-width lk-border-radius lk-centered-column lk-border-2-accent"
			*ngIf="!is_picture_loading && !image">
			<mat-icon class="lk-accent-color"> cloud_upload </mat-icon>

			<h3 class="lk-accent-color lk-no-margin">upload</h3>

			<input
				#fileInput
				type="file"
				accept="image/*"
				(change)="onFileInput($event)"
				style="display: none" />
		</button>

		<div
			id="lk-vehicle-details-image-dialog-img-layout"
			class="lk-full-width lk-centered-column"
			*ngIf="!is_picture_loading && image">
			<img
				id="lk-vehicle-details-image-dialog-img"
				class="lk-border-radius"
				[src]="image" />
			<p
				class="lk-cursor-link-hover lk-cursor-pointer lk-centered-text"
				(click)="updateInput.click()">
				{{ 'VEHICLE_DETAILS.UPDATE_IMAGE_CTA' | translate }}
			</p>
			<input
				#updateInput
				type="file"
				accept="image/*"
				(change)="onFileInput($event)"
				style="display: none" />
		</div>

		<div
			class="lk-full-width lk-padding lk-centered-row"
			*ngIf="is_picture_loading">
			<mat-spinner
				diameter="40"
				color="accent"></mat-spinner>
		</div>

		<app-button
			class="lk-width__full lk-margin__x24-vert lk-display__block"
			[button_class]="'lk-button__secondary lk-width__full'"
			[spinner_class]="'lk-button__spinner-white'"
			[cta]="'GENERAL.CONFIRM_CTA' | translate"
			[is_disabled]="is_picture_loading"
			[is_spinner_visible]="is_picture_loading"
			(button_click)="onConfirmClick()"></app-button>
	</div>
</div>
