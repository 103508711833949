import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BookingAddVehicleDialogComponent } from 'src/app/booking/booking-add-vehicle-dialog/booking-add-vehicle-dialog.component';
import {
	Booking,
	BookingService,
	BookingStatus,
} from 'src/app/core/booking.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LocalManagerService } from 'src/app/core/local-manager.service';
import { Station } from 'src/app/core/station.service';
import { Vehicle, VehicleStatus } from 'src/app/core/vehicle.service';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
	PROD_STAGE,
	LOCAL_STATION_KEY,
	ADAPTER_CRED_NOT_FOUND_ERROR_CODE,
	ERROR_DESCRIPTION_500,
	ADAPTER_FAILED_ERROR_CODE,
	ADAPTER_OFFLINE_ERROR_CODE,
} from 'src/app/shared/constants';
import {
	ADAPTER_CRED_NOT_FOUND_ERROR_MESSAGE,
	ADAPTER_FAILED_ERROR_MESSAGE,
	ADAPTER_GENERIC_ERROR_MESSAGE,
	ADAPTER_OFFLINE_ERROR_MESSAGE,
	BOOKING_COMPLETED_LABEL,
	BOOKING_ERROR_WO_START_LABEL,
	BOOKING_ERROR_WO_STOP_LABEL,
	BOOKING_ERROR_WO_VEHICLE_LABEL,
	BOOKING_IN_PROGRESS_LABEL,
	BOOKING_PENDING_LABEL,
	GENERAL_ERROR_MESSAGE,
} from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';
import { VEHICLES_DETAILS_PATH } from 'src/app/core/navigation.service';
import {
	UnlockCommand,
	UnlockCommandStatus,
	UnlockCommandsService,
} from 'src/app/core/unlock-commands.service';
import {
	LockCommand,
	LockCommandStatus,
	LockCommandsService,
} from 'src/app/core/lock-commands.service';
import { SharedInteractionErrorDialogComponent } from 'src/app/shared/shared-interaction-error-dialog/shared-interaction-error-dialog.component';
import { ErrorService } from 'src/app/core/error.service';

@Component({
	selector: 'app-booking-details-vehicle',
	templateUrl: './booking-details-vehicle.component.html',
	styleUrls: ['./booking-details-vehicle.component.scss'],
})
export class BookingDetailsVehicleComponent implements OnInit {
	@Input() booking: Booking;
	@Output() addVehicleClick: EventEmitter<{
		booking: Booking;
		vehicle: Vehicle;
	}> = new EventEmitter<{ booking: Booking; vehicle: Vehicle }>();
	@Output() refresh: EventEmitter<void> = new EventEmitter<void>();
	pending_label: string = BOOKING_PENDING_LABEL;
	progress_label: string = BOOKING_IN_PROGRESS_LABEL;
	is_production: boolean;
	is_start_vehicle_running: boolean = false;
	is_stop_vehicle_running: boolean = false;

	constructor(
		private dialog_service: DialogService,
		private dialog: MatDialog,
		private booking_service: BookingService,
		private local_manager: LocalManagerService,
		private router: Router,
		private unlock_commands_service: UnlockCommandsService,
		private lock_commands_service: LockCommandsService,
		private error_service: ErrorService,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit() {
		this.is_production = environment.STAGE == PROD_STAGE;
	}

	onVehicleUpdateClick() {
		this.dialog_service
			.openDialog(BookingAddVehicleDialogComponent, { booking: this.booking })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.vehicle) {
					this.addVehicleClick.emit({
						booking: this.booking,
						vehicle: dialogResult.vehicle,
					});
				}
			});
	}

	onVehicleClick() {
		this.router.navigate([VEHICLES_DETAILS_PATH + this.booking.vehicle_id]);
	}

	onBookingUnlockLinkValidityChange(booking: Booking) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		booking.settings.unlock_settings.is_link_invalid =
			!booking.settings.unlock_settings.is_link_invalid;
		this.booking_service.update(station.id, booking).subscribe({
			next: response => {},
			error: error => {},
		});
	}

	onBookingLockLinkValidityChange(booking: Booking) {
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		booking.settings.lock_settings.is_link_invalid =
			!booking.settings.lock_settings.is_link_invalid;
		this.booking_service.update(station.id, booking).subscribe({
			next: response => {},
			error: error => {},
		});
	}

	onStartVehicleClick(vehicle: Vehicle): void {
		this.is_start_vehicle_running = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service.create(station.id, vehicle.id).subscribe({
			next: response => {
				const command: UnlockCommand = response.data as UnlockCommand;
				this.readUnlockCommand(command.id, vehicle.id);
			},
			error: error => {
				this.is_start_vehicle_running = false;
				this.error_service.wrapError(error);
			},
		});
	}

	onStopVehicleClick(vehicle: Vehicle): void {
		this.is_stop_vehicle_running = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.lock_commands_service.create(station.id, vehicle.id).subscribe({
			next: response => {
				const command: LockCommand = response.data as LockCommand;
				this.readLockCommand(command.id, vehicle.id);
			},
			error: error => {
				this.is_stop_vehicle_running = false;
				this.error_service.wrapError(error);
			},
		});
	}

	readUnlockCommand(command_id: string, vehicle_id: string) {
		this.is_start_vehicle_running = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.unlock_commands_service
			.read(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					const command: UnlockCommand = response.data as UnlockCommand;
					if (
						command.status == UnlockCommandStatus.PENDING &&
						this.unlock_commands_service.current_read_index <=
							this.unlock_commands_service.max_read_retry
					) {
						this.unlock_commands_service.current_read_index++;
						setTimeout(() => {
							this.readUnlockCommand(command_id, vehicle_id);
						}, 5000);
					} else if (command.status == UnlockCommandStatus.SUCCEEDED) {
						this.unlock_commands_service.current_read_index = 0;
						this.is_start_vehicle_running = false;
						this.refresh.emit();
					} else if (command.status == UnlockCommandStatus.FAILED) {
						this.unlock_commands_service.current_read_index = 0;
						this.is_start_vehicle_running = false;
						const command_error = command.error.code
							? command.error.code
							: command.error;
						switch (command_error) {
							case ADAPTER_FAILED_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_FAILED_ERROR_MESSAGE,
									},
								);
								break;
							case ADAPTER_OFFLINE_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_OFFLINE_ERROR_MESSAGE,
									},
								);
								break;
							default:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_GENERIC_ERROR_MESSAGE,
									},
								);
								break;
						}
					}
				},
				error: error => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_start_vehicle_running = false;
				},
			});
	}

	readLockCommand(command_id: string, vehicle_id: string) {
		this.is_stop_vehicle_running = true;
		const station: Station =
			this.local_manager.getLocalObject(LOCAL_STATION_KEY);
		this.lock_commands_service
			.read(command_id, station.id, vehicle_id)
			.subscribe({
				next: response => {
					const command: LockCommand = response.data as LockCommand;
					if (
						command.status == LockCommandStatus.PENDING &&
						this.lock_commands_service.current_read_index <=
							this.lock_commands_service.max_read_retry
					) {
						this.lock_commands_service.current_read_index++;
						setTimeout(() => {
							this.readLockCommand(command_id, vehicle_id);
						}, 5000);
					} else if (command.status == LockCommandStatus.SUCCEEDED) {
						this.lock_commands_service.current_read_index = 0;
						this.is_stop_vehicle_running = false;
						this.refresh.emit();
					} else if (command.status == LockCommandStatus.FAILED) {
						this.lock_commands_service.current_read_index = 0;
						this.is_stop_vehicle_running = false;
						const command_error = command.error.code
							? command.error.code
							: command.error;
						switch (command_error) {
							case ADAPTER_FAILED_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_FAILED_ERROR_MESSAGE,
									},
								);
								break;
							case ADAPTER_OFFLINE_ERROR_CODE:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_OFFLINE_ERROR_MESSAGE,
									},
								);
								break;
							default:
								this.dialog_service.openDialog(
									SharedInteractionErrorDialogComponent,
									{
										message: ADAPTER_GENERIC_ERROR_MESSAGE,
									},
								);
								break;
						}
					}
				},
				error: error => {
					this.dialog_service.openInfoDialog(GENERAL_ERROR_MESSAGE);
					this.is_stop_vehicle_running = false;
				},
			});
	}

	getUnlockOnAssignClass(booking: Booking) {
		return {
			'lk-booking-details-vehicle-true':
				booking.settings.unlock_settings.is_unlockable_on_vehicle_assignment,
			'lk-booking-details-vehicle-false':
				!booking.settings.unlock_settings.is_unlockable_on_vehicle_assignment,
		};
	}

	getUnlockTillEndClass(booking: Booking) {
		return {
			'lk-booking-details-vehicle-true':
				booking.settings.unlock_settings.is_unlockable_until_booking_end_date,
			'lk-booking-details-vehicle-false':
				!booking.settings.unlock_settings.is_unlockable_until_booking_end_date,
		};
	}

	getLockOnUnlockClass(booking: Booking) {
		return {
			'lk-booking-details-vehicle-true':
				booking.settings.lock_settings.is_lockable_after_vehicle_unlock,
			'lk-booking-details-vehicle-false':
				!booking.settings.lock_settings.is_lockable_after_vehicle_unlock,
		};
	}

	getBookingStatusLabel(booking: Booking) {
		if (!booking.errors || !booking.errors.length) {
			if (this.isPendingBooking(booking)) {
				return BOOKING_PENDING_LABEL;
			} else if (this.isCompletedBooking(booking)) {
				return BOOKING_COMPLETED_LABEL;
			} else {
				return BOOKING_IN_PROGRESS_LABEL;
			}
		} else {
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_VEHICLE)) {
				return BOOKING_ERROR_WO_VEHICLE_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_START)) {
				return BOOKING_ERROR_WO_START_LABEL;
			}
			if (booking.errors.includes(BookingStatus.EXPIRED_WO_STOP)) {
				return BOOKING_ERROR_WO_STOP_LABEL;
			}
		}
	}

	getToggleClass(isValid: boolean) {
		return {
			'lk-warned-toggle': !isValid,
			'': isValid,
		};
	}

	isPendingBooking(booking: Booking): boolean {
		const isUnlocked: boolean = booking.is_user_vehicle_unlocked;
		return !isUnlocked;
	}

	isCompletedBooking(booking: Booking): boolean {
		const lockAfterTime: number =
			booking.settings.lock_settings
				.time_to_lock_availability_after_booking_end;
		const currentTimezone: string =
			Intl.DateTimeFormat().resolvedOptions().timeZone;
		const isInTime: boolean =
			new Date().getTime() >
			zonedTimeToUtc(
				booking.end_date + lockAfterTime,
				currentTimezone,
			).getTime();
		const isLocked: boolean = booking.is_user_vehicle_locked;
		return isInTime || isLocked;
	}

	getVehicleStatusClass(vehicle: Vehicle) {
		return {
			'lk-vehicle-green-status':
				vehicle.status.toLowerCase() == VehicleStatus.UNLOCKED,
			'lk-vehicle-red-status':
				vehicle.status.toLowerCase() == VehicleStatus.LOCKED,
		};
	}

	isAddVehicleVisible(booking: Booking) {
		return this.getBookingStatusLabel(booking) == this.pending_label;
	}

	getProductionLayoutClass() {
		return {
			'lk-booking-details-vehicle-full-layout': this.is_production,
			'lk-booking-details-vehicle-half-layout': !this.is_production,
		};
	}

	getStartDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': this.is_start_vehicle_running,
			'lk-main-button': !this.is_start_vehicle_running,
		};
	}

	getStopDisabledClass(): { [key: string]: boolean } {
		return {
			'lk-disabled-button': this.is_stop_vehicle_running,
			'lk-main-button': !this.is_stop_vehicle_running,
		};
	}
}
