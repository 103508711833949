<div class="lk-height__max-80vh lk-width__max-450 lk-hide-scrollbar">
	<div class="lk-row__end">
		<button
			mat-icon-button
			class="lk-icon__button-neutral-40"
			(click)="onCloseClick()">
			<mat-icon class="lk-primary-medium-color"> clear </mat-icon>
		</button>
	</div>

	<div class="lk-horiz-padding lk-hide-scrollbar">
		<form
			class="lk-full-width lk-spaced-row lk-wrapped-flex lk-margin-bottom"
			[formGroup]="form_group">
			<h3 class="lk-full-width lk-no-margin lk-half-margin-bottom">
				{{ 'BOOKING.FILTER_TITLE' | translate }}
			</h3>

			<mat-form-field
				appearance="fill"
				class="lk-third-width lk-form-field lk-margin__x12-bottom">
				<mat-label>
					{{ 'BOOKING.CURRENT_FILTER_LABEL' | translate }}
				</mat-label>
				<mat-select formControlName="current">
					<mat-option value="plate">
						{{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate | titlecase }}
					</mat-option>
					<mat-option value="email">
						{{ 'GENERAL.EMAIL_LABEL' | translate | titlecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				*ngIf="isPlateVisible()"
				class="lk-three-third-width lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'VEHICLE.PLATE_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="plate" />
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				*ngIf="isEmailVisible()"
				class="lk-three-third-width lk-form-field lk-margin__x12-bottom">
				<mat-label>
					{{ 'GENERAL.EMAIL_LABEL' | translate | titlecase }}
				</mat-label>
				<input
					matInput
					type="email"
					formControlName="email" />
			</mat-form-field>

			<h3 class="lk-full-width lk-half-margin-bottom">
				{{ 'BOOKING.FILTER_SUBTITLE' | translate }}
			</h3>

			<mat-form-field
				appearance="fill"
				class="lk-width__half-x4 lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'BOOKING.START_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="start_date"
					[matDatepicker]="startPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="startPicker"></mat-datepicker-toggle>
				<mat-datepicker #startPicker></mat-datepicker>
			</mat-form-field>

			<mat-form-field
				appearance="fill"
				class="lk-width__half-x4 lk-form-field lk-margin__x12-bottom">
				<mat-label> {{ 'BOOKING.END_COLUMN_LABEL' | translate }} </mat-label>
				<input
					matInput
					type="text"
					formControlName="end_date"
					[matDatepicker]="endPicker" />
				<mat-datepicker-toggle
					matSuffix
					[for]="endPicker"></mat-datepicker-toggle>
				<mat-datepicker #endPicker></mat-datepicker>
			</mat-form-field>

			<div class="lk-full-width lk-ended-row">
				<span
					class="lk-cursor-pointer"
					(click)="onFiltersResetClick()">
					<u> {{ 'GENERAL.DELETE_FILTERS_CTA' | translate }} </u>
				</span>
			</div>

			<app-button
				class="lk-width__full lk-margin__x24-top lk-display__block"
				[button_class]="'lk-button__secondary lk-width__full'"
				[spinner_class]="'lk-button__spinner-white'"
				[cta]="'GENERAL.CONFIRM_CTA' | translate"
				(button_click)="onConfirmClick()"></app-button>
		</form>
	</div>
</div>
