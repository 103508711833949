<h2 class="lk-margin__0 lk-margin__x12-bottom">
	{{ 'STATION.NOTIF_SETTINGS_LABEL' | translate }}
</h2>

<div
	*ngIf="station"
	class="lk-padding__x48 lk-shadow lk-border-radius lk-background__light-neutral-0 lk-margin__x24-bottom">
	<div class="lk-column">
		<mat-slide-toggle
			(change)="onSmsChange($event)"
			[(ngModel)]="is_sms_checked">
			<div class="lk-margin__x12-left">
				<h3>{{ 'STATION_BUILDER.SMS_NOTIF_LABEL' | translate }}</h3>
				<p
					class="lk-color__neutral-30 lk-text__12 lk-margin__0"
					style="max-width: 250px; line-height: 12px">
					{{ 'STATION_BUILDER.SMS_NOTIF_DESCRIPTION' | translate }}
				</p>
			</div>
		</mat-slide-toggle>

		<mat-slide-toggle
			(change)="onEmailChange($event)"
			[(ngModel)]="is_email_checked"
			class="lk-margin-top">
			<div class="lk-margin__x12-left">
				<h3>{{ 'STATION_BUILDER.EMAIL_NOTIF_LABEL' | translate }}</h3>
				<p
					class="lk-color__neutral-30 lk-text__12 lk-margin__0"
					style="max-width: 250px; line-height: 12px">
					{{ 'STATION_BUILDER.EMAIL_NOTIF_DESCRIPTION' | translate }}
				</p>
			</div>
		</mat-slide-toggle>

		<h3 class="lk-margin__x48-top">
			{{ 'STATION.SETTINGS_NOTIF_LANG_TITLE' | translate }}
		</h3>
		<p class="lk-primary-medium-color">
			{{ 'STATION.SETTINGS_LANG_SUBTITLE' | translate }}
		</p>
		<form
			[formGroup]="form_group"
			class="lk-margin__x24-bottom lk-width__full">
			<mat-form-field class="lk-width__full lk-margin__x12-top lk-form-field">
				<mat-label>
					{{ 'STATION.SETTINGS_NOTIF_LANG_CTA' | translate }}
				</mat-label>
				<mat-select
					formControlName="lang"
					required
					(selectionChange)="onLangSelectChange($event.value)">
					<mat-option
						*ngFor="let lang of languages"
						[value]="lang"
						class="lk-options-list-item">
						<img
							[src]="'../../../assets/imgs/lk-flag-icons/' + lang + '.png'" />
						<span> {{ getLanguageLabel(lang) | translate }}; </span>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>
	</div>
</div>

<div
	*ngIf="!station"
	style="height: 400px; display: block"
	class="lk-shadow lk-border-radius">
	<app-loading class="lk-width__full lk-height__full"></app-loading>
</div>
