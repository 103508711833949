<button
	mat-button
	(click)="button_click.emit()"
	[disabled]="is_disabled || is_spinner_visible"
	[ngClass]="button_class"
	class="hmn-width__full">
	<mat-spinner
		*ngIf="is_spinner_visible"
		[ngClass]="spinner_class"
		color="primary"
		diameter="20"></mat-spinner>
	<mat-icon
		*ngIf="icon"
		[ngClass]="icon_class"
		matPrefix>
		{{ icon }}
	</mat-icon>
	<span *ngIf="!is_spinner_visible">
		{{ cta | translate }}
	</span>
</button>
