import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Vehicle } from '../../core/vehicle.service';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { VehicleDetailsImageDialogComponent } from '../vehicle-details-image-dialog/vehicle-details-image-dialog.component';
import { getUrl } from 'aws-amplify/storage';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-vehicle-details-gallery',
	templateUrl: './vehicle-details-gallery.component.html',
	styleUrls: ['./vehicle-details-gallery.component.scss'],
})
export class VehicleDetailsGalleryComponent implements OnInit {
	@Input() vehicleSubject: BehaviorSubject<Vehicle>;
	@Output() vehicleUpdateClick: EventEmitter<Vehicle> =
		new EventEmitter<Vehicle>();
	vehicle: Vehicle;
	images: { key: string; result: string }[] = [];

	constructor(
		private dialog_service: DialogService,
		private dialog: MatDialog,
	) {
		this.dialog_service.dialog = this.dialog;
	}

	ngOnInit() {
		this.vehicleSubject.subscribe(vehicle => {
			if (vehicle) {
				this.vehicle = vehicle;
				this.images = [];
				this.vehicle?.img_urls?.map(key => {
					getUrl({ path: key })
						.then(result => {
							this.images.push({ key: key, result: result.toString() });
						})
						.catch(err => {});
				});
			}
		});
	}

	onAddImageClick() {
		this.dialog_service
			.openDialog(VehicleDetailsImageDialogComponent, { vehicle: this.vehicle })
			.afterClosed()
			.subscribe(dialogResult => {
				if (dialogResult && dialogResult.imageKey) {
					if (this.vehicle.img_urls) {
						this.vehicle.img_urls.push(dialogResult.imageKey);
					} else {
						this.vehicle.img_urls = [dialogResult.imageKey];
					}
					this.vehicleUpdateClick.emit(this.vehicle);
				}
			});
	}

	onRemoveImageClick(img: { key: string; result: string }) {
		let index: number = this.vehicle.img_urls.indexOf(img.key);
		if (index != -1) {
			this.vehicle.img_urls.splice(index, 1);
			this.vehicleUpdateClick.emit(this.vehicle);
		}
	}
}
