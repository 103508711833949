<div class="lk-row__space lk-width__full">
	<div class="lk-row">
		<img
			class="lk-flag lk-margin__0"
			src="{{ getStationFlag() }}" />
		<p class="lk-margin__x12-left">
			{{ getTemplateLabel() | translate }}
		</p>
	</div>

	<div class="lk-row">
		<div
			*ngIf="is_default_selected"
			class="lk-display__hidden-before-md lk-row">
			<mat-icon class="lk-margin__x12-right"> check_circle </mat-icon>
			<app-shared-pill [text]="'default template'"></app-shared-pill>
		</div>

		<div
			*ngIf="!is_default_selected"
			class="lk-hidden-before-md lk-row">
			<mat-icon class="lk-accent-color lk-half-margin-right">
				check_circle
			</mat-icon>

			<app-shared-pill
				[background]="'lk-accent-background lk-hidden-before-md'"
				[color]="'lk-primary-color'"
				[text]="'custom template'"></app-shared-pill>
		</div>

		<button
			mat-icon-button
			matTooltip="{{ 'STATION.TEST_NOTIF_CTA' | translate }}"
			(click)="$event.stopPropagation(); onTestClick()">
			<mat-icon [ngClass]="is_default_selected ? '' : 'lk-accent-color'">
				send
			</mat-icon>
		</button>
	</div>
</div>
