import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiddenStationsRoutingModule } from './hidden-stations-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { ButtonModule } from '../button/button.module';
import { LoadingModule } from '../loading/loading.module';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { HiddenStationsComponent } from './hidden-stations.component';
import { HiddenStationsListItemComponent } from './hidden-stations-list-item/hidden-stations-list-item.component';
import { HiddenStationsHeaderComponent } from './hidden-stations-header/hidden-stations-header.component';
import { HiddenStationsCredentialsDialogComponent } from './hidden-stations-credentials-dialog/hidden-stations-credentials-dialog.component';
import { HiddenStationsAliasDialogComponent } from './hidden-stations-alias-dialog/hidden-stations-alias-dialog.component';
import { HiddenStationsSettingsDialogComponent } from './hidden-stations-settings-dialog/hidden-stations-settings-dialog.component';
import { HiddenStationsChecklistDialogComponent } from './hidden-stations-checklist-dialog/hidden-stations-checklist-dialog.component';
import { HiddenStationsChecklistDialogHeaderComponent } from './hidden-stations-checklist-dialog-header/hidden-stations-checklist-dialog-header.component';
import { HiddenStationsResetMfaDialogComponent } from './hidden-stations-reset-mfa-dialog/hidden-stations-reset-mfa-dialog.component';
import { HiddenStationsAdminAddDialogComponent } from './hidden-stations-admin-add-dialog/hidden-stations-admin-add-dialog.component';
import { HiddenStationsAdminRemoveConfirmDialogComponent } from './hidden-stations-admin-remove-confirm-dialog/hidden-stations-admin-remove-confirm-dialog.component';
import { HiddenStationsHeaderFormComponent } from './hidden-stations-header-form/hidden-stations-header-form.component';
import { HiddenStationsHeaderFiltersComponent } from './hidden-stations-header-filters/hidden-stations-header-filters.component';

@NgModule({
	declarations: [
		HiddenStationsComponent,
		HiddenStationsListItemComponent,
		HiddenStationsHeaderComponent,
		HiddenStationsCredentialsDialogComponent,
		HiddenStationsAliasDialogComponent,
		HiddenStationsSettingsDialogComponent,
		HiddenStationsChecklistDialogComponent,
		HiddenStationsChecklistDialogHeaderComponent,
		HiddenStationsResetMfaDialogComponent,
		HiddenStationsAdminAddDialogComponent,
		HiddenStationsAdminRemoveConfirmDialogComponent,
		HiddenStationsHeaderFormComponent,
  HiddenStationsHeaderFiltersComponent,
	],
	imports: [
		CommonModule,
		HiddenStationsRoutingModule,
		SharedModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule,
		ToolbarModule,
		ButtonModule,
		LoadingModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatIconModule,
		MatMenuModule,
		MatSnackBarModule,
		MatSlideToggleModule,
		MatExpansionModule,
		MatCheckboxModule,
		MatOptionModule,
		MatSelectModule,
		MatTooltipModule,
		MatAutocompleteModule,
		QuillModule,
	],
})
export class HiddenStationsModule {}
