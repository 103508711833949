import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Admin, AdminService } from 'src/app/core/admin.service';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { HiddenStationsAdminRemoveConfirmDialogComponent } from '../hidden-stations-admin-remove-confirm-dialog/hidden-stations-admin-remove-confirm-dialog.component';
import { ErrorService } from 'src/app/core/error.service';

@Component({
	selector: 'app-hidden-stations-admin-add-dialog',
	templateUrl: './hidden-stations-admin-add-dialog.component.html',
	styleUrls: ['./hidden-stations-admin-add-dialog.component.scss'],
})
export class HiddenStationsAdminAddDialogComponent implements OnInit {
	form_group: FormGroup = this.form_service.set_superhost_form_group;

	super_admins?: Admin[];

	is_list_running = false;
	is_save_running = false;

	constructor(
		private form_service: FormService,
		private admin_service: AdminService,
		private dialog_service: DialogService,
		private error_service: ErrorService,
		private dialog_ref: MatDialogRef<HiddenStationsAdminAddDialogComponent>,
	) {}

	ngOnInit(): void {
		this.listAdmins();
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onConfirmClick(): void {
		if (this.form_service.validateForm(this.form_group)) {
			const email: string = this.form_group.get('email')?.value;
			this.addAdmin(email);
		}
	}

	onRemoveClick(admin: Admin): void {
		this.dialog_service
			.openDialog(HiddenStationsAdminRemoveConfirmDialogComponent, {
				email: admin.email,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result?.confirm) {
					this.removeAdmin(admin.id);
				}
			});
	}

	listAdmins(): void {
		this.is_list_running = true;
		this.admin_service.listSuperAdmins().subscribe({
			next: response => {
				response.body.json().then(response => {
					this.super_admins = response;
					this.is_list_running = false;
				});
			},
			error: () => {
				this.super_admins = [];
				this.is_list_running = false;
			},
		});
	}

	addAdmin(email: string): void {
		this.is_save_running = true;
		this.admin_service.setAsSuperAdmin(email).subscribe({
			next: () => {
				this.dialog_ref.close({ success: true });
				this.is_save_running = false;
			},
			error: error => {
				const converted_error = this.error_service.convertError(error);
				this.dialog_ref.close({ error_message: converted_error.message });
				this.is_save_running = false;
			},
		});
	}

	removeAdmin(admins_id: string): void {
		this.is_list_running = true;
		this.admin_service.removeAsSuperAdmin(admins_id).subscribe({
			next: () => {
				this.listAdmins();
			},
			error: error => {
				const converted_error = this.error_service.convertError(error);
				this.dialog_ref.close({ error_message: converted_error.message });
				this.is_list_running = false;
			},
		});
	}
}
