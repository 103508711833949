import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from 'src/app/core/dialog.service';
import { Station } from 'src/app/core/station.service';
import { StationDeleteDialogComponent } from '../station-delete-dialog/station-delete-dialog.component';
import { STATION_DELETE_MESSAGE } from 'src/app/shared/translations';

@Component({
	selector: 'app-station-panel',
	templateUrl: './station-panel.component.html',
	styleUrl: './station-panel.component.scss',
})
export class StationPanelComponent {
	@Input() station?: Station;
	@Input() logo?: string;
	@Output() station_build: EventEmitter<void> = new EventEmitter<void>();
	@Output() station_delete: EventEmitter<void> = new EventEmitter<void>();

	constructor(private dialog_service: DialogService) {}

	onUpdateClick(): void {
		this.station_build.emit();
	}

	onDeleteClick(): void {
		this.dialog_service
			.openDialog(StationDeleteDialogComponent, {
				station: this.station,
			})
			.afterClosed()
			.subscribe(dialog_result => {
				if (dialog_result && dialog_result.success) {
					this.dialog_service.openInfoDialog(STATION_DELETE_MESSAGE);
					this.station_delete.emit();
				} else if (dialog_result && dialog_result.error_message) {
					this.dialog_service.openInfoDialog(dialog_result.error_message);
				}
			});
	}
}
