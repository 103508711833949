<app-loading
	*ngIf="is_links_request_running"
	class="lk-vehicle-details-links__loading lk-display__block lk-height__full lk-border-radius lk-shadow"></app-loading>

<div
	*ngIf="!is_links_request_running"
	class="lk-height__full lk-border-radius lk-shadow lk-padding__x24 lk-primary-background">
	<h3 class="lk-text__24">
		{{ 'VEHICLE_DETAILS.SENT_LINKS_LABEL' | translate }}
	</h3>

	<app-button
		class="lk-width__full lk-margin__x12-top lk-display__block"
		[button_class]="'lk-button__secondary-border lk-width__full'"
		[spinner_class]="'lk-button__spinner-accent'"
		[icon_class]="'lk-accent-color'"
		[icon]="'add'"
		[cta]="'VEHICLE_DETAILS.DIRECT_LINK_CTA' | translate"
		(button_click)="onDirectLinkClick(vehicle)"></app-button>

	<div
		*ngIf="!is_links_request_running && links?.length"
		class="lk-vehicle-details-links__list lk-margin__x24-top">
		<div
			*ngFor="let link of links"
			class="lk-row__space lk-margin__x12-bottom">
			<div class="lk-row lk-row__align-start">
				<div class="lk-column">
					<span
						*ngIf="getLinkType(link) != ''"
						class="lk-text__weight-900 lk-text__12">
						{{ getLinkType(link) | titlecase }} link
					</span>

					<span class="lk-text__12">
						{{ link.nbf | date: 'short' }} -
						{{ link.exp | date: 'short' }}
					</span>

					<a
						[href]="link.short_url"
						target="_blank">
						{{ link.short_url }}
					</a>

					<span
						class="lk-text__12"
						[ngClass]="
							link.status == 'enabled' && isLinkEditable(link)
								? 'lk-vehicle-green-color'
								: 'lk-vehicle-red-color'
						">
						<span class="lk-primary-dark-color"> Status: </span>
						{{
							(link.status == 'enabled' && isLinkEditable(link)
								? 'VEHICLE_DETAILS.LINK_STATUS_ENABLED_LABEL'
								: 'VEHICLE_DETAILS.LINK_STATUS_DISABLED_LABEL'
							)
								| translate
								| titlecase
						}}
					</span>

					<span class="lk-text__12 lk-primary-dark-color">
						{{ 'GENERAL.CREATED_AT_LABEL' | translate }}:
						{{ link.created_at | date: 'short' }}
					</span>
				</div>
			</div>

			<div>
				<button
					*ngIf="link.status == 'enabled'"
					(click)="onDisableClick(link)"
					[matTooltip]="
						(isLinkEditable(link) ? '' : 'VEHICLE_DETAILS.EXPIRED_LINK')
							| translate
					"
					mat-button
					[ngClass]="getDisabledClass(link)"
					[disabled]="!isLinkEditable(link)"
					style="padding: 0 16px !important"
					class="lk-primary-medium-background">
					<span
						*ngIf="!is_disable_request_running || link.id != disabling_link_id">
						{{ 'VEHICLE_DETAILS.DISABLE_CTA' | translate | lowercase }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_disable_request_running && link.id == disabling_link_id">
						<mat-spinner
							[diameter]="20"
							color="accent"></mat-spinner>
					</div>
				</button>

				<button
					*ngIf="link.status == 'disabled'"
					(click)="onEnableClick(link)"
					[matTooltip]="
						(isLinkEditable(link) ? '' : 'VEHICLE_DETAILS.EXPIRED_LINK')
							| translate
					"
					mat-button
					[ngClass]="getDisabledClass(link)"
					[disabled]="!isLinkEditable(link)"
					class="lk-primary-medium-background">
					<span
						*ngIf="!is_enable_request_running || link.id != enabling_link_id">
						{{ 'VEHICLE_DETAILS.ENABLE_CTA' | translate | lowercase }}
					</span>
					<div
						class="lk-spinner-layout"
						*ngIf="is_enable_request_running && link.id == enabling_link_id">
						<mat-spinner
							[diameter]="20"
							color="accent"></mat-spinner>
					</div>
				</button>
			</div>
		</div>
	</div>

	<div
		*ngIf="!links?.length"
		class="lk-padding__x24">
		<p class="lk-text__center lk-margin__x24-top">
			<i>{{ 'VEHICLE_DETAILS.NO_LINK_FOUND' | translate }}</i>
		</p>
	</div>
</div>
