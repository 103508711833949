import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../core/authentication.service';
import { NavigationService } from '../core/navigation.service';

@Injectable({
	providedIn: 'root',
})
export class CodeDeliveryDestinationGuard implements CanActivate {
	constructor(
		private auth_service: AuthenticationService,
		private navigation_service: NavigationService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const sms_delivery_destination = this.auth_service.sms_delivery_destination;
		const mfa_setup_mechanism = this.auth_service.mfa_setup_mechanism;
		if (
			mfa_setup_mechanism &&
			((mfa_setup_mechanism === 'sms' && sms_delivery_destination) ||
				mfa_setup_mechanism === 'totp')
		) {
			return true;
		} else {
			this.navigation_service.toAuthSignin();
			return false;
		}
	}
}
