import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Station } from 'src/app/core/station.service';
import { NavItem } from 'src/app/core/navigation.service';

@Component({
	selector: 'app-toolbar-station',
	templateUrl: './toolbar-station.component.html',
	styleUrl: './toolbar-station.component.scss',
})
export class ToolbarStationComponent {
	@Input() current_nav_item?: string;
	@Input() station?: Station;
	@Input() logo?: string;
	@Input() nav_items?: NavItem[];

	@Output() station_change: EventEmitter<Station> = new EventEmitter<Station>();
	@Output() no_station_found: EventEmitter<void> = new EventEmitter<void>();
	@Output() station_build: EventEmitter<void> = new EventEmitter<void>();
}
