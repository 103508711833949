import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormErrorStateMatcher, FormService } from 'src/app/core/form.service';
import { Station, StationService } from 'src/app/core/station.service';
import { DEFAULT_STATION_LOGO, PROD_STAGE } from 'src/app/shared/constants';
import { GENERAL_ERROR_MESSAGE } from 'src/app/shared/translations';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-hidden-stations-settings-dialog',
	templateUrl: './hidden-stations-settings-dialog.component.html',
	styleUrls: ['./hidden-stations-settings-dialog.component.scss'],
})
export class HiddenStationsSettingsDialogComponent {
	station?: Station;
	form_group: FormGroup = this.form_service.hidden_stations_settings_form_group;
	matcher: FormErrorStateMatcher = this.form_service.matcher;
	is_save_running: boolean = false;
	custom_logo: string = DEFAULT_STATION_LOGO;
	is_production?: boolean;

	constructor(
		private form_service: FormService,
		private station_service: StationService,
		private dialog_ref: MatDialogRef<HiddenStationsSettingsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.station = data.station;
	}

	ngOnInit(): void {
		this.is_production = environment.STAGE == PROD_STAGE;

		this.form_group
			.get('area_control')
			.setValue(this.station?.settings?.is_area_control_enabled || false);

		this.form_group
			.get('interact_after_close')
			.setValue(
				this.station?.settings?.is_interact_after_close_enabled || false,
			);

		this.form_group
			.get('parking_input')
			.setValue(this.station?.settings?.is_parking_input_enabled || false);

		this.form_group
			.get('unlock_confirmation')
			.setValue(
				this.station?.settings?.is_unlock_confirmation_enabled || false,
			);

		this.form_group
			.get('custom_logo')
			.setValue(this.station?.settings?.is_custom_logo_enabled || false);

		this.form_group
			.get('auto_close')
			.setValue(this.station?.settings?.is_auto_close_enabled || false);

		this.form_group
			.get('booking_closure_notif')
			.setValue(
				this.station?.settings?.is_booking_complete_notif_enabled || false,
			);

		this.form_group
			.get('booking_closure_custom_notif')
			.setValue(
				this.station?.settings?.is_booking_complete_custom_template_enabled ||
					false,
			);

		this.form_group
			.get('vehicle_change_custom_notif')
			.setValue(
				this.station?.settings
					?.is_booking_vehicle_changed_custom_template_enabled || false,
			);

		this.form_group
			.get('team_mgmt')
			.setValue(this.station?.settings?.is_team_management_enabled || false);

		this.form_group
			.get('sms_alias')
			.setValue(this.station?.settings?.custom_sms_sender_alias || '');

		this.form_group
			.get('email_alias')
			.setValue(this.station?.settings?.custom_email_sender_address || '');

		this.form_group
			.get('low_voltage_threshold')
			.setValue(this.station?.settings?.low_voltage_threshold || 2);

		if (this.station.settings.custom_guest_logo) {
			this.setCustomLogo(this.station.settings.custom_guest_logo);
		} else {
			this.custom_logo = DEFAULT_STATION_LOGO;
		}
	}

	onCloseClick(): void {
		this.dialog_ref.close();
	}

	onSettingsUpdateClick(): void {
		this.station.settings.is_area_control_enabled =
			this.form_group.get('area_control')?.value;
		this.station.settings.is_interact_after_close_enabled = this.form_group.get(
			'interact_after_close',
		)?.value;
		this.station.settings.is_parking_input_enabled =
			this.form_group.get('parking_input')?.value;
		this.station.settings.is_unlock_confirmation_enabled = this.form_group.get(
			'unlock_confirmation',
		)?.value;
		this.station.settings.is_custom_logo_enabled =
			this.form_group.get('custom_logo')?.value;
		this.station.settings.is_auto_close_enabled =
			this.form_group.get('auto_close')?.value;
		this.station.settings.is_booking_complete_notif_enabled =
			this.form_group.get('booking_closure_notif')?.value;
		this.station.settings.is_booking_complete_custom_template_enabled =
			this.form_group.get('booking_closure_custom_notif')?.value;
		this.station.settings.is_booking_vehicle_changed_custom_template_enabled =
			this.form_group.get('vehicle_change_custom_notif')?.value;
		this.station.settings.is_team_management_enabled =
			this.form_group.get('team_mgmt')?.value;

		this.station.settings.low_voltage_threshold = this.form_group.get(
			'low_voltage_threshold',
		)?.value;

		if (
			this.form_group.get('sms_alias')?.value &&
			this.form_group.get('sms_alias')?.value != '' &&
			this.form_group.get('sms_alias')?.value != ' '
		) {
			this.station.settings.custom_sms_sender_alias =
				this.form_group.get('sms_alias')?.value;
		} else {
			this.station.settings.custom_sms_sender_alias = undefined;
		}

		if (
			this.form_group.get('email_alias')?.value &&
			this.form_group.get('email_alias')?.value != '' &&
			this.form_group.get('email_alias')?.value != ' '
		) {
			this.station.settings.custom_email_sender_address =
				this.form_group.get('email_alias')?.value;
		} else {
			this.station.settings.custom_email_sender_address = undefined;
		}

		this.updateStation(this.station);
	}

	onLogoUpdateClick(event: any): void {
		const file: File = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = _event => {
			const image: string | ArrayBuffer = reader.result;
			this.custom_logo = null;
			this.station_service
				.setCustomLogo(this.station.id, image.toString())
				.subscribe({
					next: response => {
						this.station = response.data as Station;
						this.setCustomLogo(this.station.settings.custom_guest_logo);
					},
					error: error => {
						this.custom_logo = DEFAULT_STATION_LOGO;
					},
				});
		};
	}

	updateStation(station: Station): void {
		this.is_save_running = true;
		this.station_service.update(station).subscribe({
			next: () => {
				this.dialog_ref.close({ success: true });
				this.is_save_running = false;
			},
			error: () => {
				this.is_save_running = false;
				this.dialog_ref.close({ error_message: GENERAL_ERROR_MESSAGE });
			},
		});
	}

	isFormValid(): boolean {
		return this.form_service.validateForm(this.form_group);
	}

	isCustomLogoEnabled(): boolean {
		return this.form_group.get('custom_logo')?.value;
	}

	setCustomLogo(custom_logo: string): void {
		let base_path: string;
		const is_production = environment.STAGE == PROD_STAGE;
		if (is_production) {
			base_path = 'linkey-stations-bucket-prod';
		} else if (environment.API_BASE_PATH.includes('mcattaneo')) {
			base_path = 'linkey-stations-bucket-mcattaneo';
		} else if (environment.API_BASE_PATH.includes('gmarino')) {
			base_path = 'linkey-stations-bucket-gmarino';
		} else {
			base_path = 'linkey-stations-bucket-dev';
		}
		this.custom_logo = `https://${base_path}.s3.amazonaws.com/${custom_logo}`;
	}
}
